export default {
    INSTRUMENTATION_KEY_DEV: '99c74b58-2f4f-439b-87e1-995278363e74',
    INSTRUMENTATION_KEY_PROD: 'a9b7b974-2d4a-44ce-81d2-4ac29d5ef199',
    SEVERITY_LEVEL: {
        VERBOSE: 0,
        INFORMATION: 1,
        WARNING: 2,
        ERROR: 3,
        CRITICAL: 4
    }
}