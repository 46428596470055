<template>
  <form @submit.prevent="Submit(nextPage)">
    <h1>What is your date of birth?</h1>

    <InputDateRefined
      :id="idConcat(pageName,'DoB')"
      label="Date of birth"
      name="form.dateOfBirth"
      nameDay="dobDay"
      nameMonth="dobMonth"
      nameYear="dobYear"
      :valueDay="parseInt(day)"
      :valueMonth="parseInt(month)"
      :valueYear="parseInt(year)"
      autocomplete="bday"
      v-model.trim="$v.dob.$model"
      :v="$v.dob"
      :minDate="minDOB"
      :maxDate="maxDOB"
      :idDay="idConcat(pageName,'Day')"
      :idMonth="idConcat(pageName,'Month')"
      :idYear="idConcat(pageName,'Year')"
      :sensitive-data="true"
    />

    <ButtonContinue :id="idConcat(pageName,'ContinueBtn')" />

    <ButtonBack :id="idConcat(pageName,'BackBtn')" />
    <InfoFooter :showTopOnly="true" />
  </form>
</template>

<script>
import { required, between} from 'vuelidate/lib/validators'
import {LocationHelper, ReorderHelper, EmploymentReOrderHelper} from '@/helpers';
import {AFFILIATES} from '@/factories';
import { SENSITIVE_DATA } from '../const';
import {AnalyticsService } from '../services';

let maxDOB, minDOB, minYear, maxYear

setMinMaxDOB()

function setMinMaxDOB() {
    let today = new Date()
    let day = today.getDate()
    let month = today.getMonth()
    let year = today.getFullYear() - 18
    let date = new Date(year, month, day)
    maxDOB = date
    maxYear = year

    day = today.getDate() + 1
    year = today.getFullYear() - 81
    date = new Date(year, month, day)
    minDOB = date
    minYear = year
}

function validateYear() {
  let host = LocationHelper.getHost();
  if (AFFILIATES[host] !== undefined) {
    if (this.year >= minYear && this.year <= maxYear) {
      return true;
    }
    return false;
  } else {

    if (this.year <= maxYear) {
      return true;
    }
    return false;
  }
}

function validateDob() {
  let host = LocationHelper.getHost();

  var hostCheck = ['carcraft', 'social'];
  if ( hostCheck.indexOf(host) !== -1) {
    let today = new Date()
    let day = today.getDate()
    let month = today.getMonth()
    let year = today.getFullYear() - 25
    let date = new Date(year, month, day)
    maxDOB = date
    maxYear = year
  }

  if (AFFILIATES[host] !== undefined) {
    if (this.dob >= minDOB && this.dob <= maxDOB) {
      return true;
    }
    return false;
  } else {
    if (this.dob <= maxDOB) {
      return true;
    }
    return false;
  }
}

export default {
  data() {
    return {
      pageName: 'DateOfBirth',
      email: this.$store.state.form.email,
      phone: this.$store.state.form.contactNumber,
      dob: this.$store.state.form.dateOfBirth,
      maxDOB: maxDOB,
      minDOB: minDOB,
      minYear: minYear,
      maxYear: maxYear,
      SENSITIVE_DATA: SENSITIVE_DATA,
      pageViewSubmitted: false,
      isCKDirectReorder: LocationHelper.getHost() === 'ckdirect',
    }
  },
  computed:{
    isSocialRecorder() {
      const host = ['social','ecrm', 'tiktok', 'social-premier', 'social-vans', 'social-bad-credit'];

      return host.indexOf(LocationHelper.getHost()) !== -1;
    },
    isEmploymentReOrder(){
      return EmploymentReOrderHelper.isEmploymentReOrder();
    },
    nextPage() {
        if (this.isEmploymentReOrder || this.isCKDirectReorder || this.isSocialRecorder) {
          return { name: 'address-add', params: { number: 1 } }
        }

        if( ReorderHelper.isReorderMulti(['cargurus', 'pistonheads']) || ReorderHelper.isGenericReorder()) {
          let result = ReorderHelper.getNextRoute('generic', this.$router.currentRoute.name );
          if(Object.keys(result).length> 0 ){
            return result;
          } 
        }

        return { name: 'employment' , params: { number: 1 } }
    },
    day(){
      return this.$store.state.dobDay
    },
    month(){
      return this.$store.state.dobMonth
    },
    year(){
      return this.$store.state.dobYear
    }
  },
  validations: {
      dob: {
        required,
        validateDob
      },
      day: {
        required,
        between: between(1, 31)
      },
      month: {
        required,
        between: between(1, 12)
      },
      year: {
        required,
        validateYear
      }
  },

  mounted() {
    this.$store.dispatch('updateFormValue', [this.pageName, 'pageName']);

    this.$store.dispatch('updateFormValue', [true, 'form.maritalStatusCompleted'])

    if (!this.pageViewSubmitted) {
      this.pageViewSubmitted = true;

      AnalyticsService.trackPageView({
        page_name: 'date-of-birth'
      });
    }
  }
}
</script>
