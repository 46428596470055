<template>
  <div >
    <Generic_Holding_page  />
  </div>
</template>

<script>
  import Generic_Holding_page from '@/views/Affiliates/HoldingPage/Generic_Holding_Page.vue';

  export default {
    name: 'Holding_Page',
    components: {
      Generic_Holding_page,
    },
    data() {
      return {

      }
    },
    mounted() {

    },
    methods: {
 
    }
  }
</script>

<style lang="scss" scoped>
  .header-container {
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
  }
</style>