<template>
  <img alt="CarFinance247 Logo" height="45" v-lazy="imgUrl" />
</template>

<script>
export default {
  name: "OceanFinanceLogo",
    data () {
    return {
      imgUrl:  require('@/assets/img/affiliates/oceanfinance.png')
    }
  }
}
</script>

<style scoped>

</style>