import {TealiumService} from '@/services/index';
import {AFFILIATES} from '@/factories';
import {LocationHelper} from '@/helpers';

const host = LocationHelper.getHost();

function isAutomaticTesting() {
    if (AFFILIATES[host] !== undefined) {
        const affiliateConfig = AFFILIATES[host];

        if (affiliateConfig && typeof affiliateConfig.allowAutomaticTesting !== "undefined") {
            return affiliateConfig.allowAutomaticTesting && navigator.webdriver;
        }
    }

    return false;
}

export default {
    setData(key, value, hash = false) {
        if (TealiumService.isTealiumEnabled()) {
            TealiumService.setData(key, value, hash);
        }
    },
    trackPageView(obj, delay = 0) {
        if (TealiumService.isTealiumEnabled()) {
            TealiumService.trackPageView(obj, delay);
        }
    },
    trackEvent(obj, delegateFn = null, delay = 0, forceDelegate = false) {
        let trigger = new Promise((resolve) => {
            resolve();
        });

        trigger.then(() => {
            if (TealiumService.isTealiumEnabled()) {
                return TealiumService.trackEvent(obj, delay);
            }
        }).finally(() => {
            if (delegateFn && forceDelegate) {
                delegateFn();
            } else if (delegateFn && !isAutomaticTesting()) {
                delegateFn();
            }
        });
    }
}