
function getDomainName()
{
    try {
        return window.location.hostname.match(/^(?:.*?\.)?([a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/)[1];
    } catch(e) {
        return '';
    }
}

export default {
    get(cname) {
        let name = cname + '=';
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    },
    setCookie(name, value, days) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }

        const domainName = getDomainName();

        if (domainName) {
            document.cookie = name + "=" + (value || "")  + expires + ";domain=.carfinance247.co.uk;path=/";
        } else {
            document.cookie = name + "=" + (value || "")  + expires + ";path=/";
        }
    },
    getCookieValue(cookieValue, cname) {
        let name = cname + '=';
        let decodedCookie = decodeURIComponent(cookieValue);
        let ca = decodedCookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}