<template>
    <form @submit.prevent="Submit(nextPage())">
        <h1>Could you provide us your bank details?</h1>

        <InputText
            :id="idConcat(pageName, 'BankName')"
            :name="'form.banks[0].bankName'"
            label="Your bank name"
            invalidMessage="Please tell us what is the name of your bank."
            v-model="$v.bankName.$model"
            :v="$v.bankName"
            :sensitive-data="true"
        />

        <InputText
            :id="idConcat(pageName, 'AccountName')"
            :name="'form.banks[0].accountName'"
            label="Your account name"
            invalidMessage="Please tell us what is the name of your account."
            v-model="$v.accountName.$model"
            :v="$v.accountName"
            :sensitive-data="true"
        />

        <InputMaskText
            :id="idConcat(pageName, 'SortCode')"
            :name="'form.banks[0].sortCode'"
            label="Your sort code"
            invalidMessage="Please tell us what is your account sort code."
            v-model="$v.sortCode.$model"
            :v="$v.sortCode"
            :sensitive-data="true"
            mask="##-##-##"
        />

        <InputMaskText
            :id="idConcat(pageName, 'AccountNumber')"
            :name="'form.banks[0].accountNumber'"
            label="Your account number"
            invalidMessage="Please tell us what is your account number."
            v-model="$v.accountNumber.$model"
            :v="$v.accountNumber"
            :sensitive-data="true"
            mask="########"
        />

        <ButtonContinue :id="idConcat(pageName, 'ContinueBtn')" />

        <ButtonBack :id="idConcat(pageName, 'BackBtn')" />
        
        <InfoFooter :showTopOnly="true" />
    </form>
</template>

<script>
import {required} from 'vuelidate/lib/validators';

const validate_uk_sort_code = (value) => {
  // validate uk sort code
  let regExp = new RegExp('^[0-9]{2}-[0-9]{2}-[0-9]{2}$');
  return regExp.test(value);
}

const validate_uk_account_number = (value) => {
  // validate uk account number
  let regExp = new RegExp('^[0-9]{8}$');
  return regExp.test(value);
}

export default {
  data() {
      return {
        pageName: 'BankDetail',
        bankName: this.$store.state.form.banks[0].bankName,
        accountName: this.$store.state.form.banks[0].accountName,
        sortCode: this.$store.state.form.banks[0].sortCode,
        accountNumber: this.$store.state.form.banks[0].accountNumber,
      }
  },
  computed: {

  },
  validations() {
    return {
      bankName: { required },
      accountName: { required },
      sortCode: { required, validate_uk_sort_code },
      accountNumber: { required, validate_uk_account_number },
    }
  },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);

    this.$store.dispatch('updateFormValue', [true, 'form.bankDetailsCompleted'])
  },
  methods: {
    nextPage: function() {
      return { name: 'bank-length' };
    }
  }
}
</script>
