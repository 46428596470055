import {CookieHelper, LocationHelper, RequestHelper} from '@/helpers/index';
import {AFFILIATES, DEALERS} from '@/factories';

const UTM_COOKIE_NAME = 'cf247-utm';

function isHostInObject(host, obj)  {
    return obj[host] !== undefined;
}

function isUtmMediumValid(value) {
    if (RequestHelper.getQueryVariable('utm_medium') === value) {
        return true;
    }

    if (document.cookie.split(';').filter((item) => item.trim().startsWith('cf247-utm=')).length) {
        const utmCookie = CookieHelper.get(UTM_COOKIE_NAME);
        const cookie = JSON.parse(utmCookie);

        if (cookie['UtmMedium'] && cookie['UtmMedium'] === value) {
            return true;
        }
    }

    return false;
}

export default {
    isEmploymentReOrder: () => {
        let host = LocationHelper.getHost();

        return !(isHostInObject(host, AFFILIATES) || isHostInObject(host, DEALERS) ||
            isUtmMediumValid('cpc') || isUtmMediumValid('display') || isUtmMediumValid('paidsocial'));
    }
}