<template>
  <form @submit.prevent="Submit({ name: 'driving-licence' })">

    <InputGroup
        id="submission-type"
        name="form.submissionTypeId"
        :label="getLabel"
        :items="[
        {label: 'Finance my next car', value: 0, canCoutinue: canCoutinue(), id: idConcat(pageName, 'Finance') },
        {label: 'Refinance my current car', value: 1, canCoutinue: canCoutinue(), id: idConcat(pageName, 'Refinance') }
      ]"
        v-model="$v.submissionType.$model"
        :v="$v.submissionType"
        :submitFunction="continueBtnClick"
    />

    <div v-if="isStartPage">
      <ButtonContinue :id="idConcat(pageName,'ContinueBtn')" v-if="isClassifyPartner" :visible="visibleCoutinueButton()" :sub-label="$t('page.finance_details.button_continue.sub_label')">{{ isClassifyPartner ? 'Continue' : $t('page.finance_details.button_continue.inner_text') }}</ButtonContinue>
      <IconButtonContinue v-else :id="idConcat(pageName,'IconContinueBtn')" :visible="visibleCoutinueButton()" :sub-label="$t('page.finance_details.button_continue.sub_label')" label="Get my quote" />

      <ButtonBack :id="idConcat(pageName,'BackBtn')" v-show="showStartPageBackButton" :is-call-back-handle="true" @callback="startPageBackButtonCallBack" />

      <RepExample v-if="isRepExampleAbovePrivacyBanner"></RepExample>
      <div style="font-size: 0.9em; text-align: center;" v-html="$t('page.finance_details.message.label')"></div>
      <PrivacyBanner :id="idConcat(pageName,'PrivacyBannerHref')" :label="isAffiliate && !isCarcraft ? 'By starting your quote you\'re agreeing to Car Finance 247\'s' : $t('page.finance_details.privacy_banner.label')" />
      <div style="font-size: 0.9em; text-align: center;">
        {{ $t('page.finance_details.trade.label') }}
      </div>
      <PowerByCf247Text v-if="!testB" />
      <RepExample v-if="!isRepExampleAbovePrivacyBanner"></RepExample>
      <DisclosureStatement/>
    </div>
    <div v-else>
      <ButtonContinue :id="idConcat(pageName,'ContinueBtn')" :visible="visibleCoutinueButton()" />
      <ButtonBack :id="idConcat(pageName,'BackBtn')" v-show="!isAffiliatePersonalised && !isIncludeVehicle" />
      <div v-show="isAffiliatePersonalised">
        <RepExample v-if="isRepExampleAbovePrivacyBanner"></RepExample>
        <div style="font-size: 0.9em; text-align: center;" v-html="$t('page.finance_details.message.label')"></div>
        <PrivacyBanner :label="isAffiliate && !isCarcraft ? 'By starting your quote you\'re agreeing to Car Finance 247\'s' : $t('page.finance_details.privacy_banner.label')" />
        <div style="font-size: 0.9em; text-align: center;">
          {{ $t('page.finance_details.trade.label') }}
        </div>
        <PowerByCf247Text v-if="!testB" />
        <RepExample v-if="!isRepExampleAbovePrivacyBanner"></RepExample>
        <DisclosureStatement/>
      </div>
    </div>

    <InfoFooter :showTopOnly="isStartPage"  />
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {AffiliatesHelper, InterstitialHelper, LocationHelper, RequestHelper} from '@/helpers';
import { AFFILIATES } from '@/factories';
import PowerByCf247Text from '@/components/PowerByCf247Text';
import DisclosureStatement from '@/components/DisclosureStatement.vue';
import RepExample from '@/components/RepExample.vue';
import IconButtonContinue from '@/components/IconButtonContinue.vue';
import {AnalyticsService} from '@/services';

export default {
  components: {
    IconButtonContinue,
    DisclosureStatement,
    RepExample,
    PowerByCf247Text,
  },
  data() {
    return {
      pageName: 'SubmissionType',
      isStartPage: false,
      testB: false,
      isCompareTheMarket: LocationHelper.getHost() === 'comparethemarketcarfinance',
      isCarcraft: LocationHelper.getHost().startsWith('carcraft'),
      skipFirstPage: RequestHelper.getQueryVariable('test') === 'skip-first-page' || LocationHelper.getHost().startsWith('ckdirect'),
      pageViewSubmitted: false,
      isRepExampleAbovePrivacyBanner: LocationHelper.getHost() === 'comparethemarketcarfinance'
    }
  },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);
    
    if( this.$store.state.fromGetMyQuote ) {
      this.isStartPage = false ;
    } else {
      let host = LocationHelper.getHost();
      let vehicle_type = RequestHelper.getQueryVariable('vehicle_type');
      if (vehicle_type) {
        this.isStartPage = true;
      }
      if (AFFILIATES[host] !== undefined) {
        this.isStartPage = false;

        if (this.isCompareTheMarket || this.isCarcraft) {
          this.isStartPage = true;
        }

        this.testB = AFFILIATES[host].testB ?? false;
      }

      let personalised = RequestHelper.getQueryVariable('personalised');

      if (personalised && AFFILIATES[host] !== undefined) {
        this.isStartPage = false;
      }
    }

    if (this.skipFirstPage) {
      this.isStartPage = true;
    }

    this.$store.dispatch('updateFormValue', [true, 'form.financeDetailsCompleted'])
    this.$store.dispatch('updateFormValue', [false, 'form.submissionTypeCompleted'])

    if (!this.pageViewSubmitted) {
      this.pageViewSubmitted = true;
      AnalyticsService.trackPageView({
        page_name: 'submission-type'
      });
    }
  },
  computed: {
    isAffiliate() {
      return AffiliatesHelper.isAffiliate(AffiliatesHelper.getAffiliateWithLanguageFile()) || false;
    },
    showStartPageBackButton() {
      let host = LocationHelper.getHost();

      if (AFFILIATES[host] !== undefined) {
        const affiliateConfig = AFFILIATES[host];

        const showInterstitial = InterstitialHelper.showInterstitial(affiliateConfig);

        if (showInterstitial) {
          return false;
        }
      }

      return false;
    },
    submissionType: {
      get: function() {
        return this.$store.getters.any('form.submissionTypeId') || 0
      },
      set: function() {
      }
    },
    isClassifyPartner() {
      let host = LocationHelper.getHost();
      let vrm = RequestHelper.getQueryVariable('vrm');
      let advertId = RequestHelper.getQueryVariable('advertId');
      let vehicleId = RequestHelper.getQueryVariable('vehicleId');

      if (AFFILIATES[host] !== undefined){
        const affiliateConfig = AFFILIATES[host];

        if (affiliateConfig.classifiedPartner && (affiliateConfig.showPartnerModal || vrm || advertId || vehicleId)) {
          return true;
        }
      }

      return false;
    },
    getLabel() {
      let label = 'What would you like a quote for?';

      return label;
    },
    isAffiliatePersonalised() {
      let host = LocationHelper.getHost();
      return RequestHelper.getQueryVariable('personalised') && AFFILIATES[host] !== undefined;
    },
    isNonAffiliatePersonalised() {
      let host = LocationHelper.getHost();
      return RequestHelper.getQueryVariable('personalised') && AFFILIATES[host] === undefined;
    },
    isIncludeVehicle() {
      let vehicleid = RequestHelper.getQueryVariable('vehicleid');
      let vrm = RequestHelper.getQueryVariable('vrm');
      let advertId = RequestHelper.getQueryVariable('advertId');
      let vehicleType = RequestHelper.getQueryVariable('vehicle_type');

      if (vehicleid || vrm || advertId || vehicleType) {
        return true;
      }

      return false;
    },
  },
  methods: {
    startPageBackButtonCallBack() {
      document.location.href= "/" + window.location.search;
    },
    continueBtnClick: function() {
      setTimeout(()=> {
        this.Submit({ name: 'driving-licence' })
      },100)
    }
  },
  validations() {
      return {
        submissionType: { required },
      }
    }
}
</script>

