import {CookieHelper, HashHelper, RequestHelper, StringHelper} from '../helpers';
import { VEHICLE_TYPE } from '../const';

export default {
    checkSegmentLoaded() {
        return new Promise((resolve, reject) => {
			setTimeout(()=>{
				if(segmentCheckingInterval) {
					clearInterval(segmentCheckingInterval);
					window.segmentLoaded = false;
                    reject(false);
				}
			}, 60000);
            var segmentCheckingInterval = setInterval(() => {
                if ( window.analytics ) {
                    clearInterval(segmentCheckingInterval);
                    segmentCheckingInterval = null;
                    window.segmentLoaded = true;
                    resolve(true);
                }
            }, 500);
          });
    },
    loadSegment(isProduction) {
        if (!isProduction) {
            window.analytics.load('lpoFRMifdbFdurOPEBJjD5bTbJpgttW3', {
                integrations: {
                    'All': true,
                    'Braze': true,
                    'Braze Cloud Mode (Actions)': true,
                    'Braze Web Device Mode (Actions)': true,
                    'Google Analytics 4 Cloud': true,
                    'Google Analytics 4 Web': true,
                    'Webhooks': true,
                    'Criteo': true,
                    'Bing Ads': true,
                    'CM360 Offline Conv - VBB - Appr - Dealt (Personas - Prod)': true,
                    'CM360 Offline Conv - VBB - Est. Rev (Personas - Prod)': true,
                    'CM360 Offline Conversions - v2 - Prod (Personas - Prod)': true,
                    'Facebook Conversions API (Actions)': true,
                    'Facebook Pixel': true,
                    'Google Ads Conversions': true,
                    'Google Ads Remarketing Lists': true,
                    'Google AdWords New': true,
                    'Lead Broker Declined list (Personas - Prod)': true,
                    'Personas Facebook Custom Audiences': true,
                    'TikTok Conversions': true
                }
            });
        }
    },
    trackApplicationFormStart(obj) {
        if (window.analytics) {
            let trackData = {
                "loan_submission_guid": obj.guid
            };

            trackData = {};

            trackData.vehicle_type = obj.vehicleType ? obj.vehicleType.toLowerCase() : "car";

            const context = {
                consent: {
                    categoryPreferences: {
                        analytics: CookieHelper.getCookieValue('cookieyes-analytics') === 'yes' || false,
                        advertising: CookieHelper.getCookieValue('cookieyes-advertisement') === 'yes' || false,
                        functional: CookieHelper.getCookieValue('cookieyes-functional') === 'yes' || false,
                        performance: CookieHelper.getCookieValue('cookieyes-performance') === 'yes' || false,
                        necessary: CookieHelper.getCookieValue('cookieyes-performance') === 'yes' || true
                    }
                }
            }

            window.analytics.page("application", "Application Form Started", trackData, context);
        }
    },
    trackPage(state, guid, pageName) {
        if (window.analytics && window.segmentLoaded ) {
            let eventName = "Application Page Change to " + pageName;

            let trackData = {
                "loan_submission_guid": guid
            };


            eventName = "Application Page Change";

            trackData = {};

            if (pageName) {
                trackData.loan_application_step = pageName.toLowerCase();

                if (state?.form?.utmParameters) {
                    trackData.gclid = state.form.utmParameters.gclid || '';
                    trackData.fbclid = state.form.utmParameters.fbclid || '';
                    trackData.utm_type = state.form.utmParameters.type || '';
                }
            
                const queryVariables = ['ttclid', 'msclkid', 'wbraid', 'gbraid'];
                queryVariables.forEach(variable => {
                    trackData[variable] = RequestHelper.getQueryVariable(variable) || '';
                });
            }
 
            const context = {
                consent: {
                    categoryPreferences: {
                        analytics: CookieHelper.getCookieValue('cookieyes-analytics') === 'yes' || false,
                        advertising: CookieHelper.getCookieValue('cookieyes-advertisement') === 'yes' || false,
                        functional: CookieHelper.getCookieValue('cookieyes-functional') === 'yes' || false,
                        performance: CookieHelper.getCookieValue('cookieyes-performance') === 'yes' || false,
                        necessary: CookieHelper.getCookieValue('cookieyes-performance') === 'yes' || true
                    }
                }
            }

            window.analytics.page("application", eventName, trackData, context);
        }
    },
    trackPersonalDetailsCompletedEvent(state, obj) {
        let eventName = "Personal Details Completed";
        let tracksData = {};

        const fbp = CookieHelper.get('_fbp');

        if (fbp) {
            tracksData.fb_browser_id = fbp.split('.').slice(-2).join(".");
        }

        const ga = CookieHelper.get('_ga');

        if (ga) {
            tracksData.ga_client_id = ga.split('.').slice(-2).join(".");
        }

        tracksData.loan_submission_guid = obj.guid;

        const email_address = StringHelper.toLowerCaseAndTrim(obj.email);

        tracksData.date_of_birth = obj.date_of_birth;
        tracksData.email_address = email_address;
        tracksData.email = email_address;
        tracksData.first_name = StringHelper.toLowerCaseAndTrim(obj.first_name);
        tracksData.last_name = StringHelper.toLowerCaseAndTrim(obj.last_name);
        tracksData.phone_number_normalised = obj.phone_number_normalised;
        tracksData.postcode = StringHelper.toLowerCaseAndTrimAndRemoveAllSpaces(obj.postcode);

        var gclid = '';

        if (obj && obj.utm) {
            tracksData.agentPublicId = StringHelper.toLowerCaseAndTrim(obj.utm.agentPublicId);
            tracksData.utm_campaign = StringHelper.toLowerCaseAndTrim(obj.utm.campaign);
            tracksData.utm_content = StringHelper.toLowerCaseAndTrim(obj.utm.content);
            tracksData.utm_medium = StringHelper.toLowerCaseAndTrim(obj.utm.medium);
            tracksData.mkwid = StringHelper.toLowerCaseAndTrim(obj.utm.mkwid);
            tracksData.rai = StringHelper.toLowerCaseAndTrim(obj.utm.referrerApplicationId);
            tracksData.utm_sid = StringHelper.toLowerCaseAndTrim(obj.utm.sid);
            tracksData.utm_source = StringHelper.toLowerCaseAndTrim(obj.utm.source);
            tracksData.utm_term = StringHelper.toLowerCaseAndTrim(obj.utm.term);
            tracksData.utm_type = StringHelper.toLowerCaseAndTrim(obj.utm.type);
            tracksData.cid = StringHelper.toLowerCaseAndTrim(obj.utm.cid);

            gclid = obj.utm.gclid;
        }

        tracksData.net_monthly_income = Number(obj.net_monthly_income);
        tracksData.city = StringHelper.toLowerCaseAndTrim(obj.city);
        tracksData.gender = StringHelper.toLowerCaseAndTrim(obj.gender);

        const vehicle_type = Object.keys(VEHICLE_TYPE).find(key => VEHICLE_TYPE[key] === obj.vehicle_type_id);

        if (vehicle_type) {
            tracksData.vehicle_type =  StringHelper.toLowerCaseAndTrim(vehicle_type);
        }

        const submission_hostname = state.subdomain ? StringHelper.toLowerCaseAndTrim(state.subdomain + ".carfinance247.co.uk") : StringHelper.toLowerCaseAndTrim(window.location.host);

        tracksData.submission_hostname = submission_hostname;

        eventName = "Loan Application Submitted";
        tracksData = {};

        tracksData.email = email_address;
        tracksData.loan_submission_guid = obj.guid;
        tracksData.phone_number_e164 = obj.phone_number_normalised;
        tracksData.submission_hostname = submission_hostname;

        tracksData.vehicle_type = vehicle_type ? StringHelper.toLowerCaseAndTrim(vehicle_type) : '';
        
        if (window.analytics) {
            const context = {
                consent: {
                    categoryPreferences: {
                        analytics: CookieHelper.getCookieValue('cookieyes-analytics') === 'yes' || false,
                        advertising: CookieHelper.getCookieValue('cookieyes-advertisement') === 'yes' || false,
                        functional: CookieHelper.getCookieValue('cookieyes-functional') === 'yes' || false,
                        performance: CookieHelper.getCookieValue('cookieyes-performance') === 'yes' || false,
                        necessary: CookieHelper.getCookieValue('cookieyes-performance') === 'yes' || true
                    }
                }
            }


            window.analytics.track(eventName, tracksData, context);

            const id = email_address;

            HashHelper.sha256(id).then(hash_result => {
                let identifyObj = {
                    user_id: hash_result,
                    date_of_birth: obj.date_of_birth,
                    email_address: email_address,
                    email: email_address,
                    first_name: StringHelper.toLowerCaseAndTrim(obj.first_name),
                    last_name: StringHelper.toLowerCaseAndTrim(obj.last_name),
                    phone_number_normalised: obj.phone_number_normalised,
                    postcode: StringHelper.toLowerCaseAndTrimAndRemoveAllSpaces(obj.postcode),
                    submission_hostname: submission_hostname,
                    _gclid: gclid
                }

                let utmData;

                // Extract UTM parameters from state
                if (state && state.form && state.form.utmParameters) {
                    utmData.gclid = state.form.utmParameters.gclid;
                    utmData.fbclid = state.form.utmParameters.fbclid;
                }

                // Extract additional parameters from query variables
                const queryParams = ['ttclid', 'msclkid', 'wbraid', 'gbraid'];
                queryParams.forEach(param => {
                    const value = RequestHelper.getQueryVariable(param);
                    if (value) {
                        utmData[param] = value;
                    }
                });

                if (obj && obj.utm) {
                    const utmFields = [
                        'agentPublicId', 'campaign', 'content', 'medium', 'mkwid', 
                        'referrerApplicationId', 'sid', 'source', 'term', 'type', 'cid'
                    ];
                
                    utmFields.forEach(field => {
                        const key = `utm_${field}`;
                        utmData[key] = StringHelper.toLowerCaseAndTrim(obj.utm[field]);
                    });
                }


                identifyObj = {
                    email: email_address,
                    phone: obj.phone_number,
                    phone_number_e164: obj.phone_number_normalised,
                    loan_application_status: 'submitted',
                    cust_last_campaign_content: utmData.utm_content || '',
                    cust_last_campaign_medium: utmData. utmData.utm_medium || '',
                    cust_last_campaign_name: utmData.utm_campaign || '',
                    cust_last_campaign_source: utmData.utm_source || '',
                    cust_last_campaign_term: utmData.utm_term || '',
                    cust_last_campaign_fbclid: utmData.fbclid || '',
                    cust_last_campaign_gbraid: utmData.gbraid || '',
                    cust_last_campaign_gclid: utmData.gclid || '',
                    cust_last_campaign_msclkid: utmData.msclkid || '',
                    cust_last_campaign_ttclid: utmData.ttclid || '',
                    cust_last_campaign_wbraid: utmData.wbraid || '',
                    cust_last_loan_application_step: 'contact-details-p2',
                    cust_last_loan_application_submission_hostname: state.subdomain ? StringHelper.toLowerCaseAndTrim(state.subdomain + ".carfinance247.co.uk") : StringHelper.toLowerCaseAndTrim(window.location.host),
                    cust_last_loan_application_loan_submission_guid: obj.guid || '',
                }

                window.analytics.identify(hash_result, identifyObj);
            });
        }

        if (window.dataLayer) {
            let gaTrackData = Object.assign({}, tracksData);

            gaTrackData.category = "application";
            gaTrackData.event = eventName;

            window.dataLayer.push(gaTrackData);
        }
    }
}