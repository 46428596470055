<template>
  <div class="fraud">
    The personal information we have collected from you will be shared with fraud prevention agencies who will use it to prevent fraud and money laundering and to verify your identity. If fraud is detected, you could be refused finance. Further details of how your information will be used by us and these fraud prevention agencies, and your data protection rights, can be found under our <a :id="id" @click.prevent="showModal('privacy-policy')">privacy-policy</a>.
  </div>
</template>

<script>
export default {
  name: 'FraudPreventionMsg',
  props: {
    id: {
      type: String,
      default() {
        return 'a_href_generic_fraudpreventionmsg'
      }
    }
  },
  methods: {
    showModal(modal) {
      this.$emit('showModal', modal);
    }
  }
}
</script>

<style scoped>
  .fraud {
    padding: 0px;
    border-radius: 5px;
    font-size: .7em;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
</style>