<template>
  <form @submit.prevent="Submit({ name: 'date-of-birth' })">
    <InputGroup
      id="marital-status"
      name="form.maritalStatusId"
      label="Which best describes you?"
      columns
      :items="[
        {label: 'Married', value: 23, canCoutinue: canCoutinue(), id: idConcat(pageName, 'Married') },
        {label: 'Single', value: 24, canCoutinue: canCoutinue(), id: idConcat(pageName, 'Single') },
        {label: 'Cohabiting', value: 25, canCoutinue: canCoutinue(), id: idConcat(pageName, 'Cohabiting') },
        {label: 'Divorced', value: 26, canCoutinue: canCoutinue(), id: idConcat(pageName, 'Divorced') },
        {label: 'Separated', value: 28, canCoutinue: canCoutinue(), id: idConcat(pageName, 'Separated') },
        {label: 'Widowed', value: 29, canCoutinue: canCoutinue(), id: idConcat(pageName, 'Widowed') },
        {label: 'Civil partnership', value: 30, canCoutinue: canCoutinue(), id: idConcat(pageName, 'CivilPartnership') }
      ]"
      v-model="$v.maritalStatus.$model"
      :v="$v.maritalStatus"
      :submitFunction="continueBtnClick"
      :mask-input="true"
    />

    <ButtonContinue :id="idConcat(pageName, 'ContinueBtn')" :visible="visibleCoutinueButton()" />

    <ButtonBack :id="idConcat(pageName, 'BackBtn')" />

    <InfoFooter :showTopOnly="true" />
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {AnalyticsService} from '@/services';

export default {
  data() {
    return {
      pageName: 'MaritalStatus',
      maritalStatus: this.$store.state.form.maritalStatusId,
      martialStatusLookup: [
        {label: 'Married', value: 23},
        {label: 'Single', value: 24},
        {label: 'Cohabiting', value: 25},
        {label: 'Divorced', value: 26},
        {label: 'Separated', value: 28},
        {label: 'Widowed', value: 29},
        {label: 'Civil partnership', value: 30}
      ],
      pageViewSubmitted: false,
    }
  },
  computed: {
    nextPage() {
      this.$emit('eventTracking', this.$store.state, 'application_form_details_completed', 'application_form', null)
      return { name: 'address', params: { number: 1 } }
    }
  },
  watch: {
    maritalStatus: function(val) {
      this.martialStatusChangeEvent(val);
    }
  },
  methods: {
    continueBtnClick: function() {
      setTimeout(()=> {
        this.Submit({ name: 'date-of-birth' })
      },100);
    },
    martialStatusChangeEvent(val) {
      this.martialStatusLookup.forEach((item) => {
        if (item.value === val) {
          const data = item.label.toLowerCase().replaceAll(' ', '_');
          AnalyticsService.setData('marital_status', data, false);
        }
      });
    },
  },
  validations() {
      return {
        maritalStatus: {required},
      }
    },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);

    this.$store.dispatch('updateFormValue', [false, 'form.maritalStatusCompleted']);
    this.$store.dispatch('updateFormValue', [true, 'form.drivinglicenceCompleted']);

    if (this.maritalStatus && this.$v.maritalStatus.$dirty) {
      this.martialStatusChangeEvent(this.maritalStatus);
    }

    if (!this.pageViewSubmitted) {
      this.pageViewSubmitted = true;

      AnalyticsService.trackPageView({
        page_name: 'marital-status'
      });
    }
  }
}
</script>

