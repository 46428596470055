
<template>
  <div class="formWrapper" v-bind:class="{ 'small-input': smallInput, 'no-padding': noPadding }">
    <div class="input-container">
      <div class="input-date">
        <input
            :id="id"
            :aria-labelledby="labeledBy + ' ' + id"
            type="number"
            :name="name"
            :placeholder="placeholder"
            :min="min"
            :max="max"
            :autocomplete="autocomplete"
            v-model="value"
            :class="this.generateClass"
            :disabled="$store.state.lockDown"
            :maxlength="maxlength"
            pattern="[0-9]*"
            inputmode="numeric"
        >
      <label class="labelWrapper" :for="id" :id="id+'-label'" v-if="label" :class="{ 'big-label': bigLabel, 'hidden': hideLabel }"><div class="textlabel">{{ label }}</div></label>
      </div>
    </div>
    <p class="error" v-if="!this.v.required && this.v.$error">{{ invalidMessage }}</p>
    <p class="error" v-if="!this.v.between && this.v.$error || !this.v.between && this.v.$invalid">Must be between {{ this.v.$params.between.min }} and {{ this.v.$params.between.max }}.</p>
    <p class="small-print" v-if="smallPrint">{{ smallPrint }}</p>
  </div>
</template>

<script>
import {SENSITIVE_DATA} from '@/const';

export default {
  props: {
    id:{
      type: String,
      default() {
        return 'input-number-' + this._uid
      }
    },
    idDay: {
      type: String,
      default() {
        return 'input_number_generic_day'
      }
    },
    idMonth: {
      type: String,
      default() {
        return 'input_number_generic_month'
      }
    },
    idYear: {
      type: String,
      default() {
        return 'input_number_generic_year'
      }
    },
    label: {
      type: String,
      default: ''
    },
    labeledBy: {
      type: String,
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    min:{
      type: Number
    },
    max:{
      type: Number
    },
    bigLabel: {
        type: Boolean,
        default: false
    },
    smallInput: {
        type: Boolean,
        default: false
    },
    smallPrint: {
        type: String,
        default: ''
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    placeholder: {
      type: String,
      default: ''
    },
    invalidMessage: {
      type: String,
      default() {
        return 'Please enter your '+ this.label.toLowerCase() +'.'
      }
    },
    input: {
      type: String,
      default: ''
    },
    v: {
      type: Object,
      default: function () { return {} }
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: String,
      default: "240"
    },
    sensitiveData: {
      type: Boolean,
      default: false
    }
  },
    mounted() {
    let input_element = document.querySelectorAll("input");
    input_element.forEach(input_array => {
      input_array.setAttribute('value', input_array.value);
      input_array.addEventListener('keyup', () => {
        input_array.setAttribute('value', input_array.value);
        });
        });
  },
  computed: {
    generateClass() {
      let cssClass = { 'error': this.v.$error, 'valid': this.v.$dirty && !this.v.$error };

      if (this.sensitiveData) {
        SENSITIVE_DATA.p11Mask.forEach((p11Mask) => {
          cssClass[p11Mask] = true;
        });
      }

      return cssClass;
    },
    value: {
      get: function() {

        let name = this.name
        let obj = this.$store.state
        let get = _.get(obj, name)
        return get

      },
      set: function(value) {

        if(value === ''){
          value = null
        }
        let data = [value, this.name]
        this.$emit('input', value)
        this.$store.dispatch('updateFormValue', data)

      }
    }
  }
}
</script>
<style scoped>
input {
  text-align: center;
}
</style>