<template>
  <div class="disclosure-statement">
    <p v-if="isCarcraft">
      Carcraft.co.uk only introduces finance enquiries to CarFinance247 Limited, a credit broker, and is subject to their terms and conditions. Carcraft will receive a fee for these introductions.
      <br /><br />
      CarFinance247 Limited, a credit broker, and is subject to their terms and conditions. Carcraft will receive a fee for these introductions.
    </p>
  </div>
</template>

<script>
  import {LocationHelper} from "../helpers";

  export default {
    name: "DisclosureStatement",
    data() {
      return {
        isShow: false,
        isCarcraft: this.isShow && LocationHelper.getHost() === 'carcraft',
      }
    },
  }
</script>

<style lang="scss" scoped>
  .disclosure-statement {
    & p {
      text-align: left;
      padding-top: 20px;

      &:first-child {
        font-size: 1rem;

        @media
        (min-width: 360px),
        only screen and (-webkit-min-device-pixel-ratio: 4.5)      and (min-width: 320px),
        only screen and (   min--moz-device-pixel-ratio: 4.5)      and (min-width: 320px),
        only screen and (     -o-min-device-pixel-ratio: 4.5/1)    and (min-width: 320px),
        only screen and (        min-device-pixel-ratio: 4.5)      and (min-width: 320px) {
          font-size: 1rem;
        }

        @media (min-width: 768px) {
          font-size: 1.2rem;
        }
      }

      @media
      (min-width: 360px),
      only screen and (-webkit-min-device-pixel-ratio: 4.5)      and (min-width: 320px),
      only screen and (   min--moz-device-pixel-ratio: 4.5)      and (min-width: 320px),
      only screen and (     -o-min-device-pixel-ratio: 4.5/1)    and (min-width: 320px),
      only screen and (        min-device-pixel-ratio: 4.5)      and (min-width: 320px) {
        font-size: 1rem;
      }
    }
  }
</style>