
<template>
<div class="formWrapper">
  <div class="input-container" v-bind:class="{ 'no-padding': noPadding }">
    <input
        :id="id"
        :aria-labelledby="labeledBy + ' ' + id"
        type="number"
        :name="name"
        :placeholder="placeholder"
        :min="min"
        :max="max"
        :autocomplete="autocomplete"
        v-model="value"
        :class="{ 'error': this.v.$error, 'valid': this.v.$dirty && !this.v.$error }"
        :disabled="$store.state.lockDown"
        :maxlength="maxlength"
        pattern="[0-9]*"
        inputmode="numeric"
    >
    <label :for="id" :id="id+'-label'" v-if="label" class="labelWrapper" :class="{ 'big-label': bigLabel, 'hidden': hideLabel }"><div class="textlabel">{{ label }}</div></label>
  </div>
    <p class="error" v-if="!this.v.required && this.v.$error">{{ invalidMessage }}</p>
    <p class="error" v-if="!this.v.between && this.v.$error || !this.v.between && this.v.$invalid">Must be between {{ this.v.$params.between.min }} and {{ this.v.$params.between.max }}.</p>
    <p class="small-print" v-if="smallPrint">{{ smallPrint }}</p>
  </div>
</template>

<script>
export default {
  props: {
    id:{
      type: String,
      default() {
        return 'input-number-' + this._uid
      }
    },
    label: {
      type: String,
      default: ''
    },
    labeledBy: {
      type: String,
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    min:{
      type: Number
    },
    max:{
      type: Number
    },
    bigLabel: {
        type: Boolean,
        default: false
    },
    smallInput: {
        type: Boolean,
        default: false
    },
    smallPrint: {
        type: String,
        default: ''
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    placeholder: {
      type: String,
      default: ''
    },
    invalidMessage: {
      type: String,
      default() {
        return 'Please enter your '+ this.label.toLowerCase() +'.'
      }
    },
    input: {
      type: String,
      default: ''
    },
    v: {
      type: Object,
      default: function () { return {} }
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: String,
      default: "240"
    }
  },
  computed: {
    value: {
      get: function() {

        let name = this.name
        let obj = this.$store.state
        let get = _.get(obj, name)
        return get

      },
      set: function(value) {

        if(value === ''){
          value = null
        } else {
          value = Math.abs(Math.ceil(value))
        }

        let data = [value, this.name]
        this.$emit('input', value)
        this.$store.dispatch('updateFormValue', data)

      }
    }
  }
}
</script>