import axios from 'axios'
import { URLS } from '../factories'

const FeatureSwitchService = {
    isFeatureEnabled(feature, state, isProduction) {
        return new Promise(function (resolve, reject) {
            const featureSwitchApiUrl = isProduction ? URLS.production.featureSwitchingApiUrl : URLS.replica.featureSwitchingApiUrl;

            axios({
                method: 'GET',
                url: featureSwitchApiUrl + '/api/features/' + feature,
            }).then((response) => {
                const isEnabled = response && response.data ? true : false;
                resolve(isEnabled);
            }).catch(() => {
                reject();
            });
        });
    },


};

export default FeatureSwitchService;