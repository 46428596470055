export default {
    extract(string, pattern) {
        let result = null;
        if (string !== null) {
            let matches = pattern.exec(string);
            if (matches !== null && matches.length > 0) {
                result = matches[1];
            }
        }
        return result;
    }
}