import {RequestHelper} from '@/helpers/index';

export default {
    showInterstitial(affiliateConfig) {
        if (affiliateConfig && typeof affiliateConfig.interstitial !== "undefined" &&
            typeof affiliateConfig.interstitial.show !== "undefined" &&
            affiliateConfig.interstitial.show) {

            let showInterstitial = true;

            if (typeof affiliateConfig.interstitial.trigger !== "undefined") {

                showInterstitial = false;

                // Trigger by Params;
                if (typeof affiliateConfig.interstitial.trigger.params !== "undefined") {
                    // Check Any Defined Params Match with Defined Query Params
                    const params = affiliateConfig.interstitial.trigger.params;
                    params.forEach(function (param) {
                        if (param.key && param.value &&
                            RequestHelper.getQueryVariable(param.key) !== null &&
                            RequestHelper.getQueryVariable(param.key) === param.value) {
                            showInterstitial = true;
                        }
                    });
                }
            }

            return showInterstitial;
        }

        return false;
    }
}