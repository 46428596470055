<template>
<div class="formWrapper">
	<div v-bind:class="{ 'small-input': smallInput }" class="input-container">
		<input
				:id="id"
				type="tel"
				minlength="10"
				maxlength="13"
				:name="name"
				:placeholder="placeholder"
				:autocomplete="autocomplete"
				v-model.lazy="value"
				:class="this.generateClass"
				:disabled="$store.state.lockDown"
				@keydown.space="(event) => event.preventDefault()"
        @blur="handleBlur"
        :style="this.styleAtt"
		>
        <label :for="id" class="labelWrapper" v-if="label" :class="{ 'big-label': bigLabel }"><div class="textlabel">{{ label }}</div></label>
    </div>
		<p class="error" v-if="!this.v.required && this.v.$error">{{ invalidMessage }}</p>
		<p class="error" v-if="this.v.required && this.v.$error">Please try again. That phone number doesn’t look
			right.</p>
		<p class="small-print" v-if="smallPrint">{{ smallPrint }}</p>
	</div>
</template>

<script>
    import {checkUKTelephoneError} from "../modules/checkUKTelephoneError.js"
    import { PartialApplicationService } from "../services";
    import {SENSITIVE_DATA} from '@/const';

    export default {
        props: {
            id: {
                type: String,
                default() {
                    return 'input-phone-' + this._uid
                }
            },
            label: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: ''
            },
            bigLabel: {
                type: Boolean,
                default: false
            },
            smallInput: {
                type: Boolean,
                default: false
            },
            smallPrint: {
                type: String,
                default: ''
            },
            autocomplete: {
                type: String,
                default: 'off'
            },
            placeholder: {
                type: String,
                default: ''
            },
            phoneNumber: {
                type: String,
                default: ''
            },
            invalidMessage: {
                type: String,
                default() {
                    let error = checkUKTelephoneError(this.input);

                    if (error) {
                        return error;
                    }

                    return 'Please enter a valid phone number.'
                }
            },
            input: {
                type: String,
                default: ''
            },
            v: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            styleAtt: {
              type: String,
              default: ''
            },
          sensitiveData: {
            type: Boolean,
            default: false
          }
        },
        computed: {
          generateClass() {
            let cssClass = { 'error': this.v.$error, 'valid': this.v.$dirty && !this.v.$error };

            if (this.sensitiveData) {
              SENSITIVE_DATA.p11Mask.forEach((p11Mask) => {
                cssClass[p11Mask] = true;
              });
            }

            return cssClass;
          },
            value: {
                get: function () {

                    let name = this.name
                    let obj = this.$store.state
                    let get = _.get(obj, name)
                    return get
                },
                set: function (newValue) {
                    // auto replace +44 country code
                    if (newValue.startsWith('+44')) {
                      newValue = '0' + newValue.substring(3);
                    }
                    if (newValue.startsWith('044')) {
                      newValue =  '0' + newValue.substring(3);
                    }
                    if (newValue.startsWith('44')) {
                      newValue =  '0' + newValue.substring(2);
                    }

                    newValue = newValue.replace(/\+/g, '')
                    newValue = newValue.replace(/,/g, '')
                    newValue = newValue.replace(/\./g, '')
                    newValue = newValue.replace(/\D/g, '')

                    let data = [newValue, this.name]
                    this.$emit('input', newValue)
                    this.$store.dispatch('updateFormValue', data)
                }
            }
        },
        methods: {
          handleBlur() {
            PartialApplicationService.sendPartialApp(this, this.$store, this.$store.state);
          }
        }
    }
</script>