import { render, staticRenderFns } from "./HonestJohnLogo.vue?vue&type=template&id=f693bafa&scoped=true"
import script from "./HonestJohnLogo.vue?vue&type=script&lang=js"
export * from "./HonestJohnLogo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f693bafa",
  null
  
)

export default component.exports