var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{attrs:{"id":"Form"},on:{"submit":function($event){$event.preventDefault();return _vm.Submit({ name: 'driving-licence' })}}},[_c('input',{attrs:{"type":"hidden","name":"dummy","value":"something"}}),_c('InputGroup',{directives:[{name:"show",rawName:"v-show",value:(_vm.showVehicleType),expression:"showVehicleType"}],attrs:{"id":"vehicle-type","name":"form.vehicleTypeId","label":_vm.getLabel,"items":[
      {label: 'Car', value: 40, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'Car') },
      {label: 'Van', value: 41, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'van') },
      {label: 'Bike', value: 42, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'Bike') }
    ],"v":_vm.$v.vehicleType,"submitFunction":_vm.continueBtnClick},model:{value:(_vm.$v.vehicleType.$model),callback:function ($$v) {_vm.$set(_vm.$v.vehicleType, "$model", $$v)},expression:"$v.vehicleType.$model"}}),_c('ButtonContinue',{attrs:{"id":_vm.idConcat(_vm.pageName, 'ContinueBtn'),"visible":_vm.visibleCoutinueButton(),"sub-label":_vm.$t('page.finance_details.button_continue.sub_label')}},[_vm._v(_vm._s(_vm.isClassifyPartner ? 'Continue' : _vm.$t('page.finance_details.button_continue.inner_text')))]),_c('div',{staticStyle:{"font-size":"0.9em","text-align":"center"},domProps:{"innerHTML":_vm._s(_vm.$t('page.finance_details.message.label'))}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isGreenTickTest),expression:"isGreenTickTest"}],staticClass:"green-tick"},[_vm._m(0),_vm._m(1)]),_c('PrivacyBanner',{attrs:{"id":_vm.idConcat(_vm.pageName, 'PrivacyBannerHref'),"label":_vm.isAffiliate && !_vm.isCarcraft ? 'By starting your quote you\'re agreeing to Car Finance 247\'s' : _vm.$t('page.finance_details.privacy_banner.label'),"is-bottom-fix":_vm.isGreenTickTest}}),(_vm.showPrivacyBannerQuote)?_c('section',[_vm._m(2)]):_vm._e(),_c('div',{staticStyle:{"font-size":"0.9em","text-align":"center"},domProps:{"innerHTML":_vm._s(_vm.$t('page.finance_details.trade.label'))}}),_c('PowerByCf247Text'),_c('RepExample'),_c('DisclosureStatement'),(_vm.showNoImpactOnCreditScore)?_c('div',{staticClass:"app-duration"},[_vm._m(3),_c('div',[_vm._v(" No impact on your credit score - see if you’ll be accepted before choosing to proceed. ")])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAppDuration),expression:"showAppDuration"}],staticClass:"app-duration"},[_vm._m(4),_c('div',[_vm._v(" Your no-obligation quote is just 1 minute away! ")])]),(_vm.infoFooterKey == 'infoFooterKeyDone')?_c('InfoFooter',{attrs:{"showTopOnly":!_vm.isStartPage,"isStartPage":_vm.isStartPage}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"checked",attrs:{"src":require("@/assets/img/checked_1.png"),"width":"20"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" Welcome to Car Finance 247, explore your options in "),_c('strong',[_vm._v("just a few clicks")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"0px","border-radius":"5px","font-size":".9em","text-align":"center","margin-top":"20px","margin-bottom":"20px"}},[_c('span',[_vm._v("Rates from 9.9% APR. Representative APR 19.9% "),_c('br'),_vm._v("Car Finance 247 is a credit broker, not a lender. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"checked",attrs:{"src":require("@/assets/img/checked_1.png"),"width":"20"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"checked",attrs:{"src":require("@/assets/img/checked_1.png"),"width":"20"}})])
}]

export { render, staticRenderFns }