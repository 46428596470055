import ckdirect from './ckdirect.json';
import ckdirect_whitelabel from './ckdirect_whitelabel.json';
import ckdirect_submission from './ckdirect_submission.json';
import ckdirect_submission_whitelabel from './ckdirect_submission_whitelabel.json';
import confused from './confused.json';
import equifax from './equifax.json';
import oceancar from './oceancar.json';
import oceancar_submission from './oceancar_submission.json';
import clearscore from './clearscore.json';
import thinkmoneycar from './thinkmoneycar.json';
import credapp from './credapp.json';
import dragonfinance from './dragonfinance.json';
import drivescore from './drivescore.json';
import parkers from './parkers.json';
import comparethemarketcarfinance from './comparethemarketcarfinance.json';
import _ from './default.json';

export const defaultLocale = '_';

export const languages = {
    _: _,
    ckdirect: ckdirect,
    ckdirect_whitelabel: ckdirect_whitelabel,
    ckdirect_submission: ckdirect_submission,
    ckdirect_submission_whitelabel: ckdirect_submission_whitelabel,
    confused: confused,
    equifax: equifax,
    oceancar: oceancar,
    oceancar_submission: oceancar_submission,
    clearscore: clearscore,
    thinkmoneycar: thinkmoneycar,
    credapp: credapp,
    dragonfinance: dragonfinance,
    drivescore: drivescore,
    parkers: parkers,
    comparethemarketcarfinance: comparethemarketcarfinance
}

