<template>
  <div>
    <button @click="onClick" type="button" class="button alpha">
    <span :class="subLabel ? '' : 'button-text'">
      <slot>Continue</slot>
    </span>
      <div>{{ subLabel }}</div>
    </button>
  </div>

</template>

<script>
  export default {
    name: 'Button',
    props: {
      subLabel: {
        type: String,
        default: '',
      }
    },
    methods: {
      onClick() {
        this.$emit('btn-clicked');
      }
    }
  }
</script>

<style scoped>
  .button-text {
    position: relative;
    top: 4px;
  }
</style>