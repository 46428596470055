<template>
  <img alt="CompareTheMarket.com Logo" height="30" v-lazy="imgUrl" />
</template>

<script>
  import {AFFILIATES} from '../../factories'
  import { LocationHelper} from '../../helpers'
  export default {
    name: 'CompareTheMarketCarFinanceLogo',
    data () {
      return {
        imgUrl: AFFILIATES[LocationHelper.getHost()] && AFFILIATES[LocationHelper.getHost()].testB ?
              require('@/assets/img/affiliates/comparethemarketcarfinance.testB.svg')
          : 
              require('@/assets/img/affiliates/comparethemarketcarfinance.svg')
      }
    }
  }
</script>

<style scoped>

</style>