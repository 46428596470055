<template>
  <section>
    <h1 v-if="!isCarcraft" id="modalTitle">Privacy Policy</h1>
    <div v-for="policy in privacyPolicy" :key="policy">
      <div v-if="content[policy]">
        <div v-for="pageContent in content[policy].pageContents" :key="pageContent.identifier" class="container -mt-35 -mb-35">
          <h1 v-if="pageContent.displayTitle" class="text -display-lg -mb-35">{{ pageContent.displayTitle }}</h1>
          <div v-for="index in 8" :key="index">
            <h2 v-if="pageContent[`subTitle${index}`]" class="text -title-xl -mb-35">{{ pageContent[`subTitle${index}`] }}</h2>
            <div v-if="pageContent[`content${index}`]" v-html="pageContent[`content${index}`]" class="markdown -mb-35"></div>
            <div v-if="pageContent[`leftSubTitle${index}`] || pageContent[`leftContent${index}`] || pageContent[`rightSubTitle${index}`] || pageContent[`rightContent${index}`]" class="row -justify-content-between -mb-40">
              <div class="col -md-6">
                <div v-if="pageContent[`leftSubTitle${index}`]" class="-ml-10">
                  <h2 class="text -title-xl -mb-35">{{ pageContent[`leftSubTitle${index}`] }}</h2>
                </div>
                <div v-if="pageContent[`leftContent${index}`]" v-html="pageContent[`leftContent${index}`]" class="markdown -ml-10"></div>
              </div>
              <div class="col -md-6">
                <div v-if="pageContent[`rightSubTitle${index}`]" class="-ml-10">
                  <h2 class="text -title-xl -mb-35">{{ pageContent[`rightSubTitle${index}`] }}</h2>
                </div>
                <div v-if="pageContent[`rightContent${index}`]" v-html="pageContent[`rightContent${index}`]" class="markdown -ml-10"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import { DotCMSService } from '../services';
import { DOTCMS_CONTENT_ID } from '../const';
import {LocationHelper} from '@/helpers';

export default {
  name: 'privacy',
  data() {
    return {
      isCarcraft: LocationHelper.getHost().startsWith('carcraft'),
      privacyPolicy: LocationHelper.getHost().startsWith('carcraft') ? DOTCMS_CONTENT_ID.CARCRAFT_PRIVACY_POLICY : DOTCMS_CONTENT_ID.PRIVACY_POLICY,
      content: {},
    }
  },
  mounted() {
    const content = LocationHelper.getHost().startsWith('carcraft') ? DOTCMS_CONTENT_ID.CARCRAFT_PRIVACY_POLICY : DOTCMS_CONTENT_ID.PRIVACY_POLICY;

    content.forEach(id => DotCMSService.getContentById(this.$store.state, id, 1).then((response) => {
      if (response.data && response.data.contentlets.length > 0) {
        const id = response.data.contentlets[0].identifier;
        this.content[id] = response.data.contentlets[0];
        this.$forceUpdate();
      }
    }));
  },
  methods: {
    navToCookiePolicy() {
      this.$emit('openModal', 'cookie-policy')
    },
  }
}
</script>
