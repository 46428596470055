<template>
  <div class="outer-wrapper">
    <div class="inner-wrapper">
      <div class="body-content">
        <h3 v-if="isPartnerOverlayTest">
          Looking for car finance? We can help!
        </h3>
        <div class="credit-broker"  v-html="$t('component.partner_rep_apr_modal_mobile.credit_broker').replace('{partner}', partnerName)"></div>

        <div v-if="!isPartnerOverlayTest" class="usp">
          <ul :class="isCompareTheMarketCarFinance ? ['width-200'] : []">
            <li>
              <div>{{ $t('component.partner_rep_apr_modal_mobile.usp_1') }}</div></li>
            <li>
              <div>{{ $t('component.partner_rep_apr_modal_mobile.usp_2') }}</div></li>
            <li>
              <div>{{ $t('component.partner_rep_apr_modal_mobile.usp_3') }}</div></li>
          </ul>
        </div>

        <div v-if="!isPartnerOverlayTest" class="trustpilot-container">
          <TrustPilot />
        </div>

        <div v-if="isPartnerOverlayTest">
          <!-- TrustBox widget - Mini -->
          <div class="trustpilot-widget" data-locale="en-GB" data-template-id="539ad0ffdec7e10e686debd7" data-businessunit-id="5193e43b00006400052dd669" data-style-height="350px" data-style-width="100%" data-theme="light" data-tags="SelectedReview" data-review-languages="en"
               style="height:108px; overflow: hidden;">
            <a href="https://uk.trustpilot.com/review/carfinance247.co.uk" target="_blank" rel="noopener">Trustpilot</a>
          </div>
          <!-- End TrustBox widget -->
        </div>

        <br />

        <div v-if="isPartnerOverlayTest" class="trustpilot-widget-container bold">
          <p>Get your no-obligation quote in just a couple of minutes!</p>
        </div>
        
        <div type="button" aria-label="Get my quote" class="btn-close btn-alpha" @click="close()">
          {{ $t('component.partner_rep_apr_modal_mobile.cta') }}
        </div>

        <br />

        <div class="rep-example">
          <div v-html="$t('component.partner_rep_apr_modal_mobile.rep_example')"></div>
           <div style="position: absolute; left: 0; right:0;margin: auto 0;">
            CarFinance 247 Limited is a credit broker, not a lender.
          </div>
        </div>
     

        <div v-html="$t('component.partner_rep_apr_modal_mobile.fee')"></div>

        <br/>
        <p class="rep-example">Universal Square, Devonshire Street North,<br/>Manchester, M12 6JH</p>
      </div>
    </div>
  </div>
</template>

<script>
import {AffiliatesHelper, LocationHelper, RequestHelper} from '@/helpers';
import {AFFILIATES} from '@/factories';
import TrustPilot from '@/components/TrustPilot.vue';

export default {
  name: "PartnerRepAprModelMobile",
  components: {
    TrustPilot
  },
  data() {
    return {
      swapLogoSide: false,
      isCompareTheMarketCarFinance: LocationHelper.getHost() === 'comparethemarketcarfinance',
    }
  },
  computed: {
    isPartnerOverlayTest() {
      return RequestHelper.getQueryVariable('test') === 'partner-overlay';
    },
    showAffiliateLogo() {
      return this.$store.state.affiliateConfig.modalShowLogo;
    },
    partnerName() {
      return AffiliatesHelper.getPartnerName(this.$store.state);
    }
  },
  mounted() {
    let host = LocationHelper.getHost();
    if (AFFILIATES[host] !== undefined) {
      const affiliateConfig = AFFILIATES[host];
      if (affiliateConfig && affiliateConfig.swapLogoSide !== undefined ) {
        this.swapLogoSide = affiliateConfig.swapLogoSide;
      }
    }
  },
  methods: {
    close() {
      this.$emit("close")
    }
  }
}
</script>

<style scoped lang="scss">

 .bold {
   font-weight: 800;
 }

 .trustpilot-widget-container {
   width: 300px;
   margin: 0 auto;
 }

.trustpilot-container {
  img {
    height: 28px;
    max-width: 100%;
  }

  @media (max-width: 412px) {
    img {
      height: auto;
      max-width: 400px;
    }
  }

  @media (max-width: 360px) {
    img {
      height: auto;
      max-width: 350px;
    }
  }

  @media (max-width: 320px) {
    img {
      height: auto;
      max-width: 300px;
    }
  }
}


.cflogo-displacement {
  position: relative;
  left: -20px;
}

.-m-auto {
  margin: auto;
}

@media (min-width: 412px) {
  .cflogo-displacement {
    position: relative;
    left: -20px;
  }
}

@media (min-width: 800px) {
  .cflogo-displacement {
    position: relative;
    left: -50px;
  }

  .-m-auto {
    margin: unset;
  }
}

.partnerLogo-displacement {
  position: relative;
  left: -5px;
}

.dualband-displacement {
  max-height: 70px;
  margin-bottom: 10px;
}

.dualband-displacement img {
  display: block;
  margin-left: auto;
  margin-right: auto;
} 

.outer-wrapper {
  margin-left: 30px;
  margin-right: 30px;
  line-height: 1.1em;


  .inner-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .trustpilot-container {
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-box-align: center;
      -webkit-box-pack: center;
    }

    .trustpilot-container img {
      height: 41px;
    }


    .rep-example {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #4A4A4A;

      margin: 0px;
    }

    p.rep-example {
      margin-top: 15px;
    }

    .btn-alpha {
      cursor: pointer;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-transition: background-color .3s;
      transition: background-color .3s;
      color: #fff;
      background-color: #604dd5;

      font-style: normal;
      font-weight: 700;
      font-size: 20px;


      padding: 18px 90px 18px 90px;
      border: none;
      width: 100%;
      margin: 0px auto 0px auto;
      line-height: 1.5rem;
      -webkit-box-shadow: 0 6px 10px 0 rgba(96,77,213,.15);
      box-shadow: 0 6px 10px 0 rgba(96,77,213,.15);
      border-radius: .3rem;
      max-width: 404px;
      max-height: 60px;
      -webkit-appearance: none;
      text-align: center;

      @media (min-width: 320px) {
        font-size: 1.3rem;
      }

      @media (min-width: 360px) {
        font-size: 1.3rem;
      }
    }

    .usp {
      display: block;
      width: 100%;
      margin-top: 35px;
      padding-bottom: 15px;
    }

    .btn-alpha span {
      display: block;
      letter-spacing: .6px;
      @include font-regular();
      text-align: center;

      @media (min-width: 320px) {
        font-size: 12px;
      }

      @media (min-width: 360px) {
        font-size: 16px;
      }
    }

    ul {
      width: 180px;
      padding-left: 0px;
      display: block;
      margin: 0 auto;
    }

    ul li {
      list-style: none;
      position: relative;
      background-color: #fff;
      line-height: 1.625rem;
      color: #4a4a4a;
      padding-bottom: 10px;
      
    

      @media (min-width: 320px) {
        font-size: 1rem;
      }

      @media (min-width: 360px) {
        font-size: 1.2rem;
      }
    }
    ul li div{
      text-align: left;
      margin-left: 32px;
   
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #4A4A4A;
    }

    ul li:before {
      content: "" !important;
      background-image: url("../assets/img/model_v3_tick.png") !important;
      background-size: 13px !important;
      background-position-x: 5px !important;
      background-position-y: 5px !important;
      color: #5f4dd5 !important;
      font-weight: bolder !important;
      width: 15px !important;
      height: 15px !important;
   
      background-repeat: no-repeat !important;

      position: relative !important;
      display: block !important;
      float: left;
      margin-left: 0px !important;
      // padding-left: 8px;
      left: 0px !important;
      padding:3px !important;
      background-color: #5FC5AD !important;
      border-radius: 12px !important;
    }

    .credit-broker {

      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      /* or 121% */

      text-align: center;

      color: #4A4A4A;
    }

    .credit-broker b {
      font-weight: 900;
      font-size: 28px;
    }
  }


  .row {
    display: flex;
    justify-content: space-between;
  }

  .column {
    padding: 5px;
  }

  .width-200 {
    width: 200px !important;
  }
}
</style>
