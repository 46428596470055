<template>
  <img alt="Prefinance Logo" height="25" v-lazy="imgUrl" />
</template>

<script>
export default {
  name: "PreFinanceLogo",
    data () {
    return {
      imgUrl:  require('@/assets/img/affiliates/prefinance.png')
    }
  }
}
</script>

<style scoped>

</style>