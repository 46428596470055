
<template>
  <button :id="id" @click="back" class="button charlie" type="button">
      <svg width="30" height="17" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.157 7l-5.293-5.293A1 1 0 1 1 22.278.293l7.071 7.071a1 1 0 0 1 0 1.414l-7.07 7.071a1 1 0 0 1-1.415-1.414L26.299 9H1.5a1 1 0 0 1 0-2h24.657z" fill-rule="nonzero"/>
      </svg>
      Previous
      <div>You won't lose your information</div>
  </button>
</template>

<script>
export default {
  name: 'ButtonBack',
  props: {
    id: {
      type: String,
      default() {
        return 'button_generic_back'
      }
    },
    isCallBackHandle:{
      type: Boolean,
      default: false
    },
  },
  methods: {
    back() {
      if (this.isCallBackHandle) {
        this.$emit("callback")
      } else {
        this.$router.go(-1);
      }


    }
  }
}
</script>
