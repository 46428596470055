import {LocationHelper, CompareTheMarketTealiumMapperHelper} from '@/helpers';
import {AFFILIATES} from '@/factories';
import {CONSENT_STATE} from '@/const';

const host = LocationHelper.getHost();

function isTealiumDataLoaded() {
    return typeof window.utag !== 'undefined' && typeof window.utag.data !== 'undefined';
}

function isTealiumGdprLoaded() {
    return typeof window.utag !== 'undefined' && typeof window.utag.gdpr !== 'undefined';
}

export default {
    isTealiumEnabled() {
        if (AFFILIATES[host] !== undefined) {
            const affiliateConfig = AFFILIATES[host];

            if (affiliateConfig && typeof affiliateConfig.tealiumEnabled !== "undefined") {
                return affiliateConfig.tealiumEnabled;
            }
        }

        return false;
    },
    getTealiumScriptFolder() {
        if (AFFILIATES[host] !== undefined) {
            const affiliateConfig = AFFILIATES[host];

            if (affiliateConfig && typeof affiliateConfig.publicFolder !== "undefined") {
                return affiliateConfig.publicFolder;
            }
        }

        return null;
    },
    initalData() {
        return new Promise((resolve) => {
            if (this.isTealiumEnabled()) {
                let timer = 0;
                const tealiumChecker = setInterval(() => {
                    if (isTealiumDataLoaded()) {

                        switch (host) {
                            case 'comparethemarketcarfinance':
                                CompareTheMarketTealiumMapperHelper.initalData();
                                break;
                            default:
                                break;
                        }

                        clearInterval(tealiumChecker);
                        resolve();
                    }

                    timer += 1;

                    if (timer > 15) {
                        clearInterval(tealiumChecker);
                        resolve();
                    }
                }, 1000);
            } else {
                resolve();
            }
        });
    },
    getConsentState() {
        return new Promise((resolve) => {
            if (this.isTealiumEnabled()) {
                let timer = 0;
                const tealiumChecker = setInterval(() => {
                    if (isTealiumGdprLoaded()) {
                        let consent;
                        let state = window.utag.gdpr.getConsentState();

                        if (Array.isArray(state)) {
                            consent = CONSENT_STATE.PARTIAL;
                        } else {
                            consent = state;
                        }

                        clearInterval(tealiumChecker);
                        resolve(consent);
                    }

                    timer += 1;

                    if (timer > 15) {
                        clearInterval(tealiumChecker);
                        resolve(CONSENT_STATE.NONE);
                    }
                }, 1000);
            } else {
                resolve(CONSENT_STATE.NONE);
            }
        });
    },
    showExplicitConsent() {
        return new Promise((resolve) => {
            if (this.isTealiumEnabled()) {
                let timer = 0;
                const tealiumChecker = setInterval(() => {
                    if (isTealiumGdprLoaded()) {

                        window.utag.gdpr.showExplicitConsent();

                        clearInterval(tealiumChecker);
                        resolve();
                    }

                    timer += 1;

                    if (timer > 15) {
                        clearInterval(tealiumChecker);
                        resolve();
                    }
                }, 1000);
            } else {
                resolve();
            }
        });
    },
    showConsentPreferences() {
        return new Promise((resolve) => {
            if (this.isTealiumEnabled()) {
                let timer = 0;
                const tealiumChecker = setInterval(() => {
                    if (isTealiumGdprLoaded()) {

                        window.utag.gdpr.showConsentPreferences();

                        clearInterval(tealiumChecker);
                        resolve();
                    }

                    timer += 1;

                    if (timer > 15) {
                        clearInterval(tealiumChecker);
                        resolve();
                    }
                }, 1000);
            } else {
                resolve();
            }
        });
    },
    setData(key, value, hash = false) {
        return new Promise((resolve) => {
            if (this.isTealiumEnabled()) {
                let timer = 0;
                const tealiumChecker = setInterval(() => {
                    if (isTealiumDataLoaded()) {

                        switch (host) {
                            case 'comparethemarketcarfinance':
                                CompareTheMarketTealiumMapperHelper.setData(key, value, hash);
                                break;
                            default:
                                break;
                        }

                        clearInterval(tealiumChecker);
                        resolve();
                    }

                    timer += 1;

                    if (timer > 15) {
                        clearInterval(tealiumChecker);
                        resolve();
                    }
                }, 1000);
            } else {
                resolve();
            }
        });
    },
    trackPageView(obj, delay = 0) {
        return new Promise((resolve) => {
            if (this.isTealiumEnabled()) {
                let timer = 0;
                const tealiumChecker = setInterval(() => {
                    if (isTealiumDataLoaded()) {

                        switch (host) {
                            case 'comparethemarketcarfinance':
                                CompareTheMarketTealiumMapperHelper.trackPageView(obj);
                                break;
                            default:
                                break;
                        }

                        clearInterval(tealiumChecker);

                        if (delay > 0) {
                            setTimeout(() => {
                                resolve();
                            }, delay);
                        } else {
                            resolve();
                        }
                    }

                    timer += 1;

                    if (timer > 15) {
                        clearInterval(tealiumChecker);
                        resolve();
                    }
                }, 1000);
            } else {
                resolve();
            }
        });
    },
    trackEvent(obj, delay = 0) {
        return new Promise((resolve) => {
            if (this.isTealiumEnabled()) {
                let timer = 0;
                const tealiumChecker = setInterval(() => {
                    if (isTealiumDataLoaded()) {

                        switch (host) {
                            case 'comparethemarketcarfinance':
                                CompareTheMarketTealiumMapperHelper.trackEvent(obj);
                                break;
                            default:
                                break;
                        }

                        clearInterval(tealiumChecker);

                        if (delay > 0) {
                            setTimeout(() => {
                                resolve();
                            }, delay);
                        } else {
                            resolve();
                        }
                    }

                    timer += 1;

                    if (timer > 15) {
                        clearInterval(tealiumChecker);
                        resolve();
                    }
                }, 1000);
            } else {
                resolve();
            }
        });
    }
}