export default {
    equalsIgnoringCase(text, other) {
        return text ? text.localeCompare(other, undefined, { sensitivity: 'accent' }) === 0 : false;
    },
    toLowerCaseAndTrim(text) {
      return text ? text.toLowerCase().trim() : text;
    },
    toLowerCaseAndTrimAndRemoveAllSpaces(text) {
        return text ? text.toLowerCase().trim().replace(' ', '') : text;
    },
    toUpperCaseFirstLetter(text) {
        return text ? text.replace(/\b\w/g, l => l.toUpperCase()) : text;
    }
}