import { RequestHelper, LocationHelper } from '@/helpers';

export default {
    isUspBannerPresistAndIconSwap() {
        let host = LocationHelper.getHost();

        if (['pistonheads'].indexOf(host) !== -1 && 
        RequestHelper.getQueryVariable('test') === 'usp-banner-presist-icon-swap') {
            return false;  
        } else {
            return true;
        }
    },
}