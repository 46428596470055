var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.Submit(_vm.nextPage)}}},[_c('InputCollapseGroup',{attrs:{"label":_vm.employmentStatusLabel,"columns":"","items":[
      {label: 'Full-time', value: 9, show: true , canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'FullTime'), maskData: false },
      {label: 'Self-employed', value: 10, show: true, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'SelfEmployed'), maskData: false },
      {label: 'Part-time', value: 11, show: true, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'PartTime'), maskData: false },
      {label: 'Disability', value: 12, show: true, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'Disability'), maskData: true },
      {label: 'Retired', value: 13, show: true, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'Retired'), maskData: false },
      {label: 'Student', value: 19, show: true, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'Student'), maskData: false },
      {label: 'Family carer', value: 21, show: false, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'FamilyCarer'), maskData: false },
      {label: 'Agency worker', value: 15, show: false, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'AgencyWorker'), maskData: false },
      {label: 'Sub-contractor', value: 14, show: false, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'SubContractor'), maskData: false },
      {label: 'Homemaker', value: 16, show: false, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'Homemaker'), maskData: false },
      {label: 'Armed forces', value: 18, show: false, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'ArmedForces'), maskData: false },
      {label: 'Not employed', value: 17, show: false, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'NotEmployed'), maskData: false },
    ],"name":'form.employments['+_vm.index+'].statusId',"stateName":'form.employmentStatusOtherClicked',"v":_vm.$v.employmentStatusId,"submitFunction":_vm.continueBtnClick,"idOther":_vm.idConcat(_vm.pageName, 'Other')},model:{value:(_vm.$v.employmentStatusId.$model),callback:function ($$v) {_vm.$set(_vm.$v.employmentStatusId, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.employmentStatusId.$model"}}),_c('ButtonContinue',{attrs:{"id":_vm.idConcat(_vm.pageName, 'ContinueBtn'),"visible":_vm.visibleCoutinueButton()}}),_c('ButtonBack',{attrs:{"id":_vm.idConcat(_vm.pageName, 'BackBtn')}}),_c('InfoFooter',{attrs:{"showTopOnly":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }