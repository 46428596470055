<template>
  <section>
    <div role="group">
      <h1 :class="isDealer ? 'text-center' : ''">Thank you for applying. <span v-if="!isDealer">Check your email now for what happens next.</span></h1>
    </div>
    <div v-if="isDealer">
        <h2 class="text-center">We are processing your application. <strong>{{ dealerName }}</strong> will take it from here.</h2>
    </div>
    <InfoFooter :showTopOnly="true" />
  </section>
</template>

<script>
    import { AffiliatesHelper } from '@/helpers';

    export default {
        name: "thank-you.vue",
        computed: {
            dealerName() {
                return AffiliatesHelper.getPartnerName(this.$store.state);
            },
            isDealer(){
                return this.$store.state.isDealer
            }
        },
    }
</script>

<style scoped>
    .text-center{
        text-align: center
    }
</style>