export default {
    map(contactNumber) {
        if (contactNumber !== undefined && contactNumber !== null && contactNumber.length > 1) {
            if (contactNumber.startsWith('+44')) {
                contactNumber = '0' + contactNumber.substring(3);
            }
            if (contactNumber.startsWith('044')) {
                contactNumber =  '0' + contactNumber.substring(3);
            }
            if (contactNumber.startsWith('44')) {
                contactNumber =  '0' + contactNumber.substring(2);
            }
            contactNumber = contactNumber.replace(/\+/g, '');
            contactNumber = contactNumber.replace(/,/g, '');
            contactNumber = contactNumber.replace(/\./g, '');
            contactNumber = contactNumber.replace(/\D/g, '');

            const onlyDigitRegExp = /^\d+$/;

            if (contactNumber.length === 0 || !onlyDigitRegExp.test(contactNumber))
                return null;

            const startWithZeroRegExp = /^0[0-9].*$/;

            return startWithZeroRegExp.test(contactNumber) ? contactNumber : "0" + contactNumber;
        }

        return null;
    }
}
