<template>
  <form @submit.prevent="Submit({ name: 'contact-details' })">
    <InputGroup
      id="title"
      name="form.titleId"
      label="Almost done, let us know who you are?"
      :items="[
        {label: 'Mr', value: 1, id: idConcat(pageName, 'Mr') },
        {label: 'Mrs', value: 2, id: idConcat(pageName, 'Mrs')},
        {label: 'Miss', value: 4, id: idConcat(pageName, 'Miss')},
        {label: 'Ms', value: 3, id: idConcat(pageName, 'Ms')}
      ]"
      v-model="$v.title.$model"
      :v="$v.title"
      :mask-input="true"
    />

    <InputText
      :id="idConcat(pageName, 'FirstName')"
      name="form.firstName"
      label="First name"
      autocomplete="off"
      v-model="$v.firstname.$model"
      :v="$v.firstname"
    />

    <InputText
      :id="idConcat(pageName, 'LastName')"
      name="form.lastName"
      label="Last name"
      autocomplete="off"
      v-model="$v.lastname.$model"
      :v="$v.lastname"
    />

    <ButtonContinue :id="idConcat(pageName, 'ContinueBtn')" />

    <ButtonBack :id="idConcat(pageName, 'BackBtn')" />
    <InfoFooter :showTopOnly="true" />
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      pageName: 'ApplicantName',
      title: this.$store.state.form.titleId,
      firstname: this.$store.state.form.firstName,
      lastname: this.$store.state.form.lastName
    }
  },
  validations: {
      title: {
        required
      },
      firstname: {
        required
      },
      lastname: {
        required
      }
  },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);
    
    this.$store.dispatch('updateFormValue', [false, 'form.personalDetailsCompleted'])
  }
}
</script>
