import axios from 'axios'
import { URLS } from '../factories'

export default {
    getCarAdvert(isProduction, advertId) {

        const pistonheadsCarAdvertApiUrl = isProduction ? URLS.production.pistonheadsCarAdvertApiUrl :  URLS.replica.pistonheadsCarAdvertApiUrl;

        return axios({
            method: 'GET',
            url: pistonheadsCarAdvertApiUrl + '/cars/v1/advertdetails',
            params: { advertId: advertId }
        });
    }
}