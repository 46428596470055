import { AffiliatesHelper } from '@/helpers/index';

const lessScreenHeightToApply = 900;

function isMobile() {
  return window.screen.height <= lessScreenHeightToApply;
}

export default {
  canCompactContactDetail(  ) {
    let canCompact = false;

    if (AffiliatesHelper.isAffiliate()) {
      const value = AffiliatesHelper.getPropertyValue('compactPageForContactDetail');
      canCompact = value ? value : false;
    }
    
    return canCompact && isMobile();
  }
}