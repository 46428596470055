<template>
  <div>
    <div
      ref="trustbox"
      class="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="5419b6ffb0d04a076446a9af"
      data-businessunit-id="5193e43b00006400052dd669"
      data-style-height="20px"
      data-style-width="420px"
      data-theme="light"
    >
      <a
        href="https://uk.trustpilot.com/review/carfinance247.co.uk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "TrustPilot",
  mounted() {
    const trustbox = this.$refs.trustbox;
    setTimeout(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustbox);
      var iOS = ( navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false );
      var ua = navigator.userAgent.toLowerCase();
      var isAndroid = ua.indexOf("android") > -1;

      var w = window.innerWidth;
      if( iOS || isAndroid){

        w = screen.width;
      }
 
      if (w < 420) {
        const parentWidth = w - 20 ;
        const elementWidth = 420;
        const scale = parentWidth / elementWidth;
        const widget = this.$el.querySelector(".trustpilot-widget");

        widget.style.transform = `scale(${scale})`;
        widget.style.transformOrigin ='left';
        widget.style.marginLeft ='10px';
      }
      
    }
    }, 2000);
  },
};
</script>

<style>
/* @media only screen and (max-width: 600px) {
  .trustpilot-widget {
    width: 420px;
    transform-origin: 0 0;
  }
} */

.trustpilot-widget iframe {
  margin: 0 auto;
}
</style>
