<template>
  <img alt="CompareTheMarket.com Logo" height="30" v-lazy="imgUrl" />
</template>

<script>
  export default {
    name: 'CompareTheMarketLogo',
    data () {
      return {
        imgUrl: require('@/assets/img/affiliates/compare-the-market.svg')
      }
    }
  }
</script>

<style scoped>

</style>