export default {
    formatAddressTown(address, town) {
        let reg = new RegExp(town,'gi')
        return address.replace(reg, town )
    },
    extractHouseNumber(street) {
        let data = street.split(" ");

        if (data && data.length > 0) {
            let houseNumber = data[0];

            if (!isNaN(houseNumber)) return houseNumber

            let rangeHouseNumber = data[0].split("-");

            if (rangeHouseNumber && rangeHouseNumber.length > 1) {
                return rangeHouseNumber.join('-');
            } else {
                let matches = houseNumber.match(/\d+/g)
                if (matches != null) {
                    let houseNumberData = houseNumber.split('/');

                    if ( houseNumberData && houseNumberData.length > 1 ) {
                        return houseNumberData[0];
                    }

                    return houseNumber
                }

                return ''
            }
        } else {
            return '';
        }
    },
    extractFlatNumber(property, street) {
        let data = property.split(",");

        if (data && data.length > 0) {
            let flatUnitcheckingList = ['apartment', 'flat', 'unit', 'room' ,'/'];
            let streetCheckingList = ['/'];
            let result = flatUnitcheckingList.filter( item =>  data[0].toLowerCase().indexOf(item) != -1 ).length > 0  ? data[0].trim() : '';

            if ( result == '' && street !== '') {
                let streetData = street.split(" ");

                if ( streetData && street.length > 0 ) {
                    result = streetCheckingList.filter( item =>  streetData[0].toLowerCase().indexOf(item) != -1 ).length > 0  ? streetData[0].trim() : '';

                    let checkUnitData = result.split('/');
                    if ( checkUnitData && checkUnitData.length > 1 ) {
                        result = checkUnitData[1];
                    }
                }
            }
            return result;
        } else {
            return '';
        }
    },
    extractBuildingName(property) {
        let data = property.split(",");

        if (data && data.length > 1) {
            return data[1];
        } else if (data && data.length > 0) {
            // flat, apt, room, unit

            let checkingList = ['apartment', 'flat', 'unit', 'room', '/'];
            return checkingList.filter( item =>  data[0].toLowerCase().indexOf(item) != -1 ).length == 0  ? data[0].trim() : '';
            //return data[0].toLowerCase().indexOf("apartment") == -1  ? data[0] : '';
        } else {
            return '';
        }
    },
    mapStreet(street) {
        if (!street) return street
        let data = street.split(" ")

        if (data && data.length > 0 && !isNaN(data[0]) || data[0].match(/\d+/g)) {
            data.splice(0, 1)
            return data.join(' ')
        }
        
        return street
    }
}
