<template>
    <form @submit.prevent="Submit(nextPage())">
        <InputYearsMonths
            :id="idConcat(pageName, 'TimeAtBank')"
            :label="yearsMonthsTitle"
            :invalidMessage="yearsMonthsInvalidMessage"
            showLabel
            :nameYears="'form.banks[0].yearsAtBank'"
            :nameMonths="'form.banks[0].monthsAtBank'"
            :valueMonths="monthsAtBank"
            :valueYears="yearsAtBank"
            :index="0"
            v-model="$v.timeAt.$model"
            :v="$v.timeAt"
            :vYears="$v.yearsAtBank"
            :vMonths="$v.monthsAtBank"
            :idYears="idConcat(pageName,'Years')"
            :idMonths="idConcat(pageName,'Months')"
        />

        <ButtonContinue :id="idConcat(pageName, 'ContinueBtn')" />

        <ButtonBack :id="idConcat(pageName, 'BackBtn')" />
        
        <InfoFooter :showTopOnly="true" />
    </form>
</template>

<script>
import {between, minValue, required} from 'vuelidate/lib/validators';
import {ContactDetails2PageHelper, LocationHelper, RequestHelper} from '@/helpers';
import {AFFILIATES} from '@/factories';

export default {
  data() {
      return {
        pageName: 'BankLength',
        timeAt: this.$store.getters.indexBankHistory(0),
      }
  },
  computed: {
    isNonAffiliatePersonalised() {
      let host = LocationHelper.getHost();
      return RequestHelper.getQueryVariable('personalised') && AFFILIATES[host] === undefined;
    },
    yearsAtBank() {
      return this.$store.getters.indexBankYears(0);
    },
    monthsAtBank() {
      return this.$store.getters.indexBankMonths(0);
    },
    yearsMonthsTitle() {
      return 'How long have you been with your bank?';
    },
    yearsMonthsInvalidMessage() {
      if (this.$v.yearsAtBank.$invalid) return 'Please enter a number between 0 and 80 years.'
      if (this.$v.monthsAtBank.$invalid) return 'Please enter a number between 0 and 11 months.'

      return 'Please tell us how long you’ve been with this bank.'
    },
  },
  validations() {
    return {
      timeAt: {
        required,
        minValue: minValue(0.0000001)
      },
      yearsAtBank: {
        between: between(0, 80)
      },
      monthsAtBank: {
        between: between(0, 11)
      }
    }
  },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);

    this.$store.dispatch('updateFormValue', [true, 'form.bankLengthCompleted'])
  },
  methods: {
    nextPage: function() {
      if (this.isNonAffiliatePersonalised) {
        return { name: 'contact-details-p2' }
      }

      if(ContactDetails2PageHelper.enableContactDetailsTwoPages()) {
        return {name: 'contact-details-p1'};
      }

      return { name: 'contact-details-optimised' };
    }
  }
}
</script>
