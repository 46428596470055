<template>
  <div id="calculator-interstitial">
    <div v-if="isCompareTheMarketCarFinance" class="body-container text-body1">
      <p>
        Compare the Market have partnered with Car Finance 247, a leading car finance broker, to help find the right deal for your circumstances.
      </p>
      <p>
        You'll receive a decision in minutes and be paired with an account manager, who will be on hand to guide you through the car buying process.
      </p>
      <p>
        Use our car finance calculator to estimate your monthly payments.
      </p>
    </div>
    <div class="calculator-container">
      <iframe v-if="$store.state.isProduction" id="CarFinance_Calculator_Container" name="CarFinance_Calculator_Container" scrolling="no" src="https://partner-calculator.carfinance247.co.uk/?defaultScore=Excellent&amount=20000&overrideApply=true&overrideCredScore=true&removeBackground=false&aid=42416"
               frameBorder="0"
               :style="{height: frameHeight + 'px', width: '100%'}"></iframe>

      <iframe v-if="!$store.state.isProduction" id="CarFinance_Calculator_Container" name="CarFinance_Calculator_Container" scrolling="no" src="https://cf247-calculator-dev.azurewebsites.net/?defaultScore=Excellent&amount=20000&overrideApply=true&overrideCredScore=true&removeBackground=false&aid=42416"
              frameBorder="0"
              :style="{height: frameHeight + 'px', width: '100%'}"></iframe>
    </div>
  </div>
</template>

<script>

import {LocationHelper} from '@/helpers';
import {AnalyticsService} from '@/services';

export default {
  name: 'Calculator_Interstitial',
  props: {
    startPage: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      frameHeight: 650,
      messageHandler: () => {},
      isCompareTheMarketCarFinance: LocationHelper.getHost() === 'comparethemarketcarfinance'
    }
  },
  methods: {
    btnClicked() {
      this.$emit('setInterstitial', false);
      this.$router.push({ path: this.startPage }).catch(() => { });
    }
  },
  mounted() {
    this.messageHandler = (event) => {
      switch (event.data.type) {
        case "cf247-calc-height":
          this.frameHeight = event.data.height;
          break;
        case "cf247-improve-cred-score-click":
          window.open("https://www.comparethemarket.com/credit-cards/content/building-your-credit-score/");
          break;
        case "cf247-apply-click":
          AnalyticsService.trackEvent({
            event_name: 'start_your_application',
            event_category: 'calculator_interstitial'
          }, this.btnClicked, 0, true);
          break;
        case "cf247-change-finance-product":
          AnalyticsService.trackEvent({
            event_name: 'change_finance_type',
            event_category: 'calculator_interstitial'
          });
          break;
        case "cf247-change-credit-score":
          AnalyticsService.trackEvent({
            event_name: 'change_credit_score',
            event_category: 'calculator_interstitial'
          });
          break;
        case "cf247-change-finance-term":
          AnalyticsService.trackEvent({
            event_name: 'change_finance_term',
            event_category: 'calculator_interstitial'
          });
          break;
        case "cf247-change-finance-amount":
          AnalyticsService.trackEvent({
            event_name: 'change_finance_amount',
            event_category: 'calculator_interstitial'
          });
          break;
        default:
          break;
      }
    };

    window.addEventListener("message", this.messageHandler);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.messageHandler);
  }
}
</script>

<style lang="scss" scoped>
  #calculator-interstitial {
    .calculator-container {
      max-width: 920px;
      margin: 0 auto;
      width: 100%;
      padding-top: 15px;
    }

    .body-container {
      max-width: 920px;
      margin: 0 auto;
      width: 100%;
      padding: 0 20px;
    }

    .text-body1 {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -.2px;
      line-height: 22px;
      @include font-regular();
      -webkit-text-size-adjust: 100%;
      -webkit-font-smoothing: antialiased;
      color: #000000;
    }
    .text-subtitle1 {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -.2px;
      line-height: 18px;
    }
  }
</style>