import { InterstitialHelper, LocationHelper, RequestHelper } from '@/helpers/index';
import { AFFILIATES } from '@/factories';

function fetchStartPage(includeInterstitial) {
    let startPage = 'finance-details';

    let vehicle_type = RequestHelper.getQueryVariable('vehicle_type');
    let vehicleid = RequestHelper.getQueryVariable('vehicleid');
    let vrm = RequestHelper.getQueryVariable('vrm');
    let advertId = RequestHelper.getQueryVariable('advertId');

    let host = LocationHelper.getHost();
    const isCarcraft = LocationHelper.getHost().startsWith('carcraft');

    if (vehicle_type) {
        startPage = 'driving-licence';
    }

    if ( (vehicleid || vrm || advertId) && !isCarcraft ) {
        startPage = 'get-my-quote';
    }

    if (AFFILIATES[host] !== undefined) {
        const affiliateConfig = AFFILIATES[host];

        if (affiliateConfig && typeof affiliateConfig.startPage !== "undefined") {
            startPage = affiliateConfig.startPage
        }

        if (includeInterstitial) {
            const showInterstitial = InterstitialHelper.showInterstitial(affiliateConfig);

            if (showInterstitial) {
                startPage = 'interstitial';
            }
        }
    }

    let personalised = RequestHelper.getQueryVariable('personalised');

    if (personalised && (startPage !== 'interstitial' ||  startPage !== 'get-my-quote')) {
        if (AFFILIATES[host] !== undefined) {
            startPage = 'contact-details-personalised';
        } else {
            startPage = 'contact-details-p1';
        }
    }

    const isCkdirect = LocationHelper.getHost().startsWith('ckdirect');
    if (RequestHelper.getQueryVariable('test') === 'skip-first-page' || isCkdirect) {
        startPage = 'driving-licence';
    }

    return startPage;
}

function getComponentByRouteName(name) {
    switch (name) {
        case 'get-my-quote':
            return import('@/views/get-my-quote.vue');
        case 'finance-details':
            return import('@/views/finance-details.vue');
        case 'driving-licence':
            return import('@/views/driving-licence.vue');
        case 'interstitial':
            return import('@/views/Affiliates/Affiliates_Interstitial.vue');
        default: {
            const host = LocationHelper.getHost();
            const personalised = RequestHelper.getQueryVariable('personalised');

            if (personalised && AFFILIATES[host] === undefined) {
                return import('@/views/contact-details-p1.vue');
            }

            if (personalised && AFFILIATES[host] !== undefined) {
                return import('@/views/contact-details-personalised.vue');
            }

            return import('@/views/finance-details.vue');
        }
    }
}

export default {
    getStartPageComponent(includeInterstitial = true) {
        return getComponentByRouteName(fetchStartPage(includeInterstitial));
    },
    getStartPageRouteName(includeInterstitial = true) {
        return fetchStartPage(includeInterstitial);
    }
}