<template>
  <form @submit.prevent="Submit(nextPage)">

    <h1 :class="SENSITIVE_DATA.p11Mask">{{address}}</h1>
    <p class="small-print">Lenders ask for 3 years' address history.</p>

    <InputYearsMonths
      id="time-at-address"
      label="Time at address"
      :nameYears="'form.addresses[' + index + '].yearsAtAddress'"
      :nameMonths="'form.addresses[' + index + '].monthsAtAddress'"
      v-model="$v.timeAt.$model"
      :valueMonths="monthsAtAddress"
      :valueYears="yearsAtAddress"
      :index="index"
      :v="$v.timeAt"
      :vYears="$v.yearsAtAddress"
      :vMonths="$v.monthsAtAddress"
      :invalidMessage="dateInvalidMessage"
      :idYears="idConcat(pageName,'Years')"
      :idMonths="idConcat(pageName,'Months')"
    />


    <AddressHistory v-if="index === 0" first />
    <AddressHistory v-else />

    <ButtonContinue :id="idConcat(pageName,'ContinueBtn')" />
    <ButtonBack :id="idConcat(pageName,'BackBtn')" />
    <InfoFooter :showTopOnly="true" />
  </form>
</template>

<script>
import { between, required, minValue } from 'vuelidate/lib/validators'
import { SENSITIVE_DATA } from '../const';
import {AnalyticsService} from '@/services';
import {
  ContactDetails2PageHelper,
  DealersHelper,
  LocationHelper,
  RequestHelper,
  ReorderHelper,
  EmploymentReOrderHelper
} from '@/helpers';

let addressNo, index

export default {
  beforeRouteEnter (to, from, next) {
    addressNo = to.params.number
    index = to.params.number - 1
    next()
  },
  data() {
    return {
      pageName: 'AddressLength',
      addressNo: addressNo,
      index: index,
      timeAt: this.$store.getters.indexAddressHistory(index),
      residentialStatusId: this.$store.state.form.addresses[index].residentialStatusId,
      SENSITIVE_DATA: SENSITIVE_DATA,
      pageViewSubmitted: false,
      isCompareTheMarketReorder: LocationHelper.getHost() === 'comparethemarketcarfinance' && RequestHelper.getQueryVariable('test') === 'reorder',
      isCKDirectReorder: LocationHelper.getHost() === 'ckdirect',
    }
  },
  computed: {
    isSocialRecorder() {
      const host = ['social','ecrm', 'tiktok', 'social-premier', 'social-vans', 'social-bad-credit'];

      return host.indexOf(LocationHelper.getHost()) !== -1;
    },
    nextNumber() {
      let number = parseInt(this.$route.params.number)
      number = number + 1
      return number
    },
    yearsAtAddress(){
      return this.$store.getters.indexAddressYears(index)
    },
    monthsAtAddress(){
      return this.$store.getters.indexAddressMonths(index)
    },
    isEmploymentReOrder(){
      return EmploymentReOrderHelper.isEmploymentReOrder();
    },
    nextPage() {
      if(this.$store.getters.totalAddressHistory >= 3){
        if (this.isEmploymentReOrder || this.isCKDirectReorder || this.isSocialRecorder) {
          return { name: 'employment' , params: { number: 1 } }
        }

        if( ReorderHelper.isReorderMulti(['cargurus', 'pistonheads']) || ReorderHelper.isGenericReorder()) {
            let result = ReorderHelper.getNextRoute('generic', this.$router.currentRoute.name );
            if(Object.keys(result).length> 0 ){
            return result;
            } 
        }

        if (this.isCompareTheMarketReorder) {
          if (DealersHelper.isDealer()) {
            return { name: 'car-finance-detail' }
          }

          if (this.isNonAffiliatePersonalised) {
            return { name: 'contact-details-p2' }
          }

          if(ContactDetails2PageHelper.enableContactDetailsTwoPages()) {
            return {name: 'contact-details-p1'};
          }

          return { name: 'contact-details-optimised' };
        }

        return { name: 'amount-to-finance' }
      } else {
        return { name: 'address-add' , params: { number: this.nextNumber } }
      }
    },
    dateInvalidMessage() {
      if(this.$v.yearsAtAddress.$invalid) return 'Please enter a number between 0 and 80 years.'
      if(this.$v.monthsAtAddress.$invalid) return 'Please enter a number between 0 and 11 months.'
      if(this.index === 0){
        return 'Please tell us how long you’ve lived here.'
      } else {
        return 'Please tell us how long you lived there.'
      }
    },
    address() {
      let livedAtStr = ""
      if (index === 0) {
        livedAtStr = "How long have you lived at"
      } else {
        livedAtStr = "And how long did you live at"
      }

      if (this.$store.state.savedAddresses[index].manual) {
        const buildingNumber = this.$store.state.form.addresses[index].buildingNumber || ''
        const flatNumber = this.$store.state.form.addresses[index].flatNumber || ''
        const street = this.$store.state.form.addresses[index].street || ''
        const town = this.$store.state.form.addresses[index].town || ''
        const buildingName = this.$store.state.form.addresses[index].buildingName || ''
        const postcode = this.$store.state.form.addresses[index].postcode || ''

        return `${livedAtStr} ${buildingName} ${buildingNumber} ${flatNumber} ${street} ${town}. ${postcode}?`
      }

      let idx = this.$store.state.fullAddresses.findIndex(i => i.index === index)

      return `${livedAtStr} ${this.$store.state.fullAddresses[idx].address}?`
    },
  },
  validations: {
      timeAt: {
        required,
        minValue: minValue(0.0000001)
      },
      monthsAtAddress: {
        between: between(0, 11)
      },
      yearsAtAddress: {
        between: between(0, 80)
      },
      residentialStatusId: {
        required
      }
  },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);
    
    this.$store.dispatch('updateFormValue', [true, 'form.addressStatusCompleted'])

    if (!this.pageViewSubmitted) {
      this.pageViewSubmitted = true;
      AnalyticsService.trackPageView({
        page_name: 'address-length'
      });
    }
  }
}
</script>


