<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <section class="modal-body modal-container" id="modalDescription">
          <div class="button-wrapper">
            <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2.12158" width="25" height="3" rx="1.5" transform="rotate(45 2.12158 0)" fill="#4a4a4a"/>
                <rect x="19.7988" y="2.12134" width="25" height="3" rx="1.5" transform="rotate(135 19.7988 2.12134)" fill="#4a4a4a"/>
              </svg>

            </button>
          </div>
          <div class="col-1" v-show="!dualBand && !isPartnerOverlayTest">
            <PowerBy :callByModel="true" width="183" height="37.72" />
          </div>
          <div class="col-1-dual" v-show="dualBand || isPartnerOverlayTest" >
            <AffiliateLogo />
            <PowerBy :callByModel="true" width="150" height="30" />
          </div>
          <div class="col-2">
            <img class="img1" src="@/assets/img/model_mobile_bg_1.png" />
            <img :class="isPartnerOverlayTest ? ['img2-overlay'] : ['img2']" src="@/assets/img/model_v3_bg_2.png" />
          </div>
          <div class="col-3">
            <slot name="body" ></slot>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import PowerBy from "./Affiliates/PowerBy";
import AffiliateLogo from '@/components/AffiliateLogo.vue';
import {LocationHelper, RequestHelper} from '@/helpers';
import {AFFILIATES} from '@/factories';

export default {
  name: "ModelMobile",
  components: {
    AffiliateLogo,
    PowerBy,
  },
  data() {
    return {
      dualBand: false
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  },
  computed: {
    isPartnerOverlayTest() {
      return RequestHelper.getQueryVariable('test') === 'partner-overlay';
    },
    showAffiliateLogo() {
      return this.$store.state.affiliateConfig.modalShowLogo;
    },
  },
  mounted() {
    let host = LocationHelper.getHost();
    if (AFFILIATES[host] !== undefined) {
      const affiliateConfig = AFFILIATES[host];
      if (affiliateConfig && affiliateConfig.useModalV3DualBand !== undefined) {
        this.dualBand = affiliateConfig.useModalV3DualBand;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cflogo-displacement {
  position: relative;
}

.-m-auto {
  margin: auto;
}

@media (min-width: 412px) {
  .cflogo-displacement {
    position: relative;
  }
}

@media (min-width: 800px) {
  .cflogo-displacement {
    position: relative;
  }

  .-m-auto {
    margin: unset;
  }
}

.partnerLogo-displacement {
  position: relative;
  left: -5px;
}

.dualband-displacement {
  max-height: 70px;
  margin-bottom: 10px;
}

.dualband-displacement img {
  display: block;
  margin-left: auto;
  margin-right: auto;
} 

.button-wrapper {
  display: block;

      @media (max-width: 800px) {
        position: fixed;
        right: 0px;
        z-index: 9999;
    }
}

.modal {
  background: #fff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  border-radius: 10px;
  width: 100%;
  // padding: 25px; remove
  position: relative;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  margin: 25px auto;

  @media screen and (max-height: 690px),
         screen and (max-width: 412px) {
    max-height: 100%;
  }

  &-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    z-index: 11;
    overflow: hidden;
  }

  &-header {
    display: flex;
    justify-content: space-between;
  }

  &-body {
    position: relative;
  }

  header {
    padding: 0;
    margin: 0;
  }

  h1 {
    margin: 0;
  }

  .btn-close {
    border: none;
    font-size: 20px;
    padding: 15px;
    cursor: pointer;
    background: transparent;
     position: absolute;
    z-index: 1;
    top: 0;
    right: 0;

   
  
    border-radius: 20px;
    transition: 0.3s ease-in-out;
    color: #DEDEDE;
    font-weight: 400;

    svg {
      display: block;
    }

    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.modal-fade-leave-active {
  -webkit-animation: vodal-fade-leave both ease-out;
  animation: vodal-fade-leave both ease-out;
}

.modal-container {
  display: inline-block; 
  overflow-y: auto; 
  overflow-x: hidden; 
  width:100%; 

}

.col-2 > div  {
  height: 100%;

}

.col-1, .col-2 {
  position: relative;
  width: 100%;
  background-color: #F8F8F8;
  display: block;
}

.col-1 {
  text-align: center;
  padding-top: 25px;
  padding-bottom: 10px;
}

.col-1-dual {
  width: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;

  text-align: center;
  padding-top: 25px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 50px;
}

.col-2 {
  height: 116px;
  margin-bottom: 50px;
  position: relative;
}

.col-2 .img1 {  
  position: absolute;
  width: 100%;
  height: 100%;
  
}

.col-2 .img2 {
  position: absolute;
  width: 217px;
  display: block;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.col-2 .img2-overlay {
  position: absolute;
  width: 300px;
  display: block;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.col-3 {
  display: block;;
  
}

  .row {
    display: flex;
    justify-content: space-between;
    margin-left: 25px;
  }

  .column {
    padding: 5px;
  }
</style>
