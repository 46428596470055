import axios from 'axios'
import { URLS } from '../factories'

export default {
    loadAgent(store) {
        const allstarsApiUrl = store.state.isProduction ? URLS.production.allstarsApiUrl :  URLS.replica.allstarsApiUrl;

        let tokenString = 'Bearer ' + store.state.token.authenticationToken
        let headersObj = {"Authorization": tokenString}

        let agentApplication = {}
        agentApplication.agentPublicId = store.state.form.utmParameters.agentPublicId
        agentApplication.applicationGuid = store.state.GUID

        axios({
            method: 'POST',
            url: allstarsApiUrl + '/application',
            headers: headersObj,
            data: agentApplication
        }).catch(error => {
            window.console.error(error.message)
        })
    }
}