<template>
  <form id="Form" @submit.prevent="Submit(nextPage())">
    <h1>{{ getLabel }}</h1>
    <InputBorrow
        :id="idConcat(pageName,'LoanAmount')"
        name="form.loanAmount"
        label=""
        :valueOverride="loanAmountUnknown"
        smallPrint="You can change this later on."
        v-model="$v.loanAmount.$model"
        :v="$v.loanAmount"
        bigLabel
        :appearDisabled=true
        v-show="!(isMonthlyBudgetAllowed && hasMonthlyBudget) && showLoanAmount"
        :max-borrow-threshold="maxBorrow"
        :min-borrow-threshold="minBorrow"
    />
    <ButtonContinue :id="idConcat(pageName,'ContinueBtn')">Continue</ButtonContinue>
    <ButtonBack :id="idConcat(pageName,'BackBtn')" />
    <InfoFooter :showTopOnly="true" />
  </form>
</template>

<script>
import InputBorrow from '@/components/InputBorrow.vue'
import {DealersHelper, ContactDetails2PageHelper, LocationHelper, RequestHelper, ReorderHelper} from '@/helpers';
import {AFFILIATES} from '@/factories';
import {AnalyticsService } from '../services';

function validateLoanAmount() {
  if (this.loanAmount === null || this.loanAmount === undefined) {
    return this.allowDefaultLoanAmount;
  }

  if (this.loanAmount >= this.minBorrow && this.loanAmount <= this.maxBorrow) {
    return true;
  }

  return false;
}

export default {
  components: {
    InputBorrow,
  },
  data() {
    return {
      pageName: 'AmountToFinance',
      loanAmount: this.$store.state.form.loanAmount,
      loanAmountUnknown: this.$store.state.loanAmountUnknown,
      hasMonthlyBudget: this.$route.query.monthlyBudget,
      showLoanAmount: this.$store.state.showLoanAmount,
      isMonthlyBudgetAllowed: this.$store.state.isMonthlyBudgetAllowed,
      pageViewSubmitted: false,
      isCompareTheMarketReorder: LocationHelper.getHost() === 'comparethemarketcarfinance' && RequestHelper.getQueryVariable('test') === 'reorder',
      isCKDirectReorder: LocationHelper.getHost() === 'ckdirect',
    }
  },
  validations: {
    loanAmount: {
      validateLoanAmount
    }
  },
  computed: {
    isSocialRecorder() {
      const host = ['social','ecrm', 'tiktok', 'social-premier', 'social-vans', 'social-bad-credit'];

      return host.indexOf(LocationHelper.getHost()) !== -1;
    },
    allowDefaultLoanAmount() {

      let host = LocationHelper.getHost();

      if (AFFILIATES[host] !== undefined){
        const affiliateConfig = AFFILIATES[host];

        if (affiliateConfig.allowDefaultLoanAmount !== undefined) {
          return affiliateConfig.allowDefaultLoanAmount;
        }
      }

      return true;
    },
    minBorrow() {
      let defaultMinBorrow = 1;

      let host = LocationHelper.getHost();

      if (AFFILIATES[host] !== undefined){
        const affiliateConfig = AFFILIATES[host];

        if (affiliateConfig.minLoanAmount !== undefined && affiliateConfig.minLoanAmount) {
          return affiliateConfig.minLoanAmount;
        }
      }

      return defaultMinBorrow;
    },
    maxBorrow() {
      let defaultMaxBorrow = 1000000;

      let host = LocationHelper.getHost();

      if (AFFILIATES[host] !== undefined){
        const affiliateConfig = AFFILIATES[host];

        if (affiliateConfig.maxLoanAmount !== undefined && affiliateConfig.maxLoanAmount) {
          return affiliateConfig.maxLoanAmount;
        }
      }

      return defaultMaxBorrow;
    },
    isNonAffiliatePersonalised() {
      let host = LocationHelper.getHost();
      return RequestHelper.getQueryVariable('personalised') && AFFILIATES[host] === undefined;
    },
    getLabel() {
      let label = "Roughly how much would you like to borrow?";

      if (this.isNonAffiliatePersonalised) {
        const name = this.$store.getters.any('form.firstName');
        label = `Roughly how much would you like to borrow ${name}?`;
      }

      return label;
    },
  },
  methods: {
    nextPage: function() {
      if (this.isCompareTheMarketReorder) {
        return { name: 'address-add', params: { number: 1 } }
      }

      if( ReorderHelper.isReorderMulti(['cargurus', 'pistonheads']) || ReorderHelper.isGenericReorder()) {
          let result = ReorderHelper.getNextRoute('generic', this.$router.currentRoute.name );
          if(Object.keys(result).length> 0 ){
          return result;
          } 
      }

      if (DealersHelper.isDealer()) {
        return { name: 'car-finance-detail' }
      }

      if (this.isNonAffiliatePersonalised) {
        return { name: 'contact-details-p2' }
      }

      if(ContactDetails2PageHelper.enableContactDetailsTwoPages() || this.isCKDirectReorder || this.isSocialRecorder) {
        return {name: 'contact-details-p1'};
      }

      return { name: 'contact-details-optimised' };
    }
  },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);
    
    this.$store.dispatch('updateFormValue', [true, 'form.personalDetailsCompleted'])

    if (!this.pageViewSubmitted) {
      this.pageViewSubmitted = true;
      AnalyticsService.trackPageView({
        page_name: 'amount-to-finance'
      });
    }
  }
}
</script>
