<template>
  <img alt="Cheap Cars For Sale Logo" width="120" v-lazy="imgUrl" />
</template>

<script>
export default {
  name: "CheapCarsForSaleLogo",
    data () {
    return {
      imgUrl:  require('@/assets/img/affiliates/cheapcarsforsale.png')
    }
  }
}
</script>

<style scoped>

</style>