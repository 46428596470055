<template>
    <img  alt="Loaneo Logo"  height="28.11" v-lazy="imgUrl" />
</template>

<script>
export default {
  name: "Loaneo",
  data () {
    return {
      imgUrl:  require('@/assets/img/affiliates/Loaneo-Logo-01.png')
      //imgUrl:  require('@/assets/img/affiliates/Loaneo-Logo-02.png')
      //imgUrl:  require('@/assets/img/affiliates/Loaneo-Logo-03.png')
      //imgUrl:  require('@/assets/img/affiliates/Loaneo-Logo-04.png')
    }
  }
}
</script>

<style scoped>

</style>
