export default {
    'generic': {
        'order': [
            { name: 'driving-licence' },
            { name: 'marital-status'},
            { name: 'date-of-birth'},
            { name: 'address-add' , params: { number: 1 } },
            { name: 'address-status' , params: { number: 1 } },
            { name: 'address-length' , params: { number: 1 } },
            { name: 'employment' , params: { number: 1 } },
            { name: 'employment-location' , params: { number: 1 } },
            { name: 'employment-length' , params: { number: 1 } },
            { name: 'employment-income' , params: { number: 1 } },
            { name: 'amount-to-finance'},
            { name: 'contact-details-p1'},
            { name: 'contact-details-p2'}
        ],
        'progress': [
            { toName: "start", stageNo: 0 },
            { toName: "driving-licence", stageNo: 1 },
            { toName: "marital-status", stageNo: 2 },
            { toName: "date-of-birth", stageNo: 3 },
            { toName: "address", stageNo: 4 },
            { toName: "employment", stageNo: 5 },
            { toName: "amount-to-finance", stageNo: 6 },
            { toName: "contact-details-p1", stageNo: 7 },
            { toName: "contact-details-p2", stageNo: 8 },
        ]
    },
}