<template>
    <div>
        <header v-if="!isAffiliate">
            <span class="secure" aria-label="CarFinance 247">
                <Logo />
            </span>
            <span class="secure">
                <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                          d="M14 10H2C1.44772 10 1 10.4477 1 11V20C1 20.5523 1.44772 21 2 21H14C14.5523 21 15 20.5523 15 20V11C15 10.4477 14.5523 10 14 10Z"
                          stroke="#604DD5" stroke-width="2" stroke-linecap="round"/>
                  <path
                          d="M8 17C9.10457 17 10 16.1046 10 15C10 13.8954 9.10457 13 8 13C6.89543 13 6 13.8954 6 15C6 16.1046 6.89543 17 8 17Z"
                          fill="#604DD5"/>
                  <path d="M8 17V17.5" stroke="#604DD5" stroke-width="2" stroke-linecap="square"/>
                  <path d="M12 10V5.186C12 2.874 10.21 1 8 1C5.79 1 4 2.874 4 5.186V9.676" stroke="#604DD5" stroke-width="2"/>
                </svg>
                Secure
          </span>
        </header>
        <AffiliateHeaderTestB v-else-if="isTestB" />
        <AffiliateHeader v-else />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Logo from "./Logo";
    import {LocationHelper} from "../helpers";
    import {AFFILIATES} from '@/factories';
    import AffiliateHeader from "./AffiliateHeader";
    import AffiliateHeaderTestB from "./AffiliateHeaderTestB";

    export default {
        name: "HeaderLogo",
        computed: {
          ...mapState({
            isAffiliate: state => state.subdomain ? true : false,
          })
        },
        data() {
          return {
            isTestB: false,
            isCompareTheMarket: LocationHelper.getHost() === 'comparethemarket',
            isCompareTheMarketCarFinance: LocationHelper.getHost() === 'comparethemarketcarfinance',
          }
        },
        components: {
            AffiliateHeader,
            AffiliateHeaderTestB,
            Logo,
        },
        methods: {
          setSymplify() {
            const url = this.isCompareTheMarket || this.isCompareTheMarketCarFinance ? 'https://cdn-sitegainer.com/5620657/es6/index.bundle.js' : 'https://cdn-sitegainer.com/5620442/es6/index.bundle.js';
            document.querySelector('#symplify-placeholder').setAttribute('src', url);
          }
        },
        mounted() {
          let host = LocationHelper.getHost();

          this.setSymplify();

          if (AFFILIATES[host] !== undefined) {
            const affiliateConfig = AFFILIATES[host];

            if (affiliateConfig && affiliateConfig.swapLogoSide !== undefined ) {
              this.swapLogoSide = affiliateConfig.swapLogoSide;
            }

            this.isTestB = affiliateConfig.testB ?? false;
          }
        }
    }
</script>

<style scoped>

</style>
