export default {
    getQueryVariable(variable){
        let query = window.location.search.substring(1)
        let vars = query.split("&");
        for (let i=0;i<vars.length;i++) {
            let pair = vars[i].split("=");
            if (pair[0].toLowerCase() == variable.toLowerCase()) { return decodeURIComponent(pair[1]) }
        }
        return null;
    },
    getQueryVariableFromUrl(url, variable){
        let anchor = document.createElement('a');
        anchor.href = url;
        let query = anchor.search.substring(1)
        let vars = query.split("&");
        for (let i=0;i<vars.length;i++) {
            let pair = vars[i].split("=");
            if (pair[0].toLowerCase() == variable.toLowerCase()) { return decodeURIComponent(pair[1]) }
        }
        return null;
    }
}