<template>
	<section>
		<div class="duel-inputs date-mod" id="date-mod">
             <label class="labelDateWrapper"><div class="textlabel" :class="{'textlabelDate': this.valueMonth || this.valueDay || this.valueYear}">{{ label }}</div></label>
			<InputNumberRefined
					:id="id + '-day'"
					:name="nameDay"
					:labeledBy="id"
                    label=""
					placeholder="DD"
					autocomplete="bday-day"
					maxlength="2"
					smallInput
					noPadding
          class="date-day-mod"
          v-model="dobDay"
          @input="changeDobDay"
          :sensitive-data="this.sensitiveData"
			/>
            <h2>/</h2>
			<InputNumberRefined
					:id="id + '-month'"
					:name="nameMonth"
					:labeledBy="id"
                    label=""
					placeholder="MM"
					autocomplete="bday-month"
					maxlength="2"
					smallInput
					noPadding
          class="date-month-mod"
          v-model="dobMonth"
          @input="changeDobMonth"
          :sensitive-data="this.sensitiveData"
			/>
            <h2>/</h2>
			<InputNumberRefined
					:id="id + '-year'"
					:name="nameYear"
					:labeledBy="id"
                    label=""
					placeholder="YYYY"
					autocomplete="bday-year"
					maxlength="4"
					smallInput
					noPadding
          class="date-year-mod"
          @input="changeDobYear"
          :sensitive-data="this.sensitiveData"
			/>
		</div>
		<template v-if="v.$anyDirty">
			<p class="error" v-if="invalidDay && invalidMonth && invalidYear">{{ invalidMessage }}</p>
			<p class="error" v-else-if="invalidDay">{{ invalidDay }}</p>
			<p class="error" v-else-if="invalidMonth">{{ invalidMonth }}</p>
			<p class="error" v-else-if="invalidYear" >{{ invalidYear }}</p>
			<p class="error" v-else-if="enableMinAgeValidation && age < minAge && !invalidDay && !invalidMonth && !invalidYear">
        {{ minAgeMessage }}
      </p>
			<p class="error" v-else-if="enableMaxAgeValidation && age >= 80 && !invalidDay && !invalidMonth && !invalidYear">
				Unfortunately, we can only arrange finance for people under 80 years old.</p>
		</template>

		<p class="small-print" v-if="smallPrint">{{ smallPrint }}</p>
		<p class="birthday" v-if="itsYourBirthday && valueYear && !v.$invalid">
			You're {{age}} today
			<template v-if="!v.$invalid">? <br/>Happy Birthday! 🥳</template>
			<template v-else>? 🤔</template>
		</p>

	</section>

</template>

<script>
    import {LocationHelper} from '@/helpers';
    import {AFFILIATES} from '@/factories';

    export default {
      data() {
        return {
          dobDay: 0,
          dobMonth: 0
        }
      },
        props: {
            id: {
                type: String,
                default() {
                    return 'input-date-' + this._uid
                }
            },
            label: {
                type: String
            },
            hideLabel: {
                type: Boolean,
                default: false
            },
            name: {
                type: String
            },
            nameDay: {
                type: String
            },
            nameMonth: {
                type: String
            },
            nameYear: {
                type: String
            },
            index: {
                type: Number
            },
            smallPrint: {
                type: String
            },
            autocomplete: {
                type: String,
                default: 'off'
            },
            placeholder: {
                type: String
            },
            invalidMessage: {
                type: String,
                default() {
                    return 'Please enter your ' + this.label.toLowerCase() + '.'
                }
            },
            valueDay: {
                type: Number,
                default: 0
            },
            valueMonth: {
                type: Number,
                default: 0
            },
            valueYear: {
                type: Number,
                default: 0
            },
            v: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            minDate: {
                type: Date,
            },
            maxDate: {
                type: Date,
            },
          sensitiveData: {
            type: Boolean,
            default: false
          }
        },
        methods: {
          changeDobDay() {
            if (this.dobDay) {
                const dayStr = this.dobDay.toString();
                if (dayStr.length >= 2) {
                    document.getElementById(this.id + '-month').focus()
                }
            }
          },
          changeDobMonth() {
            if (this.dobDay) {
              const dayStr = this.dobDay.toString();
              if (dayStr.length === 1) {
                const dayPadded = `0${this.dobDay}`

                let newdata = [dayPadded, 'dobDay']
                this.$store.dispatch('updateFormValue', newdata)
              }
            }
            if (this.dobMonth) {
              const monthStr = this.dobMonth.toString();
              if (monthStr.length >= 2) {
                document.getElementById(this.id+'-year').focus()
              }
            }
          },
          changeDobYear() {
            if (this.dobMonth) {
              const dayMonth = this.dobMonth.toString();
              if (dayMonth.length === 1) {
                const monthPadded = `0${this.dobMonth}`

                let newdata = [monthPadded, 'dobMonth']
                this.$store.dispatch('updateFormValue', newdata)
              }
            }
          }
        },
         computed: {
           minAge() {
             let host = LocationHelper.getHost();

             var hostCheck = ['carcraft', 'social'];
             if ( hostCheck.indexOf(host) !== -1) {
                return 25;
             }

             return 18;
           },
           minAgeMessage() {
             let host = LocationHelper.getHost();

             var hostCheck = ['carcraft', 'social'];
             if ( hostCheck.indexOf(host) !== -1) {
               return "We’re sorry, currently we can’t help applicants under the age of 25 years old.";
             }

             return "We can only arrange finance for over 18s. Come back in a few years – we’ll be happy to help!";
           },
           enableMinAgeValidation() {
             return true;
           },
           enableMaxAgeValidation() {
             let host = LocationHelper.getHost();

             if (AFFILIATES[host] !== undefined) {
               return true;
             }
             return false;
           },
            dateLabel() {
                return 'date-' + this.id
            },
            itsYourBirthday() {
                let a = this.date
                let b = new Date()
                if (a.getDate() === b.getDate() && a.getMonth() === b.getMonth()) return true
                return false
            },
            age() {
                var today = new Date();
                var birthDate = new Date(this.date)
                var age = today.getFullYear() - birthDate.getFullYear()
                var m = today.getMonth() - birthDate.getMonth()
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age = age - 1;
                }
                return age
            },
            invalidDay() {
                let v = this.valueDay

                if (v > 31 || v < 0) {
                    return 'Please enter a day between 1 and 31.'
                }
                if (!v) {
                    return 'What day were you born?'
                }
                return null
            },
            invalidMonth() {
                let v = this.valueMonth

                if (v > 12 || v < 0) {
                    return 'Please enter a month between 1 and 12.'
                }
                if (!v) {
                    return 'What month were you born?'
                }
                return null

            },
            invalidYear() {
                let v = this.valueYear

                if (!v) {
                    return 'What year were you born?'
                }
                if (v < 1000) {
                    return 'Please enter the year as 4 numbers \'YYYY\'.'
                }
                if (v > new Date().getFullYear()) {
                    if (v.length > 4) {
                        return 'It looks like you\'ve entered the year incorrectly.'
                    }
                    return 'Please try again. We can’t arrange finance for people from the future.'
                }

                return null

            },
            date() {
                let This = this
                let day = Math.abs(This.valueDay)
                let month = Math.abs(This.valueMonth)
                let year = Math.abs(This.valueYear)
                let date = new Date(year, month - 1, day)

                if (this.valueDay && this.valueMonth && this.valueYear.toString().length === 4 && this.valueYear) {
                    this.$emit('input', date)
                }

                let setDOB = false;

                if (this.enableMinAgeValidation && this.enableMaxAgeValidation && date > this.minDate && date < this.maxDate) {
                  setDOB = true;
                } else if (this.enableMinAgeValidation && date > this.minDate) {
                  setDOB = true;
                } else if (this.enableMaxAgeValidation && date < this.maxDate) {
                  setDOB = true;
                }

                if (setDOB) {
                  let fake_iso_date = year + '-' + month + '-' + day + 'T00:00:00.000Z'
                  // This spoofed ISO formatting is due to the DB literally stripping the date from the string for use.
                  let data = [fake_iso_date, 'form.dateOfBirth']
                  this.$store.dispatch('updateFormValue', data)
                } else {
                  let data = [null, 'form.dateOfBirth']
                  this.$store.dispatch('updateFormValue', data)
                }

                return date
            }
        }
    }
</script>

<style lang="scss" scoped>
	#app form {
    h2 {
      color: #4a4a4a;
    }
		p {
			&.error {
				margin-bottom: 0;
			}

			&.birthday {
				text-align: center;
				font-size: 2rem;
				line-height: 1.5;
			}
		}

		section {
			padding: 0 0 30px;
			position: relative;
		}

		div {
			&.small-input {
				max-width: 50%
			}

			&.duel-inputs {
				display: flex;
				justify-content: space-between;
                align-items: center;
				padding: 0;

				.small-input {
					max-width: calc(33% - 10px);
				}
			}
		}
	}
</style>
