<template>
<div class="footer" ref="infoFooter" v-if="isAffiliate" >
    <div v-show="showCF247Contact" class="top-inner" >
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_0_1)">
                <path d="M-41 -1.00001C-41 -17.5685 -27.5685 -31 -11 -31H757C773.569 -31 787 -17.5685 787 -1V234H-41V-1.00001Z" fill="#E8E8E8"/>
                <mask id="path-2-outside-1_0_1" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32" fill="black">
                    <rect fill="white" width="32" height="32"/>
                    <path d="M6.49349 1C7.00271 1.13874 7.39669 1.45686 7.77187 1.80997C9.49434 3.43053 11.1629 5.10609 12.8303 6.7829C13.6702 7.62787 13.7003 8.53471 12.9718 9.46717C12.1501 10.519 11.2425 11.4958 10.3399 12.4771C10.1777 12.6533 10.1708 12.7889 10.281 12.9958C11.8375 15.9244 13.8168 18.5193 16.376 20.643C17.8317 21.8511 19.474 22.7854 21.1156 23.7141C21.3424 23.8422 21.4645 23.7091 21.6017 23.5822C22.5343 22.7192 23.4751 21.8654 24.4735 21.0773C25.4306 20.3217 26.3432 20.3624 27.2301 21.2398C28.2592 22.2585 29.277 23.2885 30.2917 24.3216C30.9487 24.9903 31.5876 25.6765 32.2415 26.3484C32.6129 26.7302 32.8854 27.1627 33 27.6877V28.2502C32.938 28.6064 32.7751 28.9158 32.5647 29.2064C32.3693 29.4764 32.187 29.7551 31.9972 30.0289C31.0239 31.4332 29.6684 32.2538 28.0268 32.6531C27.3372 32.8206 26.635 32.8938 25.9367 33H23.7488C23.6787 32.9825 23.6098 32.9581 23.5384 32.9494C21.9963 32.7481 20.5381 32.2725 19.1382 31.6069C16.2946 30.2557 13.8406 28.3539 11.5826 26.1903C10.0418 24.7141 8.56295 23.1735 7.12109 21.6004C5.06291 19.3549 3.29034 16.9088 2.09025 14.0864C1.53217 12.7764 1.15949 11.4165 1.03548 9.9934C0.93714 8.86594 1.0574 7.75349 1.22276 6.63978C1.62174 3.95301 3.18261 2.16683 5.55585 1H6.49349Z"/>
                </mask>
                <path d="M6.49349 1C7.00271 1.13874 7.39669 1.45686 7.77187 1.80997C9.49434 3.43053 11.1629 5.10609 12.8303 6.7829C13.6702 7.62787 13.7003 8.53471 12.9718 9.46717C12.1501 10.519 11.2425 11.4958 10.3399 12.4771C10.1777 12.6533 10.1708 12.7889 10.281 12.9958C11.8375 15.9244 13.8168 18.5193 16.376 20.643C17.8317 21.8511 19.474 22.7854 21.1156 23.7141C21.3424 23.8422 21.4645 23.7091 21.6017 23.5822C22.5343 22.7192 23.4751 21.8654 24.4735 21.0773C25.4306 20.3217 26.3432 20.3624 27.2301 21.2398C28.2592 22.2585 29.277 23.2885 30.2917 24.3216C30.9487 24.9903 31.5876 25.6765 32.2415 26.3484C32.6129 26.7302 32.8854 27.1627 33 27.6877V28.2502C32.938 28.6064 32.7751 28.9158 32.5647 29.2064C32.3693 29.4764 32.187 29.7551 31.9972 30.0289C31.0239 31.4332 29.6684 32.2538 28.0268 32.6531C27.3372 32.8206 26.635 32.8938 25.9367 33H23.7488C23.6787 32.9825 23.6098 32.9581 23.5384 32.9494C21.9963 32.7481 20.5381 32.2725 19.1382 31.6069C16.2946 30.2557 13.8406 28.3539 11.5826 26.1903C10.0418 24.7141 8.56295 23.1735 7.12109 21.6004C5.06291 19.3549 3.29034 16.9088 2.09025 14.0864C1.53217 12.7764 1.15949 11.4165 1.03548 9.9934C0.93714 8.86594 1.0574 7.75349 1.22276 6.63978C1.62174 3.95301 3.18261 2.16683 5.55585 1H6.49349Z" fill="#464646"/>
                <path d="M6.49349 1C7.00271 1.13874 7.39669 1.45686 7.77187 1.80997C9.49434 3.43053 11.1629 5.10609 12.8303 6.7829C13.6702 7.62787 13.7003 8.53471 12.9718 9.46717C12.1501 10.519 11.2425 11.4958 10.3399 12.4771C10.1777 12.6533 10.1708 12.7889 10.281 12.9958C11.8375 15.9244 13.8168 18.5193 16.376 20.643C17.8317 21.8511 19.474 22.7854 21.1156 23.7141C21.3424 23.8422 21.4645 23.7091 21.6017 23.5822C22.5343 22.7192 23.4751 21.8654 24.4735 21.0773C25.4306 20.3217 26.3432 20.3624 27.2301 21.2398C28.2592 22.2585 29.277 23.2885 30.2917 24.3216C30.9487 24.9903 31.5876 25.6765 32.2415 26.3484C32.6129 26.7302 32.8854 27.1627 33 27.6877V28.2502C32.938 28.6064 32.7751 28.9158 32.5647 29.2064C32.3693 29.4764 32.187 29.7551 31.9972 30.0289C31.0239 31.4332 29.6684 32.2538 28.0268 32.6531C27.3372 32.8206 26.635 32.8938 25.9367 33H23.7488C23.6787 32.9825 23.6098 32.9581 23.5384 32.9494C21.9963 32.7481 20.5381 32.2725 19.1382 31.6069C16.2946 30.2557 13.8406 28.3539 11.5826 26.1903C10.0418 24.7141 8.56295 23.1735 7.12109 21.6004C5.06291 19.3549 3.29034 16.9088 2.09025 14.0864C1.53217 12.7764 1.15949 11.4165 1.03548 9.9934C0.93714 8.86594 1.0574 7.75349 1.22276 6.63978C1.62174 3.95301 3.18261 2.16683 5.55585 1H6.49349Z" stroke="#464646" stroke-width="2" mask="url(#path-2-outside-1_0_1)"/>
            </g>
            <defs>
                <clipPath id="clip0_0_1">
                    <rect width="828" height="1792" fill="white" transform="translate(-41 -1201)"/>
                </clipPath>
            </defs>
        </svg>
        <div>&nbsp;<b>CarFinance 247</b>&nbsp;will be in contact to discuss your options</div>
    </div>
    <div :class="showCF247Contact ? 'bottom-inner' : 'no-bg-bottom-inner'" v-if="!showTopOnly" >
      <div class="inner" > 
        <div class="block">
          <svg class="icon" width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2_436)">
              <path class="testB-outline" d="M44.2894 40.5466C44.867 41.1242 44.867 42.0253 44.2894 42.6028C43.7118 43.1804 42.8108 43.1804 42.2332 42.6028L33.0842 33.4539V17.9746C33.0842 17.1659 33.7311 16.519 34.5397 16.519C35.3484 16.519 35.9953 17.1659 35.9953 17.9746V32.2525L44.2894 40.5466Z" fill="#4A4A4A"/>
              <path class="testB-outline" d="M33.5 67C15.0172 67 0 51.9828 0 33.5C0 15.0172 15.0172 0 33.5 0C51.9828 0 67 15.0172 67 33.5C67 51.9828 51.9828 67 33.5 67ZM33.5 2.31034C16.311 2.31034 2.31034 16.311 2.31034 33.5C2.31034 50.689 16.311 64.6897 33.5 64.6897C50.689 64.6897 64.6897 50.689 64.6897 33.5C64.6897 16.311 50.689 2.31034 33.5 2.31034Z" fill="#4A4A4A"/>
            </g>
            <defs>
              <clipPath id="clip0_2_436">
                <rect width="67" height="67" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          Get a decision in minutes
        </div>

        <div class="block">

            <svg class="icon"  width="54" height="67" viewBox="0 0 39 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_10_467)">
                    <path class="testB-outline" d="M21.0969 60.7531C19.9398 60.7531 18.9838 59.8202 18.9838 58.6542C18.9838 57.4881 19.923 56.5552 21.0969 56.5552C22.2708 56.5552 23.2099 57.4881 23.2099 58.6542C23.2099 59.8202 22.2708 60.7531 21.0969 60.7531ZM2.11304 52.3739V62.8187C2.11304 63.9682 3.05217 64.9177 4.22609 64.9177H37.9677C39.1248 64.9177 40.0807 63.9848 40.0807 62.8187V52.3739H2.11304ZM2.11304 50.275H40.0807V43.9948H42.1938V62.8187C42.1938 65.1343 40.2988 67.0167 37.9677 67.0167H4.22609C1.89503 67.0167 0 65.1343 0 62.8187V4.19791C0 1.8824 1.89503 0 4.22609 0H37.9677C40.2988 0 42.1938 1.8824 42.1938 4.19791V12.6437H40.0807V4.19791C40.0807 3.04848 39.1416 2.09896 37.9677 2.09896H4.22609C3.06894 2.09896 2.11304 3.03182 2.11304 4.19791V50.2916V50.275Z" fill="#4A4A4A"/>
                    <path d="M40.2988 42.379C47.8658 42.379 54 36.2856 54 28.7691C54 21.2525 47.8658 15.1592 40.2988 15.1592C32.7318 15.1592 26.5975 21.2525 26.5975 28.7691C26.5975 36.2856 32.7318 42.379 40.2988 42.379Z" 
                            class="svg-fill testB-outline" />
                </g>
                <g clip-path="url(#clip1_10_467)">
                    <path class="testB" d="M45.26 33.43C45.62 33.43 45.9 33.71 45.9 34.07C45.9 34.21 45.86 34.31 45.82 34.39C45.18 35.66 44.23 36.12 42.9 36.12C41.57 36.12 40.47 35.17 39.16 35.17C38.05 35.17 37.41 35.71 37.17 35.85C37.07 35.91 36.99 35.99 36.77 35.99C36.41 35.99 36.13 35.71 36.13 35.35C36.13 35.13 36.21 34.99 36.41 34.81C37.11 34.25 38.02 32.82 38.02 31.81C38.02 31.19 37.86 30.74 37.58 30.14H36.61C36.27 30.14 36.01 29.88 36.01 29.54C36.01 29.2 36.27 28.94 36.61 28.94H36.99C36.53 28.07 36.14 26.89 36.14 25.82C36.14 23.61 37.85 22 40.47 22C42.78 22 44.27 23.03 44.94 24.68C44.98 24.78 45 24.88 45 24.96C45 25.32 44.72 25.6 44.36 25.6C44 25.6 43.84 25.38 43.74 25.14C43.26 23.95 42.21 23.23 40.48 23.23C38.75 23.23 37.44 24.22 37.44 25.83C37.44 26.74 37.82 27.82 38.47 28.95H41.99C42.33 28.95 42.59 29.21 42.59 29.55C42.59 29.89 42.33 30.15 41.99 30.15H38.99C39.21 30.63 39.29 31.14 39.29 31.82C39.29 32.81 38.73 33.73 38.1 34.3C38.34 34.16 38.78 34.04 39.15 34.04C40.68 34.04 41.67 34.91 42.79 34.91C43.59 34.91 44.26 34.65 44.68 33.8C44.82 33.52 45.04 33.44 45.26 33.44V33.43Z" fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0_10_467">
                        <rect width="54" height="67" fill="white"/>
                    </clipPath>
                    <clipPath id="clip1_10_467">
                        <rect width="9.9" height="14.13" fill="white" transform="translate(36 22)"/>
                    </clipPath>
                </defs>
            </svg>

          No obligation quote
        </div>

        <div class="block">
            <svg class="icon" width="73" height="67" viewBox="0 0 60 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_10_453)">
                    <path d="M44.8062 29.3281H12.8593V34.6837H44.8062V29.3281Z" class="svg-fill testB-outline" />
                    <path d="M41.9948 50.3682H12.8593V55.7238H41.9948V50.3682Z" class="svg-fill testB-outline" />
                    <path d="M26.277 39.8481H12.8593V45.2038H26.277V39.8481Z" class="svg-fill testB-outline" />
                    <path d="M50.9362 16.2492V2.31056C50.9362 1.03414 49.8985 0 48.6207 0H13.8918L0 13.6734V64.6894C0 65.9659 1.03636 67 2.31424 67H48.6207C49.8985 67 50.9362 65.9659 50.9362 64.6894V44.917H48.6207V64.6894H2.31424V16.1727H15.0496C15.6885 16.1727 16.206 15.655 16.206 15.0174V2.31056H48.6207V16.2492H50.9362ZM14.0094 13.7716H3.22281L14.0094 3.15471V13.7716Z" fill="#4A4A4A" class="testB-outline" />
                    <path d="M72.6256 51.1157L62.7629 41.2741C65.2586 38.3706 66.7678 34.5949 66.7678 30.4685C66.7678 21.2964 59.3165 13.8623 50.1247 13.8623C40.9329 13.8623 33.4829 21.2977 33.4829 30.4685C33.4829 39.6394 40.9342 47.076 50.1247 47.076C54.2612 47.076 58.0424 45.5714 60.9534 43.081L70.8161 52.9213C71.3145 53.4212 72.1272 53.4212 72.6256 52.9213C73.1239 52.4214 73.1252 51.613 72.6256 51.1157ZM50.1234 44.5206C42.3463 44.5206 36.0412 38.2291 36.0412 30.4673C36.0412 22.7067 42.345 16.4151 50.1234 16.4151C57.9019 16.4151 64.2069 22.7067 64.2069 30.4673C64.2069 38.2278 57.9031 44.5206 50.1234 44.5206Z" fill="#4A4A4A"  class="testB-outline" />
                </g>
                <defs>
                    <clipPath id="clip0_10_453">
                        <rect width="73" height="67" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

          We compare over 18 lenders
        </div>


        <div class="block">
            <svg class="icon" width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="path-1-outside-1_10_194" maskUnits="userSpaceOnUse" x="0" y="0" width="63" height="63" fill="black" >
                <rect class="testB-outline" fill="white" width="63" height="63"  />
                <path d="M12.1286 2C13.0675 2.25581 13.7939 2.84233 14.4856 3.49338C17.6614 6.48129 20.7379 9.5706 23.8121 12.6622C25.3607 14.2201 25.4161 15.8921 24.0731 17.6113C22.5579 19.5507 20.8846 21.3517 19.2205 23.1608C18.9214 23.4858 18.9086 23.7358 19.1119 24.1172C21.9817 29.5169 25.6309 34.3012 30.3496 38.2167C33.0334 40.4441 36.0614 42.1668 39.0882 43.8791C39.5062 44.1154 39.7314 43.8699 39.9844 43.636C41.7039 42.0447 43.4385 40.4706 45.2793 39.0176C47.0438 37.6245 48.7264 37.6994 50.3617 39.3172C52.2591 41.1954 54.1357 43.0944 56.0065 44.9992C57.2179 46.2321 58.3959 47.4974 59.6015 48.7361C60.2863 49.4401 60.7887 50.2375 61 51.2055V52.2425C60.8857 52.8993 60.5854 53.4697 60.1974 54.0055C59.8371 54.5033 59.501 55.0173 59.1511 55.522C57.3565 58.1112 54.8574 59.6242 51.8306 60.3605C50.5592 60.6693 49.2646 60.8041 47.977 61H43.9431C43.8138 60.9677 43.6867 60.9228 43.5551 60.9067C40.7119 60.5356 38.0234 59.6587 35.4424 58.4315C30.1994 55.9403 25.6748 52.4338 21.5116 48.4445C18.6708 45.7228 15.9442 42.8824 13.2858 39.9821C9.49099 35.8419 6.22281 31.3318 4.01015 26.128C2.98119 23.7128 2.29407 21.2054 2.06541 18.5816C1.8841 16.5028 2.10583 14.4517 2.41071 12.3983C3.14633 7.44461 6.02418 4.15134 10.3998 2H12.1286Z"/>
              </mask>
              <path class="testB" d="M12.1286 2C13.0675 2.25581 13.7939 2.84233 14.4856 3.49338C17.6614 6.48129 20.7379 9.5706 23.8121 12.6622C25.3607 14.2201 25.4161 15.8921 24.0731 17.6113C22.5579 19.5507 20.8846 21.3517 19.2205 23.1608C18.9214 23.4858 18.9086 23.7358 19.1119 24.1172C21.9817 29.5169 25.6309 34.3012 30.3496 38.2167C33.0334 40.4441 36.0614 42.1668 39.0882 43.8791C39.5062 44.1154 39.7314 43.8699 39.9844 43.636C41.7039 42.0447 43.4385 40.4706 45.2793 39.0176C47.0438 37.6245 48.7264 37.6994 50.3617 39.3172C52.2591 41.1954 54.1357 43.0944 56.0065 44.9992C57.2179 46.2321 58.3959 47.4974 59.6015 48.7361C60.2863 49.4401 60.7887 50.2375 61 51.2055V52.2425C60.8857 52.8993 60.5854 53.4697 60.1974 54.0055C59.8371 54.5033 59.501 55.0173 59.1511 55.522C57.3565 58.1112 54.8574 59.6242 51.8306 60.3605C50.5592 60.6693 49.2646 60.8041 47.977 61H43.9431C43.8138 60.9677 43.6867 60.9228 43.5551 60.9067C40.7119 60.5356 38.0234 59.6587 35.4424 58.4315C30.1994 55.9403 25.6748 52.4338 21.5116 48.4445C18.6708 45.7228 15.9442 42.8824 13.2858 39.9821C9.49099 35.8419 6.22281 31.3318 4.01015 26.128C2.98119 23.7128 2.29407 21.2054 2.06541 18.5816C1.8841 16.5028 2.10583 14.4517 2.41071 12.3983C3.14633 7.44461 6.02418 4.15134 10.3998 2H12.1286Z" fill="white" />
              <path class="testB-stroke"  d="M12.1286 2C13.0675 2.25581 13.7939 2.84233 14.4856 3.49338C17.6614 6.48129 20.7379 9.5706 23.8121 12.6622C25.3607 14.2201 25.4161 15.8921 24.0731 17.6113C22.5579 19.5507 20.8846 21.3517 19.2205 23.1608C18.9214 23.4858 18.9086 23.7358 19.1119 24.1172C21.9817 29.5169 25.6309 34.3012 30.3496 38.2167C33.0334 40.4441 36.0614 42.1668 39.0882 43.8791C39.5062 44.1154 39.7314 43.8699 39.9844 43.636C41.7039 42.0447 43.4385 40.4706 45.2793 39.0176C47.0438 37.6245 48.7264 37.6994 50.3617 39.3172C52.2591 41.1954 54.1357 43.0944 56.0065 44.9992C57.2179 46.2321 58.3959 47.4974 59.6015 48.7361C60.2863 49.4401 60.7887 50.2375 61 51.2055V52.2425C60.8857 52.8993 60.5854 53.4697 60.1974 54.0055C59.8371 54.5033 59.501 55.0173 59.1511 55.522C57.3565 58.1112 54.8574 59.6242 51.8306 60.3605C50.5592 60.6693 49.2646 60.8041 47.977 61H43.9431C43.8138 60.9677 43.6867 60.9228 43.5551 60.9067C40.7119 60.5356 38.0234 59.6587 35.4424 58.4315C30.1994 55.9403 25.6748 52.4338 21.5116 48.4445C18.6708 45.7228 15.9442 42.8824 13.2858 39.9821C9.49099 35.8419 6.22281 31.3318 4.01015 26.128C2.98119 23.7128 2.29407 21.2054 2.06541 18.5816C1.8841 16.5028 2.10583 14.4517 2.41071 12.3983C3.14633 7.44461 6.02418 4.15134 10.3998 2H12.1286Z" stroke="#464646" stroke-width="4" mask="url(#path-1-outside-1_10_194)"/>
            </svg>

            Discuss your options
        </div>


      </div>
    </div>
</div>
</template>

<script>
  import { AffiliatesHelper } from '@/helpers/index';

  export default {
    name: "InfoFooterTestB",
    props: {
      showTopOnly: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      isAffiliate(){ return  AffiliatesHelper.isAffiliate() },
      showCF247Contact() { return false }
    },
    mounted() {
      if( this.isAffiliate ) {
        setTimeout( ()=>{
          if (this.$refs.infoFooter !== undefined && this.$refs.infoFooter.clientHeight !== undefined) {
            document.documentElement.style.cssText = "--infoFooterHeigh: " + this.$refs.infoFooter.clientHeight + "px";
          }
        }, 700);
      }
    }
  }
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footer .inner {
    max-width: 610px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-color: #F8F7FA;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center ;

    gap: 5px;
    flex-shrink: 0;
}

.footer .inner .block{
  background-color: #fff;

  border-radius: 15px;
  align-items: center;
  justify-content: top;
  display: flex;
  flex-direction: column;

  font-size: 12px;
  line-height: 11px;

  max-width: 98px;
  min-width: 80px;
  height: 80px;

  padding: 5px;
  flex-basis: 100%;
}

.footer .inner .block .icon {
  height: 35px;
  margin-bottom: 5px;
}

.footer .top-inner{
    background-color: #E8E8E8;
    max-width: 610px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 3px;
    font-size: .9em;
    color: #4a4a4a;
}

.footer .top-inner svg g path:first-of-type {
    fill: #E8E8E8;
}

.footer .top-inner svg g path:nth-of-type(2) {
  fill: #4a4a4a;
}

.footer .bottom-inner {
    max-width: 610px; 
    background-color: #E8E8E8;
    margin: 0 auto;
}

.footer .no-bg-bottom-inner {
  max-width: 610px;
  margin: 0 auto;
}

.footer .top-inner div, .footer .top-inner svg {
    vertical-align: middle;
    display: inline-block;
    font-size: 0.8em;
}



.footer .top-inner svg {
   width: 0.8em;
}

  @media (max-width: 320px) {
    .footer .top-inner div {
      font-size: 0.7em;

    }
    .footer .top-inner svg {
      width: 0.7em;
    }
  }

.svg-fill {
    fill: $primary;
}
</style>

<style scss>

html {
  --infoFooterHeigh: 0px;
} 

.app-form-container:has( .footer) {
  /* height: calc(100vh - var(--infoFooterHeigh)); */
  padding-bottom: var(--infoFooterHeigh);
  overflow-y: scroll;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  
}

.app-form-container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
</style>