<template>
  <form @submit.prevent="Submit(nextPage)">
    <InputCollapseGroup
      :label="employmentStatusLabel"
      columns
      :items="[
        {label: 'Full-time', value: 9, show: true , canCoutinue: canCoutinue(), id: idConcat(pageName, 'FullTime'), maskData: false },
        {label: 'Self-employed', value: 10, show: true, canCoutinue: canCoutinue(), id: idConcat(pageName, 'SelfEmployed'), maskData: false },
        {label: 'Part-time', value: 11, show: true, canCoutinue: canCoutinue(), id: idConcat(pageName, 'PartTime'), maskData: false },
        {label: 'Disability', value: 12, show: true, canCoutinue: canCoutinue(), id: idConcat(pageName, 'Disability'), maskData: true },
        {label: 'Retired', value: 13, show: true, canCoutinue: canCoutinue(), id: idConcat(pageName, 'Retired'), maskData: false },
        {label: 'Student', value: 19, show: true, canCoutinue: canCoutinue(), id: idConcat(pageName, 'Student'), maskData: false },
        {label: 'Family carer', value: 21, show: false, canCoutinue: canCoutinue(), id: idConcat(pageName, 'FamilyCarer'), maskData: false },
        {label: 'Agency worker', value: 15, show: false, canCoutinue: canCoutinue(), id: idConcat(pageName, 'AgencyWorker'), maskData: false },
        {label: 'Sub-contractor', value: 14, show: false, canCoutinue: canCoutinue(), id: idConcat(pageName, 'SubContractor'), maskData: false },
        {label: 'Homemaker', value: 16, show: false, canCoutinue: canCoutinue(), id: idConcat(pageName, 'Homemaker'), maskData: false },
        {label: 'Armed forces', value: 18, show: false, canCoutinue: canCoutinue(), id: idConcat(pageName, 'ArmedForces'), maskData: false },
        {label: 'Not employed', value: 17, show: false, canCoutinue: canCoutinue(), id: idConcat(pageName, 'NotEmployed'), maskData: false },
      ]"
      :name="'form.employments['+index+'].statusId'"
      :stateName="'form.employmentStatusOtherClicked'"
      v-model.trim="$v.employmentStatusId.$model"
      :v="$v.employmentStatusId"
      :submitFunction="continueBtnClick"
      :idOther="idConcat(pageName, 'Other')"
    />

  <ButtonContinue :id="idConcat(pageName, 'ContinueBtn')" :visible="visibleCoutinueButton()" />

  <ButtonBack :id="idConcat(pageName, 'BackBtn')" />

  <InfoFooter :showTopOnly="true" />
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {AnalyticsService} from '@/services';

let index, routeNo

export default {
  beforeCreate(){
    if(index !== 0){
      let newObj = {
        occupation: null,
        employerName: null,
        employerTown: null,
        statusId: null,
        yearsAtEmployer: null,
        monthsAtEmployer: null,
        subBuilding: null,
        buildingName: null,
        buildingNumber: null,
        locality: null,
        postcode: null,
        county: null,
        street: null,
        flatNumber: null // doesn't seem to be used
      }

      let array = this.$store.state.form.employments
      let count = this.$store.getters.employmentsCount
      
      if(count < routeNo){
        array.push(newObj)
      }

    }
  },
  beforeRouteEnter (to, from, next) {
    routeNo = to.params.number
    index = to.params.number - 1
    next()
  },
  data() {
    return {
      pageName: 'Employment',
      index: index,
      employmentStatusId: this.$store.state.form.employments[index].statusId,
      employmentStatusLookup: [
        {label: 'Full Time', value: 9},
        {label: 'Self Employed', value: 10},
        {label: 'Part Time', value: 11},
        {label: 'Disability', value: 12},
        {label: 'Retired', value: 13},
        {label: 'Student', value: 19},
        {label: 'Family Carer', value: 21},
        {label: 'Agency Worker', value: 15},
        {label: 'Sub Contractor', value: 14},
        {label: 'Homemaker', value: 16},
        {label: 'Armed Forces', value: 18},
        {label: 'Not Employed', value: 17},
      ],
      pageViewSubmitted: false,
    }
  },
  validations: {
    employmentStatusId: {
      required
    }
  },
  watch: {
    employmentStatusId: function(val) {
      this.employmentStatusChangeEvent(val);
    },
  },
  methods: {
    continueBtnClick: function() {
      setTimeout(
       () => {
        this.Submit(this.nextPage)
       }, 100);
    },
    employmentStatusChangeEvent(val) {
      if (index === 0) {
        this.employmentStatusLookup.forEach((item) => {
          if (item.value === val) {
            const data = item.label.toLowerCase().replaceAll(' ', '_');
            AnalyticsService.setData('employment_status', data, false);
          }
        });
      }
    },
  },
  mounted() {

    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);
    
    this.$store.dispatch('updateFormValue', [false, 'form.employmentStatusCompleted'])

    if (this.employmentStatusId && this.$v.employmentStatusId.$dirty) {
      this.employmentStatusChangeEvent(this.employmentStatusId);
    }

    if (!this.pageViewSubmitted) {
      this.pageViewSubmitted = true;

      AnalyticsService.trackPageView({
        page_name: 'employment-status'
      });
    }
  },
  computed: {
    employmentDetail() {
      return this.$route.query.employmentDetail === '1';
    },
    status() {
      let v = this.$store.getters.employmentStatus(index)
      let status
      if(v === 9 || v === 11 || v === 14 || v === 15 || v === 20){
        status = 'default'
      }
      if(v === 10 || v === 18){
        // remove employer name
        status = 'no-name'
      }
      if(v === 13 || v === 19 || v === 12 || v === 16 || v === 17 || v === 21){
        // remove employer name, town and occupation
        status = 'no-employer'
      }
      return status
    },
    statusName() {
      let v = this.$store.getters.employmentStatus(index)
      let status
      if(v === 10){status = 'self-employed'}
      if(v === 13){status = 'retired'}
      if(v === 19){status = 'a student'}
      if(v === 12){status = 'on disability living allowance'}
      if(v === 16){status = 'a homemaker'}
      if(v === 17){status = 'unemployed'}
      if(v === 21){status = 'a non professional carer'}
      if(v === 18){status = 'in the armed forces'}
      return status
    },
    nextPage() {
      if(this.status === 'default' || this.status === 'no-name') {

        const pageName = this.employmentDetail ?   'employment-detail' : 'employment-location';

        return {name: pageName, params: {number: Number(routeNo)}};
      }
      else
      {
        return { name: 'employment-length' , params: { number: Number(routeNo) } }
      }
    },
    employmentStatusLabel() {
      if(this.index === 0) {
        return 'What is your employment status?'
      } else {
        return 'What was your previous employment status?'
      }
    }
  }

}
</script>

