<template>

  <form @submit.prevent="Submit(nextPage)">
     <h1>{{ getLabel }}</h1>
    <InputBorrow
        :id="idConcat(pageName, 'Income')"
        v-if="index === 0"
        name="form.netMonthlyIncome"
        label=""
        invalidMessage="Let us know your monthly income to continue."
        bigLabel
        v-model="$v.netMonthlyIncome.$model"
        :v="$v.netMonthlyIncome"
        :smallPrint="benefitsMicroCopy"
        :input="debounceInput"
        :mask-input="true"
    />
    <p class="error" v-if="showWarning && this.netMonthlyIncome > 10000">This looks quite high. Please enter the monthly income you take home after tax. </p>
    <p class="error" v-else-if="!this.$v.netMonthlyIncome.$invalid && showWarning && this.netMonthlyIncome < 900">Please check this includes your wage, benefits and pension after tax that you receive every month.</p>
    <template v-if="index === 0">
      <EmploymentHistory first />
    </template>

    <template v-else>
      <EmploymentHistory />
    </template>

    <div v-show="showAppDuration && !isEmploymentReOrder" class="app-duration">
      <div>
        <img src="@/assets/img/checked_1.png" width="20" class="checked" />
      </div>
      <div>
        Just a few more questions until your no-obligation quote!
      </div>
    </div>

    <ButtonContinue :id="idConcat(pageName, 'ContinueBtn')" />

    <ButtonBack :id="idConcat(pageName, 'BackBtn')" />
    
    <InfoFooter :showTopOnly="true" />
  </form>
</template>

<script>
import InputBorrow from '@/components/InputBorrow.vue'
import { between, required, minValue } from 'vuelidate/lib/validators'
import {DealersHelper, LocationHelper, RequestHelper, ReorderHelper, EmploymentReOrderHelper} from '@/helpers';
import {AFFILIATES} from '@/factories';
import {AnalyticsService } from '../services';
import {SENSITIVE_DATA} from '@/const';

let routeNo, index

export default {
  beforeRouteEnter (to, from, next) {
    routeNo = to.params.number
    index = to.params.number - 1
    next()
  },
  beforeRouteUpdate (to, from, next) {
    routeNo = to.params.number
    index = to.params.number - 1
    next()
  },
  components: {
    InputBorrow
  },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);

    if (!this.pageViewSubmitted) {
      this.pageViewSubmitted = true;
      AnalyticsService.trackPageView({
        page_name: 'employment-income'
      });
    }
  },
  data() {
    return {
      pageName: 'EmploymentIncome',
      routeNo: routeNo,
      index: index,
      occupation: this.$store.state.form.employments[index].occupation,
      employerName: this.$store.state.form.employments[index].employerName,
      employerTown: this.$store.state.form.employments[index].employerTown,
      timeAt: this.$store.getters.indexEmploymentHistory(index),
      netMonthlyIncome: this.$store.state.form.netMonthlyIncome,
      employmentStatus: this.$store.getters.employmentStatus(index),
      showWarning: false,
      numberOfSubmit: 0,
      errorElementId: '',
      pageViewSubmitted: false,
      isCompareTheMarketReorder: LocationHelper.getHost() === 'comparethemarketcarfinance' && RequestHelper.getQueryVariable('test') === 'reorder',
      isCKDirectReorder: LocationHelper.getHost() === 'ckdirect',
    }
  },
  validations() {
    if(this.status === 'no-employer'){
      return {
        occupation: {},
        employerName: {},
        employerTown: {},
        timeAt: {
          required,
          minValue: minValue(0.0000001)
        },
        yearsAtEmployer: {
          between: between(0, 80)
        },
        monthsAtEmployer: {
          between: between(0, 12)
        }
        ,
        netMonthlyIncome: {
        }
      }
    }
    else if(this.status === 'no-name-town'){
        return {
            occupation: {required},
            employerName: {},
            employerTown: {},
            timeAt: {
                required,
                minValue: minValue(0.0000001)
            },
            yearsAtEmployer: {
                between: between(0, 80)
            },
            monthsAtEmployer: {
                between: between(0, 12)
            },
            netMonthlyIncome: {
                required
            }
        }
    }
    else if(this.status === 'no-name'){
      if (this.isDealer) {
        return {
          occupation: {required},
          employerName: {},
          employerTown: {},
          timeAt: {
            required,
            minValue: minValue(0.0000001)
          },
          yearsAtEmployer: {
            between: between(0, 80)
          },
          monthsAtEmployer: {
            between: between(0, 12)
          },
          netMonthlyIncome: {
            required
          }
        }
      }

      return {
        occupation: {required},
        employerName: {},
        employerTown: {required},
        timeAt: {
          required,
          minValue: minValue(0.0000001)
        },
        yearsAtEmployer: {
          between: between(0, 80)
        },
        monthsAtEmployer: {
          between: between(0, 12)
        },
        netMonthlyIncome: {
          required
        }
      }
    }
    else {
      if (this.isDealer) {
        return {
          occupation: {required},
          employerName: {required},
          employerTown: {},
          timeAt: {
            required,
            minValue: minValue(0.0000001)
          },
          yearsAtEmployer: {
            between: between(0, 80)
          },
          monthsAtEmployer: {
            between: between(0, 11)
          },
          netMonthlyIncome: {
            required
          }
        }
      }

      return {
        occupation: {required},
        employerName: {required},
        employerTown: {required},
        timeAt: {
          required,
          minValue: minValue(0.0000001)
        },
        yearsAtEmployer: {
          between: between(0, 80)
        },
        monthsAtEmployer: {
          between: between(0, 11)
        },
        netMonthlyIncome: {
          required
        }
      }
    }
  },
  computed: {
    isSocialRecorder() {
      const host = ['social','ecrm', 'tiktok', 'social-premier', 'social-vans', 'social-bad-credit'];

      return host.indexOf(LocationHelper.getHost()) !== -1;
    },
    isEmploymentReOrder(){
      return EmploymentReOrderHelper.isEmploymentReOrder();
    },
    isDealer() {
      return DealersHelper.isDealer();
    },
    SENSITIVE_DATA() {
      return SENSITIVE_DATA
    },
    employmentDetail() {
        return this.$route.query.employmentDetail === '1';
    },
    isNonAffiliatePersonalised() {
      let host = LocationHelper.getHost();
      return RequestHelper.getQueryVariable('personalised') && AFFILIATES[host] === undefined;
    },
    showAppDuration() {
      let appDuration = true;

      let host = LocationHelper.getHost();

      if (AFFILIATES[host] !== undefined) {
        appDuration = false;
      }

      return appDuration;
    },
    getLabel() {
      let label = "Roughly, how much do you earn each month?";

      if (this.isNonAffiliatePersonalised) {
        const name = this.$store.getters.any('form.firstName');
        label = `Roughly, how much do you earn each month ${name}?`;
      }

      return label;
    },
    status() {
      let v = this.$store.getters.employmentStatus(index)
      let status

      if (this.employmentDetail)  {
          if(v === 9 || v === 11 || v === 14 || v === 15 || v === 20 || v === 10 || v === 18){
              status = 'no-name-town'
          }
          if(v === 13 || v === 19 || v === 12 || v === 16 || v === 17 || v === 21){
              // remove employer name, town and occupation
              status = 'no-employer'
          }
      } else {
          if(v === 9 || v === 11 || v === 14 || v === 15 || v === 20){
              status = 'default'
          }
          if(v === 10 || v === 18){
              // remove employer name
              status = 'no-name'
          }
          if(v === 13 || v === 19 || v === 12 || v === 16 || v === 17 || v === 21){
              // remove employer name, town and occupation
              status = 'no-employer'
          }
      }


      return status
    },
    statusName() {
      let v = this.$store.getters.employmentStatus(index)
      let status
      if(v === 10){status = 'self-employed'}
      if(v === 13){status = 'retired'}
      if(v === 19){status = 'a student'}
      if(v === 12){status = 'on disability living allowance'}
      if(v === 16){status = 'a homemaker'}
      if(v === 17){status = 'unemployed'}
      if(v === 21){status = 'a non professional carer'}
      if(v === 18){status = 'in the armed forces'}
      return status
    },
    headerOne() {
      let v = this.$store.getters.employmentStatus(index)
      let status = 'Okay, where do you currently work?'
      if(index !== 0){status = 'And where did you work previously?'}

      if(this.status === 'no-name'){
        if(v === 10) return 'Self-employment'
        if(v === 18) return 'Armed Forces'
      }

      if(this.status !== 'default'){
        return null
      }
      return status
    },
    yearsMonthsTitle() {
      if(this.status === 'default'){
        if(this.index === 0){
          return 'How long have you worked here?'
        }
        return 'How long did you work there?'
      } else {
        if(this.index === 0){
          return 'How long have you been ' + this.statusName +'?'
        }
        return 'How long were you ' + this.statusName +'?'
      }
    },
    benefitsMicroCopy() {
      let v = this.$store.getters.employmentStatus(index)
      if(v === 12 || v === 16 || v === 17 || v === 21){
        return 'Your monthly income should include any regular benefits that you currently receive.'
      }
      return null
    },
    townLabel() {
      let v = this.$store.getters.employmentStatus(index)
      if (v === 10 || v === 18) return 'Town'
      return 'Employer\'s town'
    },
    townInvalidMessage() {
      let v = this.$store.getters.employmentStatus(index)
      if (v === 10 || v === 18) return 'Please tell us which town you work in.'
      return 'Please tell us which town you work in.'
    },
    yearsAtEmployer() {
      return this.$store.getters.indexEmploymentYears(index)
    },
    monthsAtEmployer() {
      return this.$store.getters.indexEmploymentMonths(index)
    },
    yearsMonthsInvalidMessage() {
      if(this.$v.yearsAtEmployer.$invalid) return 'Please enter a number between 0 and 80 years.'
      if(this.$v.monthsAtEmployer.$invalid) return 'Please enter a number between 0 and 11 months.'
      if(this.status === 'default'){
        if(this.index === 0){
          return 'Please tell us how long you’ve worked at this job.'
        }
        return 'Please tell us how long you worked at this job.'
      } else {
        if(this.index === 0){
          return 'Please tell us how long have you been ' + this.statusName +'?'
        }
        return 'Please tell us how long you were ' + this.statusName +'?'
      }
    },
    nextPage() {
      let nextRouteNo = Number(routeNo) + 1
      if(this.$store.getters.totalEmploymentHistory >= 1) {
        if (this.isEmploymentReOrder || this.isCompareTheMarketReorder || this.isCKDirectReorder || this.isSocialRecorder) {
          return {name: 'amount-to-finance'}
        }

        if( ReorderHelper.isReorderMulti(['cargurus', 'pistonheads']) || ReorderHelper.isGenericReorder()) {
            let result = ReorderHelper.getNextRoute('generic', this.$router.currentRoute.name );
            if(Object.keys(result).length> 0 ){
            return result;
            } 
        }

        return { name: 'address-add', params: { number: 1 } }
      } else {
        return { name: 'employment' , params: { number: nextRouteNo } }
      }
    }
  },
  methods: {

    warnIncomeValue(input) {
      if (typeof(input) !== undefined && input !== null && input !== '') {
        const income = parseInt(input.replace(",", ""));
        if (income < 900 || income > 10000) {
          this.errorElementId = 'input-borrow'
          return true
        } else {
          this.errorElementId = ''
          return false
        }
      }
      return false;
    },
    debounceInput(event) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.showWarning = this.warnIncomeValue(event.target.value);
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
  .app-duration {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    position: relative;
    top: -25px;

    div {
      font-size: 1.15em;
      line-height: 30px;
      text-align: center;
    }
  }
  .checked {
    margin-right: 15px;
  }
</style>
