<template>
  <div id="creditkarma-interstitial">
    <div id="cta">
      <div>
        <div>
          <div class="flex-box">
            <div>
              <h2>
                We’ve partnered with CarFinance 247, the UKs largest online car finance platform to find you the best deal from their wide panel of lenders.
              </h2>
              <p>
                Sort your finance first before shopping for a car.
              </p>
              <div>
                <ul>
                  <li>Choose a car from any reputable dealer in the UK</li>
                  <li>Dedicated account manager to guide you through the car buying journey</li>
                  <li>No deposit options available</li>
                </ul>
              </div>
              <div>
                <Button @btn-clicked="btnClicked" />
                <p class="button-info-text">
                  Rates from 9.9% APR. Representative APR 19.9%.
                </p>
              </div>
            </div>
            <div class="img-container">
              <img v-lazy="require('@/assets/img/interstitials/affiliates/creditkarma/discuss_your_quote.png')" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="representative-example">
      <div>
        <div>
          <div>
            <TrustPilot widget-type="microstar" />
          </div>
          <div>
            <p>
              Representative example: Borrowing £6,500 over 60 months with a representative APR of 19.9%, an annual interest rate of 19.9% (fixed) and a deposit of £0. The amount payable would be £166.07 per month, with a total cost of credit of £3,464.37 and a total amount repayable of £9,964.37.
            </p>
            <p>
              CarFinance 247 is a credit broker, not a lender.
            </p>
            <p>
              We earn a commission for providing our services, but this does not influence the interest rate you’re offered in anyway.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="info">
      <div>
        <div>
          <h2>
            Credit Karma and CarFinance 247
          </h2>
          <p>
            We’ve joined forces with CarFinance 247 to find you the best finance deal from their wide panel of lenders.
          </p>
          <p>
            Their service is free to use and getting a quote won’t impact your credit score. A hard search only happens when you decide to go ahead with a deal.
          </p>
          <p>
            CarFinance 247 works with a wide panel of lenders to offer you their best deals on finance. Whether you’re after PCP or HP, they’ll look to help you find a solution that fits your circumstances.
          </p>
          <p>
            If you’ve already found your next car and just need help with the finance, they can help set it up for you. If you’re still looking for your dream car, their car search gives you access to over 125,000 cars from trusted dealers across the country.
          </p>
          <p>
            Once you’ve requested a quote, you will get a call from one of CarFinance 247’s friendly account managers to discuss it in more detail.
          </p>
          <p>
            Alternatively, you can have them get in touch at a time that suits by booking a call in their Members’ Area.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Button from '@/components/Button.vue';
  import TrustPilot from '@/components/TrustPilot.vue';

  export default {
    name: 'CreditKarma_Interstitial',
    props: {
      startPage: {
        type: String,
        default: ''
      },
    },
    components: {
      TrustPilot,
      Button,
    },
    methods:{
      btnClicked() {
        this.$emit('setInterstitial', false);
        this.$router.push({ path: this.startPage }).catch(() => { });
      }
    }
  }
</script>

<style lang="scss" scoped>
  #creditkarma-interstitial {

    h2 {
      font-weight: bolder;
      font-size: 18px;
      margin: 0px 0px 20px;
    }

    >div {
      >div {
        max-width: 600px;
        margin: 0 auto;
        width: 100%;

        li, p {
          font-size: 14px;
        }
      }
    }

    #representative-example {
      margin: 50px 10px 20px;

      p {
        text-align: center;
        font-size: 14px;
      }
    }

    #info {
      background-color: #DFCFEB;
      padding: 10px 0px;

      h2 {
        margin: 20px 10px 20px;
      }

      p {
        margin: 0px 10px 20px;
      }
    }

    #cta {
       background-color: #CCE3D1;
       padding: 30px 0px;

      .flex-box {
        display: flex;
        align-items: normal;
        flex-wrap: nowrap;
        justify-content: center;

        div:first-child {
          width: 350px;
        }
      }

      .img-container {
        text-align: center;

        img {
          width: 100%;
          height: auto;
        }
      }

      @media only screen and (max-width: 600px) {
        .flex-box {
          display: flex;
          align-items: center;
          flex-wrap: wrap-reverse;
          justify-content: center;

          > div {
            width: 100%;
          }
        }

        .img-container {
          text-align: center;

          img {
            width: auto;
            height: 200px;
          }
        }
      }

      ul {
        list-style-position: outside;
        padding-left: 0;
        margin-left: 15px;
      }

      .button-info-text {
        font-size: 14px;
        text-align: center;
      }
    }
  }
</style>