import { REORDER } from '@/factories';
import { LocationHelper, RequestHelper } from '@/helpers';

export default {
    getNextRoute(reorderName, currentRouteName){
        let currentOrderIdx = REORDER[reorderName].order.findIndex( item => item.name === currentRouteName )
        if ( currentOrderIdx != -1  ) {
          if ( currentOrderIdx <= REORDER[reorderName].order.length-1 ) {
            return REORDER[reorderName].order[currentOrderIdx+1];
          }
        } else {
            return {};
        }
    },
    isReorder(reorderName) {
      return LocationHelper.getHost() === reorderName && RequestHelper.getQueryVariable('test') === reorderName + '-reorder';
    },
    isReorderMulti(reorderNameArr) {
      var result = false;
      // winnning test 
      var winningTestReorder = ['pistonheads','cargurus'];
      if(winningTestReorder.findIndex( val => {
        return LocationHelper.getHost() === val
      }) != -1) {
        result = true;
      } else {
        result = reorderNameArr.findIndex( val => {
          return LocationHelper.getHost() === val && RequestHelper.getQueryVariable('test') === val + '-reorder';
        }) != -1;
      }

      return result;
    },
    isGenericReorder() {
      return RequestHelper.getQueryVariable('test') === 'generic-reorder';
    },
    getProgress(reorderName) {
      return REORDER[reorderName].progress;
    }
}