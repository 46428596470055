<template>
  <img alt="DriveScore Logo" width="150" v-lazy="imgUrl" />
</template>

<script>
export default {
  name: "DriveScoreLogo",
    data () {
    return {
      imgUrl:  require('@/assets/img/affiliates/drive_score.svg')
    }
  }
}
</script>

<style scoped>

</style>
