<template>
    <svg v-show="showCF247Logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="137" height="42" viewBox="0 0 999 206">
        <image v-show="showPowerBy" data-name="Powered by"  x="722" width="277" height="54"   :xlink:href="powerByTextImage" />
        <image y="1" width="998" height="205" :xlink:href="logoImage" />
    </svg>
</template>

<script>
    import {LocationHelper} from '@/helpers';
    import {AFFILIATES} from '@/factories';

    export default {
        name: "PowerByTestB",
        props: {
          callByModel: {
            type: Boolean,
            default: false,
          },
          isHeader: {
            type:Boolean,
            default: false
          }
        },
        data() {
          return {
            showPowerBy: true,
            showCF247Logo: true,
            logoImage: require('@/assets/img/affiliates/cf247-logo.png'),
            powerByTextImage: require('@/assets/img/affiliates/cf247-powerby-text.png')
          }
        },
        mounted() {
          let host = LocationHelper.getHost();

          if (AFFILIATES[host] !== undefined) {
            const affiliateConfig = AFFILIATES[host];

            if ( this.isHeader  ){
              this.logoImage = require('@/assets/img/affiliates/cf247-logo.testB.png'),
              this.powerByTextImage = require('@/assets/img/affiliates/cf247-powerby-text.testB.png')
            }

            if (affiliateConfig && typeof affiliateConfig.showPowerBy !== "undefined") {
              if(!this.callByModel) {
                this.showPowerBy = affiliateConfig.showPowerBy;
              }
              
              if(this.callByModel) {
                if (affiliateConfig && typeof affiliateConfig.useModelV3 !== "undefined") {
                  if (affiliateConfig && typeof affiliateConfig.useModelV3PowerBy !== "undefined") {
                    this.showPowerBy = affiliateConfig.useModelV3PowerBy;
                  } else {
                    this.showPowerBy = false;
                  }
                }
              }
            }

            if (affiliateConfig && typeof affiliateConfig.showCF247Logo !== "undefined") {
              this.showCF247Logo = affiliateConfig.showCF247Logo;
            }
          }
        }
    }
</script>