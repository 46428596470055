<template>
    <ButtonContinue :id="id" :showNextArrow="false" :sub-label="subLabel" :visible="visible">
        <svg class="icon" width="52" height="51" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_0_1)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.7089 1.55938e-07C20.6246 -0.000555983 15.6544 1.48646 11.4266 4.27301C7.19892 7.05955 3.90366 11.0205 1.95759 15.6548C0.0115192 20.2892 -0.497954 25.3889 0.493599 30.3089C1.48515 35.229 3.9332 39.7484 7.52814 43.2957C11.1231 46.8431 15.7035 49.2589 20.69 50.2377C25.6766 51.2166 30.8454 50.7145 35.5427 48.7949C40.2401 46.8754 44.255 43.6246 47.0797 39.4537C49.9045 35.2827 51.4122 30.379 51.4122 25.3626C51.4126 22.0321 50.748 18.7341 49.4565 15.657C48.1649 12.5799 46.2717 9.78389 43.8849 7.42872C41.4981 5.07354 38.6645 3.2053 35.5458 1.93067C32.4272 0.656045 29.0846 1.35466e-07 25.7089 1.55938e-07ZM25.7089 42.6132C21.8275 42.6085 18.0575 41.3321 14.991 38.9844C11.9244 36.6367 9.73493 33.3507 8.7663 29.6422L13.5285 28.6947C14.3763 31.7401 16.3639 34.3546 19.0899 36.0101C21.8158 37.6655 25.077 38.2386 28.2147 37.6135C31.3523 36.9885 34.1326 35.2118 35.994 32.6425C37.8554 30.0731 38.6591 26.9025 38.2428 23.7712L43.0021 22.8236C43.3726 25.2766 43.2025 27.7796 42.5035 30.1618C41.8045 32.5441 40.5929 34.7496 38.9515 36.628C37.3101 38.5063 35.2775 40.0133 32.9921 41.0462C30.7068 42.0791 28.2226 42.6136 25.7089 42.6132Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_0_1">
                    <rect width="828" height="1792" fill="white" transform="translate(-218 -746)"/>
                </clipPath>
            </defs>
        </svg>
        {{ label }}
    </ButtonContinue>
</template>

<script>
import ButtonContinue from '@/components/ButtonContinue';

export default {
    name: 'IconButtonContinue',
    components: {
      ButtonContinue
    },
    props: {
      id: {
        type: String,
        default() {
          return 'button_generic_iconcontinue'
        }
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: 'Continue',
      },
      subLabel: {
        type: String,
        default: '',
      },
      visible: {
        type: Boolean,
        default: true
      }
    }
}
</script>

<style scoped>
  .button-text {
    position: relative;
    top: 4px;
  }

  .icon {
    height: 22px;
    width: 30px;
    vertical-align: baseline;
  }
</style>
