<template>
  <form @submit.prevent="Submit(nextPage)">
    <InputGroup
      id="residential-status"
      :name="'form.addresses['+index+'].residentialStatusId'"
      label="Ok, which best describes you?"
      columns
      :items="[
        {label: 'Private tenant', value: 31, canCoutinue: canCoutinue(), id: idConcat(pageName, 'PrivateTenant')},
        {label: 'Home owner', value: 32, canCoutinue: canCoutinue(), id: idConcat(pageName, 'HomeOwner')},
        {label: 'Council tenant', value: 33, canCoutinue: canCoutinue(), id: idConcat(pageName, 'CouncilTenant')},
        {label: 'Living with parents', value: 34, canCoutinue: canCoutinue(), id: idConcat(pageName, 'LivingWithParents')}
      ]"
      v-model="$v.residentialStatusId.$model"
      :v="$v.residentialStatusId"
      :submitFunction="continueBtnClick"
    />
    <ButtonContinue :id="idConcat(pageName, 'ContinueBtn')" :visible="visibleCoutinueButton()" />

    <ButtonBack :id="idConcat(pageName, 'BackBtn')" />
    <InfoFooter :showTopOnly="true" />
  </form>
</template>

<script>
import {  required } from 'vuelidate/lib/validators'
import {AnalyticsService } from '../services';

let addressNo, index

export default {
  beforeRouteEnter (to, from, next) {
    addressNo = to.params.number
    index = to.params.number - 1
    next()
  },
  data() {
    return {
      pageName: 'AddressStatus',
      addressNo: addressNo,
      index: index,
      timeAt: this.$store.getters.indexAddressHistory(index),
      residentialStatusId: this.$store.state.form.addresses[index].residentialStatusId,
      pageViewSubmitted: false,
    }
  },
  computed: {
    nextNumber() {
      let number = parseInt(this.$route.params.number)
      number = number + 1
      return number
    },
    nextPage() {
      return { name: 'address-length' , params: { number: Number(addressNo) } }
    },
    dateInvalidMessage() {
      if(this.$v.yearsAtAddress.$invalid) return 'Please enter a number between 0 and 80 years.'
      if(this.$v.monthsAtAddress.$invalid) return 'Please enter a number between 0 and 11 months.'
      if(this.index === 0){
        return 'Please tell us how long you’ve lived here.'
      } else {
        return 'Please tell us how long you lived there.'
      }
    }
  },
  validations: {
      residentialStatusId: {
        required
      }
  },
  methods: {
    continueBtnClick: function() {
      setTimeout(()=> {
        this.Submit(this.nextPage)
      }, 100);
    }
  },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);
    
    this.$store.dispatch('updateFormValue', [false, 'form.addressStatusCompleted'])

    if (!this.pageViewSubmitted) {
      this.pageViewSubmitted = true;

      AnalyticsService.trackPageView({
        page_name: 'address-status'
      });
    }
  }
}
</script>


