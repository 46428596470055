var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.Submit({ name: 'date-of-birth' })}}},[_c('InputGroup',{attrs:{"id":"marital-status","name":"form.maritalStatusId","label":"Which best describes you?","columns":"","items":[
      {label: 'Married', value: 23, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'Married') },
      {label: 'Single', value: 24, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'Single') },
      {label: 'Cohabiting', value: 25, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'Cohabiting') },
      {label: 'Divorced', value: 26, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'Divorced') },
      {label: 'Separated', value: 28, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'Separated') },
      {label: 'Widowed', value: 29, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'Widowed') },
      {label: 'Civil partnership', value: 30, canCoutinue: _vm.canCoutinue(), id: _vm.idConcat(_vm.pageName, 'CivilPartnership') }
    ],"v":_vm.$v.maritalStatus,"submitFunction":_vm.continueBtnClick,"mask-input":true},model:{value:(_vm.$v.maritalStatus.$model),callback:function ($$v) {_vm.$set(_vm.$v.maritalStatus, "$model", $$v)},expression:"$v.maritalStatus.$model"}}),_c('ButtonContinue',{attrs:{"id":_vm.idConcat(_vm.pageName, 'ContinueBtn'),"visible":_vm.visibleCoutinueButton()}}),_c('ButtonBack',{attrs:{"id":_vm.idConcat(_vm.pageName, 'BackBtn')}}),_c('InfoFooter',{attrs:{"showTopOnly":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }