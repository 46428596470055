export default {
    PRIVACY_POLICY: [
        'd0790122-0629-449d-ab57-04e0c33b7f7d',
        '796fe7e7-cd92-43e3-a53b-bcef1382c8b8'
    ],
    CARCRAFT_PRIVACY_POLICY: [
        '15072073-5939-48b2-bbf1-1db93c4d09d2'
    ],
    TERMS_AND_CONDITIONS: [
        'e3f5d573-a693-4d22-9546-9e602b176e66',
        '97072540-d4ab-44bc-9192-b512faf1ae02'
    ],
    CARCRAFT_TERMS_AND_CONDITIONS: [
        '54fce13c-b370-4406-9117-fc7defb442ae'
    ],
}