import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueLazyload from 'vue-lazyload'
import { CanCoutinuButtonHelper, LocationHelper } from './helpers'
import {AFFILIATES} from '@/factories';

Vue.use(VueLazyload, {
  lazyComponent: true
});

import VueRouter from 'vue-router'
const { isNavigationFailure, NavigationFailureType } = VueRouter

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import Popover from 'vue-js-popover'
Vue.use(Popover)

import Maska from 'maska'
Vue.use(Maska)

import { languages } from './lang/index.js'
import { defaultLocale } from './lang/index.js'

const messages = Object.assign(languages);

const i18n = new VueI18n({
  locale: defaultLocale,
  messages
})

import VueAppInsights from 'vue-application-insights'
import { APPLICATION_INSIGHTS } from './const';


import { AnalyticsBrowser } from '@segment/analytics-next';

if(process.env.NODE_ENV === "production") {
  window.analytics = AnalyticsBrowser.load({ writeKey: 'KuVyis8zpJQhSetIVMoK04XzvB1bA1QC' });
} else {
  window.analytics = AnalyticsBrowser.load({ 
    writeKey: 'lpoFRMifdbFdurOPEBJjD5bTbJpgttW3',
    integrations: {
        'All': true,
        'Braze': true,
        'Braze Cloud Mode (Actions)': true,
        'Braze Web Device Mode (Actions)': true,
        'Google Analytics 4 Cloud': true,
        'Google Analytics 4 Web': true,
        'Webhooks': true,
        'Criteo': true,
        'Bing Ads': true,
        'CM360 Offline Conv - VBB - Appr - Dealt (Personas - Prod)': true,
        'CM360 Offline Conv - VBB - Est. Rev (Personas - Prod)': true,
        'CM360 Offline Conversions - v2 - Prod (Personas - Prod)': true,
        'Facebook Conversions API (Actions)': true,
        'Facebook Pixel': true,
        'Google Ads Conversions': true,
        'Google Ads Remarketing Lists': true,
        'Google AdWords New': true,
        'Lead Broker Declined list (Personas - Prod)': true,
        'Personas Facebook Custom Audiences': true,
        'TikTok Conversions': true
    }
  });
}

const INSTRUMENTATION_KEY = process.env.NODE_ENV === "production" ?
    APPLICATION_INSIGHTS.INSTRUMENTATION_KEY_PROD : APPLICATION_INSIGHTS.INSTRUMENTATION_KEY_DEV;

Vue.use(VueAppInsights, {
  baseName: 'cf247-application-form',
  id: INSTRUMENTATION_KEY,
  router,
  appInsightsConfig: {
    instrumentationKey: INSTRUMENTATION_KEY,
    disableFetchTracking: false,
    enableCorsCorrelation: false,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableAutoRouteTracking: true,
    enableAjaxErrorStatusText: true,
    enableAjaxPerfTracking: true,
  }
})

import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

// Events
  // DONE // Page Change
  // DONE // Started
  // DONE // Partial
  // DONE // Partial Error
  // DONE // Completed
  // DONE // Completed Error
  // DONE // Submit 
  // DONE // Submit Error

// Nice to have events:
  // Autofill First Name
  // Autofill Last Name
  // Autofill Email
  // Autofill Phone
  // Autofill Date of Birth
                    

import ButtonBack from '@/components/ButtonBack.vue'
Vue.component('ButtonBack', ButtonBack)

import ButtonContinue from '@/components/ButtonContinue.vue'
Vue.component('ButtonContinue', ButtonContinue)

import InputGroup from '@/components/InputGroup.vue'
Vue.component('InputGroup', InputGroup)

import InputCollapseGroup from '@/components/InputCollapseGroup.vue'
Vue.component('InputCollapseGroup', InputCollapseGroup)

import InputText from '@/components/InputText.vue'
Vue.component('InputText', InputText)

import InputMaskText from '@/components/InputMaskText.vue'
Vue.component('InputMaskText', InputMaskText)

import InputNumber from '@/components/InputNumber.vue'
Vue.component('InputNumber', InputNumber)

import InputNumberRefined from '@/components/InputNumberRefined.vue'
Vue.component('InputNumberRefined', InputNumberRefined)

import InputEmail from '@/components/InputEmail.vue'
Vue.component('InputEmail', InputEmail)

import InputPhone from '@/components/InputPhone.vue'
Vue.component('InputPhone', InputPhone)

import InputCheckbox from '@/components/InputCheckbox.vue'
Vue.component('InputCheckbox', InputCheckbox)

import InputCheckboxTC from '@/components/InputCheckboxTC.vue'
Vue.component('InputCheckboxTC', InputCheckboxTC)

import InputYearsMonths from '@/components/InputYearsMonths.vue'
Vue.component('InputYearsMonths', InputYearsMonths)

import InputDate from '@/components/InputDate.vue'
Vue.component('InputDate', InputDate)

import InputDateRefined from '@/components/InputDateRefined.vue'
Vue.component('InputDateRefined', InputDateRefined)

import InputSelect from '@/components/InputSelect.vue'
Vue.component('InputSelect', InputSelect)

import Progress from '@/components/Progress.vue'
Vue.component('Progress', Progress)

import AddressHistory from '@/components/AddressHistory.vue'
Vue.component('AddressHistory', AddressHistory)

import EmploymentHistory from '@/components/EmploymentHistory.vue'
Vue.component('EmploymentHistory', EmploymentHistory)

import Modal from '@/components/Modal.vue'
Vue.component('Modal', Modal)

import PrivacyBanner from '@/components/PrivacyBanner.vue'
Vue.component('PrivacyBanner', PrivacyBanner)

import ButtonGetYourQuote from '@/components/ButtonGetYourQuote.vue'
Vue.component('ButtonGetYourQuote', ButtonGetYourQuote)


import InfoFooter from '@/components/InfoFooter';
import InfoFooterTestB from '@/components/InfoFooterTestB';
let host = LocationHelper.getHost();

if (AFFILIATES[host] !== undefined) {
  const affiliateConfig = AFFILIATES[host];

  if ( affiliateConfig.testB ) {
    Vue.component('InfoFooter', InfoFooterTestB)
  } else {
    Vue.component('InfoFooter', InfoFooter)
  }
} else {
  Vue.component('InfoFooter', InfoFooter)
}

// import '@/assets/css/tailwind.css'

import _ from 'lodash'
Vue.mixin({
  watch: {
    v: {
      handler: _.debounce( function (v) {
        let This = this
        // console.log(v)
        if(v.$anyError){
          // console.log('errors!')
          // setTimeout(function(){
            let errors = document.querySelectorAll('p.error')
            errors.forEach(error => { 
              if(error.textContent !== ''){
                // console.log(error.textContent)
                This.$appInsights.trackEvent({
                  name: 'Application Form - Validation Error',
                  properties: {
                    event_category: 'Application Form',
                    event_action: 'Validation Error',
                    event_label: error.textContent
                  }
                });
              }
            })
          //},0)
        }
      }, 1000),
      deep: true
    }
  },
  methods: {
    idConcat(pageName, fieldName) {
      return `CarFinance_${pageName}_${fieldName}`
    },
    canCoutinue: CanCoutinuButtonHelper.canCoutinue,
    visibleCoutinueButton: CanCoutinuButtonHelper.visibleCoutinueButton,
    Submit(to) {
      let page_from_to = this.$route.name + ' -> ' + to.name

      if(this.$v){
        this.$v.$touch()
        if (this.$v.$invalid) {

          this.$nextTick(() => {
            let error = document.querySelector('.error')
            let errors = document.querySelectorAll('.error')
            errors.forEach(error => { 
                error.classList.add('shake')
                setTimeout(function(){
                  error.classList.remove('shake')
                }, 500)
              },
              
            )

            if (error && error.parentNode) {
              let domRect = error.parentNode.getBoundingClientRect()

              window.scrollTo(
                  domRect.left + document.documentElement.scrollLeft,
                  domRect.top + document.documentElement.scrollTop
              );
            }

            this.$appInsights.trackEvent({
              name: 'Application Form - Submit Error',
              properties: {
                event_category: 'Application Form',
                event_action: 'Submit Error',
                event_label: page_from_to
              }
            });

          })


        } else {
          if (this.numberOfSubmit <= 0) {
            if (this.netMonthlyIncome >= 900 && this.netMonthlyIncome <= 10000) {

              this.$router.push(to).catch((e) => {
                if (!isNavigationFailure(e, NavigationFailureType.redirected)) {
                  Promise.reject(e)
                }
              });

              this.$appInsights.trackEvent({
                name: 'Application Form - Submit',
                properties: {
                  event_category: 'Application Form',
                  event_action: 'Submit',
                  event_label: page_from_to
                }
              });
            }else{
              let error = document.querySelector('.error')
              let errors = document.querySelectorAll('.error')
              errors.forEach(error => {
                    error.classList.add('shake')
                    setTimeout(function(){
                      error.classList.remove('shake')
                    }, 500)
                  },

              )

              if (error && error.parentNode) {
                let domRect = error.parentNode.getBoundingClientRect()

                if (this.errorElementId === 'input-borrow') {
                  let incomeElement = document.querySelector('#' + this.errorElementId)
                  domRect = incomeElement.getBoundingClientRect()
                }

                window.scrollTo(
                    domRect.left + document.documentElement.scrollLeft,
                    domRect.top + document.documentElement.scrollTop
                );
              } else {

                this.$router.push(to).catch((e) => {
                  if (!isNavigationFailure(e, NavigationFailureType.redirected)) {
                    Promise.reject(e)
                  }
                });

                this.$appInsights.trackEvent({
                  name: 'Application Form - Submit',
                  properties: {
                    event_category: 'Application Form',
                    event_action: 'Submit',
                    event_label: page_from_to
                  }
                });
              }
            }
            this.numberOfSubmit++;
          } else {

            this.$router.push(to).catch((e) => {
              if (!isNavigationFailure(e, NavigationFailureType.redirected)) {
                Promise.reject(e)
              }
            });

            this.$appInsights.trackEvent({
              name: 'Application Form - Submit',
              properties: {
                event_category: 'Application Form',
                event_action: 'Submit',
                event_label: page_from_to
              }
            });
          }
        }
      } else {

        this.$router.push(to).catch((e) => {
          if (!isNavigationFailure(e, NavigationFailureType.redirected)) {
            Promise.reject(e)
          }
        });

        this.$appInsights.trackEvent({
          name: 'Application Form - Submit',
          properties: {
            event_category: 'Application Form',
            event_action: 'Submit',
            event_label: page_from_to
          }
        });
      }
    },
    showModal(id) {
      // console.log(id)
      if(id === 'Terms') this.isTermsVisible = true
      if(id === 'Dealerships') this.isDealershipsVisible = true
      if(id === 'Privacy') this.isPrivacyVisible = true
      if(id === 'Cookie') this.isCookieVisible = true
    },
    closeModal() {
      this.isTermsVisible = false
      this.isDealershipsVisible = false
      this.isPrivacyVisible = false
      this.isCookieVisible = false
    }
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
