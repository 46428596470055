import axios from 'axios'
import { URLS } from '../factories'
import { ErrorService } from '.'
import {ObjectHelper, RequestHelper} from '../helpers'
import {DEFAULT_VALUE} from '@/const';

export default {
    prePopAppForm(store, state) {
        let tokenString = 'Bearer ' + state.token.authenticationToken
        let headersObj = {"Authorization": tokenString}

        const delegateFn = () => {
            const applicationApiFullUrl = state.isProduction ? URLS.production.applicationApiFullUrl : URLS.replica.applicationApiFullUrl;

            axios({
                method: 'GET',
                url: applicationApiFullUrl + '/api/LoanApplication',
                headers: headersObj,
                params: { appId: state.applicationId,  validation : state.affiliateConfig && typeof state.affiliateConfig.prePopulateFormValidation !== 'undefined' ? state.affiliateConfig.prePopulateFormValidation : true }
            }).then(function (response) {


                const existingApp = response.data;
                store.dispatch('setExistingApp', existingApp);

                store.dispatch('updateFormValue', [state.applicationId, 'form.referralId']);
                store.dispatch('updateFormValue', [existingApp.riskBand, 'form.riskBand']);
                store.dispatch('updateFormValue', [ObjectHelper.defaultIfUndefinedOrNull(existingApp.loanAmount, DEFAULT_VALUE.loanAmount), 'form.loanAmount']);
                store.dispatch('updateFormValue', [existingApp.vehicleType ? existingApp.vehicleType.id : state.form.vehicleTypeId, 'form.vehicleTypeId']);
                store.dispatch('updateFormValue', [existingApp.title ? existingApp.title.id : null, 'form.titleId']);
                store.dispatch('updateFormValue', [existingApp.firstName, 'form.firstName']);
                store.dispatch('updateFormValue', [existingApp.lastName, 'form.lastName']);
                store.dispatch('updateFormValue', [existingApp.email, 'form.email']);
                store.dispatch('updateFormValue', [existingApp.submissionType, 'form.submissionTypeId']);

                const startWithZeroRegExp = /^0[0-9].*$/;

                const contactNumber = (existingApp.contactNumber !== undefined && !isNaN(parseInt(existingApp.contactNumber))) ?
                    (startWithZeroRegExp.test(parseInt(existingApp.contactNumber).toString()) ? parseInt(existingApp.contactNumber) : "0" + parseInt(existingApp.contactNumber)) : null;

                store.dispatch('updateFormValue', [contactNumber, 'form.contactNumber']);

                const dateOfBirth = new Date(existingApp.dateOfBirth);
                const dobYear = parseInt(dateOfBirth.getFullYear().toString());
                const dobMonth = parseInt(dateOfBirth.getMonth() + 1);
                const dobDay = parseInt(dateOfBirth.getDate().toString());
                const existingDob = new Date(dobYear, dobMonth, dobDay);

                store.dispatch('updateFormValue', [existingDob, 'form.dateOfBirth']);
                store.dispatch('updateFormValue', [dobDay, 'dobDay']);
                store.dispatch('updateFormValue', [dobMonth, 'dobMonth']);
                store.dispatch('updateFormValue', [dobYear, 'dobYear']);

                store.dispatch('updateFormValue', [existingApp.maritalStatus ? existingApp.maritalStatus.id : state.form.maritalStatusId, 'form.maritalStatusId']);
                store.dispatch('updateFormValue', [existingApp.drivingLicence ? existingApp.drivingLicence.id : state.form.drivingLicenceTypeId, 'form.drivingLicenceTypeId']);

                if (existingApp.addresses && Array.isArray(existingApp.addresses)) {
                    const addressDetails = [];

                    existingApp.addresses.forEach(function (address) {
                        if(address ) {
                            let residentialStatusId = address['residentialStatusLookup'] && address['residentialStatusLookup']['id'] ? parseInt(address['residentialStatusLookup']['id']) : null
                            let addressObj = {
                                residentialStatusId: residentialStatusId && residentialStatusId !== -1? residentialStatusId : state.form.addresses[0].residentialStatusId,
                                postcode: ObjectHelper.defaultIfUndefinedOrNull(address.postcode, null),
                                subBuilding: ObjectHelper.defaultIfUndefinedOrNull(address.subBuilding, null),
                                flatNumber: ObjectHelper.defaultIfUndefinedOrNull(address.flatNumber, null),
                                buildingName: ObjectHelper.defaultIfUndefinedOrNull(address.buildingName, null),
                                buildingNumber: ObjectHelper.defaultIfUndefinedOrNull(address.buildingNumber, null),
                                street: ObjectHelper.defaultIfUndefinedOrNull(address.street, null),
                                locality: ObjectHelper.defaultIfUndefinedOrNull(address.locality, null),
                                town: ObjectHelper.defaultIfUndefinedOrNull(address.town, null),
                                county: ObjectHelper.defaultIfUndefinedOrNull(address.county, null),
                                yearsAtAddress: ObjectHelper.defaultIfUndefinedOrNull(address.yearsAtAddress, null),
                                monthsAtAddress: ObjectHelper.defaultIfUndefinedOrNull(address.monthsAtAddress, null),
                                addressLevel: ObjectHelper.defaultIfUndefinedOrNull(address.addressLevel, null)
                            }
                            addressDetails.push(addressObj);

                            let addressList = [];
                            if(addressObj.flatNumber) addressList.push(addressObj.flatNumber);
                            if(addressObj.subBuilding) addressList.push(addressObj.subBuilding);
                            if(addressObj.buildingNumber && addressObj.buildingName) addressList.push(addressObj.buildingNumber + " ", addressObj.buildingName);
                            if(addressObj.street) addressList.push(addressObj.street);
                            if(addressObj.town) addressList.push(addressObj.town + ".");
                            if(addressObj.postcode) addressList.push(addressObj.postcode);

                            const fullAddress = {
                                index: 0,
                                address: addressList.join(", "),
                                town: ObjectHelper.defaultIfUndefinedOrNull(address.town, null),
                                hideAddressLookup: true,
                                isFromPrepop: true
                            }
                
                            store.dispatch('setFullAddress', fullAddress)
                        }
                    }, this);

                    store.dispatch('updateFormValue', [addressDetails, 'form.addresses']);
                }

                if (existingApp.employments && Array.isArray(existingApp.employments)) {
                    const employmentDetails = [];

                    existingApp.employments.forEach(function (employment) {
                        let statusId = employment['employmentStatusLookup'] && employment['employmentStatusLookup']['id'] ? parseInt(employment['employmentStatusLookup']['id']) : null;
                        employmentDetails.push({
                            statusId: statusId && statusId !== -1 ? statusId : state.form.employments[0].statusId,
                            occupation: ObjectHelper.defaultIfUndefinedOrNull(employment.occupation, null),
                            employerName: ObjectHelper.defaultIfUndefinedOrNull(employment.employerName, null),
                            employerTown: ObjectHelper.defaultIfUndefinedOrNull(employment.employerTown, null),
                            MonthlyIncome: existingApp.employments[0] === employment ? ObjectHelper.defaultIfUndefinedOrNull(existingApp.netMonthlyIncome, null) : null,
                            yearsAtEmployer: ObjectHelper.defaultIfUndefinedOrNull(employment.yearsAtEmployer, null),
                            monthsAtEmployer: ObjectHelper.defaultIfUndefinedOrNull(employment.monthsAtEmployer, null),
                            employmentLevel: ObjectHelper.defaultIfUndefinedOrNull(employment.employmentLevel, null)
                        });
                    }, this);

                    store.dispatch('updateFormValue', [employmentDetails, 'form.employments']);

                    store.dispatch('updateFormValue', [existingApp.netMonthlyIncome ? ObjectHelper.defaultIfUndefinedOrNull(existingApp.netMonthlyIncome, null) : null, 'form.netMonthlyIncome']);


                }
            }).catch(function (error) {
                ErrorService.submit(state, error.message, error.stack);
            });
        };

        delegateFn();
    },
    getLoanOption(store, state) {
        let tokenString = 'Bearer ' + state.token.authenticationToken
        let headersObj = {"Authorization": tokenString}

        const delegateFn = () => {
            const applicationApiFullUrl = state.isProduction ? URLS.production.applicationApiFullUrl : URLS.replica.applicationApiFullUrl;

            let clickId = RequestHelper.getQueryVariable('clickid');

            let queryParams = { appId: state.applicationId }

            if (clickId) {
                queryParams.clickId = clickId;
            }

            axios({
                method: 'GET',
                url: applicationApiFullUrl + '/api/LoanApplication/get-loan-option',
                headers: headersObj,
                params: queryParams
            }).then(function (response) {
                const loanOption = response.data;
                store.dispatch('setLoanOption', loanOption);
            }).catch(function (error) {
                ErrorService.submit(state, error.message, error.stack);
            });
        };

        delegateFn();
    }
}