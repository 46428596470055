<template>
    <div>
      <footer v-if="isShow">
        <small>

          <section>

            <address>
              CarFinance 247 <abbr title="limited">Ltd</abbr>.
              <br>Universal Square,
              <br>Devonshire Street North,
              <br>Manchester,
              <br>M12 6JH
            </address>

            <ul>
              <li>
                <a rel="noopener" href="https://www.carfinance247.co.uk/careers" target="_blank">Careers</a>
              </li>
              <li>
                <a rel="noopener" href="https://www.carfinance247.co.uk/terms-and-conditions" target="_blank">Terms &amp; Conditions</a>
              </li>
              <li>
                <a rel="noopener" href="https://www.carfinance247.co.uk/privacy-policy" target="_blank">Privacy Policy</a>
              </li>
              <li>
                <a rel="noopener" href="https://www.carfinance247.co.uk/cookie-policy" target="_blank">Cookie Policy</a>
              </li>
            </ul>

          </section>


          <p>
            CarFinance 247 Limited ("CarFinance 247") is a credit broker,
            not a lender and is also an insurance intermediary. CarFinance 247 is authorised and
            regulated by the Financial Conduct Authority under reference number: 653019. Registered office:
            Universal Square, Devonshire Street North, Manchester, M12 6JH. Registered in England.
            Company registration no. 06035525. Data Protection Registration Number Z1897658.
          </p>

          <p>
            247 Money Group Holdings Limited ("247 Money") is a lender.
            247 Money is authorised and regulated by the Financial Conduct Authority.
            Registered office: Universal Square, Devonshire Street North, Manchester, M12 6JH.
            Registered in England. Company registration no. 10989530. Data Protection Registration Number ZA456831.
          </p>

          <p>
            Both CarFinance 247 and 247 Money are part of the 247 group of companies.
          </p>

          <p>
            CarFinance 247 can introduce you to a limited number of finance providers, including 247Money,
            based upon your individual circumstances and will receive commission for such introductions.
            All finance is subject to status and income. CarFinance 247 do not charge a fee for arranging the finance,
            however some of our lenders may charge a fee. Your Account Manager can provide more information on this.
          </p>

          <p>
            * The UK's number one online car finance broker by unique users to the website. Based on Hitwise data – 108,204 to CarFinance 247 vs. 63,343 for nearest competitor. January 2019.
          </p>

          <p>
            This site uses cookies so that we can provide you with the best user experience. By continuing to use the site you are agreeing to our use of cookies. To read more about the cookies we use and to change your settings, please see our <a href="https://www.carfinance247.co.uk/cookie-policy" target="_blank">cookie policy</a>.
          </p>

          <p>
            {{ nodeEnv }} v{{appVersion}}
          </p>
        </small>
      </footer>
      <footer v-if="isSocial">
        <hr class="separator mt-100" />

        <div v-if="isSocialPremier">
          <p>
            Rates from 8.9% APR. Representative <strong>10.9% APR. Personal Contract Purchase (PCP) Example:</strong> Car price <strong>£27,500.00</strong>, Term 4 years, Deposit <strong>£2,750.00</strong>, Total amount of credit <strong>£24,750.00</strong>, Representative APR <strong>10.9% fixed rate</strong>, Monthly payment <strong>£392.47</strong>, Total cost of credit <strong>£8,291.09</strong>, Total amount payable <strong>£35,791.09</strong>, Optional final payment <strong>£14,595.00</strong>, Annual mileage 8,000pa.
          </p>
        </div>

        <div v-else-if="isSocialVans">
          <p>
            <strong>Rates from 9.9% APR.</strong> Representative 13.9% APR.
          </p>
          <p>
            Hire Purchase (HP) Example: Borrow £13,000 over 5 years with a £0 deposit. Representative APR 13.9% fixed rate. Monthly payment: £296.36. Total cost of credit: £4,781.72. Total amount repayable: £17,781.72. Car Finance 247 Limited is a credit broker not a lender.
          </p>
        </div>

        <div v-else-if="isSocialBadCredit">
          <p>
            <strong>Rates from 19.9% APR.</strong> Representative 29.9% APR.
          </p>
          <p>
            Hire Purchase (HP) Example: Borrow £8,000 over 5 years with a £0 deposit. Representative APR 29.9% fixed rate. Monthly payment: £241.64. Total cost of credit: £6,498.63. Total amount repayable: £14,498.63. Car Finance 247 Limited is a credit broker not a lender.
          </p>
        </div>

        <div v-else-if="isSocialGeneric">
          <p>
            <strong>Rates from 9.9% APR.</strong> Representative 19.9% APR.
          </p>
          <p>
            Hire Purchase (HP) Example: Borrow £10,000 over 5 years with a £0 deposit. Representative APR 19.9% fixed rate. Monthly payment: £255.50. Total cost of credit: £5,329.80. Total amount repayable: £15,329.80. Car Finance 247 Limited is a credit broker not a lender.
          </p>
        </div>

        <div v-else-if="isSocialExcellectPCP">
          <p>
            <strong>Rates from 9.9% APR.</strong> Representative 11.9% APR.
          </p>
          <p>
            Personal Contract Purchase (PCP) Example: Car price £28,300.00, Term 4 years, Deposit £3,800.00, Total amount of credit £24,500.00, Representative APR 11.9% fixed rate, Monthly payment £394.58, Total cost of credit £9,186.76, Total amount payable £37,486.76, Optional final payment £14,746.92, Annual mileage 8,000pa.
          </p>
        </div>

        <div v-else>
          <p>
            <strong>Rates from 9.9% APR</strong>: the exact rate you will be offered will be based on your circumstances, subject to status.
          </p>
          <p>
            <strong>Representative example</strong>: borrowing <strong>£10,000</strong> over <strong>5 years</strong> with a representative <strong>APR of 19.9%</strong>, an annual interest rate of <strong>19.9% (Fixed)</strong> and a deposit of £0.00, the amount payable would be <strong>£255.50</strong> per month, with a total cost of credit of <strong>£5,329.80</strong> and a total amount payable of <strong>£15,329.80</strong>
          </p>
        </div>

        <div>
          <p>
            We look to find the <strong>best product from our panel of lenders</strong> and will offer you the best deal that you’re eligible for. We earn a commission for providing our services, but this does not influence the interest rate you’re offered in any way.
          </p>
          <p class="mt-100">
            CarFinance 247 is a trading name of CarFinance 247 Limited. Registered office: Universal Square, Devonshire Street North, Manchester, M12 6JH. Registered in England. (Registration Number 06035525).
          </p>
          <p>
            CarFinance 247 is authorised and regulated by the Financial Conduct Authority for insurance distribution and credit broking (Firm Reference Number: 653019). CarFinance 247 is registered with the ICO (Registration Number Z1897658).
          </p>
          <p>
            Finance is subject to status and is only available to UK residents aged 18 and over. Written quotations are available on request.
          </p>
          <p>
            This site uses cookies so that we can provide you with the best user experience. By continuing to use the site you are consenting for cookies to be used. Further information on cookies and how you can disable them is available on our <a class="no-text-decoration" href="/cookie-policy" @click.prevent="showModal('Cookie')">cookie policy</a>.
          </p>
        </div>
      </footer>

      <Modal v-show="isCookieVisible" @close="closeModal">
        <template v-slot:body>
          <Cookie @openModal="openModal" />
        </template>
      </Modal>
    </div>
</template>

<script>
import {LocationHelper, RequestHelper} from '@/helpers';
  import Cookie from '@/components/Cookie.vue';

  export default {
    name: "Footer",
    components: {Cookie},
    data() {
      return {
        appVersion: process.env.VUE_APP_VERSION,
        nodeEnv: process.env.NODE_ENV,
        isShow: false,
        isCookieVisible: false,
      }
    },
    methods: {
      openModal() {
          this.isCookieVisible = true;
      },
    },
    computed: {
      isSocialGeneric() {
        return RequestHelper.getQueryVariable('utm_type') === 'cw';
      },
      isSocialExcellectPCP() {
        return RequestHelper.getQueryVariable('utm_type') === 'pr';
      },
      isSocialPremier() {
        return LocationHelper.getHost() === 'social-premier';
      },
      isSocialVans() {
        return LocationHelper.getHost() === 'social-vans' || RequestHelper.getQueryVariable('utm_type') === 'vp';
      },
      isSocialBadCredit() {
        return LocationHelper.getHost() === 'social-bad-credit' || RequestHelper.getQueryVariable('utm_type') === 'bc';
      },
      isSocial() {
        const host = ['social','ecrm', 'tiktok', 'social-premier', 'social-vans', 'social-bad-credit'];

        return host.indexOf(LocationHelper.getHost()) !== -1 && this.$route.name === 'start';
      }     
    }
  }
</script>

<style lang="scss" scoped>
  footer {
    max-width: 940px;
    margin: 0 auto;
    width: 100%;
    padding-top: 200px;

    @media (min-width: 360px) {
      p {
        font-size: 0.7em;
      }
    }

    @media (min-width: 768px) {
      p {
        font-size: 0.8em;
      }
    }
  }

  hr.separator {
    border: 0;
    margin: 0;
    box-shadow: inset 0 1px 0 0 #e5e5e5;
    height: 5px;
  }

  p.center {
    text-align: center;
  }

  p.mt-100 {
    margin-top: 100px;
  }

  a.no-text-decoration {
    text-decoration: none;
  }
</style>
