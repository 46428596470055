<template>
  <div :class="canCompactContactDetail ? 'canCompactContactDetail' : null">
    <form @submit.prevent="completeApplication()">
      
      <InputGroup
          id="title"
          name="form.titleId"
          label="Almost done, let us know who you are?"
          :sub-label="!canCompactContactDetail ? 'Your title' : null"
          :items="[
          {label: 'Mr', value: 1, id: idConcat(pageName, 'Mr')},
          {label: 'Mrs', value: 2, id: idConcat(pageName, 'Mrs')},
          {label: 'Miss', value: 4, id: idConcat(pageName, 'Miss')},
          {label: 'Ms', value: 3, id: idConcat(pageName, 'Ms')}
        ]"
          v-model="$v.title.$model"
          :v="$v.title"
          :show-tool-tip="showToolTip"
          :mask-input="true"
      />

      <InputText
          :id="idConcat(pageName, 'FirstName')"
          name="form.firstName"
          label="First name"
          autocomplete="off"
          v-model="$v.firstname.$model"
          :v="$v.firstname"
          v-show="!isPersonalised"
          :sensitive-data="true"
      />

      <InputText
          :id="idConcat(pageName, 'LastName')"
          name="form.lastName"
          label="Last name"
          autocomplete="off"
          v-model="$v.lastname.$model"
          :v="$v.lastname"
          :sensitive-data="true"
      />

      <InputEmail
        :id="idConcat(pageName, 'Email')"
        label="Email address"
        invalidMessage="Please enter an email address."
        name="form.email"
        autocomplete="email"
        v-model.trim="$v.email.$model"
        :v="$v.email"
        :blur="emailBlurEvent"
        :sensitive-data="true"
      />

      <InputPhone
        :id="idConcat(pageName, 'Phone')"
        label="Phone number"
        invalidMessage="Please enter a phone number."
        name="form.contactNumber"
        autocomplete="tel"
        v-model.trim="$v.phone.$model"
        :v="$v.phone"
        :phoneNumber="$v.phone.$model"
        :styleAtt="'width: 50%;'"
        :sensitive-data="true"
      />

      <InputCheckboxTC
          :id="idConcat(pageName, 'TermsCheckbox')"
          header="Terms and conditions"
          name="form.agreedTermsAndConditions"
          invalidMessage="Please agree to the T&amp;Cs to get your quote."
          :submitPressed="this.submitPressed"
          v-model="$v.terms.$model"
          :v="$v.terms"
          class="tAndCArea"
      >
        I have read and agree to <a :id="idConcat(pageName, 'TermsHref')" href="/terms" @click.prevent="showModal('Terms')"><span v-if="showCF247Band">Car Finance 247 Limited's </span>terms &amp; conditions</a>
      </InputCheckboxTC>

      <p class="error" v-if="!$v.terms.$model && this.submitPressed">
        {{ "Please agree to the T&amp;Cs to get your quote." }}
      </p>

      <ButtonContinue :v-center-arrow="isTestFinishButton" :id="idConcat(pageName,'ContinueBtn')" @click.once="completeApplication()" :disable='isSubmitted' class="finishBtn"
                      :sub-label="isTestFinishButton ? 'See if you\'ll be accepted<br />No impact on your credit score' : ''">
        <span v-show="!isSubmitted">{{ ctaWording }}</span>
        <span v-show="isSubmitted">Submitting...</span>
      </ButtonContinue>



      <ButtonBack :id="idConcat(pageName,'BackBtn')" class="previousBtn" />

      <FraudPreventionMsg :id="idConcat(pageName,'FraudPreventionMsgHref')" @showModal="openModal" />

      <Modal v-show="isTermsVisible" @close="closeModal">
        <template v-slot:body>
          <Terms @openModal="openModal" />
        </template>
        <Terms />
      </Modal>

      <Modal v-show="isDealershipsVisible" @close="closeModal">
        <template v-slot:body>
          <Dealerships />
        </template>
        <Terms />
      </Modal>

      <Modal v-show="isPrivacyVisible" @close="closeModal">
        <template v-slot:body>
          <Privacy @openModal="openModal" />
        </template>
        <Terms />
      </Modal>

      <Modal v-show="isCookieVisible" @close="closeModal">
        <template v-slot:body>
          <Cookie />
        </template>
        <Terms />
      </Modal>

      <InfoFooter :showTopOnly="true" />
    </form>
  </div>
</template>

<script>
import { required, email} from 'vuelidate/lib/validators'
import { checkUKTelephoneError } from "../modules/checkUKTelephoneError.js"
import Terms from '@/components/Terms.vue'
import Dealerships from '@/components/Dealerships.vue'
import Privacy from '@/components/Privacy.vue'
import Cookie from '@/components/Cookie.vue'
import FraudPreventionMsg from '@/components/FraudPreventionMsg'
import { debounce } from 'lodash'
import {AnalyticsService, SegmentService} from '../services';
import { phoneNormalised } from '../helpers/phone-normalization-helper'
import {CanCompactContactDetailPageHelper, LocationHelper, RequestHelper} from "@/helpers"
import {AFFILIATES} from '@/factories';
import { SENSITIVE_DATA } from '../const';

const validate_uk_phone_number = (value) => {
  if (!value || value.length === 0 || !value.trim()) {
    return false;
  }

  let phoneError = checkUKTelephoneError(value);

  if (phoneError) {
    return false;
  }

  return true;
}

export default {
  components: {
    Terms,
    Dealerships,
    Privacy,
    Cookie,
    FraudPreventionMsg,
  },
  data() {
    return {
      pageName: 'ContactDetailsOptimised',
      title: this.$store.state.form.titleId,
      firstname: this.$store.state.form.firstName,
      lastname: this.$store.state.form.lastName,
      email: this.$store.state.form.email,
      phone: this.$store.state.form.contactNumber,
      terms: this.$store.state.form.agreedTermsAndConditions,
      isTermsVisible: false,
      isDealershipsVisible: false,
      isPrivacyVisible: false,
      isCookieVisible: false,
      isSubmitted: false,
      submitPressed: false,
      canCompactContactDetail: CanCompactContactDetailPageHelper.canCompactContactDetail(),
      SENSITIVE_DATA: SENSITIVE_DATA,
      pageViewSubmitted: false,
      showCF247Band: true,
      isTestFinishButton: RequestHelper.getQueryVariable('test') === 'finish-button-sub-text',
    }
  },
  computed: {
    isPersonalised() {
      let host = LocationHelper.getHost();

      let personalised = RequestHelper.getQueryVariable('personalised');

      return personalised && AFFILIATES[host] !== undefined;
    },
    showToolTip() {
      return LocationHelper.getHost() === 'comparethemarketcarfinance'
    },
    ctaWording() {
      var medium = this.$store.state && 
              this.$store.state.form && 
              this.$store.state.form.utmParameters && 
              this.$store.state.form.utmParameters['medium'] 
              ? 
                this.$store.state.form.utmParameters['medium']  
              : 
                null;

      var isPaidFinalCtaText = RequestHelper.getQueryVariable('test') === 'paid-final-cta-text'

      return medium && isPaidFinalCtaText ? 
              ['cpc', 'display', 'paidsocial'].indexOf(medium) != -1 ? 'Next' : 'Finish' 
            : 
              'Finish'
    }
  },
  validations: {
    title: {
      required
    },
    firstname: {
      required
    },
    lastname: {
      required
    },
    email: {
      required,
      email
    },
    terms: {
    },
    phone: {
      required,
      validate_uk_phone_number
    },
  },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);
    
    this.$store.dispatch('updateFormValue', [true, 'form.personalDetailsCompleted']);

    if (this.email) {
      this.$v.email.$touch();
      this.emailBlurEvent();
    }

    if (!this.pageViewSubmitted) {
      this.pageViewSubmitted = true;

      AnalyticsService.trackPageView({
        page_name: 'contact-details'
      });
    }

    let host = LocationHelper.getHost();

    if (AFFILIATES[host] !== undefined) {
      const affiliateConfig = AFFILIATES[host];

      if (affiliateConfig && typeof affiliateConfig.showCF247Band !== "undefined") {
        this.showCF247Band = affiliateConfig.showCF247Band;
      }
    }
  },
  methods: {
    emailBlurEvent() {
      if (!(this.$v.email.$pending || this.$v.email.$error)) {
        AnalyticsService.setData('customer_email', this.email, true);
      }
    },
    submitApplication() {
      SegmentService.trackPersonalDetailsCompletedEvent(this.$store.state, {
        guid: this.$store.state.GUID,
        email: this.email,
        date_of_birth: this.$store.state.form.dateOfBirth,
        first_name: this.firstname,
        last_name: this.lastname,
        phone_number: this.phone,
        phone_number_normalised: phoneNormalised(this.phone),
        postcode: this.$store.state.form.addresses[0].postcode || null,
        vehicle_type_id: this.$store.state.form.vehicleTypeId,
        utm: this.$store.state.form.utmParameters,
        net_monthly_income: this.$store.state.form.netMonthlyIncome,
        city: this.$store.state.form.addresses[0].town || null,
        gender: this.$store.state.form.titleId === 1 ? 'male' : 'female'
      });

      const self = this;
      self.isSubmitted = true;
      setTimeout(function () {
        self.isSubmitted = false;
      }, 10000)

      let data = [true, 'form.IsCompleted']
      this.$store.dispatch('updateFormValue', data)
          .then(() => this.$emit('sendFullApp', this.$store.state))
    },
    completeApplication() {
      this.submitPressed = true

      this.$v.$touch();

      if (this.$v.terms.$model && !this.$v.$invalid) {
        AnalyticsService.trackEvent({
          event_name: 'complete_application',
          state: this.$store.state,
        }, this.submitApplication);
      }
    },
    openModal(modal) {
      this.isTermsVisible = false;

      if (modal === 'privacy-policy') {
        this.isPrivacyVisible = true;
      }

      if (modal === 'cookie-policy') {
        this.isCookieVisible = true;
      }
    },
    created() {
      let self = this;
      self.completeApplication = debounce(self.completeApplication, 500)
    }
  }
}
</script>
<style lang="scss" scoped>
#app form {
  .duel-inputs {
    display: flex;
    justify-content: space-between;

    .small-input {
      max-width: calc(50% - 20px)
    }
  }
}
</style>

<style >
  .canCompactContactDetail form h1 {
    margin: 5px 0 5px !important;
  }

  .canCompactContactDetail .formWrapper {
    padding: 7px 0 !important;
  }

  .canCompactContactDetail  h1{
    font-size: 1.1em !important;
  }

  .canCompactContactDetail  .checkbox-container p {
    font-size: 0.8em !important;
  }

  .canCompactContactDetail .input-container input {
    height: 3rem !important;
  }


  .canCompactContactDetail form .input-container input:focus + .labelWrapper .textlabel, 
  .canCompactContactDetail form .input-container :not([value=""]) + .labelWrapper .textlabel {
      -webkit-transform: translate(0, -180%) !important;
      transform: translate(0, -180%) !important;
  }

  .canCompactContactDetail #title label {
    margin-bottom: 0px !important;
  }

  .canCompactContactDetail .previousBtn,  .canCompactContactDetail .finishBtn button {
    margin-top: 7px !important;
  }

 .canCompactContactDetail .finishBtn button {
    margin-top: 5px !important;
  }

  .canCompactContactDetail .tAndCArea {
    padding-top: 0px !important;
  }

  .canCompactContactDetail .tAndCArea .checkmark {
    height: 25px !important;
    width: 25px !important;
  }
</style>
