import { debounce } from 'lodash'
import { PartialApplicationService } from '../services'

const sendAppDebounceTime = 4000

export default {
    subscribe(This, store) {
        store.subscribe((mutation, state) => {
            window.sessionStorage.setItem('store', JSON.stringify(state))
            if (!(mutation.type === 'UPDATE_FORM_VALUE' && mutation.payload[1] === 'form.dateOfBirth' )) {
                debounce(() => { PartialApplicationService.sendPartialApp(This, state, store) }, sendAppDebounceTime)();
            }
        })
    }
}