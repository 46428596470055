<template>
    <section>
        <div role="group" :id="id" :aria-label="label" class="input-group" :class="{ 'columns': columns }">
            <h1 v-if="label">{{ label }}</h1>
            <div v-if="subLabel">{{ subLabel }}</div>
            <template  v-for="(item, index) in inputItems">
                <input v-if="item.show" :class="item.maskData ? SENSITIVE_DATA.p11Mask : ''" type="radio" :disabled="$store.state.lockDown" v-model="value" :id="item.id" :value="item.value" :key="index" @click="(event) => coutineClick(event, item.canCoutinue)">
                <label v-if="item.show" :class="item.maskData ? 
                                        isNewApplyBtn ? ['abtestNewApplyBtn'].concat(SENSITIVE_DATA.p11Mask) : SENSITIVE_DATA.p11Mask 
                                        : 
                                        isNewApplyBtn ? 'abtestNewApplyBtn' : '' " 
                              :for="item.id" :key="'label'+index">{{ item.label }}</label>
            </template>
            <input v-if="showOtherInput" type="radio" :disabled="$store.state.lockDown" :id="idOther" @click="hideOtherInput">
            <label v-if="showOtherInput" :for="idOther" id="other-label" :class="isNewApplyBtn ? 'abtestNewApplyBtn' : ''">Other</label>
        </div>
        <p class="error" v-if="this.v.$error">Please choose an option to continue.</p>
    </section>
</template>

<script>
import {SENSITIVE_DATA} from '@/const';
import {NewApplyBtnHelper} from '@/helpers';

export default {
    name: 'InputCollapseGroup',
    props: {
        id:{
            type: String,
            default() {
                return 'input-group-' + this._uid
            }
        },
        idOther: {
            type: String,
            default() {
                return 'carfinance-btn-other'
            }
        },
        items: {
            type: Array
        },
        name: {
            type: String,
            default: ''
        },
        stateName: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        subLabel: {
            type: String,
            default: ''
        },
        columns: {
            type: Boolean,
            default: false
        },
        v: {
            type: Object,
            default: function () { return {} }
        },
        submitFunction: {
            type: Function
        }
    },
    data() {
        return {
            showOtherInput: true,
            inputItems: this.items,
        }
    },
    methods: {
        coutineClick: function(event, canCoutinue) {
            if(this.submitFunction && canCoutinue) {
                this.submitFunction();
            }
        },
        hideOtherInput: function () {
            this.showOtherInput = false;
            this.inputItems.forEach((item) => {
                item.show = true;
            });
            this.$store.dispatch('updateFormValue', [true, this.stateName])
        }
    },
    computed: {
      SENSITIVE_DATA() {
        return SENSITIVE_DATA
      },
        value: {
            get: function () {

                let name = this.name
                let obj = this.$store.state
                let get = _.get(obj, name)
                return get

            },
            set: function (newValue) {
                let data = [newValue, this.name]
                this.$store.dispatch('updateFormValue', data)
                this.$emit('input', newValue)
                return newValue

            }
        },
      isNewApplyBtn() {
        return NewApplyBtnHelper.isNewApplyBtn();
      }
    },
    mounted() {
        if (this.$store.state.form.employmentStatusOtherClicked) {
            this.hideOtherInput();
        } else {
            const This = this;
            this.items.forEach((item) => {
                if (item.value === This.value && item.show === false) {
                    This.hideOtherInput();
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
#app form {
  section {
    padding: 0px 0 0;
  }

  h1 {
    margin-top: 0px;
    margin-bottom: 40px;
    width: 100%
  }

  .abtestNewApplyBtn {
    box-shadow: 0px 2px 0px 0px #604DD533;
    background: rgba(96, 77, 213, 0.1) !important;
    color: rgba(96, 77, 213, 1);
    font-weight: 700;
    font-size: 20px;
    line-height: 22.04px;
  }

  .input-group {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    div {
      margin-top: 0px;
      margin-bottom: 20px;
      width: 100%
    }

    &.columns {
      justify-content: space-between;
      align-items: center;

      label {
        min-width: 40%;
        max-width: calc(50% - 10px);
        margin-right: 0;

        &:nth-of-type(odd) {
          margin-right: 10px
        }

        &:nth-of-type(even) {
          margin-left: 9px /* Safari Rendering bug fix */
        }
      }
    }

    label {
      padding: 12.5px;
      max-height: 65px;
      font-size: 1.2rem;
      border: 1px solid $label;
      border-radius: 2rem;
      background: $label;
      flex-grow: 1;
      text-align: center;
      margin: 0 20px 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    input {
      opacity: 0;
      height: 0;
      width: 0;
      margin: 0;
      -webkit-appearance: none;
      -moz-appearance: none;

      &:checked + label {
        border: 1px solid $electric-purple;
        color: $electric-purple;
        background: #fff;
      }

      &:checked + .abtestNewApplyBtn {
        background: rgba(96, 77, 213, 1) !important;
        color: #fff;
        box-shadow: none;
      }

      &:focus + label {
        box-shadow: 0 0 0 2px $electric-purple;
      }
    }

    #other {
      &:focus + label {
        box-shadow: 0 0 0 0px;
      }
    }

    #other-label {
      min-width: 100%;
      max-width: 100%;
      margin-right: 0;
    }
  }

  #other {
    display: none;
  }
}
</style>