<template>
  <img alt="RAC Cars Logo" height="25" v-lazy="imgUrl" />
</template>

<script>
export default {
  name: "RacCarsLogo",
    data () {
    return {
      imgUrl:  require('@/assets/img/affiliates/raccars.png')
    }
  }
}
</script>

<style scoped>

</style>