import axios from 'axios'
import { MapperService } from '.'
import { URLS } from '../factories'
import router from '../router';

export default {
    async sendFullApp(This, state, store) {
        let token = state.token && state.token['authenticationToken'] ? state.token['authenticationToken'] : null
        if(token) {
            let tokenUpdateTime = state.token && state.token['tokenUpdateTime'] ? state.token['tokenUpdateTime'] : 0
            let authenticationTokenLifetime = state.token && state.token['authenticationTokenLifetime'] ? state.token['authenticationTokenLifetime'] : 0
            if (tokenUpdateTime + authenticationTokenLifetime < Math.floor(Date.now() / 1000) ) {
                await store.dispatch('updateAuthToken')
                token = state.token && state.token['authenticationToken'] ? state.token['authenticationToken'] : null
            }

            let tokenString = 'Bearer ' + token
            let headersObj = {"Authorization": tokenString}

            // Complete application
            if(state.form.IsCompleted && state.form.agreedTermsAndConditions && state.fullAppSuccess === false) {
                let fullApps = MapperService.map(state);

                fullApps.agreedTermsAndConditions = true;
                fullApps.IsCompleted = true;

                const delegateFn = () => {
                    const applicationApiFullUrl = state.isProduction ? URLS.production.applicationApiFullUrl : URLS.replica.applicationApiFullUrl;

                    axios({
                        method: 'POST',
                        url: applicationApiFullUrl + '/api/application/' + state.GUID,
                        headers: headersObj,
                        data: fullApps
                    }).then(response=>{

                        let statusCode = response.data['accountCreationStatusCode']

                        if (statusCode === 1 || statusCode === 2) {
                            let data = [response.data['membersUrl'], 'membersURL']
                            store.dispatch('updateFormValue', data)

                            data = [true, 'fullAppSuccess']
                            store.dispatch('updateFormValue', data)

                            data = [true, 'lockDown']
                            store.dispatch('updateFormValue', data)

                            if (statusCode === 2) {
                                data = [true, 'duplicateApp']
                                store.dispatch('updateFormValue', data)
                            }
                        }

                        window.sessionStorage.clear();

                        let url = response.data['membersUrl'];

                        if (url.includes("?")) {
                            url = url + "&showMarketingPreferences=true"
                        } else {
                            url = url + "?showMarketingPreferences=true"
                        }

                        if (fullApps.utmParameters) {
                            let utmParameters = '';

                            Object.entries(fullApps.utmParameters).forEach(([key, value]) => {
                                if (value) {
                                    if (key === 'source' || key === 'medium' || key === 'term' || key === 'content' || key === 'campaign' || key === 'type' || key === 'sid') {
                                        key = 'utm_' + key;
                                    }

                                    if (key === 'agentPublicId' ) {
                                        key = 'advisor';
                                    }

                                    if (key === 'referrerApplicationId' ) {
                                        key = 'rai';
                                    }

                                    if (key !== 'cid') {
                                        utmParameters += `${key}=${value}&`;
                                    }
                                }
                            });

                            if (utmParameters) {
                                utmParameters = utmParameters.slice(0, -1);

                                if (url.includes("?")) {
                                    url = url + "&" + utmParameters;
                                } else {
                                    url = url + "?" + utmParameters;
                                }
                            }

                            if (fullApps.submissionTypeId && !url.includes('submissionTypeId')) {
                                if (url.includes("?")) {
                                    url = url + "&submissionTypeId=" + fullApps.submissionTypeId;
                                } else {
                                    url = url + "?submissionTypeId=" + fullApps.submissionTypeId;
                                }
                            }
                        }

                        if (typeof statusCode === undefined || statusCode === null) {
                            This.$appInsights.trackEvent({
                                name: 'Application Form - Complete App Status Code Error',
                                properties: {
                                    event_category: 'Application Form',
                                    event_action: 'Complete App Status Code Error',
                                    event_label: state.GUID,
                                    status_code: statusCode
                                }
                            });

                            router.push({ name: 'thank-you' });
                        }

                        if(statusCode === 1){
                            This.$appInsights.trackEvent({
                                name: 'Application Form - Complete App',
                                properties: {
                                    event_category: 'Application Form',
                                    event_action: 'Complete App',
                                    event_label: state.GUID
                                }
                            });

                            if (state.noTransition) {
                                router.push({ name: 'thank-you' });
                            }
                            else {
                                setTimeout(function () {
                                    window.location.replace(url);
                                }, 3000)
                            }
                            
                        }

                        if(statusCode === 2){
                            This.$appInsights.trackEvent({
                                name: 'Application Form - Complete App Duplicate',
                                properties: {
                                    event_category: 'Application Form',
                                    event_action: 'Complete App Duplicate',
                                    event_label: state.GUID
                                }
                            });

                            if (state.noTransition) {
                                router.push({ name: 'thank-you' });
                            }
                            else {
                                setTimeout(function () {
                                    window.location.replace(url);
                                }, 3000)
                            }
                        }

                        if (statusCode < 1 || statusCode > 2) {
                            This.$appInsights.trackEvent({
                                name: 'Application Form - Complete App Status Code Error',
                                properties: {
                                    event_category: 'Application Form',
                                    event_action: 'Complete App Status Code Error',
                                    event_label: state.GUID,
                                    status_code: statusCode
                                }
                            });

                            router.push({ name: 'thank-you' });
                        }
                    }).catch(() => {
                        let requestData = fullApps;

                        This.$appInsights.trackEvent({
                            name: 'Application Form - Complete App Error',
                            properties: {
                                event_category: 'Application Form',
                                event_action: 'Complete App Error',
                                event_label: state.GUID,
                                data: requestData
                            }
                        });

                        window.sessionStorage.clear();

                        router.push({ name: 'thank-you' });
                    })
                }

                delegateFn();
            }
        }
    }
}
