import {HashHelper} from '@/helpers';

export default {
    initalData() {
        window.utag.data.app_name = 'CarFinance';
        window.utag.data.app_version = 1.0;
        window.utag.data.app_platform = '99e6cb36-b175-4b48-bfe8-5adc10969963';
        window.utag.data.tealium_profile = 'car-finance';
    },
    setData(key, value, hash = false) {
        let data_key = key;

        switch(key) {
            case 'customer_email':
                data_key = 'customer_id';
                break;
            case 'marital_status':
                data_key = 'marital_status';
                break;
            case 'driving_licence':
                data_key = 'driving_licence_type';
                break;
             case 'postcode':
                data_key = 'customer_partialpostcode';
                break;
            case 'employment_status':
                data_key = 'employment_status';
                break;
            default:
                break;
        }

        let data = value;

        if (key === 'marital_status') {
            switch(value) {
                case 'married':
                    data = 'Married';
                    break;
                case 'single':
                    data = 'Single';
                    break;
                case 'cohabiting':
                    data = 'Cohabiting';
                    break;
                case 'divorced':
                    data = 'Divorced';
                    break;
                case 'separated':
                    data = 'Separated';
                    break;
                case 'widowed':
                    data = 'Widowed';
                    break;
                case 'civil_partnership':
                    data = 'CivilPartnership';
                    break;
                default:
                    break;
            }
        }

        if (key === 'driving_licence') {
            switch(value) {
                case 'full_uk_licence':
                    data = 'FullUKLicence';
                    break;
                case 'none':
                    data = 'None';
                    break;
                case 'provisional_uk':
                    data = 'ProvisionalUK';
                    break;
                case 'eu':
                    data = 'EU';
                    break;
                case 'international':
                    data = 'International';
                    break;
                default:
                    break;
            }
        }

        if (key === 'postcode') {
            data = data.slice(0, 4).trimEnd();
        }

        if (key === 'employment_status') {
            switch(value) {
                case 'full_time':
                    data = 'FullTime';
                    break;
                case 'self_employed':
                    data = 'SelfEmployed';
                    break;
                case 'part_time':
                    data = 'PartTime';
                    break;
                case 'disability':
                    data = 'Disability';
                    break;
                case 'retired':
                    data = 'Retired';
                    break;
                case 'student':
                    data = 'Student';
                    break;
                case 'family_carer':
                    data = 'FamilyCarer';
                    break;
                case 'agency_worker':
                    data = 'AgencyWorker';
                    break;
                case 'sub_contractor':
                    data = 'SubContractor';
                    break;
                case 'homemaker':
                    data = 'Homemaker';
                    break;
                case 'armed_forces':
                    data = 'ArmedForces';
                    break;
                case 'not_employed':
                    data = 'NotEmployed';
                    break;
                default:
                    break;
            }
        }

        if (hash && data) {
            HashHelper.sha256(data.toLowerCase().trim()).then(hash_result => {
                window.utag.data[data_key] = hash_result;
            });
        } else {
            window.utag.data[data_key] = data;
        }
    },
    trackPageView(obj) {
        if (obj && obj.page_name) {
            switch (obj.page_name) {
                case 'contact-details':
                    window.utag.data.page_name = 'YourDetails';
                    break;
                case 'marital-status':
                    window.utag.data.page_name = 'MaritalStatus';
                    break;
                case 'driving-licence':
                    window.utag.data.page_name = 'DrivingLicence';
                    break;
                case 'submission-type':
                    window.utag.data.page_name = 'FinanceType';
                    break;
                case 'address-details':
                    window.utag.data.page_name = 'AddressDetails';
                    break;
                case 'employment-detail':
                    window.utag.data.page_name = 'EmploymentHistory';
                    break;
                case 'employment-status':
                    window.utag.data.page_name = 'EmploymentStatus';
                    break;
                case 'address-length':
                    window.utag.data.page_name = 'AddressDurationLived';
                    break;
                case 'employment-length':
                    window.utag.data.page_name = 'EmploymentLength';
                    break;
                case 'address-status':
                    window.utag.data.page_name = 'AddressOwnershipType';
                    break;
                case 'employment-income':
                    window.utag.data.page_name = 'EmploymentIncome';
                    break;
                case 'employment-location':
                    window.utag.data.page_name = 'JobDetails';
                    break;
                case 'amount-to-finance':
                    window.utag.data.page_name = 'AmountToBorrow';
                    break;
                case 'date-of-birth':
                    window.utag.data.page_name = 'DateOfBirth';
                    break;
                case 'calculator-interstitial':
                    window.utag.data.page_name = 'FinanceCalculator';
                    break;
                default:
                    break;
            }
        }

        window.utag.data.page_type = 'RiskJourney';

        window.console.log('utag.view', window.utag.data);
        window.utag.view(window.utag.data);
    },
    trackEvent(obj) {
        if (obj && obj.event_name) {
            switch (obj.event_name) {
                case 'complete_application':
                    window.utag.data.event_action = "sendClickedThroughEvent";
                    window.utag.data.event_category = "CarFinance_ClickedThrough";
                    window.utag.data.event_label = "CarFinance_ClickedThrough";
                    window.utag.data.brand_code = "CF247"
                    if (obj.state) {
                        window.utag.data.encodedEmail = window.btoa(obj.state.form.email);
                        window.utag.data.enquirySubject = `£${obj.state.form.loanAmount.toLocaleString()}`;
                    }
                    break;
                case 'start_your_application':
                    if (obj.event_category && obj.event_category === 'calculator_interstitial') {
                        window.utag.data.event_action = "onClick";
                        window.utag.data.event_category = "CarFinance_FinanceCalculator";
                        window.utag.data.event_label = "CarFinance_FinanceCalculator_StartYourApplication";
                    }
                    break;
                case 'change_finance_type':
                    if (obj.event_category && obj.event_category === 'calculator_interstitial') {
                        window.utag.data.event_action = "onClick";
                        window.utag.data.event_category = "CarFinance_FinanceCalculator";
                        window.utag.data.event_label = "CarFinance_FinanceCalculator_FinanceType";
                    }
                    break;
                case 'change_credit_score':
                    if (obj.event_category && obj.event_category === 'calculator_interstitial') {
                        window.utag.data.event_action = "onClick";
                        window.utag.data.event_category = "CarFinance_FinanceCalculator";
                        window.utag.data.event_label = "CarFinance_FinanceCalculator_MyCreditScoreIs";
                    }
                    break;
                case 'change_finance_term':
                    if (obj.event_category && obj.event_category === 'calculator_interstitial') {
                        window.utag.data.event_action = "onClick";
                        window.utag.data.event_category = "CarFinance_FinanceCalculator";
                        window.utag.data.event_label = "CarFinance_FinanceCalculator_HowManyMonthsDoYouWantToBorrowFor";
                    }
                    break;
                case 'change_finance_amount':
                    if (obj.event_category && obj.event_category === 'calculator_interstitial') {
                        window.utag.data.event_action = "onClick";
                        window.utag.data.event_category = "CarFinance_FinanceCalculator";
                        window.utag.data.event_label = "CarFinance_FinanceCalculator_HowMuchDoYouWantToBorrow";
                    }
                    break;
                default:
                    break;
            }
        }

        window.console.log('utag.link', window.utag.data);
        window.utag.link(window.utag.data);

        if (obj && obj.event_name) {
            switch (obj.event_name) {
                case 'complete_application':
                    window.utag.data.brand_code = undefined;
                    if (obj.state) {
                        window.utag.data.encodedEmail = undefined;
                        window.utag.data.enquirySubject = undefined;
                    }
                    break;
                default:
                    break;
            }
        }
    }
}