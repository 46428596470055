<template>
    <img alt="Parkers Logo" height="25" v-lazy="imgUrl" />
</template>

<script>
    export default {
        name: "ParkersLogo",
        data () {
            return {
                imgUrl: require('@/assets/img/affiliates/parkers.png')
            }
        }
    }
</script>

<style scoped>

</style>
