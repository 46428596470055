
<template>
<div v-if="visible">
  <button v-if="vCenterArrow" :id="id" type="submit" :class="'button alpha ' + ( isNewApplyBtn ? ' abtestNewApplyBtn' : '')" :disabled="false">
      <span :class="subLabel ? '' : 'button-text'" style="display: flex;">
        <span>
          <slot>Continue</slot>
          <div v-html="subLabel"></div>
        </span>
        <svg v-if="showNextArrow" style="position: relative; top: 25px; left: 20px;" width="30" height="17" xmlns="http://www.w3.org/2000/svg">
          <path d="M26.157 7l-5.293-5.293A1 1 0 1 1 22.278.293l7.071 7.071a1 1 0 0 1 0 1.414l-7.07 7.071a1 1 0 0 1-1.415-1.414L26.299 9H1.5a1 1 0 0 1 0-2h24.657z" fill-rule="nonzero"/>
        </svg>
      </span>
  </button>
  <button v-else :id="id" type="submit" :class="'button alpha ' + (isNewApplyBtn ? ' abtestNewApplyBtn' : '')" :disabled="false">
    <span :class="subLabel ? '' : 'button-text'">
      <slot>Continue</slot>
      <svg v-if="showNextArrow" width="30" height="17" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.157 7l-5.293-5.293A1 1 0 1 1 22.278.293l7.071 7.071a1 1 0 0 1 0 1.414l-7.07 7.071a1 1 0 0 1-1.415-1.414L26.299 9H1.5a1 1 0 0 1 0-2h24.657z" fill-rule="nonzero"/>
      </svg>
    </span>
    <div v-html="subLabel"></div>
  </button>
</div>

</template>

<script>
import {NewApplyBtnHelper} from '@/helpers';

export default {
    name: 'ButtonContinue',
    props: {
      id: {
        type: String,
        default() {
          return 'button_generic_countinue'
        }
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      subLabel: {
        type: String,
        default: '',
      },
      visible: {
        type: Boolean,
        default: true
      },
      showNextArrow: {
        type: Boolean,
        default: true,
      },
      vCenterArrow: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      isNewApplyBtn() {
        return NewApplyBtnHelper.isNewApplyBtn();
      }
    }
}
</script>

<style scoped>
  .button-text {
    position: relative;
    top: 4px;
  }

  .abtestNewApplyBtn {
    margin-left: 20px !important;
    margin-right: 20px !important;
    width: calc(100% - 40px) !important;
    border-radius: 2rem !important;

    box-shadow: 0px 2px 0px 0px #604DD533 !important;
    background: rgba(96, 77, 213, 0.1) !important;
    color: rgba(96, 77, 213, 1) !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 22.04px !important;
    border: none !important;

  }

  .abtestNewApplyBtn svg {
    display: none;
  }
</style>
