export default {
    AfdListQueryParams: {
        uniqueId: "56485",
        data: "Address",
        task: "FastFindv4",
        fields: "Standard",
        maxQuantity: "40",
        format: "JSON"
    },
    AfdSingleLookUp: {
        data: "Address",
        task: "Retrieve",
        fields: "Standard",
        format: "JSON"
    }
}
