<template>
  <header  >
    <PowerBy v-if="swapLogoSide && !(isCompareTheMarket || isCompareTheMarketCarFinance )" />
    <img height="30" v-if="swapLogoSide && (isCompareTheMarket || isCompareTheMarketCarFinance )" src="../assets/img/logos/New_CarFinance247_PowerBy_Logo.png" />

    <AffiliateLogo />

    <PowerBy v-if="!swapLogoSide && !(isCompareTheMarket || isCompareTheMarketCarFinance )" />
    <img height="30" v-if="!swapLogoSide && (isCompareTheMarket || isCompareTheMarketCarFinance )" src="../assets/img/logos/New_CarFinance247_PowerBy_Logo.png" />
  </header>
</template>

<script>
import {LocationHelper} from "../helpers";
import PowerBy from "./Affiliates/PowerBy";
import {AFFILIATES, DEALERS} from '@/factories';
import AffiliateLogo from '@/components/AffiliateLogo';

export default {
  name: "AffiliateHeader",
  data() {
    return {
      swapLogoSide: false,
      isCompareTheMarket: LocationHelper.getHost() === 'comparethemarket',
      isCompareTheMarketCarFinance: LocationHelper.getHost() === 'comparethemarketcarfinance',
    }
  },
  components: {
    AffiliateLogo,
    PowerBy
  },
  mounted() {
    let host = LocationHelper.getHost();

    if (AFFILIATES[host] !== undefined) {
      const affiliateConfig = AFFILIATES[host];

      if (affiliateConfig && affiliateConfig.swapLogoSide !== undefined ) {
        this.swapLogoSide = affiliateConfig.swapLogoSide;
      }
    }

    if (DEALERS[host] !== undefined) {
      const dealerConfig = DEALERS[host];

      if (dealerConfig && dealerConfig.swapLogoSide !== undefined ) {
        this.swapLogoSide = dealerConfig.swapLogoSide;
      }
    }
  }
}
</script>

<style scoped>

</style>
