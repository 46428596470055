<template>
  <div class="outer-wrapper">
    <div class="inner-wrapper">
      <div class="row dualband-displacement" >
        <div class="column partnerLogo-displacement" v-if="this.lenderLogoUrl">
          <img alt="Lender Logo" height="25" v-lazy="this.lenderLogoUrl" />
        </div>
        <div class="column">
          <img alt="CarFinance247 Logo" width="137" height="42" src="@/assets/img/logos/CarFinance247_Logo_Horizontal.svg" />
        </div>
      </div>
      <div class="body-content">
        <p v-if="this.lenderName">
          We are working with {{ this.lenderName }}
        </p>
        <p>
          As a credit broker, we have access to a panel of lenders to find a deal that suits you.
        </p>
        <p>
          We look to find the <b>best rate from our panel of lenders</b> and will offer you the best deal that you’re eligible for.
        </p>
        <TrustPilot></TrustPilot>
        <div type="button" aria-label="Get my quote" class="btn-close btn-alpha" @click="close()">
          Get my quote
        </div>
        <p class="rep-example" v-if="this.representativeExample">
          <strong>Representative example:</strong> {{ this.representativeExample }}
        </p>
        <p class="cf247__text">
          <strong>Carfinance247 Limited.</strong>
        </p>
        <p>Universal Square, Devonshire Street North, Manchester, M12 6JH</p>
        <p class="cf247__text"><strong>Finance is subject to status. Terms and Conditions Apply. 18+ Only</strong></p>
        <ul>
          <li>No deposit options</li>
          <li>Buy a car from any reputable dealer</li>
          <li>Dedicated account manager</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TrustPilot from "@/components/TrustPilot";

export default {
  name: "PartnerTransitionModal",
  components: {
    TrustPilot,
  },
  methods: {
    close() {
      this.$emit("close")
    }
  },
  computed: {
    lenderName() {
      const loanOption = this.$store.state.loanOption;

      return loanOption ? loanOption.lenderName ||  '' : '';
    },
    lenderLogoUrl() {
      const loanOption = this.$store.state.loanOption;

      return loanOption ? loanOption.lenderLogoUrl ||  '' : '';
    },
    representativeExample() {
      const loanOption = this.$store.state.loanOption;

      return loanOption ? loanOption.representativeExample || '' : '';
    },
  }
}
</script>

<style scoped lang="scss">
img {
  max-width: 100%;
  font-style: italic;
  vertical-align: middle;
}

.cflogo-displacement {
  position: relative;
  left: -20px;
}

.-m-auto {
  margin: auto;
}

@media (min-width: 412px) {
  .cflogo-displacement {
    position: relative;
    left: -20px;
  }
}

@media (min-width: 800px) {
  .cflogo-displacement {
    position: relative;
    left: -50px;
  }

  .-m-auto {
    margin: unset;
  }
}

.partnerLogo-displacement {
  position: relative;
  left: -5px;
}

.dualband-displacement {
  max-height: 70px;
}

.outer-wrapper {
  .inner-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .trustpilot-container {
      display: flex;
    }

    .trustpilot {
      width: 100%;
      display: flex;
      margin: 20px auto;
      justify-content: center;
      align-items: center;
      -webkit-box-align: center;
      -webkit-box-pack: center;

      .trustpilot__logo {
        max-width: 108px;
        width: 100%;
      }

      .trustpilot__stars {
        max-width: 108px;
        margin: 0 10px;
      }

      .trustpilot__text {
         font-size: 0.9em;
        min-width: 90px;
      }
    }

    .rep-example {
      font-size: 0.9em;
    }

    .btn-alpha {
      cursor: pointer;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-transition: background-color .3s;
      transition: background-color .3s;
      color: #fff;
      background-color: #604dd5;
      @include font-bold();
      padding: 11px 15px;
      border: none;
      width: 100%;
      margin: 20px auto;
      line-height: 1.5rem;
      -webkit-box-shadow: 0 6px 10px 0 rgba(96,77,213,.15);
      box-shadow: 0 6px 10px 0 rgba(96,77,213,.15);
      border-radius: .3rem;
      max-width: 300px;
      -webkit-appearance: none;
      text-align: center;

      @media (min-width: 320px) {
        font-size: 1.3rem;
      }

      @media (min-width: 360px) {
        font-size: 1.5rem;
      }
    }

    .btn-alpha span {
      display: block;
      letter-spacing: .6px;
      @include font-regular();
      text-align: center;

      @media (min-width: 320px) {
        font-size: 12px;
      }

      @media (min-width: 360px) {
        font-size: 16px;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    ul li {
      list-style: none;
      position: relative;
      @include font-regular();
      background-color: #fff;
      line-height: 1.625rem;
      color: #4a4a4a;
      margin: 0;

      @media (min-width: 320px) {
        font-size: 1rem;
      }

      @media (min-width: 360px) {
        font-size: 1.2rem;
      }
    }

    ul li:before {
      content: "";
      width: 14px;
      height: 26px;
      background-image: url('../assets/img/check.svg');
      background-position: 0;
      background-repeat: no-repeat;
      background-size: 14px;
      position: relative;
      display: block;
      float: left;
      margin-left: -25px;
      padding-left: 8px;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .column {
    padding: 5px;
  }
}
</style>
