
<template>
<section>

  <h1 :id="id" v-show="showLabel" :class="SENSITIVE_DATA.p11Mask">{{ label }}</h1>

  <div class="duel-inputs">

    <InputNumber
      :id="idYears"
      :name="nameYears"
      :labeledBy="id + ' ' + id+'-years-label'"
      label="Years"
      placeholder=""
      smallInput
      noPadding
      :value="valueYears"
      @input="calcTime($event)"
    />
  <div class="spacer"></div>
    <InputNumber
      :id="idMonths"
      :name="nameMonths"
      :labeledBy="id + ' ' + id+'-months-label'"
      label="Months"
      placeholder=""
      smallInput
      noPadding
      :value="valueMonths"
      @input="calcTime($event)"
    />

  </div>

  <p class="error" v-if="this.v.$anyDirty && this.v.$invalid || this.vYears.$invalid || this.vMonths.$invalid">{{ invalidMessage }}</p>
  <p class="small-print" v-if="smallPrint">{{ smallPrint }}</p>

  </section>

</template>

<script>
import {SENSITIVE_DATA} from '@/const';

export default {
  props: {
    id:{
      type: String,
      default() {
        return 'input-years-months-' + this._uid
      }
    },
    idMonths: {
      type: String,
      default() {
        return 'input_number_generic_months'
      }
    },
    idYears: {
      type: String,
      default() {
        return 'input_number_generic_years'
      }
    },
    label: {
      type: String
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    name: {
      type: String
    },
    nameYears: {
      type: String
    },
    nameMonths: {
      type: String
    },
    index: {
      type: Number
    },
    smallPrint: {
      type: String
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    placeholder: {
      type: String
    },
    invalidMessage: {
      type: String,
      default() {
        return 'Please answer this question.'
      }
    },
    valueMonths: {
      type: Number,
      default: 0
    },
    valueYears: {
      type: Number,
      default: 0
    },
    v: {
      type: Object,
      default: function () { return {} }
    },
    vYears: {
      type: Object,
      default: function () { return {} }
    },
    vMonths: {
      type: Object,
      default: function () { return {} }
    }
  },
  data() {
    return {
      SENSITIVE_DATA: SENSITIVE_DATA,
    }
  },
  mounted() {
    let input_element = document.querySelectorAll("input");
    input_element.forEach(input_array => {
      input_array.setAttribute('value', input_array.value);
      input_array.addEventListener('keyup', () => {
        input_array.setAttribute('value', input_array.value);
        });
        });
  },
  methods: {
    setValue(value) {
      this.$emit('input', value)
    },
    calcTime() {
      let This = this
      setTimeout(function(){
        let months = Number(This.valueMonths)
        let years = Number(This.valueYears)
        // console.log('calc values: ', years, months)
        let monthYears = months / 12
        let time = years + monthYears
        time = Number(time)
        // console.log('time', time)
        This.setValue(time)
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
  #app form {
    section {
      padding: 0px 0 30px;
      position: relative;
    }

    p.error {
      margin-bottom: 0
    }

    .duel-inputs {
      display: flex;
      justify-content: space-between;
      padding: 0;

      .small-input {
        max-width: calc(50% - 20px)
      }
    }
  }
</style>