<template>
    <img alt="Credit Karma Logo" height="30" v-lazy="imgUrl" />
</template>

<script>
import {AFFILIATES} from '../../factories'
import { LocationHelper} from '../../helpers'
export default {
  name: "CreditKarma",
  data () {
      return {
          imgUrl: AFFILIATES[LocationHelper.getHost()] && AFFILIATES[LocationHelper.getHost()].testB ?
                require('@/assets/img/affiliates/ckdirect.testB.png')
            :
                require('@/assets/img/affiliates/ckdirect.png')
      }
  }
}
</script>

<style scoped>

</style>
