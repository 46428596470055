<template>
    <form @submit.prevent="Submit(nextPage)">

        <h1 v-if="headerOne">{{ headerOne }}</h1>

        <InputText
                :id="idConcat(pageName, 'JobTitle')"
                v-if="status !== 'no-employer'"
                :name="'form.employments['+index+'].occupation'"
                label="Your job title"
                invalidMessage="Please tell us what you do for a job."
                v-model="$v.occupation.$model"
                :v="$v.occupation"
                :sensitive-data="true"
        />

        <InputYearsMonths
            :id="idConcat(pageName, 'TimeAtEmployer')"
            :label="yearsMonthsTitle"
            :invalidMessage="yearsMonthsInvalidMessage"
            showLabel
            :nameYears="'form.employments['+index+'].yearsAtEmployer'"
            :nameMonths="'form.employments['+index+'].monthsAtEmployer'"
            :valueMonths="monthsAtEmployer"
            :valueYears="yearsAtEmployer"
            :index="index"
            v-model="$v.timeAt.$model"
            :v="$v.timeAt"
            :vYears="$v.yearsAtEmployer"
            :vMonths="$v.monthsAtEmployer"
            :idYears="idConcat(pageName,'Years')"
            :idMonths="idConcat(pageName,'Months')"
        />

        <template v-if="index === 0">
            <!--      <EmploymentHistory first />-->
        </template>

        <template v-else>
            <EmploymentHistory />
        </template>

        <ButtonContinue :id="idConcat(pageName, 'ContinueBtn')" />

        <ButtonBack :id="idConcat(pageName, 'BackBtn')" />
        
        <InfoFooter :showTopOnly="true" />
    </form>
</template>

<script>
import {between, minValue, required} from 'vuelidate/lib/validators'
import {AnalyticsService} from '@/services';
import {LocationHelper, RequestHelper} from '@/helpers';
let routeNo, index

export default {
    beforeRouteEnter (to, from, next) {
        routeNo = to.params.number
        index = to.params.number - 1
        next()
    },
    beforeRouteUpdate (to, from, next) {
        routeNo = to.params.number
        index = to.params.number - 1
        next()
    },
    components: {

    },
    data() {
        return {
            pageName: 'EmploymentDetail',
            routeNo: routeNo,
            index: index,
            occupation: this.$store.state.form.employments[index].occupation,
            employerName: this.$store.state.form.employments[index].employerName,
            employerTown: this.$store.state.form.employments[index].employerTown,
            timeAt: this.$store.getters.indexEmploymentHistory(index),
            employmentStatus: this.$store.getters.employmentStatus(index),
            errorElementId: '',
            pageViewSubmitted: false,
            isCompareTheMarketReorder: LocationHelper.getHost() === 'comparethemarketcarfinance' && RequestHelper.getQueryVariable('test') === 'reorder',
            isCKDirectReorder: LocationHelper.getHost() === 'ckdirect',
        }
    },
    validations() {
        if(this.status === 'no-employer'){
            return {
                occupation: {},
                employerName: {},
                employerTown: {},
                timeAt: {
                    required,
                    minValue: minValue(0.0000001)
                },
                yearsAtEmployer: {
                    between: between(0, 80)
                },
                monthsAtEmployer: {
                    between: between(0, 11)
                }
            }
        }
        else {
            return {
                occupation: {required},
                employerName: {},
                employerTown: {},
                timeAt: {
                    required,
                    minValue: minValue(0.0000001)
                },
                yearsAtEmployer: {
                    between: between(0, 80)
                },
                monthsAtEmployer: {
                    between: between(0, 11)
                }
            }
        }
    },
    computed: {
        isSocialRecorder() {
          const host = ['social','ecrm', 'tiktok', 'social-premier', 'social-vans', 'social-bad-credit'];

          return host.indexOf(LocationHelper.getHost()) !== -1;
        },
        status() {
            let v = this.$store.getters.employmentStatus(index)
            let status
            if(v === 9 || v === 11 || v === 14 || v === 15 || v === 20 || v === 10 || v === 18){
                status = 'default'
            }
            if(v === 13 || v === 19 || v === 12 || v === 16 || v === 17 || v === 21){
                // remove employer name, town and occupation
                status = 'no-employer'
            }
            return status
        },
        statusName() {
            let v = this.$store.getters.employmentStatus(index)
            let status
            if(v === 10){status = 'self-employed'}
            if(v === 13){status = 'retired'}
            if(v === 19){status = 'a student'}
            if(v === 12){status = 'on disability living allowance'}
            if(v === 16){status = 'a homemaker'}
            if(v === 17){status = 'unemployed'}
            if(v === 21){status = 'a non professional carer'}
            if(v === 18){status = 'in the armed forces'}
            return status
        },
        headerOne() {
            let status = "Could you tell us a little about your job?"
            if(index !== 0){status = 'What is your job title previously?'}

            return status
        },
        yearsMonthsTitle() {
            if (this.status === 'default') {
                return 'And how long have you worked there?'
            } else {
                return 'And How long have you been ' + this.statusName + '?'
            }
        },
        yearsAtEmployer() {
            return this.$store.getters.indexEmploymentYears(index)
        },
        monthsAtEmployer() {
            return this.$store.getters.indexEmploymentMonths(index)
        },
        yearsMonthsInvalidMessage() {
            if (this.$v.yearsAtEmployer.$invalid) return 'Please enter a number between 0 and 80 years.'
            if (this.$v.monthsAtEmployer.$invalid) return 'Please enter a number between 0 and 11 months.'
            if (this.status === 'default') {
                return 'Please tell us how long you worked at this job.'
            } else {
                return 'Please tell us how long you were ' + this.statusName + '?'
            }
        },
        nextPage() {
            let nextRouteNo = Number(routeNo) + 1
            if (this.$store.getters.totalEmploymentHistory >= 1) {
                // return {name: 'address-add', params: {number: 1}}
                if(Number(routeNo)==1) {
                    return {name: 'employment-income'}
                }
                else
                {
                     if (this.isCompareTheMarketReorder || this.isCKDirectReorder || this.isSocialRecorder) {
                        return { name: 'amount-to-finance' }
                     }

                    return { name: 'address-add', params: { number: 1 } }
                }
            } else {

                if(Number(routeNo)==1) {
                    return {name: 'employment-income'}
                }
                else {
                    return {name: 'employment', params: {number: nextRouteNo}}
                }
            }
        }
    },
    methods: {
        warnIncomeValue(input) {
            if (typeof(input) !== undefined && input !== null && input !== '') {
                const income = parseInt(input.replace(",", ""));
                if (income < 900 || income > 10000) {
                    this.errorElementId = 'input-borrow'
                    return true
                } else {
                    this.errorElementId = ''
                    return false
                }
            }
            return false;
        },
        debounceInput(event) {
            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
                this.showWarning = this.warnIncomeValue(event.target.value);
            }, 1000)
        }
    },
    mounted() {

      this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);
        
      this.$store.dispatch('updateFormValue', [true, 'form.employmentStatusCompleted'])

      if (!this.pageViewSubmitted) {
        this.pageViewSubmitted = true;

        AnalyticsService.trackPageView({
          page_name: 'employment-detail'
        });
      }
    }
}
</script>
