import {isEmpty, cloneDeep} from 'lodash'
import { ContactNumberMapperService } from "./mappers";
import {DEFAULT_VALUE} from '@/const';

function removeEmpty(obj) {
    Object.keys(obj).forEach((key) => (obj[key] === null) && delete obj[key]);
}

export default {
    map(state) {
        let result = {};

        if (state && state.form) {
            if (state.form.referralId)
                result.referralId = state.form.referralId;

            if (state.form.clickId)
                result.clickId = state.form.clickId;

            if (state.form.riskBand)
                result.riskBand = state.form.riskBand;

            let loanAmount = state.form.loanAmount;

            if (loanAmount === null)
                loanAmount = DEFAULT_VALUE.loanAmount;

            result.loanAmount = loanAmount;
            result.firstName = state.form.firstName;
            result.lastName = state.form.lastName;
            result.maritalStatusId = state.form.maritalStatusId;
            result.email = state.form.email;
            result.contactNumber = ContactNumberMapperService.map(state.form.contactNumber);

            if (state.dobYear && state.dobMonth && state.dobDay) {
                const dateOfBirth = new Date(state.dobYear,
                    (state.dobMonth - 1),
                    state.dobDay, 0, 0, 0, 0);

                const dateOfBirthYearString = dateOfBirth.getFullYear().toString();

                let dateOfBirthMonthString = (dateOfBirth.getMonth() + 1).toString();
                dateOfBirthMonthString = dateOfBirthMonthString.length > 1 ? dateOfBirthMonthString : '0' + dateOfBirthMonthString;

                let dateOfBirthDayString = dateOfBirth.getDate().toString();
                dateOfBirthDayString = dateOfBirthDayString.length > 1 ? dateOfBirthDayString : '0' + dateOfBirthDayString;

                result.dateOfBirth = dateOfBirthYearString + "-" + dateOfBirthMonthString + "-" + dateOfBirthDayString + "T00:00:00.000Z";
            }

            result.titleId = state.form.titleId;
            result.vehicleTypeId = state.form.vehicleTypeId;
            result.drivingLicenceTypeId = state.form.drivingLicenceTypeId;

            result.addresses = [];

            if (state.form.addresses && state.form.addresses.length > 0) {
                let addressLevel = 0;
                let totalMonths = 0;

                const addresses = cloneDeep(state.form.addresses);

                addresses.forEach( (item, index, arr) => {
                    removeEmpty(item);

                    if (isEmpty(item) === false) {
                        let address = {};

                        address.addressLevel = addressLevel;
                        address.subBuilding = item.subBuilding ? item.subBuilding : "";
                        address.buildingName = item.buildingName ? item.buildingName : "";
                        address.buildingNumber = item.buildingNumber ? item.buildingNumber : "";
                        address.locality = item.locality ? item.locality : "";
                        address.town = item.town ? item.town : "";
                        address.postcode = item.postcode ? item.postcode : "";
                        address.yearsAtAddress = (item.yearsAtAddress && item.yearsAtAddress > 10) ? 10 : item.yearsAtAddress;
                        address.monthsAtAddress = (item.monthsAtAddress && item.monthsAtAddress > 11) ? 11 : item.monthsAtAddress;
                        address.residentialStatusId = item.residentialStatusId;
                        address.county = item.county ? item.county : "";
                        address.street = item.street ? item.street : "";

                        address.flatNumber = (!item.flatNumber || item.flatNumber.length === 0) ? item.subBuilding : item.flatNumber;
                        address.subBuilding = (!item.subBuilding || item.subBuilding.length === 0) ? item.flatNumber : item.subBuilding;

                        address.flatNumber = address.flatNumber ? address.flatNumber : "";
                        address.subBuilding = address.subBuilding ? address.subBuilding : "";

                        if(item.companyName)
                        {
                            if(!address.subBuilding)
                            {
                                address.subBuilding = item.companyName
                            }
                            else
                            {
                                address.buildingName = item.companyName
                            }
                        }

                        if (address.yearsAtAddress === undefined || address.yearsAtAddress === null)
                            address.yearsAtAddress = 0;

                        if (address.monthsAtAddress === undefined || address.monthsAtAddress === null)
                            address.monthsAtAddress = 0;

                        totalMonths = totalMonths +( address.yearsAtAddress * 12 ) + (address.monthsAtAddress) ;

                        result.addresses.push(address);
                        addressLevel++;
                    }

                    if( totalMonths >= 36 ) {
                        arr.length = index + 1;
                    }
                })
            }

            result.employments = [];

            if (state.form.employments && state.form.employments.length > 0) {
                let employmentLevel = 0;
                let totalMonths = 0;

                const employments = cloneDeep(state.form.employments);

                employments.forEach( (item, index, arr) => {
                    removeEmpty(item);

                    if (isEmpty(item) === false) {
                        let employment = {};

                        employment.employmentLevel = employmentLevel;
                        employment.statusId = item.statusId;
                        employment.yearsAtEmployer = (item.yearsAtEmployer && item.yearsAtEmployer > 10) ? 10 : item.yearsAtEmployer;
                        employment.monthsAtEmployer = (item.monthsAtEmployer && item.monthsAtEmployer > 11) ? 11 : item.monthsAtEmployer;
                        employment.occupation = item.occupation;
                        employment.employerName = item.employerName;
                        employment.employerTown = item.employerTown;

                        employment.subBuilding = item.subBuilding;
                        employment.buildingName = item.buildingName;
                        employment.buildingNumber = item.buildingNumber;
                        employment.locality = item.locality;
                        employment.postcode = item.postcode;
                        employment.county = item.county;
                        employment.street = item.street;
                        employment.flatNumber = item.flatNumber;

                        if (employment.yearsAtEmployer === undefined || employment.yearsAtEmployer === null)
                            employment.yearsAtEmployer = 0;

                        if (employment.monthsAtEmployer === undefined || employment.monthsAtEmployer === null)
                            employment.monthsAtEmployer = 0;

                        totalMonths = totalMonths +( employment.yearsAtEmployer * 12 ) + (employment.monthsAtEmployer) ;

                        result.employments.push(employment);
                        employmentLevel++;
                    }

                    if( totalMonths >= 12 ) {
                        arr.length = index + 1;
                    }
                })
            }

            if (state.form.netMonthlyIncome && state.form.netMonthlyIncome > 0)
                result.netMonthlyIncome = state.form.netMonthlyIncome;

            if (state.form.agreedTermsAndConditions && state.form.agreedTermsAndConditions === true)
                result.agreedTermsAndConditions = true;

            result.isPromoEmail = state.form.isPromoEmail;
            result.isPromoSms = state.form.isPromoSms;
            result.isPromoWhatsApp = state.form.isPromoWhatsApp;
            result.isPromoTelephone = state.form.isPromoTelephone;
            result.isShareInfo = state.form.IsShareInfo;

            result.utmParameters = {};

            if (state.form.utmParameters) {
                result.utmParameters.source = state.form.utmParameters.source,
                result.utmParameters.medium = state.form.utmParameters.medium,
                result.utmParameters.term = state.form.utmParameters.term,
                result.utmParameters.content = state.form.utmParameters.content,
                result.utmParameters.campaign = state.form.utmParameters.campaign,
                result.utmParameters.type = state.form.utmParameters.type,
                result.utmParameters.cid = state.form.utmParameters.cid,
                result.utmParameters.sid = state.form.utmParameters.sid,
                result.utmParameters.mkwid = state.form.utmParameters.mkwid,
                result.utmParameters.agentPublicId = state.form.utmParameters.agentPublicId,
                result.utmParameters.gclid = state.form.utmParameters.gclid,
                result.utmParameters.referrerApplicationId = state.form.utmParameters.referrerApplicationId
            }

            if (state.form.affiliateUserId)
                result.affiliateUserId = state.form.affiliateUserId;

            if (state.form.monthlyBudget)
                result.monthlyBudget = state.form.monthlyBudget;

            if (state.form.loanTypeId)
                result.loanTypeId = state.form.loanTypeId;

            if (state.form.userAgent)
                result.userAgent = state.form.userAgent;

            if (state.form.remoteAddress)
                result.remoteAddress = state.form.remoteAddress;

            if (state.subdomain && state.subdomain === 'ckdirect') {
                if (state.form.utmParameters && state.form.utmParameters.term && state.form.utmParameters.term.toLowerCase().trim() === 'refinance_2') {
                    result.submissionTypeId = 1;
                }
            }

            if (state.form.submissionTypeId)
                result.submissionTypeId = state.form.submissionTypeId;

            if (state.form.vehicle) {
                result.vehicle = {};
                result.vehicle.vehicleId = Number(state.form.vehicle.vehicleId);
                result.vehicle.vrm = state.form.vehicle.vrm;
                result.vehicle.registrationYear = state.form.vehicle.year;
                result.vehicle.make = state.form.vehicle.make;
                result.vehicle.model = state.form.vehicle.model;
                result.vehicle.price = state.form.vehicle.price;
                result.vehicle.mileage = state.form.vehicle.mileage;
                result.vehicle.fuelType = state.form.vehicle.fuel;
                result.vehicle.transmission = state.form.vehicle.transmission;
                result.vehicle.colour = state.form.vehicle.colour;
                result.vehicle.imageUrl = state.form.vehicle.imageUrl;
                result.vehicle.vdpUrl = state.form.vehicle.vdpUrl;
                result.vehicle.dealerInfoUrl = state.form.vehicle.dealerInfoUrl;
            }

            result.banks = [];

            if (state.form.banks && state.form.banks.length > 0) {
                const banks = cloneDeep(state.form.banks);

                banks.forEach( (item) => {
                    removeEmpty(item);

                    if (isEmpty(item) === false) {
                        let bank = {};

                        bank.yearsAtBank = (item.yearsAtBank && item.yearsAtBank > 10) ? 10 : item.yearsAtBank;
                        bank.monthsAtBank = (item.monthsAtBank && item.monthsAtBank > 11) ? 11 : item.monthsAtBank;

                        bank.bankName = item.bankName;
                        bank.accountName = item.accountName;
                        bank.accountNumber = item.accountNumber;
                        bank.sortCode = item.sortCode;

                        if (bank.yearsAtBank === undefined || bank.yearsAtBank === null)
                            bank.yearsAtBank = 0;

                        if (bank.monthsAtBank === undefined || bank.monthsAtBank === null)
                            bank.monthsAtBank = 0;

                        result.banks.push(bank);
                    }
                })
            }

            if (state.form.vehicleFinance) {
                let vehicleFinance = {};

                vehicleFinance = state.form.vehicleFinance;

                removeEmpty(vehicleFinance);

                if (isEmpty(vehicleFinance) === false) {
                    if (vehicleFinance.term && vehicleFinance.term < 0)
                        vehicleFinance.term = 0;

                    if (vehicleFinance.deposit && vehicleFinance.deposit < 0)
                        vehicleFinance.deposit = null;

                    if (vehicleFinance.partExchangeValue && vehicleFinance.partExchangeValue < 0)
                        vehicleFinance.partExchangeValue = null;

                    if (vehicleFinance.partExchangeSettlement && vehicleFinance.partExchangeSettlement < 0)
                        vehicleFinance.partExchangeSettlement = null;

                    if (vehicleFinance.estimatedAnnualMileage && vehicleFinance.estimatedAnnualMileage < 0)
                        vehicleFinance.estimatedAnnualMileage = null;

                    result.vehicleFinance = vehicleFinance;
                }
            }

            if (state.form.deviceContextId)
                result.deviceContextId = state.form.deviceContextId;

            result.urlReferrer = state.subdomain ? state.subdomain + ".carfinance247.co.uk" : window.location.host;

            result.appliedDate = state.appliedDate;
            result.submittedBy = "Carfinance.Application.Form.Prototype";
        }

        removeEmpty(result);

        return result;
    }
}
