import axios from 'axios'
import { MapperService } from '.'
import { URLS } from '../factories'

let retryLimit = 1

export default {
    async sendPartialApp(This, state, store) {
        let token = state.token && state.token['authenticationToken'] ? state.token['authenticationToken'] : null
        if(token){
            let tokenUpdateTime = state.token && state.token['tokenUpdateTime'] ? state.token['tokenUpdateTime'] : 0
            let authenticationTokenLifetime = state.token && state.token['authenticationTokenLifetime'] ? state.token['authenticationTokenLifetime'] : 0
            if (tokenUpdateTime + authenticationTokenLifetime < Math.floor(Date.now() / 1000) ) {
                await store.dispatch('updateAuthToken')
                token = state.token && state.token['authenticationToken'] ? state.token['authenticationToken'] : null
            }

            let tokenString = 'Bearer ' + token
            let headersObj = {"Authorization": tokenString}

            // Partial application
            if(!state.form.IsCompleted){
                if(state.form.email || state.form.contactNumber) {
                    let partialData = MapperService.map(state);

                    const requestURL = state.isProduction ? URLS.production.applicationApiPartialUrl : URLS.replica.applicationApiPartialUrl;

                    axios({
                        method: 'POST',
                        url: requestURL + '/api/application/' + state.GUID,
                        headers: headersObj,
                        data: partialData
                    })
                        .then(()=>{
                            This.$appInsights.trackEvent({
                                name: 'Application Form - Partial App',
                                properties: {
                                    event_category: 'Application Form',
                                    event_action: 'Partial App',
                                    event_label: state.GUID
                                }
                            });
                        })
                        .catch(error => {

                            window.console.error(error.message)

                            if(error.response && error.response.status === 401){
                                // maybe update the token in case it's out of date

                                if (retryLimit > 0){
                                    retryLimit--
                                    store.dispatch('updateAuthToken')
                                        .then(()=>{
                                            this.sendPartialApp()
                                        })

                                }
                            }

                            This.$appInsights.trackEvent({
                                name: 'Application Form - Partial App Error',
                                properties: {
                                    event_category: 'Application Form',
                                    event_action: 'Partial App Error',
                                    event_label: error.message + ' guid: ' + state.GUID
                                }
                            });

                        })
                }
            }
        }

    }
}
