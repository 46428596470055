<template>
  <img alt="Equifax Logo" height="39" v-lazy="imgUrl" />
</template>

<script>
export default {
  name: "EquifaxLogo",
  data () {
    return {
      imgUrl:  require('@/assets/img/affiliates/equifax.jpg')
    }
  }
}
</script>

<style scoped>

</style>