<template>
    <div class="rep-example">
      <p v-if="isCarcraft">CarFinance 247 Limited is a credit broker. Rates from 7.9% APR.<br/><br/><strong>Representative example:</strong> borrowing <b>&pound;6,500</b> over <b>5 years</b> with a representative <b>APR of 19.9%</b>, an annual interest rate of <b>19.9% (Fixed)</b> and a deposit of &pound;0.00, the amount payable would be <b>&pound;166.07</b> per month, with a total cost of credit of <b>&pound;3,464.37</b> and a total amount payable of <b>&pound;9,964.37</b>.<br/><br/>We look to find the <b>best rate from our panel of lenders</b> and will offer you the best deal that you’re eligible for. We earn a commission for providing our services, but this does not influence the interest rate you’re offered in any way.</p>
      <div v-if="isSocial">
        <p v-if="isSocialPremier">Rates from 8.9% APR. Representative APR 10.9%<br />CarFinance 247 is a credit broker, not a lender. </p>
        <p v-else-if="isSocialVans">Rates from 9.9% APR. Representative 13.9% APR<br />CarFinance 247 is a credit broker, not a lender. </p>
        <p v-else-if="isSocialBadCredit">Rates from 19.9% APR. Representative APR 29.9%<br />CarFinance 247 is a credit broker, not a lender. </p>
        <p v-else-if="isSocialGeneric">Rates from 9.9% APR. Representative APR 19.9%<br />CarFinance 247 is a credit broker, not a lender. </p>
        <p v-else-if="isSocialExcellectPCP">Rates from 9.9% APR. Representative APR 11.9%<br />CarFinance 247 is a credit broker, not a lender. </p>
        <p v-else>Rates from 9.9% APR. Representative APR 19.9%<br />CarFinance 247 is a credit broker, not a lender. </p>
      </div>
    </div>
</template>

<script>
import {LocationHelper, RequestHelper} from "../helpers";

    export default {
      name: "RepExample",
      data() {
        return {
          isShow: false,
          isCarcraft: this.isShow && LocationHelper.getHost() === 'carcraft',
          isCompareTheMarketCarFinance: LocationHelper.getHost() === 'comparethemarketcarfinance'
        }
      },
      computed: {
        isSocialGeneric() {
          return RequestHelper.getQueryVariable('utm_type') === 'cw';
        },
        isSocialExcellectPCP() {
          return RequestHelper.getQueryVariable('utm_type') === 'pr';
        },
        isSocialPremier() {
          return LocationHelper.getHost() === 'social-premier';
        },
        isSocialVans() {
          return LocationHelper.getHost() === 'social-vans' || RequestHelper.getQueryVariable('utm_type') === 'vp';
        },
        isSocialBadCredit() {
          return LocationHelper.getHost() === 'social-bad-credit' || RequestHelper.getQueryVariable('utm_type') === 'bc';
        },
        isSocial() {
          const host = ['social','ecrm', 'tiktok', 'social-premier', 'social-vans', 'social-bad-credit'];

          return host.indexOf(LocationHelper.getHost()) !== -1;
        }
      }
    }
</script>

<style lang="scss" scoped>
 .rep-example {
     & p {
         text-align: center;
         padding-top: 0px;


         &:first-child {
             font-size: 1rem;

             @media
             (min-width: 360px),
             only screen and (-webkit-min-device-pixel-ratio: 4.5)      and (min-width: 320px),
             only screen and (   min--moz-device-pixel-ratio: 4.5)      and (min-width: 320px),
             only screen and (     -o-min-device-pixel-ratio: 4.5/1)    and (min-width: 320px),
             only screen and (        min-device-pixel-ratio: 4.5)      and (min-width: 320px) {
                font-size: 1rem;
             }

             @media (min-width: 768px) {
                 font-size: 1.0rem;
             }
         }

         @media
         (min-width: 360px),
         only screen and (-webkit-min-device-pixel-ratio: 4.5)      and (min-width: 320px),
         only screen and (   min--moz-device-pixel-ratio: 4.5)      and (min-width: 320px),
         only screen and (     -o-min-device-pixel-ratio: 4.5/1)    and (min-width: 320px),
         only screen and (        min-device-pixel-ratio: 4.5)      and (min-width: 320px) {
             font-size: 1rem;
         }
     }
 }

</style>
