<template>
  <div class="outer-wrapper">
    <div class="inner-wrapper">
      <div class="row dualband-displacement">
        <div class="topCfLogo" v-show="!dualBand && !isPartnerOverlayTest" >
          <PowerBy :callByModel="true" width="271" height="55" />
        </div>
        <div class="topCfLogoDual" v-show="dualBand || isPartnerOverlayTest" >
          <AffiliateLogo />
          <PowerBy :callByModel="true" width="150" height="30" />
        </div>
      </div>

      <div class="body-content">
        <div v-if="!isPartnerOverlayTest" class="trustpilot-container">
          <TrustPilot />
        </div>

        <h3 v-if="isPartnerOverlayTest">
          Looking for car finance? We can help!
        </h3>

        <div class="credit-broker"  v-html="$t('component.partner_rep_apr_modal_v3.credit_broker').replace('{partner}', partnerName)"></div>
   
        <div v-if="!isPartnerOverlayTest" class="usp">
          <ul>
            <li>
              <div>{{ $t('component.partner_rep_apr_modal_v3.usp_1') }}</div></li>
            <li>
              <div>{{ $t('component.partner_rep_apr_modal_v3.usp_2') }}</div></li>
            <li>
              <div>{{ $t('component.partner_rep_apr_modal_v3.usp_3') }}</div></li>
          </ul>
        </div>

        <div v-if="isPartnerOverlayTest">
          <!-- TrustBox widget - Mini -->
          <div class="trustpilot-widget" data-locale="en-GB" data-template-id="539ad0ffdec7e10e686debd7" data-businessunit-id="5193e43b00006400052dd669" data-style-height="350px" data-style-width="100%" data-theme="light" data-tags="SelectedReview" data-review-languages="en"
               style="height:108px; overflow: hidden;">
            <a href="https://uk.trustpilot.com/review/carfinance247.co.uk" target="_blank" rel="noopener">Trustpilot</a>
          </div>
          <!-- End TrustBox widget -->
        </div>

        <div v-if="isPartnerOverlayTest" class="trustpilot-widget-container bold">
          <p>Get your no-obligation quote in just a couple of minutes!</p>
        </div>

        <div type="button" aria-label="Get my quote" class="btn-close btn-alpha  alpha" @click="close()">
          {{ $t('component.partner_rep_apr_modal_v3.cta') }}
        </div>

        <br />

        <div :class="isPartnerOverlayTest ? ['rep-example-overlay'] : ['rep-example']">
          <div v-html="$t('component.partner_rep_apr_modal_v3.rep_example')"></div>
           <div>
            CarFinance 247 Limited is a credit broker, not a lender.
          </div>
        </div>

        <div v-html="$t('component.partner_rep_apr_modal_v3.fee')"></div>

        <br/>
        <p :class="isPartnerOverlayTest ? ['rep-example-overlay'] : ['rep-example']">Universal Square, Devonshire Street North,<br/>Manchester, M12 6JH</p>
      </div>
    </div>
  </div>
</template>

<script>
import {AffiliatesHelper, LocationHelper, RequestHelper} from '@/helpers';
import {AFFILIATES} from '@/factories';
import PowerBy from "./Affiliates/PowerBy";
import AffiliateLogo from '@/components/AffiliateLogo.vue';
import TrustPilot from '@/components/TrustPilot.vue';

export default {
  name: "PartnerRepAprModelV3",
  components: {
    AffiliateLogo,
    PowerBy,
    TrustPilot
  },
  data() {
    return {
      swapLogoSide: false,
      dualBand: false
    }
  },
  computed: {
    isPartnerOverlayTest() {
      return RequestHelper.getQueryVariable('test') === 'partner-overlay';
    },
    showAffiliateLogo() {
      return this.$store.state.affiliateConfig.modalShowLogo;
    },
    partnerName() {
      return AffiliatesHelper.getPartnerName();
    }
  },
  mounted() {
    let host = LocationHelper.getHost();
    if (AFFILIATES[host] !== undefined) {
      const affiliateConfig = AFFILIATES[host];
      if (affiliateConfig && affiliateConfig.swapLogoSide !== undefined ) {
        this.swapLogoSide = affiliateConfig.swapLogoSide;
      }
      if (affiliateConfig && affiliateConfig.useModalV3DualBand !== undefined) {
        this.dualBand = affiliateConfig.useModalV3DualBand;
      }
    }
  },
  methods: {
    close() {
      this.$emit("close")
    }
  }
}
</script>

<style scoped lang="scss">

.bold {
  font-weight: 800;
}

.trustpilot-widget-container {
  width: 300px;
  margin: 0 auto;
}

.cflogo-displacement {
  position: relative;
  left: -20px;
}

.-m-auto {
  margin: auto;
}

@media (min-width: 412px) {
  .cflogo-displacement {
    position: relative;
    left: -20px;
  }
}

@media (min-width: 800px) {
  .cflogo-displacement {
    position: relative;
    left: -50px;
  }

  .-m-auto {
    margin: unset;
  }
}

.partnerLogo-displacement {
  position: relative;
  left: -5px;
}

.dualband-displacement {
  max-height: 70px;
  margin-bottom: 10px;
}

.dualband-displacement img {
  display: block;
  margin-left: auto;
  margin-right: auto;
} 

.outer-wrapper {

  line-height: 1.1em;

  margin-right: 70px;
  margin-top: 46px;

  .inner-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .trustpilot-container {
      display: flex;
      height: 50px;
      width: 500px;
    }

    .trustpilot {
      width: 100%;
      display: flex;
      margin: 20px auto;
      justify-content: left;
      align-items: left;
      -webkit-box-align: left;
      -webkit-box-pack: left;

      .trustpilot__logo {
        max-width: 108px;
        width: 100%;
      }

      .trustpilot__stars {
        max-width: 108px;
        margin: 0 10px;
      }

      .trustpilot__text {
         font-size: 0.9em;
        min-width: 90px;
      }
    }

    .rep-example {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #4A4A4A;

      margin: 0px;
    }

    .rep-example-overlay {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 14px;
      color: #4A4A4A;

      margin: 0px;
    }

    .btn-alpha {
      cursor: pointer;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-transition: background-color .3s;
      transition: background-color .3s;
      color: #fff;
      background-color: #604dd5;

      font-style: normal;
      font-weight: 700;

   
      padding: 18px 80px 18px 80px;
      border: none;
      width: 100%;
      margin: 20px 10px 0px 10px;
      line-height: 1.5rem;
      -webkit-box-shadow: 0 6px 10px 0 rgba(96,77,213,.15);
      box-shadow: 0 6px 10px 0 rgba(96,77,213,.15);
      border-radius: .3rem;
      max-width: 404px;
      max-height: 60px;
      -webkit-appearance: none;
      text-align: center;

      @media (min-width: 320px) {
        font-size: 1.3rem;
      }

      @media (min-width: 360px) {
        font-size: 1.5rem;
      }
    }

    .btn-alpha span {
      display: block;
      letter-spacing: .6px;
      @include font-regular();
      text-align: center;

      @media (min-width: 320px) {
        font-size: 12px;
      }

      @media (min-width: 360px) {
        font-size: 16px;
      }
    }

    .usp {
      display: block;
      width: 100%;
    
    }

    ul {
      width: 180px;
      padding-left: 0px;
      display: block;
      margin: 0 auto;
    }

    ul li {
      list-style: none;
      position: relative;
      background-color: #fff;
      line-height: 1.625rem;
      color: #4a4a4a;
      padding-bottom: 10px;
      width: 250px;

      @media (min-width: 320px) {
        font-size: 1rem;
      }

      @media (min-width: 360px) {
        font-size: 1.2rem;
      }
    }
    ul li div{
      text-align: left;
      margin-left: 32px;
   
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #4A4A4A;
    }

    ul li:before {
      content: "" !important;
      background-image: url("../assets/img/model_v3_tick.png") !important;
      background-size: 13px !important;
      background-position-x: 5px !important;
      background-position-y: 5px !important;
      color: #5f4dd5 !important;
      font-weight: bolder !important;
      width: 15px !important;
      height: 15px !important;
   
      background-repeat: no-repeat !important;

      position: relative !important;
      display: block !important;
      float: left !important;
      margin-left: 0px !important;
      // padding-left: 8px;
      left: 0px !important;
      padding:3px !important;
      background-color: #5FC5AD !important;
      border-radius: 12px 
    }

    .credit-broker {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      /* or 121% */

      text-align: center;

      color: #4A4A4A;
    }

    .credit-broker b {
      font-weight: 900;
      font-size: 28px;
    }
  }


  .row {
    display: flex;
    justify-content: space-between;
  }

  .column {
    padding: 5px;
  }

  .topCfLogo{
    width: 100%; 
    display: block;
  }

  .topCfLogoDual{
    width: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
  }
}
</style>
