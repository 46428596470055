// force the domain of the email to be lowercase and send it to the email validator
export const phoneNormalised = (value) => {
    let phone = value.trim();

    if( phone.indexOf('044') == 0 )  {
        phone = phone.substring(1, phone.length);
    } else if ( phone[0] == '0')  {
        phone = '44' + phone.substring(1, phone.length);
    } else if ( phone.indexOf('+44') == 0 ) {
        phone = phone.substring(1, phone.length); 
    } else  {
        phone = '44'+phone;
    }

    return phone;
  }