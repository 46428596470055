<template>
    <section>
      <div v-for="(terms, index) in termsAndConditions" :key="index">
        <h1 id="modalTitle">{{ termsTitle[index] }}</h1>
        <div v-if="content[terms]">
          <div v-for="termAndCondition in content[terms].termAndCondition" :key="termAndCondition.identifier" class="terms__numbered-block container -mt-35 -mb-35">
            <ol :style="'counter-reset: lis ' + Number(termAndCondition.listNumber - 1)  + ';'">
              <li class="text -display-md">
                <h2 class="-mb-30">{{ termAndCondition.title }}</h2>
                <section class="terms__numbered-block-section text xs:-body-xs md:-body-md lg:-body-lg" v-html="termAndCondition.terms">
                </section>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
  import { DotCMSService } from '../services';
  import { DOTCMS_CONTENT_ID } from '../const';
  import {LocationHelper} from '@/helpers';

  export default {
    name: 'terms',
    data() {
      return {
        termsTitle: LocationHelper.getHost().startsWith('carcraft') ? [
            'Terms and Conditions'
        ] : [
            'Terms and Conditions Part 1',
            'Part 2: Terms and conditions for use of CarFinance 247'
        ],
        termsAndConditions: LocationHelper.getHost().startsWith('carcraft') ? DOTCMS_CONTENT_ID.CARCRAFT_TERMS_AND_CONDITIONS : DOTCMS_CONTENT_ID.TERMS_AND_CONDITIONS,
        content: {},
      }
    },
    mounted() {
      const content = LocationHelper.getHost().startsWith('carcraft') ? DOTCMS_CONTENT_ID.CARCRAFT_TERMS_AND_CONDITIONS : DOTCMS_CONTENT_ID.TERMS_AND_CONDITIONS;

      content.forEach(id => DotCMSService.getContentById(this.$store.state, id, 1).then((response) => {
        if (response.data && response.data.contentlets.length > 0) {
          const id = response.data.contentlets[0].identifier;
          this.content[id] = response.data.contentlets[0];
          this.$forceUpdate();
        }
      }));
    },
    methods: {
      navToPrivacyPolicy() {
        this.$emit('openModal', 'privacy-policy')
      },
      navToCookiePolicy() {
        this.$emit('openModal', 'cookie-policy')
      },
    }
  }
</script>

<style>
  .terms__numbered-block ol {
    counter-reset: lis;
    list-style-position: inside;
    margin-left: 0px;
  }

  .terms__numbered-block ol ol {
    margin-left: -25px;
  }

  .terms__numbered-block ol ol ol {
    margin-left: 0px;
  }

  .terms__numbered-block ol li {
    display: table;
    margin-top: 30px;
  }

  .terms__numbered-block ol li h2 {
    position: relative;
    top: -22px;
  }

  .terms__numbered-block ol li:before {
    counter-increment: lis;
    content: counters(lis, ".") ". " ;
    display: table-cell;
    vertical-align: top;
    padding-right: 15px;
  }

  .terms__numbered-block-section h1,
  .terms__numbered-block-section h2,
  .terms__numbered-block-section h3,
  .terms__numbered-block-section h4,
  .terms__numbered-block-section h5,
  .terms__numbered-block-section h6 {
    font-size: 1.75rem;
    margin-top: 30px;
  }
</style>