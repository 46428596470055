<template>
  <img alt="CarFinance247 Logo" height="45" v-lazy="imgUrl" />
</template>

<script>
export default {
  name: "WilliamsLogo",
  data () {
    return {
      imgUrl:  require('@/assets/img/affiliates/williams.png')
    }
  }
}
</script>

<style scoped>

</style>