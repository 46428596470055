<template>
  <div class="power-by-cf247-text">
    {{ $t('component.power_by_cf247_text.label_part_1') }} <img v-show="showPowerByCF247Logo" height="25" style="position: relative; top:5px; padding-left: 5px; padding-right: 5px;" src='@/assets/img/logos/CarFinance247_Logo.svg' /> {{ $t('component.power_by_cf247_text.label_part_2') }}
  </div>
</template>

<script>
import {AFFILIATES} from '@/factories';
import {LocationHelper} from '@/helpers';

  export default {
    name: "PowerByCf247Text",
    computed: {
      showPowerByCF247Logo() {
        let host = LocationHelper.getHost();

        if (AFFILIATES[host] !== undefined) {
          const affiliateConfig = AFFILIATES[host];

          if (affiliateConfig && typeof affiliateConfig.showPowerByCF247Logo !== "undefined") {
            return affiliateConfig.showPowerByCF247Logo;
          }
        }

        return false;
      }
    },
  }
</script>

<style lang="scss" scoped>
  .power-by-cf247-text {
    padding: 0px;
    border-radius: 5px;
    font-size: .9em;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media (min-width: 1024px) {
    margin-top: 20px;
    font-size: 1.125rem;
    text-align: left;
    padding: 15px 50px;
  }
</style>