<template>
  <form @submit.prevent="addAddress" :messages="{ validBuilding: '' }">

    <h1 v-if="index === 0">Next, where is {{ employerName }} located?</h1>
    <h1 v-else>Where is {{ employerName }} locate?</h1>

    <div @click="previousPage" v-if="manual" class="manual-address-back">
        <span class="magnifier-icon">
          <img  src="../assets/img/magnifier.svg">
        </span>
        <span class="magnifier-text">
          Search for an address
        </span>
    </div>

		<template v-if="!manual">
        <div class="postcode-search">
          <p v-if="!hideAddressLookup">Just start typing your address...</p>
          <div class="input-container">
          <input
              :id="idConcat(pageName, 'Postcode')"
              :name="'form.employments['+index+'].postcode'"
              v-model.trim="$v.postcode.$model"
              :v="$v.postcode"
              placeholder="Eg 'CRO3AG' or 'Factory Lane'"
              @input="postcodeLookup"
              class="postcode-input"
              v-if="!hideAddressLookup"
              :class="SENSITIVE_DATA.p11Mask"
              @keypress.13.prevent
          />
          <label class="labelWrapper" v-if="!hideAddressLookup"><div class="textlabel"></div></label>
          </div>
            <div id="postcode-list" class="postcode-list" v-if="postcode && isAddressHidden === false">
              <ul class="addresses">
                <li class="address-item" v-for="(address, addressIndex) in addresses" :key="address.Key" @click="setAddress(address.Key, addressIndex); isAddressHidden=true;">
                  <span :class="SENSITIVE_DATA.p11Mask.concat(['address'])">{{address.List}}</span>
                </li>
                <li class="manual-text" @click="toggleManual(manual)">
                  <span style="margin: 0 15px">Can't see your address? <span class="manual-enter-text">Enter address manually</span></span>
                </li>
              </ul>
            </div>

          <div v-if="address && hideAddressLookup" class="address-summary">
            <div class="address-summary-text">
              <p :class="SENSITIVE_DATA.p11Mask">{{address}}</p>
            </div>
            <img :id="idConcat(pageName,'PencilBtn')" @click="hideAddressLookup = false" class="pencil" src="../assets/img/pencil.svg">
          </div>
        </div>
		</template>

    <template v-else>

      <p class="error" v-if="($v.flatNumber.$dirty && !$v.flatNumber.validBuilding) ||
                                   ($v.buildingName.$dirty && !$v.buildingName.validBuilding) ||
                                   ($v.buildingName.$dirty && !$v.buildingName.validBuilding)">
        Please enter a 'Flat or unit number' or 'Building name' or 'Building number'
      </p>

      <InputText
          :id="idConcat(pageName,'FlatUnitNumberName')"
          :name="'form.employments['+index+'].flatNumber'"
          label="Flat or Unit Number"
          v-model="$v.flatNumber.$model"
          :v="$v.flatNumber"
          invalidMessage=""
          :sensitive-data="true"
      />

      <InputText
          :id="idConcat(pageName,'BuildingName')"
          :name="'form.employments['+index+'].buildingName'"
          label="Building Name"
          v-model="$v.buildingName.$model"
          :v="$v.buildingName"
          invalidMessage=""
          :sensitive-data="true"
      />

      <InputText
          :id="idConcat(pageName,'BuildingNumber')"
          :name="'form.employments['+index+'].buildingNumber'"
          label="Building Number"
          v-model="$v.buildingNumber.$model"
          :v="$v.buildingNumber"
          invalidMessage=""
          :sensitive-data="true"
      />

      <InputText
          :id="idConcat(pageName,'Street')"
          :name="'form.employments['+index+'].street'"
          label="Street"
          v-model="$v.streetAddress.$model"
          :v="$v.streetAddress"
          :sensitive-data="true"
      />

      <InputText
          :id="idConcat(pageName,'Locality')"
          :name="'form.employments['+index+'].locality'"
          label="Locality"
          autocomplete="address-level3"
          v-model="$v.locality.$model"
          :v="$v.locality"
          v-if="$v.showLocality"
          :sensitive-data="true"
      />

      <InputText
          :id="idConcat(pageName,'Town')"
          :name="'form.employments['+index+'].employerTown'"
          label="Town/City"
          autocomplete="address-level2"
          v-model="$v.town.$model"
          :v="$v.town"
          :sensitive-data="true"
      />

      <InputText
          :id="idConcat(pageName,'PostcodeManual')"
          :name="'form.employments['+index+'].postcode'"
          label="Postcode"
          autocomplete="postal-code"
          uppercase
          smallInput
          v-model.trim="$v.postcodeManual.$model"
          :v="$v.postcodeManual"
          :invalidMessage="postcodeInvalidMessage"
          :sensitive-data="true"
      />

    </template>

    <template v-if="!manual && hideAddressLookup">
      <ButtonContinue :id="idConcat(pageName,'ContinueBtn')">Add address</ButtonContinue>
    </template>

    <template v-if="manual">
      <ButtonContinue :id="idConcat(pageName,'ContinueBtn')">Add address</ButtonContinue>
    </template>

    <ButtonBack :id="idConcat(pageName,'BackBtn')" :is-call-back-handle="true" @callback="previousPage"/>

    <InfoFooter :showTopOnly="true" />
  </form>
</template>

<script>
import {required} from 'vuelidate/lib/validators'
import {AnalyticsService, PostCodeService} from "../services"
import AfdHelper from '../helpers/afd-helper'
import {checkPostCode} from "@/modules/checkUKPostcodes.js"
import { SENSITIVE_DATA } from '../const';

const validBuilding = (value, component) => {
  if (component.$v.buildingNumber.$model || component.$v.flatNumber.$model || component.$v.buildingName.$model) {
    return true;
  }

  return false;
};

const validPostcode = (value, component) => {
  if ((component.$v.postcode && component.$v.postcode.$dirty) ||
      (component.$v.postcodeManual && component.$v.postcodeManual.$dirty)) {
    return checkPostCode(value) !== false;
  }
  return true;
}

document.addEventListener('mouseup', function(e) {
  let postcodeList = document.getElementById('postcode-list')
    if (postcodeList) {
      if (!postcodeList.contains(e.target)) {
        postcodeList.style.display = 'none'
      }
    }
})

document.addEventListener('input', function(e) {
  let container = document.getElementById('postcode')
  if (container && container.contains(e.target)) {
    let postcodeList = document.getElementById('postcode-list')
    if (postcodeList) {
      postcodeList.style.display = 'block'}
    }
})

let routeNo, index

export default {
  beforeRouteEnter(to, from, next) {
    routeNo = to.params.number
    index = to.params.number - 1
    next()
  },
  data() {
    return {
      pageName: 'EmploymentAddressAdd',
      routeNo: routeNo,
      index: index,
      employerName: this.$store.state.form.employments[index].employerName,
      postcodeManual: this.$store.state.form.employments[index].postcode,
      addresses: this.$store.getters.any('savedEmploymentAddresses[' + index + '].array'),
      addressValue: this.$store.getters.any('savedEmploymentAddresses[' + index + '].value'),
      streetAddress: this.$store.state.form.employments[index].street,
      locality: this.$store.state.form.employments[index].locality,
      town: this.$store.state.form.employments[index].employerTown,
      buildingName: this.$store.state.form.employments[index].buildingName,
      buildingNumber: this.$store.state.form.employments[index].buildingNumber,
      subBuilding: this.$store.state.form.employments[index].subBuilding,
      flatNumber: this.$store.state.form.employments[index].flatNumber,
      previousPostcode: this.$store.getters.any('savedEmploymentAddresses[' + index + '].previousPostcode'),
      showLocality: false,
      isAddressHidden: true,
      SENSITIVE_DATA: SENSITIVE_DATA,
      pageViewSubmitted: false,
      postcode: null,
      manual: false,
    }
  },
  validations() {
    if (this.manual) {
      return {
        addressValue: {},
        streetAddress: {required},
        postcodeManual: {required, validPostcode},
        town: {required},
        locality: {},
        buildingName: {validBuilding},
        buildingNumber: {validBuilding},
        companyName: {},
        subBuilding: {},
        flatNumber: {validBuilding},
        addressKey: {}
      }
    } else {
      return {
        addressValue: {},
        streetAddress: {},
        postcode: {},
        town: {},
        locality: {},
        buildingName: {},
        buildingNumber: {},
        subBuilding: {},
        companyName: {},
        flatNumber: {},
        addressKey: {}
      }
    }
  },
  methods: {
    resetManualComponentDirtyState(vm) {
      vm.$v.postcodeManual.$model = this.$store.state.form.employments[index].postcode;

      if (vm.$v.postcodeManual.$model) {
        vm.$v.postcodeManual.$touch();
      } else {
        vm.$v.postcodeManual.$reset();
      }

      vm.$v.town.$model = this.$store.state.form.employments[index].employerTown;

      if (vm.$v.town.$model) {
        vm.$v.town.$touch();
      } else {
        vm.$v.town.$reset();
      }

      vm.$v.locality.$model = this.$store.state.form.employments[index].locality;

      if (vm.$v.locality.$model) {
        vm.$v.locality.$touch();
      } else {
        vm.$v.locality.$reset();
      }

      vm.$v.buildingName.$model = this.$store.state.form.employments[index].buildingName;

      if (vm.$v.buildingName.$model) {
        vm.$v.buildingName.$touch();
      } else {
        vm.$v.buildingName.$reset();
      }

      vm.$v.buildingNumber.$model = this.$store.state.form.employments[index].buildingNumber;

      if (vm.$v.buildingNumber.$model) {
        vm.$v.buildingNumber.$touch();
      } else {
        vm.$v.buildingNumber.$reset();
      }

      vm.$v.streetAddress.$model = this.$store.state.form.employments[index].street;
      if (vm.$v.streetAddress.$model) {
        vm.$v.streetAddress.$touch();
      } else {
        vm.$v.streetAddress.$reset();
      }

      vm.$v.subBuilding.$model = this.$store.state.form.employments[index].subBuilding;

      if (vm.$v.subBuilding.$model) {
        vm.$v.subBuilding.$touch();
      } else {
        vm.$v.subBuilding.$reset();
      }

      vm.$v.flatNumber.$model = this.$store.state.form.employments[index].flatNumber;

      if (vm.$v.flatNumber.$model) {
        vm.$v.flatNumber.$touch();
      } else {
        vm.$v.flatNumber.$reset();
      }
    },
    postcodeLookup() {
      if (this.postcode.length > 0) {
        let This = this
        this.isAddressHidden = false;
        this.previousPostcode = this.postcode
        let data = [this.previousPostcode, 'savedEmploymentAddresses[' + index + '].previousPostcode']
        This.$store.dispatch('updateFormValue', data)

         PostCodeService.fastPostcodeLookup(this.$store.state, this.postcode)
            .then(function (response) {
              let data = [0, 'savedEmploymentAddresses[' + index + '].value']
              This.$store.dispatch('updateFormValue', data)

              let array = response.data
              let newArray = []

              if (response.data.ErrorText === 'No Results Found' || !response.data || array.length === 0) {
                This.addresses = null
                This.$appInsights.trackEvent({
                  name: 'Application Form - Postcode Lookup',
                  properties: {
                    event_category: 'Application Form',
                    event_action: 'Postcode Lookup',
                    event_label: 'No Results'
                  }
                });
              } else {     
                array.Item.forEach(function (el) {
                  This.postcode = This.postcode.toUpperCase()

                  String.prototype.insert = function (index, string) {
                    if (index > 0)
                      return this.substring(0, index) + string + this.substring(index, this.length)
                    return string + this;
                  }
                  el.List = AfdHelper.formatAddressTown(el.List, el.Town)
                  newArray.push(el)
                })

                data = [newArray, 'savedEmploymentAddresses[' + index + '].array']
                This.$store.dispatch('updateFormValue', data)
                This.addresses = newArray

                This.$appInsights.trackEvent({
                  name: 'Application Form - Postcode Lookup',
                  properties: {
                    event_category: 'Application Form',
                    event_action: 'Postcode Lookup'
                  }
                });

              }
            })
            .catch(error => {
              This.$appInsights.trackEvent({
                name: 'Application Form - Postcode Lookup Error',
                properties: {
                  event_category: 'Application Form',
                  event_action: 'Postcode Lookup Error',
                  event_label: error.message
                }
              });
            })
      }
    },
    setAddress(value) {
      function removeEmptyStrings(obj) {
        let newObj = {};
        Object.keys(obj).forEach((prop) => {
          if (obj[prop] !== '') {
            newObj[prop] = obj[prop];
          }
        });
        return newObj;
      }

      let This = this

      if (!value.target) {
        this.addressValue = value
      }
      else {
         value = value.target.value
      }

      PostCodeService.getAddressByKeyAfd(This.$store.state, value)
          .then(function (response) {
            let data = {}
            if (response.data) {
              data = response.data.Item[0] ? response.data.Item[0] : This.toggleManual()
            }
            if (data) {
              let currentAddress = This.$store.state.form.employments[index]

              let address = {
                  flatNumber: data.SubBuilding || AfdHelper.extractFlatNumber(data.Property, data.Street),
                  buildingName: data.BuildingName || AfdHelper.extractBuildingName(data.Property),
                  subBuilding: data.SubBuilding,
                  buildingNumber: data.BuildingNumber || AfdHelper.extractHouseNumber(data.Street),
                  companyName: data.CompanyName,
                  street: AfdHelper.mapStreet(data.Street),
                  locality: data.Locality,
                  employerTown: data.Town,
                  county: data.County,
                  postcode: data.Postcode,
                  addressKey: data.Key
              }

              address = removeEmptyStrings(address)

              let mergedAddress = Object.assign(currentAddress, address)

              let newData = [mergedAddress, 'form.employments[' + index + ']']
              This.$store.dispatch('updateFormValue', newData)

              const fullAddress = {
                index: index,
                address: data.List,
                town: data.Town,
                hideAddressLookup: true
              }

              This.$store.dispatch('setFullEmploymentAddress', fullAddress)

              This.validateAddress(address)
            }
          })
    },
    validateAddress(address) {
      let newData;

      if (!address.street) {
        newData = [null, 'form.employments[' + index + '].streetAddress']
        this.$store.dispatch('updateFormValue', newData)

        this.toggleManual()
        this.$v.streetAddress.$touch()
      } else if (!address.buildingName && !address.buildingNumber && !address.subBuilding) {
        newData = [null, 'form.employments[' + index + '].buildingName']
        this.$store.dispatch('updateFormValue', newData)

        newData = [null, 'form.employments[' + index + '].buildingNumber']
        this.$store.dispatch('updateFormValue', newData)

        newData = [null, 'form.employments[' + index + '].subBuilding']
        this.$store.dispatch('updateFormValue', newData)

        this.toggleManual()
        this.$v.buildingName.$touch()
        this.$v.buildingNumber.$touch()
        this.$v.flatNumber.$touch()
      }
    },
    toggleManual(usePostcodeService) {
      if (!validPostcode(this.postcode, this)) {
        let newdata = [null, 'form.employments['+index+'].postcode']
        this.$store.dispatch('updateFormValue', newdata)
      }

      this.$store.dispatch('toggleManualEmploymentAddress', index)
      this.manual = !this.manual;
      
      this.$appInsights.trackEvent({
        name: 'Application Form - address_toggle_manual',
        properties: {
          event_category: 'Application Form',
          event_action: 'address_toggle_manual',
          event_label: usePostcodeService === false ? 'enter_address_manually' : 'enter_address_postcode'
        }
      });

      if (this.manual) {
        this.resetManualComponentDirtyState(this);
      }
    },
    resetState() {
      let data = [0, 'savedEmploymentAddresses[' + index + '].value']
      this.$store.dispatch('updateFormValue', data)

      data = [null, 'savedEmploymentAddresses[' + index + '].array']
      this.$store.dispatch('updateFormValue', data)

      data = [null, 'savedEmploymentAddresses[' + index + '].streetAddress']
      this.$store.dispatch('updateFormValue', data)

      this.addresses = null
      this.addressValue = 0
    },
    previousPage() {
      this.postcode = '';

      if (!this.manual && !this.hideAddressLookup) {
        this.$router.go(-1);
      } else if (!this.manual && this.hideAddressLookup) {
        this.hideAddressLookup = false;
      } else if (this.manual) {
        this.toggleManual(false);
      }

      this.resetState();
    },
    formatPostcode(str) {
      str = str.toUpperCase();
      str = str.replace(/[^0-9a-z]/gi, '');
      var parts = str.match(/^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/);
      parts.shift();
      str = parts.join(' ');
      return str;
    },
    addAddress() {
      if (this.$store.state && this.$store.state.form && this.$store.state.form.employments && this.$store.state.form.employments.length > 0) {
        let postcode = this.$store.state.form.employments[0].postcode;

        if (checkPostCode(postcode)) {
          const data = this.formatPostcode(postcode);
          AnalyticsService.setData('postcode', data, false);
        }
      }

      this.Submit(this.nextPage);
    }
  },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);

    let input_element = document.querySelectorAll("input");
    input_element.forEach(input_array => {
      input_array.setAttribute('value', input_array.value);
      input_array.addEventListener('keyup', () => {
        input_array.setAttribute('value', input_array.value);
        });
        });

    if (!this.pageViewSubmitted) {
      this.pageViewSubmitted = true;

      AnalyticsService.trackPageView({
        page_name: 'employment-address-details'
      });
    }
  },
  computed: {
    addressKey: {
      cache: false,
      get: function () {
        return this.$store.getters.any('form.employments[' + index + '].addressKey')
      },
      set: function (value) {
        let data = [value, 'form.employments[' + index + '].addressKey']
        this.$store.dispatch('updateFormValue', data)
      },
    },
    hideAddressLookup: {
      get: function() {
        let idx = this.$store.state.fullEmploymentAddresses.findIndex(i => i.index === index)
        return this.$store.getters.any('fullEmploymentAddresses[' + idx + '].hideAddressLookup') || false
      },
      set: function() {
        this.$store.dispatch('setIsEmploymentAddressConfirmed', index)
      }
    },
    employmentPostCode() {
      return this.$store.state.form.employments[index].postcode ? this.$store.state.form.employments[index].postcode.toLowerCase() : ''
    },
    address() {
      let idx = this.$store.state.fullEmploymentAddresses.findIndex(i => i.index === index)
      return this.$store.state.fullEmploymentAddresses[idx] ? this.$store.state.fullEmploymentAddresses[idx].address : ''
    },
    nextPage() {
      return { name: 'employment-length' , params: { number: Number(routeNo) } }
    },
    postcodeInvalidMessage() {
      return 'Please enter a valid UK postcode'
    },
  },
  beforeCreate() {
    let newObj = {
      occupation: null,
      employerName: null,
      employerTown: null,
      statusId: null,
      yearsAtEmployer: null,
      monthsAtEmployer: null,
      subBuilding: null,
      buildingName: null,
      buildingNumber: null,
      locality: null,
      postcode: null,
      county: null,
      street: null,
      flatNumber: null // doesn't seem to be used
    }

    let moreObj = {
      manual: false,
      streetAddress: null,
    }

    let array = this.$store.state.form.employments
    let arrayMore = this.$store.state.savedEmploymentAddresses
    let count = this.$store.getters.totalEmploymentCount

    if (count < routeNo) {
      array.push(newObj)
      arrayMore.push(moreObj)
    }

  }
}
</script>

<style lang="scss" scoped>

  .addresses {
    overflow-x: hidden;
    height: 13em;
    padding: 0;
    margin: 0;
    border: 1px solid #bbbbbb;
    border-radius: 5px;
  }

  #address-select {
    font-size: 1.2rem;
  }

  .address {
    display: block;
    font-size: 1.2rem;
    border-bottom: 1px solid #bbbbbb;
    color: #7f7f7f;
    padding: 10px;
    margin: 5px;
  }

  .address-summary {
    border: 1px solid #bbbbbb;
    border-radius: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 10px;
  }

  .address-summary-text {
    max-width: 80%;
  }

  .pencil {
    margin-bottom: 20px;
  }

  .pencil:hover {
    cursor: pointer;
  }

  .address-item:hover {
    color: white;
    cursor: pointer;
    background-color: #e5e5e5;
    width: 100%;
  }

  .address-item {
    font-size: 1.3rem;
    margin: 5px 0;
  }

  .manual-text {
    display: flex;
    align-items: center;
    color: #7f7f7f;
    margin: 5px 0;
  }

  .manual-enter-text {
    color: #604dd5;
  }

  .manual-text:hover {
    cursor: pointer;
    background-color: #e5e5e5;
    width: 100%;
  }

  .postcode-input {
    width: 100%;
  }

  textarea:focus, input:focus{
    outline: none;
  }

  @media only screen and (max-width: 400px) {
    .address-summary-text {
      max-width: 90%;
    }
  }

  .manual-address-back {
    cursor: pointer;
  }

  .magnifier-icon{
    @include font-regular();
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .magnifier-text {
    position: static;
    width: 155px;
    height: 23px;
    left: 24px;
    top: 0px;
    @include font-regular();
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #5A52CD;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 6px;
  }
</style>
