
<template>
  <div v-bind:class="{ 'small-input': smallInput, 'has-header': header }">
    <label :for="id" v-if="label" :class="{ 'big-label': bigLabel }">{{ label }}</label>
    <select
    :id="id"
    :name="name"
    :value="value"
    @change="selectChange"
    :class="{ 'error': v.$error, 'valid': v.$dirty && !v.$error }"
    :disabled="$store.state.lockDown"
    >
    <!-- v-model="value" -->
      <option disabled value="0">Please choose an address</option>
      <template v-for="(item, index) in array">
        <option :key="index" :value="item.Key">{{ item.Address }}</option>
      </template>
    </select>

    <p class="error" v-if="v.$error">{{ invalidMessage }}</p>
    <p class="small-print" v-if="smallPrint">{{ smallPrint }}</p>
  </div>
</template>

<script>
export default {
  props: {
    id:{
      type: String,
      default() {
        return 'input-group-' + this._uid
      }
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    nameData: {
      type: String,
      default: null
    },
    value: {
      type: [Number, String],
      default: 0
    },
    array: {
      type: Array,
    },
    bigLabel: {
        type: Boolean,
        default: false
    },
    smallInput: {
        type: Boolean,
        default: false
    },
    smallPrint: {
        type: String,
        default: ''
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    placeholder: {
      type: String,
      default: ''
    },
    invalidMessage: {
      type: String,
      default() {
        return 'Please choose your '+ this.label.toLowerCase() +'.'
      }
    },
    header:{
      type: String
    },
    v: {
      type: Object,
      default: function () { return {} }
    }
  },
  methods: {
    selectChange(e) {

      let dataArray = [this.array, this.nameData]
      this.$store.dispatch('updateFormValue', dataArray)

      if (e.target.value)
      {
        let addressKey = e.target.value

        let data = [addressKey, this.name]

        this.$store.dispatch('updateFormValue', data)

        this.$emit('input', addressKey)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  #app form {
    .small-input input {
      max-width: 50%;
      display: block;
    }
  }
</style>
