<template>
  <section id="input-borrow" class="currency-input">
    <div class="formWrapper">
        <div class="input-container">
          <div
            :class="{
              container: id,
              focus: hasFocus,
              error: this.v.$error,
              valid: this.v.$dirty && !this.v.$error,
            }"
          >
            <input
              :id="id"
              :aria-labelledby="ariaLabelledBy"
              :class="getInputClass"
              type="text"
              ref="input"
              v-model="value"
              inputmode="numeric"
              @focus="hasFocus = true"
              @blur="hasFocus = false"
              maxlength="7"
              v-on:keydown="isNumber"
              @keydown.space="(event) => event.preventDefault()"
              @input="input"
              :placeholder="appearDisabled ? this.defaultLoanAmountText : ''"
              :disabled="disabled"
            />
            <div class="miles">miles</div>
          <label
            :id="id + '-label'"
            :for="id"
            v-if="label"
            class="labelWrapper" 
            ><div class="textlabel">{{ label }}</div></label
          >
    </div>
    <template v-if="v.$error">
      <p class="error" v-if="!value">{{ invalidMessage }}</p>
      <p class="error" v-else-if="this.value > maxBorrow">
        The most you can borrow is £{{ this.formatAsMoney(maxBorrow) }}
      </p>
      <p class="error" v-else-if="this.value < minBorrow">
        The minimum amount you can borrow is £{{ this.formatAsMoney(minBorrow) }}
      </p>
    </template>
    </div>
    <p class="small-print" :id="id + '-small-print'" v-if="smallPrint && !v.$error">
      {{ smallPrint }}
    </p>
  </div>
  </section>
</template>

<script>
import accounting from "accounting-js";
import {SENSITIVE_DATA} from '@/const';

export default {
  data() {
    return {
      defaultValue: "",
      hasFocus: false,
      defaultLoanAmountText: "",
    };
  },
  created: function () {
    this.defaultValue = this.value;
  },
  props: {
    id: {
      type: String,
      default() {
        return "input-borrow-" + this._uid;
      },
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    bigLabel: {
      type: Boolean,
      default: false,
    },
    checkboxLabel: {
      type: String,
      default: "",
    },
    invalidMessage: {
      type: String,
      default() {
        return "Please enter an amount.";
      },
    },
    maxBorrowThreshold: {
      type: Number,
      default: 1000000,
    },
    minBorrowThreshold: {
      type: Number,
      default: 1,
    },
    smallPrint: {
      type: String,
    },
    v: {
      type: Object,
      default: function () {
        return {};
      },
    },
    valueOverride: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    appearDisabled: {
      type: Boolean,
      default: false,
    },
    input: {
      type: Function,
      default: () => 1,
    },
    maskInput: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getInputClass() {
      let classList = [];

      if (this.disabled) {
        classList.push('disable-input');
      }

      if (this.maskInput) {
        classList = classList.concat(SENSITIVE_DATA.p11Mask);
      }

      return classList;
    },
    maxBorrow() {
      return this.maxBorrowThreshold;
    },
    minBorrow() {
      return this.minBorrowThreshold;
    },
    value: {
      get: function () {
        let name = this.name;
        let obj = this.$store.state;
        let get = _.get(obj, name);
        let valueString;

        if (get === "" || get === null) {
          valueString = null;
        } else {
          valueString = this.formatAsMoney(get);
        }

        if (this.valueOverride) {
          valueString = null;
          let data = [valueString, this.name];
          this.$store.dispatch("updateFormValue", data);
        }

        return valueString;
      },
      set: function (newValue) {
        let This = this;
        newValue = newValue.replace(/\D/g, ""); // replace all non numeric characters
        if (newValue === "") {
          newValue = null;
        } else {
          newValue = Number(newValue);
        }

        if (this.valueOverride) {
          // console.log('override to null')
          let data = [null, this.name];
          this.$store.dispatch("updateFormValue", data).then(() => {
            This.$emit("input", newValue);
          });
        } else {
          let data = [newValue, this.name];
          this.$store.dispatch("updateFormValue", data).then(() => {
            This.$emit("input", newValue);
          });
        }

        let newValueString = this.formatAsMoney(newValue);
        if (this.valueOverride) {
          newValueString = null;
        }
        return newValueString;
      },
    },
    ariaLabelledBy() {
      if (this.smallPrint)
        return this.id + "-label " + this.id + "-small-print";
      return this.id + "-label";
    },
  },
  mounted() {
    if (
      this.appearDisabled &&
      !this.$route.query.amount &&
      this.value === this.defaultLoanAmountText
    ) {
      this.value = "";
    }

    let input_element = document.querySelectorAll("input");
    input_element.forEach(input_array => {
      input_array.setAttribute('value', input_array.value);
      input_array.addEventListener('keyup', () => {
        input_array.setAttribute('value', input_array.value);
        });
        });
  },
  methods: {
    formatAsMoney: function (value) {
      return accounting
        .formatMoney(value, {
          symbol: "",
          precision: 0,
          thousand: ",",
          decimal: ".",
        })
        .replace(".00", "");
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        [8, 9, 13, 16, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99,
          100, 101, 102, 103, 104, 105].indexOf(charCode) === -1
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
 .currency-input {
    padding: 0px 0 0;
    margin-bottom: 0px;
}
.miles {
      position: absolute;
      right: 0px;
      margin-right: 5px;
      padding: 10px 0;
      font-size: 1.4rem;
    }
  
  .disable-input {
    color: #979797; /* same as website monthly budget */
  }
  .container {
    display: flex;
    align-items: center;
}
</style>
