<template>
  <div id="affiliates-interstitial">
    <div class="header-container">
      <HeaderLogo v-show="showHeaderLogo" />
      <Progress v-show="showProgressBar" />
      <div v-show="showTrustPilot" class="-mb-10">
        <div class="trustpilot-container">
          <TrustPilot />
        </div>
      </div>
    </div>
    <div>
      <CreditKarma_Interstitial v-show="isCreditKarma" :start-page="startPage" @setInterstitial="setInterstitial" />
      <Calculator_Interstitial v-show="showCalculator" :start-page="startPage" @setInterstitial="setInterstitial" />
    </div>
  </div>
</template>

<script>
  import HeaderLogo from '@/components/HeaderLogo.vue';
  import CreditKarma_Interstitial from '@/views/Affiliates/Interstitial/CreditKarma_Interstitial.vue';
  import {LocationHelper, StartPageHelper} from '@/helpers';
  import Calculator_Interstitial from '@/views/Affiliates/Interstitial/Calculator_Interstitial.vue';
  import {AnalyticsService} from '@/services';
  import TrustPilot from '@/components/TrustPilot.vue';

  export default {
    name: 'Affiliates_Interstitial',
    components: {
      Calculator_Interstitial,
      HeaderLogo,
      CreditKarma_Interstitial,
      TrustPilot
    },
    data() {
      return {
        startPage: StartPageHelper.getStartPageRouteName(false),
        isCreditKarma: LocationHelper.getHost() === 'ckdirect',
        isCompareTheMarketCarFinance: LocationHelper.getHost() === 'comparethemarketcarfinance',
        pageViewSubmitted: false,
      }
    },
    computed: {
      showHeaderLogo() {
        return this.isCreditKarma || this.isCompareTheMarketCarFinance;
      },
      showProgressBar() {
        return false;
      },
      showTrustPilot() {
        return false;
      },
      showCalculator() {
        return this.isCompareTheMarketCarFinance;
      }
    },
    mounted() {
      this.$emit('setInterstitial', true);

      if (!this.pageViewSubmitted) {
        this.pageViewSubmitted = true;

        if (this.showCalculator) {
          AnalyticsService.trackPageView({
            page_name: 'calculator-interstitial'
          });
        }
      }
    },
    methods: {
      setInterstitial(data) {
        this.$emit('setInterstitial', data);
      }
    }
  }
</script>

<style lang="scss" scoped>
  #affiliates-interstitial {
    .header-container {
      max-width: 600px;
      margin: 0 auto;
      width: 100%;
    }

    .trustpilot-container {
      text-align: center;

      img {
        height: 35px;

        @media only screen and (max-width: 600px) {
          height: 30px;
        }

        @media only screen and (max-width: 412px) {
          height: 27px;
        }

        @media only screen and (max-width: 360px) {
          height: 25px;
        }

        @media only screen and (max-width: 320px) {
          height: 25px;
        }
      }
    }
  }
</style>