import { AffiliatesHelper } from '@/helpers/index';

function noClick() {
  let noClick = true;

  if (AffiliatesHelper.isAffiliate()) {
    const value = AffiliatesHelper.getPropertyValue('noClick');

    if (value) {
      noClick = value;
    }
  }

  return noClick;
}

export default {
  canCoutinue() {
    return noClick();
  },
  visibleCoutinueButton() {
    return !noClick();
  }
}