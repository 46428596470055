<template>
  <form @submit.prevent="Submit(nextPage)">

    <InputYearsMonths
      :id="idConcat(pageName, 'TimeAtEmployer')"
      :label="yearsMonthsTitle"
      :invalidMessage="yearsMonthsInvalidMessage"
      showLabel
      :nameYears="'form.employments['+index+'].yearsAtEmployer'"
      :nameMonths="'form.employments['+index+'].monthsAtEmployer'"
      :valueMonths="monthsAtEmployer"
      :valueYears="yearsAtEmployer"
      :index="index"
      v-model="$v.timeAt.$model"
      :v="$v.timeAt"
      :vYears="$v.yearsAtEmployer"
      :vMonths="$v.monthsAtEmployer"
      :idYears="idConcat(pageName,'Years')"
      :idMonths="idConcat(pageName,'Months')"
    />

    <template v-if="index === 0">
<!--      <EmploymentHistory first />-->
    </template>

    <template v-else>
      <EmploymentHistory />
    </template>

    <ButtonContinue :id="idConcat(pageName,'ContinueBtn')" />

    <ButtonBack :id="idConcat(pageName,'BackBtn')" />

    <InfoFooter :showTopOnly="true" />
  </form>
</template>

<script>
import { between, required, minValue } from 'vuelidate/lib/validators'
import {AnalyticsService} from '../services'
import {EmploymentReOrderHelper, LocationHelper, RequestHelper} from '@/helpers';

let routeNo, index

export default {
  beforeRouteEnter(to, from, next) {
    routeNo = to.params.number
    index = to.params.number - 1
    next()
  },
  beforeRouteUpdate(to, from, next) {
    routeNo = to.params.number
    index = to.params.number - 1
    next()
  },
  components: {},
  data() {
    return {
      pageName: 'EmploymentLength',
      routeNo: routeNo,
      index: index,
      timeAt: this.$store.getters.indexEmploymentHistory(index),
      employmentStatus: this.$store.getters.employmentStatus(index),
      errorElementId: '',
      pageViewSubmitted: false,
      isCompareTheMarketReorder: LocationHelper.getHost() === 'comparethemarketcarfinance' && RequestHelper.getQueryVariable('test') === 'reorder',
      isCKDirectReorder: LocationHelper.getHost() === 'ckdirect',
    }
  },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);

    if (!this.pageViewSubmitted) {
      this.pageViewSubmitted = true;
      AnalyticsService.trackPageView({
        page_name: 'employment-length'
      });
    }
  },
  validations() {
    if (this.status === 'no-employer') {
      return {
        timeAt: {
          required,
          minValue: minValue(0.0000001)
        },
        yearsAtEmployer: {
          between: between(0, 80)
        },
        monthsAtEmployer: {
          between: between(0, 11)
        }
      }
    } else if (this.status === 'no-name') {
      return {
        timeAt: {
          required,
          minValue: minValue(0.0000001)
        },
        yearsAtEmployer: {
          between: between(0, 80)
        },
        monthsAtEmployer: {
          between: between(0, 12)
        }
      }
    } else {
      return {
        timeAt: {
          required,
          minValue: minValue(0.0000001)
        },
        yearsAtEmployer: {
          between: between(0, 80)
        },
        monthsAtEmployer: {
          between: between(0, 11)
        }
      }
    }
  },
  computed: {
    isSocialRecorder() {
      const host = ['social','ecrm', 'tiktok', 'social-premier', 'social-vans', 'social-bad-credit'];

      return host.indexOf(LocationHelper.getHost()) !== -1;
    },
    isEmploymentReOrder(){
      return EmploymentReOrderHelper.isEmploymentReOrder();
    },
    status() {
      let v = this.$store.getters.employmentStatus(index)
      let status
      if(v === 9 || v === 11 || v === 14 || v === 15 || v === 20){
        status = 'default'
      }
      if(v === 10 || v === 18){
        // remove employer name
        status = 'no-name'
      }
      if(v === 13 || v === 19 || v === 12 || v === 16 || v === 17 || v === 21){
        // remove employer name, town and occupation
        status = 'no-employer'
      }
      return status
    },
    statusName() {
      let v = this.$store.getters.employmentStatus(index)
      let status
      if(v === 10){status = 'self-employed'}
      if(v === 13){status = 'retired'}
      if(v === 19){status = 'a student'}
      if(v === 12){status = 'on disability living allowance'}
      if(v === 16){status = 'a homemaker'}
      if(v === 17){status = 'unemployed'}
      if(v === 21){status = 'a non professional carer'}
      if(v === 18){status = 'in the armed forces'}
      return status
    },
    getEmployerName() {
      return this.$store.getters.getEmployerName(index)
    },
    yearsMonthsTitle() {
      if (this.status === 'default') {
        if (this.index === 0) {
          return `How long have you worked at ${this.getEmployerName}?`
        }
        return 'How long did you work there?'
      } else {
        if (this.index === 0) {
          return 'How long have you been ' + this.statusName + '?'
        }
        return 'How long were you ' + this.statusName + '?'
      }
    },
    yearsAtEmployer() {
      return this.$store.getters.indexEmploymentYears(index)
    },
    monthsAtEmployer() {
      return this.$store.getters.indexEmploymentMonths(index)
    },
    yearsMonthsInvalidMessage() {
      if (this.$v.yearsAtEmployer.$invalid) return 'Please enter a number between 0 and 80 years.'
      if (this.$v.monthsAtEmployer.$invalid) return 'Please enter a number between 0 and 11 months.'
      if (this.status === 'default') {
        if (this.index === 0) {
          return 'Please tell us how long you’ve worked at this job.'
        }
        return 'Please tell us how long you worked at this job.'
      } else {
        if (this.index === 0) {
          return 'Please tell us how long have you been ' + this.statusName + '?'
        }
        return 'Please tell us how long you were ' + this.statusName + '?'
      }
    },
    nextPage() {
      let nextRouteNo = Number(routeNo) + 1
      if (this.$store.getters.totalEmploymentHistory >= 1) {
       // return {name: 'address-add', params: {number: 1}}
        if(Number(routeNo)==1) {
          return {name: 'employment-income'}
        }
        else
        {
          if (this.isEmploymentReOrder || this.isCompareTheMarketReorder || this.isCKDirectReorder || this.isSocialRecorder) {
            return {name: 'amount-to-finance'}
          }

          return { name: 'address-add', params: { number: 1 } }
        }
      } else {

        if(Number(routeNo)==1) {
          return {name: 'employment-income'}
        }
        else {
          return {name: 'employment', params: {number: nextRouteNo}}
        }
      }
    }
  },
  methods: {}
}
</script>
