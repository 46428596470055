<template>
  <form @submit.prevent="completeApplication()">
    <h1>{{ getLabel }}</h1>

    <InputEmail
      :id="idConcat(pageName, 'Email')"
      label="Email address"
      invalidMessage="Please enter an email address."
      name="form.email"
      autocomplete="email"
      v-model.trim="$v.email.$model"
      :v="$v.email"
      :sensitive-data="true"
    />

    <InputPhone
      :id="idConcat(pageName, 'Phone')"
      label="Phone number"
      invalidMessage="Please enter a phone number."
      name="form.contactNumber"
      autocomplete="tel"
      v-model.trim="$v.phone.$model"
      :v="$v.phone"
      :phoneNumber="$v.phone.$model"
      :styleAtt="'width: 50%;'"
      :sensitive-data="true"
    />

    <InputCheckboxTC
        :id="idConcat(pageName, 'TermsCheckbox')"
        header="Terms and conditions"
        name="form.agreedTermsAndConditions"
        invalidMessage="Please agree to the T&amp;Cs to get your quote."
        :submitPressed="this.submitPressed"
        v-model="$v.terms.$model"
        :v="$v.terms"
    >
      I have read and agree to <a :id="idConcat(pageName, 'TermsHref')" href="/terms" @click.prevent="showModal('Terms')">Car Finance 247 Limited's terms &amp; conditions</a>
    </InputCheckboxTC>

    <p class="error" v-if="!$v.terms.$model && this.submitPressed">
      {{ "Please agree to the T&amp;Cs to get your quote." }}
    </p>

    <ButtonContinue :v-center-arrow="isTestFinishButton" :id="idConcat(pageName,'ContinueBtn')" @click.once="completeApplication()" :disable='isSubmitted' :sub-label="isTestFinishButton ? 'See if you\'ll be accepted<br />No impact on your credit score' : ''">
      <span v-show="!isSubmitted">{{ctaWording}}</span>
      <span v-show="isSubmitted">Submitting...</span>
    </ButtonContinue>
    <ButtonBack :id="idConcat(pageName,'BackBtn')" />

    <FraudPreventionMsg :id="idConcat(pageName,'FraudPreventionMsgHref')" @showModal="openModal" />

    <Modal v-show="isTermsVisible" @close="closeModal">
      <template v-slot:body>
        <Terms @openModal="openModal" />
      </template>
      <Terms />
    </Modal>

    <Modal v-show="isDealershipsVisible" @close="closeModal">
      <template v-slot:body>
        <Dealerships />
      </template>
      <Terms />
    </Modal>

    <Modal v-show="isPrivacyVisible" @close="closeModal">
      <template v-slot:body>
        <Privacy @openModal="openModal" />
      </template>
      <Terms />
    </Modal>

    <Modal v-show="isCookieVisible" @close="closeModal">
      <template v-slot:body>
        <Cookie />
      </template>
      <Terms />
    </Modal>

    <InfoFooter :showTopOnly="true" />
  </form>
</template>

<script>
import { required, email} from 'vuelidate/lib/validators'
import { checkUKTelephoneError } from "../modules/checkUKTelephoneError.js"
import Terms from '@/components/Terms.vue'
import Dealerships from '@/components/Dealerships.vue'
import Privacy from '@/components/Privacy.vue'
import Cookie from '@/components/Cookie.vue'
import FraudPreventionMsg from '@/components/FraudPreventionMsg'
import { debounce } from 'lodash'
import { SegmentService } from '../services';
import { phoneNormalised } from '../helpers/phone-normalization-helper'
import {LocationHelper, RequestHelper} from '@/helpers';
import {AFFILIATES} from '@/factories';
import { SENSITIVE_DATA } from '../const';

const validate_uk_phone_number = (value) => {
  if (!value || value.length === 0 || !value.trim()) {
    return false;
  }

  let phoneError = checkUKTelephoneError(value);

  if (phoneError) {
    return false;
  }

  return true;
}

export default {
  components: {
    Terms,
    Dealerships,
    Privacy,
    Cookie,
    FraudPreventionMsg,
  },
  data() {
    return {
      pageName: 'ContactDetailsP2',
      email: this.$store.state.form.email,
      phone: this.$store.state.form.contactNumber,
      terms: this.$store.state.form.agreedTermsAndConditions,
      isTermsVisible: false,
      isDealershipsVisible: false,
      isPrivacyVisible: false,
      isCookieVisible: false,
      isSubmitted: false,
      submitPressed: false,
      SENSITIVE_DATA: SENSITIVE_DATA,
      isTestFinishButton: RequestHelper.getQueryVariable('test') === 'finish-button-sub-text',
    }
  },
  computed: {
    isNonAffiliatePersonalised() {
      let host = LocationHelper.getHost();
      return RequestHelper.getQueryVariable('personalised') && AFFILIATES[host] === undefined;
    },
    getLabel() {
      let label = "And finally, where should we send your quote?";

      if (this.isNonAffiliatePersonalised) {
        const name = this.$store.getters.any('form.firstName');
        label = `Lastly, we just need your contact details ${name}.`;
      }

      return label;
    },
    ctaWording() {
      var medium = this.$store.state && 
              this.$store.state.form && 
              this.$store.state.form.utmParameters && 
              this.$store.state.form.utmParameters['medium'] 
              ? 
                this.$store.state.form.utmParameters['medium']  
              : 
                null;

      var isPaidFinalCtaText = RequestHelper.getQueryVariable('test') === 'paid-final-cta-text'

      return medium && isPaidFinalCtaText ? 
              ['cpc', 'display', 'paidsocial'].indexOf(medium) != -1 ? 'Next' : 'Finish' 
            : 
              'Finish'
    }
  },
  validations: {
    email: {
      required,
      email
    },
    terms: {
    },
    phone: {
      required,
      validate_uk_phone_number
    },
  },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);
    
    this.$store.dispatch('updateFormValue', [true, 'form.personalDetailsCompleted'])

    let input_element = document.querySelectorAll("input");
    input_element.forEach(input_array => {
      input_array.setAttribute('value', input_array.value);
      input_array.addEventListener('keyup', () => {
        input_array.setAttribute('value', input_array.value);
        });
        });

  },
  methods: {
    completeApplication() {
      this.submitPressed = true

      this.$v.$touch();

      if (this.$v.terms.$model && !this.$v.$invalid) {
        SegmentService.trackPersonalDetailsCompletedEvent(this.$store.state, {
          guid: this.$store.state.GUID,
          email: this.email,
          date_of_birth: this.$store.state.form.dateOfBirth,
          first_name: this.firstname,
          last_name: this.lastname,
          phone_number: this.phone,
          phone_number_normalised: phoneNormalised(this.phone),
          postcode: this.$store.state.form.addresses[0].postcode || null,
          vehicle_type_id: this.$store.state.form.vehicleTypeId,
          utm: this.$store.state.form.utmParameters,
          net_monthly_income: this.$store.state.form.netMonthlyIncome,
          city: this.$store.state.form.addresses[0].town || null,
          gender: this.$store.state.form.titleId === 1 ? 'male' : 'female'
        });
        
        const self = this;
        self.isSubmitted = true;
        setTimeout(function () {
          self.isSubmitted = false;
        }, 10000)

        let data = [true, 'form.IsCompleted']
        this.$store.dispatch('updateFormValue', data)
            .then(() => this.$emit('sendFullApp', this.$store.state))
      }
    },
    openModal(modal) {
      this.isTermsVisible = false;

      if (modal === 'privacy-policy') {
        this.isPrivacyVisible = true;
      }

      if (modal === 'cookie-policy') {
        this.isCookieVisible = true;
      }
    },
    created() {
      let self = this;
      self.completeApplication = debounce(self.completeApplication, 500)
    }
  }
}
</script>
<style lang="scss" scoped>
#app form {
  .duel-inputs {
    display: flex;
    justify-content: space-between;

    .small-input {
      max-width: calc(50% - 20px)
    }
  }
}
</style>
