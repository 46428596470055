<template>
  <img alt="My Car Check Logo" width="150" v-lazy="imgUrl" />
</template>

<script>
export default {
  name: "DriveScoreLogo",
    data () {
    return {
      imgUrl:  require('@/assets/img/affiliates/mycarcheck.png')
    }
  }
}
</script>

<style scoped>

</style>
