<template>
    <section>
      <div :class="isBottomFix ? ['privacy-banner', 'privacy-banner-fixed'] : ['privacy-banner']">
        <span v-html="label"></span>&nbsp;<a :id="id" href="/privacy-policy" @click.prevent="showModal('Privacy')">privacy policy.</a>
      </div>

    <Modal v-show="isPrivacyVisible" @close="closeModal">
      <template v-slot:body>
        <Privacy @openModal="openModal" />
      </template>
      <Terms />
    </Modal>

    <Modal v-show="isCookieVisible" @close="closeModal">
      <template v-slot:body>
        <Cookie />
      </template>
      <Terms />
    </Modal>

  </section>
</template>

<script>
import Privacy from '@/components/Privacy.vue'
import Cookie from '@/components/Cookie.vue'

export default {
    props: {
      id: {
        type: String,
        default() {
          return 'a_href_generic_privacybanner'
        }
      },
      label: {
        type: String,
        default: 'By starting your quote you\'re agreeing to our',
      },
      isBottomFix: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        isPrivacyVisible: false,
        isCookieVisible: false,
      }
    },
    components: {
      Privacy,
      Cookie
    },
    methods: {
      openModal(modal) {
        this.isPrivacyVisible = false;

        if (modal === 'cookie-policy') {
          this.isCookieVisible = true;
        }
      },
    },
}
</script>

<style lang="scss" scoped>
  a {
    text-decoration: none;
  }

  .privacy-banner {
    padding: 0px;
    //background: $gray;
    border-radius: 5px;
    font-size: .9em;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media (min-width: 1024px) {
    margin-top: 20px;
    font-size: 1.125rem;
    text-align: left;
    padding: 15px 50px;
  }

  .privacy-banner-fixed {
    position: fixed;
    bottom: 100px;
    left: 5px;

    @media (min-width: 1024px) {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
</style>