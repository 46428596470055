<template>
  <header  >
    <PowerBy :isHeader="true" v-if="swapLogoSide" />

    <AffiliateLogo />
    <PowerByTestB :isHeader="true" v-if="!swapLogoSide" />
  </header>
</template>

<script>
import {LocationHelper} from "../helpers";
import PowerByTestB from "./Affiliates/PowerByTestB";
import {AFFILIATES} from '@/factories';
import AffiliateLogo from '@/components/AffiliateLogo';

export default {
  name: "AffiliateHeaderTestB",
  data() {
    return {
      swapLogoSide: false,
    }
  },
  components: {
    AffiliateLogo,
    PowerByTestB
  },
  mounted() {
    let host = LocationHelper.getHost();

    if (AFFILIATES[host] !== undefined) {
      const affiliateConfig = AFFILIATES[host];

      if (affiliateConfig && affiliateConfig.swapLogoSide !== undefined ) {
        this.swapLogoSide = affiliateConfig.swapLogoSide;
      }
    }
  }
}
</script>

<style scoped>

</style>
