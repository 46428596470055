<template>
  <div id="holding_page" >
    Sorry! This page is under development.
  </div>
</template>

<script>

  export default {
    name: 'Generic_Holding_Page',
    props: {

    },
    components: {

    },
    methods:{
 
    }
  }
</script>

<style lang="scss" scoped>
 #holding_page {
    text-align: center;
    padding-top: 25px;
 }
</style>