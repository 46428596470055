<template>
  <img alt="Clearscore Logo" width="150" v-lazy="imgUrl" />
</template>

<script>
  export default {
    name: 'ClearscoreLogo',
    data () {
      return {
        imgUrl: require('@/assets/img/affiliates/clear-score-logo.png')
      }
    }
  }
</script>

<style scoped>

</style>