<template>
  <div>
    <div v-if="isTealiumEnabled">
      <script type="application/javascript" src="/tealium/setting.js"></script>
      <script type="application/javascript" :src="scriptSource"></script>
    </div>
  </div>
</template>

<script>
  import {LocationHelper} from '@/helpers';
  import {TealiumService} from '@/services';

  export default {
    name: "Tealium",
    data() {
      return {
        isProduction: process.env.NODE_ENV === 'production',
        isTealiumEnabled: TealiumService.isTealiumEnabled(),
        isCompareTheMarketCarFinance: LocationHelper.getHost() === 'comparethemarketcarfinance'
      }
    },
    computed: {
      scriptSource() {
        if (this.isProduction) {
          return `/affiliates/${TealiumService.getTealiumScriptFolder()}/tealium-prod.js`;
        }

        return `/affiliates/${TealiumService.getTealiumScriptFolder()}/tealium-dev.js`;
      },
    },
    mounted() {
      TealiumService.initalData();
    },
  }
</script>

<style scoped>

</style>