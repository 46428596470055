<template>
  <img alt="Confused.com Logo" height="25" v-lazy="imgUrl" />
</template>

<script>
  export default {
    name: 'ConfusedLogo',
    data () {
      return {
        imgUrl: require('@/assets/img/affiliates/confused.svg')
      }
    }
  }
</script>

<style scoped>

</style>