
<template>
<div v-if="visible">
  <button :id="id" type="submit" class="button alpha" :disabled="false">
    <span :class="subLabel ? '' : 'button-text'">
      <slot><strong>Get Your Quote!</strong></slot>
    </span>
    <div>{{ subLabel }}</div>
  </button>
</div>

</template>

<script>
export default {
    name: 'ButtonGetYourQuote',
    props: {
      id: {
        type: String,
        default() {
          return 'button_generic_getyourquote'
        }
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      subLabel: {
        type: String,
        default: '',
      },
      visible: {
        type: Boolean,
        default: true
      }
    }
}
</script>

<style scoped>
  .button-text {
    position: relative;
    top: 4px;
  }
</style>
