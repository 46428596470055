<template>
  <img class="piston-head-logo" alt="Piston Heads Logo" height="25" v-lazy="imgUrl" />
</template>

<script>
export default {
  name: "PistonHeadsLogo",
  data () {
    return {
      imgUrl:  require('@/assets/img/affiliates/pistonheads-logo.svg')
    }
  }
}
</script>

<style scoped>
  .piston-head-logo {
    position: relative;
    top: 5px;
  }
</style>
