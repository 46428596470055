<template>
  <form @submit.prevent="Submit(nextPage)">

    <InputGroup
      id="driving-licence"
      name="fullLicence"
      :label="getLabel"
      :items="[
        {label: 'Yes', value: 35, canCoutinue: canCoutinue(), id: idConcat(pageName,'Yes') },
        {label: 'No', value: false, canCoutinue: false, id: idConcat(pageName,'No') }
      ]"
      v-model="$v.drivingLicence.$model"
      :v="$v.drivingLicence"
      @input="updateDrivingLicence"
      :submitFunction="continueBtnClick"
    />

    <InputGroup
      id="driving-licence-type"
      columns
      v-if="fullLicence === false"
      name="form.drivingLicenceTypeId"
      label="What licence do you have?"
      :items="[
        {label: 'None', value: 38, canCoutinue: canCoutinue(), id: idConcat(pageName,'None') },
        {label: 'Provisional UK', value: 36, canCoutinue: canCoutinue(), id: idConcat(pageName,'ProvisionalUK') },
        {label: 'EU', value: 37, canCoutinue: canCoutinue(), id: idConcat(pageName,'EU') },
        {label: 'International', value: 39, canCoutinue: canCoutinue(), id: idConcat(pageName,'International') },
      ]"
      v-model="$v.drivingLicence.$model"
      :v="$v.drivingLicence"
      :submitFunction="continueBtnClick"
    />

    <div v-if="isStartPage">
      <ButtonContinue :id="idConcat(pageName,'ContinueBtn')" v-if="isClassifyPartner" :visible="visibleCoutinueButton()" :sub-label="$t('page.finance_details.button_continue.sub_label')">{{ isClassifyPartner ? 'Continue' : $t('page.finance_details.button_continue.inner_text') }}</ButtonContinue>
      <IconButtonContinue v-else :id="idConcat(pageName,'IconContinueBtn')" :visible="visibleCoutinueButton()" :sub-label="$t('page.finance_details.button_continue.sub_label')" label="Get my quote" />

      <ButtonBack :id="idConcat(pageName,'BackBtn')" v-show="showStartPageBackButton" :is-call-back-handle="true" @callback="startPageBackButtonCallBack" />

      <RepExample v-if="isRepExampleAbovePrivacyBanner"></RepExample>
      <div style="font-size: 0.9em; text-align: center;" v-html="$t('page.finance_details.message.label')"></div>
      <PrivacyBanner :id="idConcat(pageName,'PrivacyBannerHref')" :label="isAffiliate && !isCarcraft ? 'By starting your quote you\'re agreeing to Car Finance 247\'s' : $t('page.finance_details.privacy_banner.label')" />
      <div style="font-size: 0.9em; text-align: center;">
        {{ $t('page.finance_details.trade.label') }}
      </div>
      <PowerByCf247Text v-if="!testB" />
      <RepExample v-if="!isRepExampleAbovePrivacyBanner"></RepExample>
      <DisclosureStatement/>
    </div>
    <div v-else>
      <ButtonContinue :id="idConcat(pageName,'ContinueBtn')" :visible="visibleCoutinueButton()" />
      <ButtonBack :id="idConcat(pageName,'BackBtn')" v-show="!isAffiliatePersonalised && !isIncludeVehicle" />
      <div v-show="isAffiliatePersonalised">
        <RepExample v-if="isRepExampleAbovePrivacyBanner"></RepExample>
        <div style="font-size: 0.9em; text-align: center;" v-html="$t('page.finance_details.message.label')"></div>
        <PrivacyBanner :label="isAffiliate && !isCarcraft ? 'By starting your quote you\'re agreeing to Car Finance 247\'s' : $t('page.finance_details.privacy_banner.label')" />
        <div style="font-size: 0.9em; text-align: center;">
          {{ $t('page.finance_details.trade.label') }}
        </div>
        <PowerByCf247Text v-if="!testB" />
        <RepExample v-if="!isRepExampleAbovePrivacyBanner"></RepExample>
        <DisclosureStatement/>
      </div>
    </div>

    <InfoFooter :showTopOnly="!isStartPage"  />
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {AffiliatesHelper, InterstitialHelper, LocationHelper, RequestHelper, ReorderHelper} from '@/helpers';
import {AFFILIATES } from '@/factories';
import PowerByCf247Text from '@/components/PowerByCf247Text';
import DisclosureStatement from '@/components/DisclosureStatement.vue';
import RepExample from '@/components/RepExample.vue';
import IconButtonContinue from '@/components/IconButtonContinue.vue';
import {AnalyticsService} from '@/services';

export default {
  components: {
    IconButtonContinue,
    DisclosureStatement,
    RepExample,
    PowerByCf247Text,
  },
  data() {
    return {
      pageName: 'DrivingLicence',
      isStartPage: false,
      testB: false,
      isCompareTheMarket: LocationHelper.getHost() === 'comparethemarketcarfinance',
      isCarcraft: LocationHelper.getHost().startsWith('carcraft'),
      skipFirstPage: RequestHelper.getQueryVariable('test') === 'skip-first-page' || LocationHelper.getHost().startsWith('ckdirect'),
      pageViewSubmitted: false,
      drivingLicenceLookup: [
        {label: 'Full UK Licence', value: 35},
        {label: 'None', value: 38},
        {label: 'Provisional UK', value: 36},
        {label: 'EU', value: 37},
        {label: 'International', value: 39}
      ],
      isRepExampleAbovePrivacyBanner: LocationHelper.getHost() === 'comparethemarketcarfinance'
    }
  },
  watch: {
    drivingLicence: function(val) {
      this.drivingLicenceChangeEvent(val);
    },
  },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);
    
    if( this.$store.state.fromGetMyQuote ) {
      this.isStartPage = false ;
    } else {
      let host = LocationHelper.getHost();
      let vehicle_type = RequestHelper.getQueryVariable('vehicle_type');
      if (vehicle_type) {
        this.isStartPage = true;
      }
      if (AFFILIATES[host] !== undefined) {
        this.isStartPage = false;

        if (this.isCarcraft) {
          this.isStartPage = true;
        }

        this.testB = AFFILIATES[host].testB ?? false;
      }

      let personalised = RequestHelper.getQueryVariable('personalised');

      if (personalised && AFFILIATES[host] !== undefined) {
        this.isStartPage = false;
      }
    }

    if (this.skipFirstPage) {
      this.isStartPage = true;
    }

    this.$store.dispatch('updateFormValue', [true, 'form.financeDetailsCompleted'])
    this.$store.dispatch('updateFormValue', [false, 'form.drivinglicenceCompleted'])

    if (this.drivingLicence && this.$v.drivingLicence.$dirty) {
      this.drivingLicenceChangeEvent(this.drivingLicence);
    }

    if (!this.pageViewSubmitted) {
      this.pageViewSubmitted = true;
      AnalyticsService.trackPageView({
        page_name: 'driving-licence'
      });
    }
  },
  computed: {
    isAffiliate() {
      return AffiliatesHelper.isAffiliate(AffiliatesHelper.getAffiliateWithLanguageFile()) || false;
    },
    showStartPageBackButton() {
      let host = LocationHelper.getHost();

      if (AFFILIATES[host] !== undefined) {
        const affiliateConfig = AFFILIATES[host];

        const showInterstitial = InterstitialHelper.showInterstitial(affiliateConfig);

        if (showInterstitial) {
          return false;
        }
      }

      return false;
    },
    fullLicence() {
      return this.$store.getters.any('fullLicence')
    },
    drivingLicence: {
      get: function() {
        return this.$store.getters.any('form.drivingLicenceTypeId')
      },
      set: function() {
      }
    },
    isClassifyPartner() {
      let host = LocationHelper.getHost();
      let vrm = RequestHelper.getQueryVariable('vrm');
      let advertId = RequestHelper.getQueryVariable('advertId');
      let vehicleId = RequestHelper.getQueryVariable('vehicleId');

      if (AFFILIATES[host] !== undefined){
        const affiliateConfig = AFFILIATES[host];

        if (affiliateConfig.classifiedPartner && (affiliateConfig.showPartnerModal || vrm || advertId || vehicleId)) {
          return true;
        }
      }

      return false;
    },
    nextPage() {
      return { name: 'address', params: { number: 1 } }
    },
    getLabel() {
      let vehicle_type = RequestHelper.getQueryVariable('vehicle_type');

      let label = 'Do you have a full UK driving licence?';

      if (this.isAffiliatePersonalised) {
        const name = this.$store.getters.any('form.firstName');
        label = `Thanks ${name}, do you have a full UK driving licence?`;
      }

      if (this.isNonAffiliatePersonalised && vehicle_type) {
        const name = this.$store.getters.any('form.firstName');
        label = `Thanks ${name}, do you have a full UK driving licence?`;
      }

      return label;
    },
    isAffiliatePersonalised() {
      let host = LocationHelper.getHost();
      return RequestHelper.getQueryVariable('personalised') && AFFILIATES[host] !== undefined;
    },
    isNonAffiliatePersonalised() {
      let host = LocationHelper.getHost();
      return RequestHelper.getQueryVariable('personalised') && AFFILIATES[host] === undefined;
    },
    isIncludeVehicle() {
      let vehicleid = RequestHelper.getQueryVariable('vehicleid');
      let vrm = RequestHelper.getQueryVariable('vrm');
      let advertId = RequestHelper.getQueryVariable('advertId');
      let vehicleType = RequestHelper.getQueryVariable('vehicle_type');

      if (vehicleid || vrm || advertId || vehicleType) {
        return true;
      }

      return false;
    },
  },
  methods: {
    startPageBackButtonCallBack() {
      document.location.href= "/" + window.location.search;
    },
    drivingLicenceChangeEvent(val) {
      this.drivingLicenceLookup.forEach((item) => {
        if (item.value === val) {
          const data = item.label.toLowerCase().replaceAll(' ', '_');
          AnalyticsService.setData('driving_licence', data, false);
        }
      });
    },
    updateDrivingLicence() {
      if(this.$store.getters.any('fullLicence')){
        let data = [35, 'form.drivingLicenceTypeId']
        this.$store.dispatch('updateFormValue', data)
      } else {
        let data = [38, 'form.drivingLicenceTypeId']
        this.$store.dispatch('updateFormValue', data)
      }
    },
    continueBtnClick: function() {
      setTimeout(()=> {
        if( ReorderHelper.isReorderMulti(['cargurus', 'pistonheads']) || ReorderHelper.isGenericReorder()) {
          let result = ReorderHelper.getNextRoute('generic', this.$router.currentRoute.name  );
            if(Object.keys(result).length> 0 ){
              this.Submit(result)
            } 
        } else {
          this.Submit({ name: 'marital-status' })
        }
      },100)
    }
  },
  validations() {
      return {
        drivingLicence: {required},
      }
    }
}
</script>

