import {LocationHelper, StringHelper} from '@/helpers/index';
import {AFFILIATES} from '@/factories';

const host = LocationHelper.getHost();
export default {
    isAffiliate(excludeHost = []) {
        return ((host.startsWith('carcraft') && AFFILIATES['carcraft'] !== undefined) || AFFILIATES[host] !== undefined) && !excludeHost.includes(host);
    },
    getAffiliateWithLanguageFile() {
        return [
            'ckdirect',
            'ckdirect-submission',
            'ckdirect-submission-whitelabel',
            'ckdirect-whitelabel',
            'clearscore',
            'comparethemarketcarfinance',
            'confused',
            'credapp',
            'dragonfinance',
            'drivescore',
            'equifax',
            'oceancar',
            'oceancar-submission',
            'parkers',
            'thinkmoneycar',
        ];
    },
    getPropertyValue(property) {
        if (this.isAffiliate()) {
            let affiliateConfig = AFFILIATES[host];

            if (host.startsWith('carcraft')) {
                affiliateConfig = AFFILIATES['carcraft'];
            }

            if (affiliateConfig && typeof affiliateConfig[property] !== "undefined") {
                return affiliateConfig[property];
            }
        }

        return null;
    },
    getPartnerName(state) {
        let name = '';

        if (state && state.isDealer && state.dealerName) {
            return state.dealerName ? state.dealerName : "We";
        }

        let host = LocationHelper.getHost();
        if (AFFILIATES[host] !== undefined) {
            const affiliateConfig = AFFILIATES[host];
            if (affiliateConfig && affiliateConfig.companyName !== undefined ) {
                name = affiliateConfig.companyName;
            }
        }

        if (name === '') {
            name = StringHelper.toUpperCaseFirstLetter(host);
        }

        return name;
    },
    checkHostForUtmSource() {
        return StringHelper.equalsIgnoringCase(host, 'clearscore') ||
                StringHelper.equalsIgnoringCase(host, 'confused') ||
                StringHelper.equalsIgnoringCase(host, 'comparethemarketcarfinance');
    },
}