<template>
    <section>
      <div role="group" :id="id" :aria-label="label" class="input-group" :class="{ 'columns': columns }">
        <h1 v-if="label">{{ label }}</h1>
        <div v-if="subLabel">
          {{ subLabel }}
          <a v-if="showToolTip" class="tooltip" v-popover:info.right>
            <svg class="icon-info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="#000000" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
            </svg>
          </a>
        </div>
        <popover name="info" event="click">
          What if the title options don’t apply to me? We understand that not everyone may identify with these titles, however our panel of providers have asked you to select one. We want everyone to feel included and are working hard to correct this. For the time being, please refer to your birth certificate or gender recognition certificate to help select the option which best matches you and any other people you tell us about. Your answer won’t impact the prices or results available to you. Thank you for your patience.
        </popover>
        <template  v-for="(item, index) in items">
          <input :class="maskInput ? SENSITIVE_DATA.p11Mask : ''" type="radio" :disabled="$store.state.lockDown" v-model="value" :id="item.id" :value="item.value" :key="index" @click="(event) => coutineClick(event, item.canCoutinue)">
          <label :class="maskInput ? 
                          isNewApplyBtn ? ['abtestNewApplyBtn'].concat(SENSITIVE_DATA.p11Mask) : SENSITIVE_DATA.p11Mask 
                          : 
                          isNewApplyBtn ? 'abtestNewApplyBtn' : ''" 
                  :for="item.id" :key="'label'+index" >{{ item.label }}</label>
        </template>
      </div>
      <p class="error" v-if="this.v.$error">Please choose an option to continue.</p>
    </section>
</template>

<script>
import {SENSITIVE_DATA} from '@/const';
import {NewApplyBtnHelper} from '@/helpers';

export default {
    props: {
        id:{
          type: String,
          default() {
            return 'input-group-' + this._uid
          }
        },
        items: {
          type: Array
        },
        name: {
          type: String,
          default: ''
        },
        label: {
          type: String,
          default: ''
        },
        subLabel: {
          type: String,
          default: ''
        },
        columns: {
          type: Boolean,
          default: false
        },
        v: {
          type: Object,
          default: function () { return {} }
        },
        submitFunction: {
          type: Function
        },
        showToolTip: {
          type: Boolean,
          default: false
        },
        maskInput: {
          type: Boolean,
          default: false
        }
    },
    methods: {
      coutineClick: function(event, canCoutinue) {
        if(this.submitFunction && canCoutinue) {
          this.submitFunction();
        }
      }
    },
    computed: {
      SENSITIVE_DATA() {
        return SENSITIVE_DATA
      },
      value: {
        get: function () {
          
          let name = this.name
          let obj = this.$store.state
          let get = _.get(obj, name)
          return get

        },
        set: function (newValue) {
          let data = [newValue, this.name]
          this.$store.dispatch('updateFormValue', data)
          this.$emit('input', newValue)
          return newValue

        }
      },
      isNewApplyBtn() {
        return NewApplyBtnHelper.isNewApplyBtn();
      }
    }
}
</script>

<style lang="scss" scoped>
  #app form {
    section {
      padding: 0px 0 0;
    }

    h1 {
      margin-top: 0px;
      margin-bottom: 40px;
      width: 100%
    }

    .input-group {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;

      div {
        margin-top: 0px;
        margin-bottom: 20px;
        width: 100%
      }

      &.columns {
        justify-content: space-between;
        align-items: center;

        label {
          min-width: 40%;
          max-width: calc(50% - 10px);
          margin-right: 0;

          &:nth-of-type(odd) {
            margin-right: 10px
          }

          &:nth-of-type(even) {
            margin-left: 9px /* Safari Rendering bug fix */
          }
        }
      }

      label {
        padding: 12.5px;
        max-height: 65px;
        font-size: 1.2rem;
        border: 1px solid $label;
        border-radius: 2rem;
        background: $label;
        flex-grow: 1;
        text-align: center;
        margin: 0 20px 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;

        &:hover {
          cursor: pointer;
        }

        /*
        &:last-of-type {
          margin-right: 0;
        }
        */
      }

      .abtestNewApplyBtn {
        box-shadow: 0px 2px 0px 0px #604DD533;
        background: rgba(96, 77, 213, 0.1);
        color: rgba(96, 77, 213, 1);
        font-weight: 700;
        font-size: 20px;
        line-height: 22.04px;
      }

      input {
        opacity: 0;
        height: 0;
        width: 0;
        margin: 0;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:checked + label {
          border: 1px solid $electric-purple;
          color: $electric-purple;
          background: #fff;
        }

        &:checked + .abtestNewApplyBtn {
          background: rgba(96, 77, 213, 1);
          color: #fff;
          box-shadow: none;
        }

        &:focus + label {
          box-shadow: 0 0 0 2px $electric-purple;
        }
      }
      
    }

    .icon-info {
      width: 15px;
      position: relative;
      top: 1px;
    }

    .icon-info path {
      fill: #000000;
    }

    .tooltip {
      background-color: white;
      border: none;
      color: white;
      text-align: center;
      text-decoration: none;
      display: inline-block;
    }

    [data-popover='info'] {
      font-size: 14px;
      line-height: 1.5;
      margin: 5px;
    }

    .vue-popover {
      display: block;
      position: absolute;
      background: #fff;
      box-shadow: 0px 4px 20px 0px rgba(52,73,94,0.2);
      padding: 5px;
      border-radius: 5px;
      z-index: 998 !important;
      width: 180px;
    }

    @media only screen and (min-width: 413px) {
      .vue-popover {
        display: block;
        position: absolute;
        background: #fff;
        box-shadow: 0px 4px 20px 0px rgba(52,73,94,0.2);
        padding: 5px;
        border-radius: 5px;
        z-index: 998 !important;
        width: 300px !important;
      }
    }

    @media only screen and (max-width: 412px) {
      .vue-popover {
        display: block;
        position: absolute;
        background: #fff;
        box-shadow: 0px 4px 20px 0px rgba(52,73,94,0.2);
        padding: 5px;
        border-radius: 5px;
        z-index: 998 !important;
        width: 250px !important;
      }
    }

    @media only screen and (max-width: 360px) {
      .vue-popover {
        display: block;
        position: absolute;
        background: #fff;
        box-shadow: 0px 4px 20px 0px rgba(52,73,94,0.2);
        padding: 5px;
        border-radius: 5px;
        z-index: 998 !important;
        width: 225px !important;
      }
    }

    @media only screen and (max-width: 320px) {
      .vue-popover {
        display: block;
        position: absolute;
        background: #fff;
        box-shadow: 0px 4px 20px 0px rgba(52,73,94,0.2);
        padding: 5px;
        border-radius: 5px;
        z-index: 998 !important;
        width: 180px !important;
      }
    }
  }
</style>