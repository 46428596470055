<template>
  <div></div>
</template>

<script>
  import {LocationHelper} from '@/helpers';
  import {TealiumService} from '@/services';
  import {CONSENT_STATE} from '@/const';

  export default {
    name: "ConsentManager",
    data() {
      return {}
    },
    methods: {
      showTealiumConsentManager() {
        TealiumService.getConsentState().then((consentState) => {
          if (consentState === CONSENT_STATE.NONE) {
            // Do Nothing - Consent Manager will be shown by default
          }
        });
      },
    },
    mounted() {
      const host = LocationHelper.getHost();
      switch (host) {
        case 'comparethemarketcarfinance':
          this.showTealiumConsentManager();
          break;
        default:
          break;
      }
    },
  }
</script>

<style scoped>

</style>