<template>
    <section>
        <p v-if="!first && $store.getters.totalAddressHistory < requiredYears">Next, we'll ask for another {{ needed }} of your address history.</p>
        <p v-if="!first && $store.getters.totalAddressHistory >= requiredYears">Great, that's the last address we need.</p>
    </section>
</template>

<script>
export default {
    data() {
        return {
            requiredYears: 3
        }
    },
    props: {
        first: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        needed() {
            let value = this.requiredYears - this.$store.getters.totalAddressHistory
            let totalDays = value * 365
            let years = Math.floor(totalDays/365)
            let months = Math.floor((totalDays-(years *365))/30)
            let result = ''

            if(years !== 0){
                if(years > 1) {
                    result = years + ' years'
                 } else {
                    result = years + ' year'
                }
            }

            if(years > 0 && months > 0){
                result = result + ' and '
            }
            
            if(months !== 0){
                if(months > 1) {
                    result = result + months + ' months'
                 } else {
                    result = result + 'month'
                }
            }
            return result
        }
    }
}
</script>
