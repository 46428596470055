import axios from 'axios'
import { CONSTANTS, URLS } from '../factories'

export default {
    submit(state, message, stackTrace) {
        let tokenString = 'Bearer ' + state.token.authenticationToken
        let headersObj = {"Authorization": tokenString}

        const errorApiUrl = state.isProduction ? URLS.production.errorApiUrl : URLS.replica.errorApiUrl;

        const exception = {
            message: message,
            stackTrace: stackTrace
        };

        return axios({
            method: 'POST',
            url: errorApiUrl + '/errors/' + CONSTANTS.clientId,
            headers: headersObj,
            data: exception
        })
    }
}