<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
      <div class="button-wrapper">
        <button
          type="button"
          class="btn-close"
          @click="close"
          aria-label="Close modal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
          >
            <path
              d="M8.586 10L.72 2.136A1 1 0 0 1 2.136.72L10 8.586 17.864.72a1 1 0 0 1 1.415 1.415L11.414 10l7.865 7.864a1 1 0 0 1-1.415 1.415L10 11.414 2.136 19.28A1 1 0 0 1 .72 17.864L8.586 10z"
            ></path>
          </svg>
        </button>
        </div>
        <section class="modal-body modal-container" id="modalDescription">
          <slot name="body"></slot>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "modal",
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.button-wrapper {
  display: block;

      @media (max-width: 800px) {
        position: fixed;
        right: 20px;
        z-index: 9999;
    }
}
.modal {
  background: #fff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  border-radius: 4px;
  width: 100%;
  padding: 25px;
  position: relative;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  margin: 25px auto;
  max-width: 800px;
  max-height: 80%;

  @media screen and (max-height: 690px),
         screen and (max-width: 412px) {
    max-height: 100%;
  }

  &-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    z-index: 11;
    overflow: hidden;
  }

  &-header {
    display: flex;
    justify-content: space-between;
  }

  &-body {
    position: relative;
  }

  header {
    padding: 0;
    margin: 0;
  }

  h1 {
    margin: 0;
  }

  .btn-close {
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    background: transparent;
    position: relative;
    z-index: 1;
    top: 0;
    right: 0;
    float: right;
    border-radius: 20px;
    transition: 0.3s ease-in-out;

    svg {
      display: block;
    }

    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.modal-fade-leave-active {
  -webkit-animation: vodal-fade-leave both ease-out;
  animation: vodal-fade-leave both ease-out;
}

.modal-container {
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  width:100%;
}
</style>
