<template>
  <form @submit.prevent="Submit(nextPage)">

    <h1 v-if="headerOne">
      <span>{{ headerOne }}</span>
    </h1>

    <p class="employment-contact">
      (We won't contact your employer)
    </p>

    <InputText
      :id="idConcat(pageName, 'JobTitle')"
      v-if="status !== 'no-employer'"
      :name="'form.employments['+index+'].occupation'"
      label="Your job title"
      invalidMessage="Please tell us what you do for a job."
      v-model="$v.occupation.$model"
      :v="$v.occupation"
      :sensitive-data="true"
    />

    <InputText
      :id="idConcat(pageName, 'Employer')"
      v-if="status === 'default'"
      :name="'form.employments['+index+'].employerName'"
      label="Which company do you work for?"
      invalidMessage="Please tell us who you work for."
      v-model="$v.employerName.$model"
      :v="$v.employerName"
      :sensitive-data="true"
    />

    <InputText
      :id="idConcat(pageName, 'EmployerTown')"
      v-if="status !== 'no-employer' && !isDealer"
      :name="'form.employments['+index+'].employerTown'"
      :label="townLabel"
      :invalidMessage="townInvalidMessage"
      v-model="$v.employerTown.$model"
      :v="$v.employerTown"
      :sensitive-data="true"
    />

    <ButtonContinue :id="idConcat(pageName, 'ContinueBtn')" />

    <ButtonBack :id="idConcat(pageName, 'BackBtn')" />

    <InfoFooter :showTopOnly="true" />
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {LocationHelper, RequestHelper, DealersHelper} from '@/helpers';
import {AFFILIATES} from '@/factories';
import {AnalyticsService } from '../services';

let routeNo, index

export default {
  beforeRouteEnter (to, from, next) {
    routeNo = to.params.number
    index = to.params.number - 1
    next()
  },
  beforeRouteUpdate (to, from, next) {
    routeNo = to.params.number
    index = to.params.number - 1
    next()
  },
  components: {

  },
  data() {
    return {
      pageName: 'EmploymentLocation',
      routeNo: routeNo,
      index: index,
      occupation: this.$store.state.form.employments[index].occupation,
      employerName: this.$store.state.form.employments[index].employerName,
      employerTown: this.$store.state.form.employments[index].employerTown,
      pageViewSubmitted: false,
    }
  },
  validations() {
    if(this.status === 'no-employer'){
      return {
        occupation: {},
        employerName: {},
        employerTown: {},
      }
    }
    else if(this.status === 'no-name'){
      if (this.isDealer) {
        return {
          occupation: {required},
          employerName: {},
          employerTown: {},
        }
      }

      return {
        occupation: {required},
        employerName: {},
        employerTown: {required},
       }
    }
    else {
      if (this.isDealer) {
        return {
          occupation: {required},
          employerName: {required},
          employerTown: {},
        }
      }

      return {
        occupation: {required},
        employerName: {required},
        employerTown: {required},
      }
    }
  },
  computed: {
    isDealer() {
      return DealersHelper.isDealer();
    },
    status() {
      let v = this.$store.getters.employmentStatus(index)
      let status
      if(v === 9 || v === 11 || v === 14 || v === 15 || v === 20){
        status = 'default'
      }
      if(v === 10 || v === 18){
        // remove employer name
        status = 'no-name'
      }
      if(v === 13 || v === 19 || v === 12 || v === 16 || v === 17 || v === 21){
        // remove employer name, town and occupation
        status = 'no-employer'
      }
      return status
    },
    statusName() {
      let v = this.$store.getters.employmentStatus(index)
      let status
      if(v === 10){status = 'self-employed'}
      if(v === 13){status = 'retired'}
      if(v === 19){status = 'a student'}
      if(v === 12){status = 'on disability living allowance'}
      if(v === 16){status = 'a homemaker'}
      if(v === 17){status = 'unemployed'}
      if(v === 21){status = 'a non professional carer'}
      if(v === 18){status = 'in the armed forces'}
      return status
    },
    isNonAffiliatePersonalised() {
      let host = LocationHelper.getHost();
      return RequestHelper.getQueryVariable('personalised') && AFFILIATES[host] === undefined;
    },
    headerOne() {

      let status = "Great! What's your current job title?"

      if (this.isNonAffiliatePersonalised) {
        const name = this.$store.getters.any('form.firstName');
        status = `Great! What's your current job title ${name}?`;
      }

      if(index !== 0){status = 'And where did you work previously?'}

      return status
    },
    townLabel() {
      let v = this.$store.getters.employmentStatus(index)
      if (v === 10 || v === 18) return 'Town'
      return 'Where is your workplace based?'
    },
    townInvalidMessage() {
      let v = this.$store.getters.employmentStatus(index)
      if (v === 10 || v === 18) return 'Please tell us which town you work in.'
      return 'Please tell us which town you work in.'
    },
    nextPage() {
      if (this.isDealer) {
        return { name: 'employment-address-add' , params: { number: Number(routeNo) } }
      }

      return { name: 'employment-length' , params: { number: Number(routeNo) } }
    }
  },
  methods: {
    warnIncomeValue(input) {
      if (typeof(input) !== undefined && input !== null && input !== '') {
        const income = parseInt(input.replace(",", ""));
        if (income < 900 || income > 10000) {
          this.errorElementId = 'input-borrow'
          return true
        } else {
          this.errorElementId = ''
          return false
        }
      }
      return false;
    },
    debounceInput(event) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.showWarning = this.warnIncomeValue(event.target.value);
      }, 1000)
    }
  },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);
    
    this.$store.dispatch('updateFormValue', [true, 'form.employmentStatusCompleted'])

    if (!this.pageViewSubmitted) {
      this.pageViewSubmitted = true;
      AnalyticsService.trackPageView({
        page_name: 'employment-location'
      });
    }
  }
}
</script>

<style>
  .employment-contact {
    font-size: 1.4rem !important;
  }
</style>
