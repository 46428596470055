<template>
  <img alt="Honest John Logo" height="16"  v-lazy="imgUrl" />
</template>

<script>
export default {
  name: "HonestJohnLogo",
    data () {
    return {
      imgUrl:  require("@/assets/img/affiliates/HonestJohn-FullLogo-FullColour-NoBackground.png")
    }
  }
}
</script>

<style scoped>

</style>
