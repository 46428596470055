<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      height="27.995001"
      width="137"
      id="svg42"
      version="1.1"
      viewBox="0 0 137 27.995001">
    <g
        transform="matrix(0.15518276,0,0,0.15518276,-0.00844817,-4.6830224e-7)"
        data-name="Layer 2"
        id="Layer_2">
      <g
          id="SMILE">
        <rect
            id="rect8"
            height="84.510002"
            width="126.45"
            y="68.540001"
            x="28.209999"
            class="cls-1" />
        <path
            id="path10"
            d="m 250.27,75.83 a 15,15 0 0 0 -3.27,-3.75 15.78,15.78 0 0 0 -4.51,-2.52 15.58,15.58 0 0 0 -5.28,-0.9 17.43,17.43 0 0 0 -8.55,2 16.25,16.25 0 0 0 -5.7,5.22 23.27,23.27 0 0 0 -3.18,7.46 37.43,37.43 0 0 0 -1,8.65 34.75,34.75 0 0 0 1,8.31 22.81,22.81 0 0 0 3.18,7.27 16.3,16.3 0 0 0 5.7,5.17 17.43,17.43 0 0 0 8.55,2 q 6.84,0 10.69,-4.18 a 19.31,19.31 0 0 0 4.7,-11 H 267 a 34.55,34.55 0 0 1 -2.94,11.5 27.62,27.62 0 0 1 -6.27,8.74 26.87,26.87 0 0 1 -9.12,5.51 33.63,33.63 0 0 1 -11.5,1.9 34.87,34.87 0 0 1 -14,-2.71 30,30 0 0 1 -10.5,-7.46 33,33 0 0 1 -6.55,-11.16 41,41 0 0 1 -2.28,-13.82 42.39,42.39 0 0 1 2.28,-14.11 33.85,33.85 0 0 1 6.55,-11.35 29.88,29.88 0 0 1 10.5,-7.6 36.31,36.31 0 0 1 24.61,-1.14 28.57,28.57 0 0 1 8.93,4.7 25.54,25.54 0 0 1 6.5,7.65 27.26,27.26 0 0 1 3.23,10.45 H 252 a 12.14,12.14 0 0 0 -1.73,-4.83 z"
            class="cls-2" />
        <path
            id="path12"
            d="m 274.21,91.56 a 15.62,15.62 0 0 1 2.38,-7.89 16,16 0 0 1 5.32,-5 23.05,23.05 0 0 1 7.27,-2.71 41.11,41.11 0 0 1 8.12,-0.81 56.29,56.29 0 0 1 7.5,0.52 22.8,22.8 0 0 1 6.94,2 13.66,13.66 0 0 1 5.13,4.22 11.84,11.84 0 0 1 2,7.18 v 25.55 a 52.06,52.06 0 0 0 0.38,6.37 11.75,11.75 0 0 0 1.33,4.56 h -13.69 a 18.55,18.55 0 0 1 -0.61,-2.33 21.35,21.35 0 0 1 -0.34,-2.42 17.51,17.51 0 0 1 -7.6,4.65 30.38,30.38 0 0 1 -8.93,1.33 24.07,24.07 0 0 1 -6.55,-0.85 15,15 0 0 1 -5.32,-2.66 12.43,12.43 0 0 1 -3.56,-4.56 15.35,15.35 0 0 1 -1.29,-6.56 14.24,14.24 0 0 1 1.48,-6.89 12.28,12.28 0 0 1 3.83,-4.26 16.91,16.91 0 0 1 5.32,-2.42 54.34,54.34 0 0 1 6,-1.28 c 2,-0.32 4,-0.57 6,-0.76 a 35.87,35.87 0 0 0 5.23,-0.86 9.11,9.11 0 0 0 3.59,-1.68 3.65,3.65 0 0 0 1.23,-3.18 7.09,7.09 0 0 0 -0.71,-3.47 5.23,5.23 0 0 0 -1.9,-2 7.59,7.59 0 0 0 -2.75,-1 23.84,23.84 0 0 0 -3.38,-0.23 10.24,10.24 0 0 0 -6.27,1.71 c -1.52,1.14 -2.4,3 -2.66,5.7 z m 31.16,10 a 6.09,6.09 0 0 1 -2.13,1.19 23.14,23.14 0 0 1 -2.76,0.71 q -1.47,0.28 -3.09,0.48 c -1.07,0.12 -2.15,0.28 -3.23,0.47 a 25.67,25.67 0 0 0 -3,0.76 9,9 0 0 0 -2.56,1.28 6,6 0 0 0 -1.76,2 6.48,6.48 0 0 0 -0.67,3.13 6.24,6.24 0 0 0 0.67,3 5.13,5.13 0 0 0 1.8,2 7.75,7.75 0 0 0 2.66,1 17.06,17.06 0 0 0 3.14,0.28 11.86,11.86 0 0 0 6.17,-1.33 8.8,8.8 0 0 0 3.23,-3.18 10.23,10.23 0 0 0 1.29,-3.75 27.45,27.45 0 0 0 0.23,-3 z"
            class="cls-2" />
        <path
            id="path14"
            d="m 341.19,76.45 v 9.12 h 0.19 a 16.43,16.43 0 0 1 2.56,-4.23 17,17 0 0 1 3.71,-3.34 18.14,18.14 0 0 1 4.46,-2.14 16.3,16.3 0 0 1 4.94,-0.76 10.45,10.45 0 0 1 2.95,0.48 v 12.56 c -0.64,-0.13 -1.4,-0.24 -2.28,-0.34 a 25.66,25.66 0 0 0 -2.57,-0.14 14.27,14.27 0 0 0 -6.27,1.24 10.73,10.73 0 0 0 -4.13,3.37 13.36,13.36 0 0 0 -2.23,5 26.7,26.7 0 0 0 -0.67,6.17 v 22.14 H 328.36 V 76.45 Z"
            class="cls-2" />
        <path
            id="path16"
            d="M 413.48,57.74 V 70.28 H 380.71 V 86 h 28.4 v 11.54 h -28.4 v 28 h -14.92 v -67.8 z"
            class="cls-2" />
        <rect
            id="rect18"
            height="12.61"
            width="13.49"
            y="57.740002"
            x="420.13"
            class="cls-2" />
        <rect
            id="rect20"
            height="49.119999"
            width="13.49"
            y="76.449997"
            x="420.13"
            class="cls-2" />
        <path
            id="path22"
            d="m 456.13,76.45 v 6.84 h 0.29 a 15.5,15.5 0 0 1 6.65,-6.22 19.2,19.2 0 0 1 8.36,-1.95 22.93,22.93 0 0 1 8.88,1.47 13.27,13.27 0 0 1 5.46,4.09 15.82,15.82 0 0 1 2.81,6.32 40.3,40.3 0 0 1 0.8,8.32 v 30.21 h -13.49 v -27.7 c 0,-4.06 -0.63,-7.08 -1.89,-9.08 -1.26,-2 -3.52,-3 -6.75,-3 q -5.5,0 -8,3.28 -2.5,3.28 -2.45,10.79 v 25.75 H 443.31 V 76.45 Z"
            class="cls-2" />
        <path
            id="path24"
            d="m 498.12,91.56 a 15.62,15.62 0 0 1 2.38,-7.89 16.09,16.09 0 0 1 5.32,-5 23.05,23.05 0 0 1 7.27,-2.71 41.11,41.11 0 0 1 8.12,-0.81 56.29,56.29 0 0 1 7.5,0.52 22.8,22.8 0 0 1 6.94,2 13.66,13.66 0 0 1 5.13,4.22 11.84,11.84 0 0 1 2,7.18 v 25.55 a 52.06,52.06 0 0 0 0.38,6.37 11.75,11.75 0 0 0 1.33,4.56 H 530.8 a 18.55,18.55 0 0 1 -0.61,-2.33 21.35,21.35 0 0 1 -0.34,-2.42 17.51,17.51 0 0 1 -7.6,4.65 30.38,30.38 0 0 1 -8.93,1.33 24.13,24.13 0 0 1 -6.55,-0.85 15,15 0 0 1 -5.32,-2.66 12.43,12.43 0 0 1 -3.56,-4.56 15.35,15.35 0 0 1 -1.29,-6.56 14.24,14.24 0 0 1 1.48,-6.89 12.28,12.28 0 0 1 3.8,-4.32 16.91,16.91 0 0 1 5.32,-2.42 54.34,54.34 0 0 1 6,-1.28 q 3,-0.48 6,-0.76 a 35.87,35.87 0 0 0 5.23,-0.86 9.11,9.11 0 0 0 3.62,-1.62 3.65,3.65 0 0 0 1.23,-3.18 7.09,7.09 0 0 0 -0.71,-3.47 5.23,5.23 0 0 0 -1.9,-2 7.59,7.59 0 0 0 -2.75,-1 23.84,23.84 0 0 0 -3.38,-0.23 10.24,10.24 0 0 0 -6.27,1.71 c -1.52,1.14 -2.4,3 -2.66,5.7 z m 31.16,10 a 6.09,6.09 0 0 1 -2.13,1.19 23.14,23.14 0 0 1 -2.76,0.71 q -1.47,0.28 -3.09,0.48 c -1.07,0.12 -2.15,0.28 -3.23,0.47 a 25.67,25.67 0 0 0 -3,0.76 9,9 0 0 0 -2.56,1.28 6,6 0 0 0 -1.76,2 6.48,6.48 0 0 0 -0.67,3.13 6.24,6.24 0 0 0 0.67,3 5.13,5.13 0 0 0 1.8,2 7.75,7.75 0 0 0 2.66,1 17.06,17.06 0 0 0 3.14,0.28 11.86,11.86 0 0 0 6.17,-1.33 8.8,8.8 0 0 0 3.23,-3.18 10.23,10.23 0 0 0 1.29,-3.75 27.45,27.45 0 0 0 0.23,-3 z"
            class="cls-2" />
        <path
            id="path26"
            d="m 565.1,76.45 v 6.84 h 0.28 a 15.5,15.5 0 0 1 6.62,-6.22 19.23,19.23 0 0 1 8.36,-1.95 23,23 0 0 1 8.89,1.47 13.27,13.27 0 0 1 5.46,4.09 15.81,15.81 0 0 1 2.8,6.36 39.6,39.6 0 0 1 0.81,8.32 v 30.21 H 584.86 V 97.83 c 0,-4.06 -0.64,-7.08 -1.9,-9.08 -1.26,-2 -3.52,-3 -6.75,-3 q -5.51,0 -8,3.28 -2.49,3.28 -2.47,10.78 v 25.75 H 552.27 V 76.45 Z"
            class="cls-2" />
        <path
            id="path28"
            d="m 629,85.29 a 9.46,9.46 0 0 0 -5.41,1.47 11.57,11.57 0 0 0 -3.56,3.8 16.35,16.35 0 0 0 -2,5.13 27.84,27.84 0 0 0 -0.57,5.56 26.61,26.61 0 0 0 0.57,5.41 16.88,16.88 0 0 0 1.85,5 10.66,10.66 0 0 0 3.47,3.66 9.51,9.51 0 0 0 5.32,1.42 c 3.23,0 5.71,-0.9 7.46,-2.71 a 12.75,12.75 0 0 0 3.27,-7.26 h 13 q -1.34,9.78 -7.6,14.91 -6.26,5.13 -16.06,5.13 a 27,27 0 0 1 -10.06,-1.81 22.37,22.37 0 0 1 -7.84,-5.18 23.53,23.53 0 0 1 -5,-7.93 27.56,27.56 0 0 1 -1.8,-10.12 32.65,32.65 0 0 1 1.66,-10.59 24,24 0 0 1 4.89,-8.46 22.46,22.46 0 0 1 7.89,-5.55 26.78,26.78 0 0 1 10.64,-2 30.87,30.87 0 0 1 8.41,1.14 22.58,22.58 0 0 1 7.22,3.47 17.93,17.93 0 0 1 7.45,14 H 639 q -1.37,-8.48 -10,-8.49 z"
            class="cls-2" />
        <path
            id="path30"
            d="m 674.6,113.79 c 2,2 4.94,2.94 8.74,2.94 a 12.06,12.06 0 0 0 7,-2 8.51,8.51 0 0 0 3.61,-4.32 h 11.87 q -2.82,8.8 -8.71,12.59 a 25.69,25.69 0 0 1 -14.25,3.8 28.15,28.15 0 0 1 -10.45,-1.8 21.77,21.77 0 0 1 -7.88,-5.27 23.67,23.67 0 0 1 -5,-8.17 30,30 0 0 1 -1.76,-10.45 28.45,28.45 0 0 1 1.81,-10.26 23.73,23.73 0 0 1 13.06,-13.68 25.41,25.41 0 0 1 10.21,-2 23.46,23.46 0 0 1 10.93,2.42 21.86,21.86 0 0 1 7.64,6.51 26.81,26.81 0 0 1 4.33,9.31 35,35 0 0 1 1,10.93 h -35.48 q 0.29,6.51 3.33,9.45 z M 689.84,88 c -1.61,-1.78 -4.07,-2.66 -7.36,-2.66 a 11.75,11.75 0 0 0 -5.37,1.09 10.3,10.3 0 0 0 -3.42,2.71 9.9,9.9 0 0 0 -1.8,3.42 15.14,15.14 0 0 0 -0.62,3.23 h 22 A 15.59,15.59 0 0 0 689.84,88 Z"
            class="cls-2" />
        <path
            id="path32"
            d="m 734.29,74 a 24.57,24.57 0 0 1 4.56,-8.55 20.59,20.59 0 0 1 7.65,-5.7 25.92,25.92 0 0 1 10.59,-2 26.44,26.44 0 0 1 8.69,1.42 22.24,22.24 0 0 1 7.27,4.09 19.48,19.48 0 0 1 5,6.55 20.18,20.18 0 0 1 1.85,8.74 21.2,21.2 0 0 1 -1.61,8.65 23,23 0 0 1 -4.29,6.4 36,36 0 0 1 -6,5.08 c -2.25,1.52 -4.51,3 -6.79,4.51 -2.28,1.51 -4.5,3.09 -6.65,4.8 a 29.76,29.76 0 0 0 -5.7,6 h 31.44 v 11.59 h -48.86 a 27.67,27.67 0 0 1 1.66,-10.07 26.89,26.89 0 0 1 4.52,-7.65 39.53,39.53 0 0 1 6.69,-6.22 q 3.86,-2.85 8.13,-5.8 2.17,-1.52 4.65,-3.09 A 30.74,30.74 0 0 0 761.6,89.29 18.51,18.51 0 0 0 765,85 10.57,10.57 0 0 0 766.38,79.59 10,10 0 0 0 763.6,72 a 9.91,9.91 0 0 0 -7.17,-2.7 8.76,8.76 0 0 0 -5,1.37 10.36,10.36 0 0 0 -3.28,3.61 16.86,16.86 0 0 0 -1.76,4.94 28.22,28.22 0 0 0 -0.52,5.37 H 733 A 31.89,31.89 0 0 1 734.29,74 Z"
            class="cls-2" />
        <path
            id="path34"
            d="M 785.65,110.18 V 97.83 l 28.88,-38.76 h 12.07 v 40 h 8.83 v 11.12 h -8.83 v 15.39 h -12.83 v -15.4 z M 813.49,76 796.29,99.08 h 17.48 V 76 Z"
            class="cls-2" />
        <path
            id="path36"
            d="m 873.56,81.87 a 78.32,78.32 0 0 0 -6.75,14.13 100.87,100.87 0 0 0 -4.18,15.2 83.75,83.75 0 0 0 -1.57,14.35 h -14.44 a 90.51,90.51 0 0 1 7,-28.69 A 101.69,101.69 0 0 1 869,71.61 H 837.41 V 59.07 h 45.41 v 11.59 a 47.2,47.2 0 0 0 -9.26,11.21 z"
            class="cls-2" />
        <path
            id="path38"
            d="M 90.2,0 A 90.2,90.2 0 1 0 180.39,90.2 90.19,90.19 0 0 0 90.2,0 Z m 0,151.55 A 61.47,61.47 0 0 1 30.75,105.42 l 16.71,-3.37 a 44.34,44.34 0 0 0 86.72,-17.51 l 16.7,-3.37 A 61.34,61.34 0 0 1 90.2,151.55 Z"
            class="cls-3" />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Logo"
}
</script>
<style scoped>
    .cls-1{fill:#fff;}.cls-2{fill:#010101;}.cls-3{fill:#f26a51;fill-rule:evenodd;}
</style>
