<template>
  <img alt="Carsnitch Logo" height="25" v-lazy="imgUrl" />
</template>

<script>
export default {
  name: "CarsnitchLogo",
    data () {
    return {
      imgUrl:  require('@/assets/img/affiliates/carsnitch-dark-blue.svg')
    }
  }
}
</script>

<style scoped>

</style>
