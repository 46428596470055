
<template>
<div class="formWrapper">
  <div v-bind:class="{ 'small-input': smallInput }" class="input-container">
    <input
        :id="id"
        type="email"
        :name="name"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        v-model.lazy="value"
        :class="this.generateClass"
        :disabled="$store.state.lockDown"
        maxlength="50"
        @keydown.space="(event) => event.preventDefault()"
        v-on:blur="blur"
    >
    <label :for="id" v-if="label" class="labelWrapper" :class="{ 'big-label': bigLabel }"><div class="textlabel">{{ label }}</div></label>
    </div>
    <p class="error" v-if="!this.v.required && this.v.$error">{{ invalidMessage }}</p>
    <p class="error" v-if="!this.v.email && this.v.$error">Please try again. That email address doesn’t look right.</p>
    <p class="small-print" v-if="smallPrint">{{ smallPrint }}</p>
  </div>
</template>

<script>
import {SENSITIVE_DATA} from '@/const';

export default {
  props: {
    id:{
      type: String,
      default() {
        return 'input-email-' + this._uid
      }
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    bigLabel: {
        type: Boolean,
        default: false
    },
    smallInput: {
        type: Boolean,
        default: false
    },
    smallPrint: {
        type: String,
        default: ''
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    placeholder: {
      type: String,
      default: ''
    },
    invalidMessage: {
      type: String,
      default() {
        return 'Please enter your '+ this.label.toLowerCase() +'.'
      }
    },
    v: {
      type: Object,
      default: function () { return {} }
    },
    blur: {
      type: Function,
      default: function () { return {} }
    },
    sensitiveData: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    generateClass() {
      let cssClass = { 'error': this.v.$error, 'valid': !this.v.$invalid };

      if (this.sensitiveData) {
        SENSITIVE_DATA.p11Mask.forEach((p11Mask) => {
          cssClass[p11Mask] = true;
        });
      }

      return cssClass;
    },
    value: {
      get: function(){

          let name = this.name
          let obj = this.$store.state
          let get = _.get(obj, name)
          return get

      },
      set: function(newValue) {

        let data = [newValue, this.name]
        this.$store.dispatch('updateFormValue', data)
        this.$emit('input', newValue)
        return newValue
      }
    }
  },
  methods: {
    setValue(value) {
      let data = [value, this.name]
      this.$store.dispatch('updateFormValue', data)
      this.$emit('input', value)
    },
    throttledMethod: _.debounce( function(value) {
      this.setValue(value)
    }, 1000)
  }
}
</script>