<template>
  <img alt="Car Gurus Logo" height="25" v-lazy="imgUrl" />
</template>

<script>
export default {
  name: "CarGurusLogo",
    data () {
    return {
      imgUrl:  require('@/assets/img/affiliates/cg_logo.png')
    }
  }
}
</script>

<style scoped>

</style>