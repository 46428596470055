<template>
  <div>
    <div v-if="showBanner">
      <div :class="showInterstitial ? '' : 'app-form-container'">
        <HeaderLogo v-show="!showInterstitial" />
      </div>
      <div class="banner banner-text">
        <div :class="showInterstitial ? '' : 'app-form-container'">
          <p v-html="bannerMessage"></p>
        </div>
      </div>
      <div :class="showInterstitial ? '' : 'app-form-container'">
        <Progress v-show="!showInterstitial" />
      </div>
    </div>

    <div :class="showInterstitial ? '' : 'app-form-container'">
      <div v-if="!showBanner">
        <HeaderLogo v-show="!showInterstitial" />
        <Progress v-show="!showInterstitial" />
      </div>

      <div v-show="!showInterstitial" :class="testB ? '-pb-10' : '-mb-20'" style="overflow:hidden;">
          <!-- <div class="trustpilot-container"> -->
            <TrustPilot />
          <!-- </div> -->
      </div>

      <div class="testB-container" style="display: none;">
        <h1>{{$t('component.testB.title').replace('{partner}', partnerName)}}</h1>
        <span>
          {{$t('component.testB.message')}}
        </span>
      </div>

      <VehicleBannerV2 />

      <div :class="showInterstitial ? '' : 'app-form-main'">
        <main>
          <transition name="fade" mode="out-in">
            <router-view :key="$route.fullPath" @sendFullApp="sendFullApp" @setInterstitial="setInterstitial" />
          </transition>
        </main>
      </div>

      <div v-if="!useModelV3">
        <Modal v-show="!showInterstitial && displayModal" @close="closeModal">
          <template v-slot:body>
            <div v-if="host === 'facebook' ">
              <FacebookModalContent @close="closeModal"/>
            </div>
            <div v-else>
              <PartnerRepAprModel @close="closeModal"/>
            </div>
          </template>
        </Modal>
        <Modal v-show="!showInterstitial && partnerTransitionModal" @close="closeModal">
          <template v-slot:body>
            <PartnerTransitionModal @close="closeModal"/>
          </template>
        </Modal>
      </div>

      <div v-else >
        <ModalV3 class="modelV3" v-if="displayModal "  @close="closeModal">
          <template v-slot:body>
            <PartnerRepAprModelV3  @close="closeModal"/>
          </template>
        </ModalV3>

        <ModalMobile class="modelMobile" v-if="displayModal" @close="closeModal">
          <template v-slot:body>
            <PartnerRepAprModelMobile  @close="closeModal"/>
          </template>
        </ModalMobile>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import Modal from '@/components/Modal.vue'
  import ModalV3 from '@/components/ModalV3.vue'
  import ModalMobile from '@/components/ModalMobile.vue'
  import FacebookModalContent from "@/components/FacebookModalContent";
  import PartnerRepAprModel from "@/components/PartnerRepAprModal";
  import PartnerRepAprModelV3 from "@/components/PartnerRepAprModelV3";
  import PartnerRepAprModelMobile from  "@/components/PartnerRepAprModelMobile";
  import PartnerTransitionModal from '@/components/PartnerTransitionModal';
  import {LocationHelper, RequestHelper, AffiliatesHelper} from '@/helpers';
  import { AFFILIATES } from '@/factories';
  import {FullApplicationService} from '@/services';
  import HeaderLogo from '@/components/HeaderLogo.vue';
  import VehicleBannerV2 from "../components/VehicleBannerV2";
  import Footer from '@/components/Footer.vue';
  import TrustPilot from '@/components/TrustPilot.vue';

  export default {
    name: 'AppForm',
    components: {
      FacebookModalContent,
      Modal,
      PartnerRepAprModel,
      PartnerTransitionModal,
      HeaderLogo,
      ModalV3,
      PartnerRepAprModelV3,
      PartnerRepAprModelMobile,
      ModalMobile,
      VehicleBannerV2,
      Footer,
      TrustPilot
    },
    data() {
      return {
        displayModal: false,
        partnerTransitionModal: false,
        showInterstitial: false,
        useModelV3: false,
        testB: false,
        showBanner: false,
        bannerMessage: '',
        isPartner: false
      }
    },
    computed: {
      host() {
        return this.$store.getters.host
      },
      partnerName() {
        return AffiliatesHelper.getPartnerName(this.$store.state);
      }
    },
    methods: {
      closeModal() {
        this.displayModal = false;
        this.partnerTransitionModal = false;
      },
      sendFullApp(state) {
        FullApplicationService.sendFullApp(this, state, this.$store)
      },
      setInterstitial(data) {
        this.showInterstitial = data;
      }
    },
    mounted() {
      let host = LocationHelper.getHost();

      if (AFFILIATES[host] !== undefined){
        const affiliateConfig = AFFILIATES[host];

        if (affiliateConfig.showPartnerModal) {
          this.useModelV3 = affiliateConfig.useModelV3;
          this.displayModal = affiliateConfig.showPartnerModal;

          var isPartnerNoOverlay = RequestHelper.getQueryVariable('test') === 'partner-no-overlay';

          if (isPartnerNoOverlay) {
            this.useModelV3 = this.displayModal = false;
          }
        }

        if (affiliateConfig.showPartnerTransitionModal) {
          this.partnerTransitionModal = affiliateConfig.showPartnerTransitionModal;
        }

        this.testB = affiliateConfig.testB ?? false;

        this.isPartner = true;

        if (affiliateConfig.showBanner && affiliateConfig.bannerMessage) {
          this.showBanner = affiliateConfig.showBanner;
          this.bannerMessage = affiliateConfig.bannerMessage;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .app-form-container {
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
  }

  .app-form-main {
    padding: 0 20px 40px;
  }

  .-mb-20 {
    margin-bottom: 20px;
  }

  .trustpilot-container {
    text-align: center;

    img {
      height: 35px;

      @media only screen and (max-width: 600px) {
        height: 30px;
      }

      @media only screen and (max-width: 412px) {
        height: 27px;
      }

      @media only screen and (max-width: 360px) {
        height: 25px;
      }

      @media only screen and (max-width: 320px) {
        height: 25px;
      }
    }
  }

  .modelV3 {
    @media not all and (min-width: 480px) {
      display: none;
    }
  }

  .modelMobile {
    @media only screen and (min-width: 479px) {
      display: none;
    }
  }
</style>