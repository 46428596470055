<template>
  <form @submit.prevent="Submit({ name: 'driving-licence' })">

    <input type="hidden" name="dummy" value="something">

    <h1>Hi! Let’s start with the basics, what’s your first name?</h1>

    <InputText
        :id="idConcat(pageName, 'FirstName')"
        name="form.firstName"
        label="First name"
        autocomplete="off"
        v-model="$v.firstname.$model"
        :v="$v.firstname"
        :sensitive-data="true"
    />

    <IconButtonContinue :id="idConcat(pageName, 'IconContinueBtn')" :sub-label="$t('page.finance_details.button_continue.sub_label')" :label="isClassifyPartner ? 'Continue' : 'Proceed'" />

    <div style="font-size: 0.9em; text-align: center;" v-html="$t('page.finance_details.message.label')"></div>
    <PrivacyBanner :id="idConcat(pageName, 'PrivacyBannerHref')" :label="$t('page.finance_details.privacy_banner.label')" />
    <div style="font-size: 0.9em; text-align: center;" v-html="$t('page.finance_details.trade.label')"></div>
    <PowerByCf247Text />
    <RepExample></RepExample>
    <DisclosureStatement/>
    <div v-show="showAppDuration" class="app-duration">
        <div>
            <img src="@/assets/img/checked_1.png" width="20" class="checked" />
        </div>
        <div>
            Your no-obligation quote is just 1 minute away!
        </div>
    </div>

    <InfoFooter />
  </form>

</template>

<script>
import {required} from 'vuelidate/lib/validators'
import RepExample from "@/components/RepExample";
import DisclosureStatement from '@/components/DisclosureStatement';
import PowerByCf247Text from '@/components/PowerByCf247Text';
import {LocationHelper, RequestHelper} from '@/helpers';
import {AFFILIATES} from '@/factories';
import IconButtonContinue from '@/components/IconButtonContinue.vue';
import { SENSITIVE_DATA } from '../const';

export default {
  components: {
    DisclosureStatement,
    RepExample,
    PowerByCf247Text,
    IconButtonContinue,
  },
  data() {
    return {
      pageName: 'ContactDetailsPersonalised',
      vehicleType: this.$store.state.form.vehicleTypeId,
      loanAmount: this.$store.state.form.loanAmount,
      loanAmountUnknown: this.$store.state.loanAmountUnknown,
      hasMonthlyBudget: this.$route.query.monthlyBudget,
      showLoanAmount: this.$store.state.showLoanAmount,
      isMonthlyBudgetAllowed: this.$store.state.isMonthlyBudgetAllowed,
      showVehicleType: true,
      firstname: this.$store.state.form.firstName,
      SENSITIVE_DATA: SENSITIVE_DATA
    }
  },
  validations: {
    firstname: {
      required
    },
  },
  methods: {
    continueBtnClick: function() {
      setTimeout(()=> {
        this.Submit({ name: 'driving-licence' })
      }, 100);
    }
  },
  computed: {
    isClassifyPartner() {
      let host = LocationHelper.getHost();

      if (AFFILIATES[host] !== undefined){
        const affiliateConfig = AFFILIATES[host];

        if (affiliateConfig.classifiedPartner) {
          return affiliateConfig.classifiedPartner;
        }
      }

      return false;
    },
    showAppDuration() {
      let appDuration = true;

      let host = LocationHelper.getHost();

      if (AFFILIATES[host] !== undefined) {
        appDuration = false;
      }

      return appDuration;
    },
  },
  mounted() {
    this.$store.dispatch('updateFormValue', [ this.pageName , 'pageName']);
    
    if( this.$store.state.fromGetMyQuote ) {
      this.isStartPage = false ;
    } else {
      let host = LocationHelper.getHost();
      let vehicle_type = RequestHelper.getQueryVariable('vehicle_type');
      if (vehicle_type) {
        this.isStartPage = true;
      }
      if (AFFILIATES[host] !== undefined) {
        this.isStartPage = true;
      }
    }

    this.$store.dispatch('updateFormValue', [true, 'form.financeDetailsCompleted'])
  }
}
</script>

<style lang="scss" scoped>
  .app-duration {
      display: flex;
      justify-content: center;
      margin-top: 25px;

      div {
        font-size: 1.15em;
        line-height: 30px;
      }
  }
  .checked {
      margin-right: 15px;
  }
</style>