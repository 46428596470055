import axios from 'axios'
import afdFactory from '../factories/afd-factory'
import { URLS } from '../factories'

export default {
    fastPostcodeLookup(state, address) {
        let uniqueId = afdFactory.AfdListQueryParams.uniqueId
        let data = afdFactory.AfdListQueryParams.data
        let task = afdFactory.AfdListQueryParams.task
        let fields = afdFactory.AfdListQueryParams.fields
        let maxQuantity = afdFactory.AfdListQueryParams.maxQuantity
        let format = afdFactory.AfdListQueryParams.format 

        const requestURL = state.isProduction ? URLS.production.afdPostcodeLookup : URLS.replica.afdPostcodeLookup;

        return axios.get(encodeURI(requestURL + '?uniqueid=' + uniqueId + '&Data='+ data + '&Task='+ task + '&Fields=' + fields + '&maxquantity=' + maxQuantity + '&Format=' + format + '&Lookup='+ address + '&Nearest=FastFind'), {timeout: 30000})
    },
    getAddressByKeyAfd(state, key) {
        let data = afdFactory.AfdSingleLookUp.data
        let task = afdFactory.AfdSingleLookUp.task
        let fields = afdFactory.AfdSingleLookUp.fields
        let format = afdFactory.AfdSingleLookUp.format 

        const requestURL = state.isProduction ? URLS.production.afdPostcodeLookup : URLS.replica.afdPostcodeLookup;

        return axios.get(encodeURI(requestURL + '?Data='+ data + '&Task='+ task + '&Fields=' + fields + '&Format=' + format + '&Key='+ key), {timeout: 30000})
    }
}
