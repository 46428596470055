<template>
  <img alt="Car Credible Logo" height="39" v-lazy="imgUrl" />
</template>

<script>
export default {
  name: "CarCredibleLogo",
    data () {
    return {
      imgUrl:  require('@/assets/img/affiliates/carcredible.png')
    }
  }
}
</script>

<style scoped>

</style>