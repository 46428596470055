<template>
<div class="formWrapper">
  <div class="input-container" :class="{ 'small-input': smallInput }">
    <input
        type="text"
        v-model="value"
        :maxlength="maxlength"
        :id="id"
        :name="name"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :class="this.generateClass"
        :disabled="$store.state.lockDown"    >
    <label :for="id" v-if="label" class="labelWrapper" :class="{ 'big-label': bigLabel }"><div class="textlabel">{{ label }}</div></label>
  </div>
  <p class="error" v-if="v.$error">{{ invalidMessage }}</p>
  <p class="small-print" v-if="smallPrint">{{ smallPrint }}</p>
</div>
</template>

<script>
import {SENSITIVE_DATA} from '@/const';

export default {
  props: {
    id:{
      type: String,
      default() {
        return 'input-text-' + this._uid
      }
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    bigLabel: {
        type: Boolean,
        default: false
    },
    smallInput: {
        type: Boolean,
        default: false
    },
    maxlength: {
      type: Number,
      default: 50
    },
    smallPrint: {
        type: String,
        default: ''
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    placeholder: {
      type: String,
      default: ''
    },
    invalidMessage: {
      type: String,
      default() {
        return 'Please enter your '+ this.label.toLowerCase() +'.'
      }
    },
    v: {
      type: Object,
      default: function () { return {} }
    },
    sensitiveData: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    let input_element = document.querySelectorAll("input");
    input_element.forEach(input_array => {
      input_array.setAttribute('value', input_array.value);
      input_array.addEventListener('keyup', () => {
        input_array.setAttribute('value', input_array.value);
        });
        });
  },
  computed: {
    generateClass() {
      let cssClass = { 'error': this.v.$error, 'valid': this.v.$dirty && !this.v.$invalid, 'uppercase': this.uppercase };

      if (this.sensitiveData) {
        SENSITIVE_DATA.p11Mask.forEach((p11Mask) => {
          cssClass[p11Mask] = true;
        });
      }

      return cssClass;
    },
    value: {
      get: function(){
          return _.get(this.$store.state, this.name)
      },
      set: function(newValue) {
        this.$store.dispatch('updateFormValue', [newValue, this.name])
        this.$emit('input', newValue)
        return newValue
      }
    }
  }
}
</script>
<style scoped>
 .small-input input {
      max-width: 100%;
      display: block;
    }
 .uppercase {
      text-transform: uppercase;
    }
  
</style>
