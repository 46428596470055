import {AffiliatesHelper} from '@/helpers/index';

export default {
    enableContactDetailsTwoPages() {
        let breakdownTwoPage = true;

        if (AffiliatesHelper.isAffiliate()) {
            const value = AffiliatesHelper.getPropertyValue('enableContactDetailsTwoPages');
            breakdownTwoPage = value ? value : false;
        }

        return breakdownTwoPage;
    }
}