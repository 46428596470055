import Vue from 'vue'
import Router from 'vue-router'
import { SegmentService } from "./services";
import store from "./store.js";
import { StartPageHelper, ProgressHelper, LocationHelper } from '@/helpers';
import { AFFILIATES } from '@/factories';

Vue.use(Router)

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

const defaultRoute = [
  {
    path: '*',
    redirect: '/'
  } ,
  {
    path: '/',
    name: 'start',
    component: () => StartPageHelper.getStartPageComponent()
  },
  {
    path: '/holding-page',
    name: 'holding-page',
    component: () => import('@/views/Affiliates/Affiliates_Holding_Page.vue')
  },
  {
    path: '/interstitial',
    name: 'interstitial',
    component: () => import('@/views/Affiliates/Affiliates_Interstitial.vue')
  },
  {
    path: '/get-my-quote',
    name: 'get-my-quote',
    component: () => import('@/views/get-my-quote.vue')
  },
  {
    path: '/finance-details',
    name: 'finance-details',
    component: () => import('@/views/finance-details.vue')
  },
  {
    path: '/driving-licence',
    name: 'driving-licence',
    component: () => import('@/views/driving-licence.vue')
  },
  {
    path: '/submission-type',
    name: 'submission-type',
    component: () => import('@/views/submission-type.vue')
  },
  {
    path: '/contact-details-personalised',
    name: 'contact-details-personalised',
    component: () => import('@/views/contact-details-personalised.vue')
  },
  {
    path: '/amount-to-finance',
    name: 'amount-to-finance',
    component: () => import('@/views/amount-to-finance.vue')
  },
  {
    path: '/marital-status',
    name: 'marital-status',
    component: () => import('@/views/marital-status.vue')
  },
  {
    path: '/date-of-birth',
    name: 'date-of-birth',
    component: () => import('@/views/date-of-birth.vue')
  },
  {
    path: '/employment/:number',
    name: 'employment',
    component: () => import('@/views/employment-status.vue')
  },
  {
    path: '/employment-address-add/:number',
    name: 'employment-address-add',
    component: () => import('@/views/employment-address-add.vue')
  },
  {
    path: '/employment-location/:number',
    name: 'employment-location',
    component: () => import('@/views/employment-location.vue')
  },
  {
    path: '/employment-detail/:number',
    name: 'employment-detail',
    component: () => import('@/views/employment-detail.vue')
  },
  {
    path: '/employment-length/:number',
    name: 'employment-length',
    component: () => import('@/views/employment-length.vue')
  },
  {
    path: '/employment-income/:number',
    name: 'employment-income',
    component: () => import('@/views/employment-income.vue')
  },
  {
    path: '/car-finance-detail',
    name: 'car-finance-detail',
    component: () => import('@/views/car-finance-detail.vue')
  },
  {
    path: '/bank-detail',
    name: 'bank-detail',
    component: () => import('@/views/bank-detail.vue')
  },
  {
    path: '/bank-length',
    name: 'bank-length',
    component: () => import('@/views/bank-length.vue')
  },
  {
    path: '/address-add/:number',
    name: 'address-add',
    component: () => import('@/views/address-add.vue')
  },
  {
    path: '/address-status/:number',
    name: 'address-status',
    component: () => import('@/views/address-status.vue')
  },
  {
    path: '/address-length/:number',
    name: 'address-length',
    component: () => import('@/views/address-length.vue')
  },
  {
    path: '/applicant-name',
    name: 'applicant-name',
    component: () => import('@/views/applicant-name.vue')
  },
  {
    path: '/contact-details',
    name: 'contact-details-optimised',
    component: () => import('@/views/contact-details-optimised.vue')
  },
  {
    path: '/contact-details-p1',
    name: 'contact-details-p1',
    component: () => import('@/views/contact-details-p1.vue')
  },
  {
    path: '/contact-details-p2',
    name: 'contact-details-p2',
    component: () => import('@/views/contact-details-p2.vue')
  },
  {
    path: '/contact-details',
    name: 'contact-details',
    component: () => import('@/views/contact-details.vue')
  },
  {
    path: '/thank-you',
    name: 'thank-you',
    component: () => import('@/views/thank-you.vue')
  },
];

const router = new Router({
  mode: 'history',
  routes: defaultRoute,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.afterEach( (to) => {
  SegmentService.trackPage(store.state,  store.state.GUID, to.name );
  ProgressHelper.changeProgressByStage(to.name);
});

router.beforeEach( (to, from, next) => {
  let goToNextPage = true;
  let host = LocationHelper.getHost();

  if (AFFILIATES[host] !== undefined && to.name != 'holding-page') {
    const affiliateConfig = AFFILIATES[host];
    
    if ( process.env.NODE_ENV === "production" ) {
      if (affiliateConfig && typeof affiliateConfig.underDevelopmentProd !== "undefined" && affiliateConfig.underDevelopmentProd) {
        goToNextPage = false;
        next('/holding-page');
      }
    } else {
      if (affiliateConfig && typeof affiliateConfig.underDevelopmentDev !== "undefined" && affiliateConfig.underDevelopmentDev) {
        goToNextPage = false;
        next('/holding-page');
      }
    }
  }
  
  if(goToNextPage) {
    if(!hasQueryParams(to) && hasQueryParams(from) ){
      const targetPage = Object.assign({}, to);
      targetPage.query = from.query;
      next(targetPage);
    } else {
      next()
    }
  }
})

export default router
